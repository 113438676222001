import { Button } from "reactstrap";

const SaveBeforeChange = ({
  tabName,
  onYes,
  onNo,
}: {
  tabName?: string;
  onYes?: () => void;
  onNo?: () => void;
}) => {
  return (
    <>
      <div>
        <h5>Do you want to save the {tabName} changes before switching tabs?</h5>
      </div>
      <Button
        type="button"
        color="danger"
        className="mr-2 mt-3 popover_btn"
        onClick={onYes}
      >
        Yes
      </Button>
      <Button
        type="button"
        color="primary"
        className="mt-3"
        onClick={onNo}
      >
        No
      </Button>
    </>
  );
};

export default SaveBeforeChange;
