import React, { useEffect, useState } from 'react';
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import GeneralSettings from './SPOPGeneralSettings';
import CargoDischarging from './CargoDischarging';
import CargoCooling from './CargoCooling';
import CargoHeating from './CargoHeating';
import TankCleaning from './TankCleaning';
import ReeferContainer from './ReeferContainer';
import CargoReliquification from './CargoReliquification';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { SpOpsConfigurationConstant, VesselConfigrationTabs, VesselTypeConstant } from 'shared/constants';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadSpecialOperationSettingList } from 'VesselMaster/vesselMaster.hooks'
import PopUp from 'Components/PopUp';
import SaveBeforeChange from 'VesselMaster/SaveBeforeChange';
import { FormikProps } from 'formik';

interface SpecialOperationsType {
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
    setCheckValuesBeforeSwitch: (value: boolean) => void;
    setTabName: (value: string) => void;
    setFormik: (value: FormikProps<any>) => void;
    formik: FormikProps<any>;
    VesselConfActiveTab: number;
}

const SpecialOperations = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
    setCheckValuesBeforeSwitch,
    setTabName,
    setFormik,
    formik,
    VesselConfActiveTab,
}: SpecialOperationsType) => {
    /** Retrieves the VesselID and Vessels data from the Redux store. */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    /** State to track the currently active tab in the settings interface. */
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');

    /** Finds the current vessel based on the VesselID. */
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /** States to manage the visibility of special operation tabs. */
    const [cargoDischarging, setCargoDischarging] = useState(false);
    const [cargoHeating, setCargoHeating] = useState(false);
    const [cargoCooling, setCargoCooling] = useState(false);
    const [refeerContainer, setReeferContainer] = useState(false);
    const [tankCleaning, setTankCleaning] = useState(false);
    const [cargoReliquification, setCargoReliquification] = useState(false);
    const [count, setCount] = useState(0);
    const [checkVesselDataValuesBeforeSwitch, setCheckVesselDataValuesBeforeSwitch] = useState(false);
    const [vesselDataTabName, setVesselDataTabName] = useState("");
    const [state, setState] = useState(false);
    const [nextTab, setNextTab] = useState<number | null>(null);
    const [tabFormik, setTabFormik] = useState<FormikProps<any>>(null);

    /**
     * Handles the tab change for special operations. It only updates the active tab
     * if the new tab is different from the current one, also increments the count state.
     * 
     * @param {string} tab - The tab identifier for switching views.
     */
    // const toggle = (tab: any) => {
    //     if (checkVesselDataValuesBeforeSwitch === true) {
    //         if (activeSettingsTab !== tab) {
    //             setCount(count + 1);
    //             setActiveSettingsTab(tab);
    //         }
    //     } else {
    //         setTabName(vesselDataTabName);
    //         setState(true);
    //     }
    // };

    // const handleSaveAndSwitchTab = async (formik: any) => {
    //     await formik.submitForm();
    //     formik.initialValues = formik?.values
    //     formik.resetForm({ values: formik.values });
    //     setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
    //     if (nextTab !== null) {
    //         setActiveSettingsTab(nextTab as any); // Switch to the next tab
    //         setNextTab(null); // Reset the next tab state
    //     }
    //     setState(false); // Close the popup
    // };

    // const handleNoSaveAndSwitchTab = () => {
    //     setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
    //     if (nextTab !== null) {
    //         setActiveSettingsTab(nextTab as any); // Switch to the next tab
    //         setNextTab(null); // Reset the next tab state
    //     }
    //     setState(false); // Close the popup
    // };

    useEffect(()=>{
            if(VesselConfActiveTab === VesselConfigrationTabs.SPECIAL_OPERATIONS && tabFormik?.values !== tabFormik?.initialValues){ 
                setFormik(tabFormik);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
        },[activeSettingsTab, VesselConfActiveTab, tabFormik])

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            if (checkVesselDataValuesBeforeSwitch) {
                setCount(count + 1);
                setActiveSettingsTab(tab);
            } else {
                setNextTab(tab);
                setState(true);
            }
        }
    };

    const handleSaveAndSwitchTab = async (formik: any) => {
        if (formik) {
            await formik.submitForm();
            formik.initialValues = formik.values;
            formik.resetForm({ values: formik.initialValues });
        }
        setCheckValuesBeforeSwitch(true);
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab as any);
            setNextTab(null);
        }
        setState(false);
    };

    const handleNoSaveAndSwitchTab = (formik:any) => {
        formik.resetForm({ values: formik.values });
        setCheckValuesBeforeSwitch(true);
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab as any);
            setNextTab(null);
        }
        setState(false);
    };

    /**
     * Fetches the general settings object for the vessel's special operations from the backend.
     * Uses the vessel's ID as a parameter to load the corresponding settings.
     */
    const { data: generalSettingObj }: { data: any } = useQuery(
        [queryKeyes.vessel.SpecialOperationSettingList.key, VesselID],
        async () => {
            return await loadSpecialOperationSettingList(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    return (
        <>
            <PopUp
                state={state}
                setState={setState}
                body={
                    <SaveBeforeChange
                        tabName={vesselDataTabName}
                        onYes={() => handleSaveAndSwitchTab(tabFormik)} // Save and switch
                        onNo={()=>handleNoSaveAndSwitchTab(tabFormik)} // Discard and switch
                    />
                }
                title="Alert"
                size="lg"
            />
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified' key={refreshVesselMachineries}>
                    <NavItem key={refreshVesselMachineries}>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsGeneralSettingsId,
                            })}
                            onClick={() => {
                                setFormik(null);
                                setTabFormik(null);
                                toggle(SpOpsConfigurationConstant.SpOpsGeneralSettingsId);
                            }}
                        >
                            General Settings
                        </NavLink>
                    </NavItem>
                    {vessel && vessel?.vessel_type === VesselTypeConstant.TANKER &&
                        <>
                            {(cargoHeating || (generalSettingObj && generalSettingObj[0]?.cargo_heating)) &&
                                <NavItem key={refreshVesselMachineries}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoHeatingId,
                                        })}
                                        onClick={() => {
                                            setFormik(null);
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoHeatingId);
                                        }}
                                    >
                                        Cargo Heating
                                    </NavLink>
                                </NavItem>}
                            {(cargoDischarging || (generalSettingObj && generalSettingObj[0]?.cargo_discharging)) &&
                                <NavItem key={refreshVesselMachineries}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoDischargingId,
                                        })}
                                        onClick={() => {
                                            setFormik(null);
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoDischargingId);
                                        }}
                                    >
                                        Cargo Discharging
                                    </NavLink>
                                </NavItem>
                            }
                            {(tankCleaning || (generalSettingObj && generalSettingObj[0]?.tank_cleaning)) &&
                                <NavItem key={refreshVesselMachineries}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsTankCleaningId,
                                        })}
                                        onClick={() => {
                                            setFormik(null);
                                            toggle(SpOpsConfigurationConstant.SpOpsTankCleaningId);
                                        }}
                                    >
                                        Tank Cleaning
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                    {vessel && (vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER ||
                        vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) &&
                        <>
                            {(cargoCooling || (generalSettingObj && generalSettingObj[0]?.cargo_cooling)) &&
                                <NavItem key={refreshVesselMachineries}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoCoolingId,
                                        })}
                                        onClick={() => {
                                            setFormik(null);
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoCoolingId);
                                        }}
                                    >
                                        Cargo Cooling
                                    </NavLink>
                                </NavItem>}
                            {(cargoReliquification || (generalSettingObj && generalSettingObj[0]?.cargo_reliquification)) &&
                                <NavItem key={refreshVesselMachineries}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoReliquificationId,
                                        })}
                                        onClick={() => {
                                            setFormik(null);
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoReliquificationId);
                                        }}
                                    >
                                        Cargo Reliquification
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                    {vessel && (vessel?.vessel_type === VesselTypeConstant.CONTAINER ||
                        vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                        <>
                            {(refeerContainer || (generalSettingObj && generalSettingObj[0]?.reefer_container)) &&
                                <NavItem key={refreshVesselMachineries}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsReeferContainerId,
                                        })}
                                        onClick={() => {
                                            setFormik(null);
                                            setTabFormik(null);
                                            toggle(SpOpsConfigurationConstant.SpOpsReeferContainerId);
                                        }}
                                    >
                                        Reefer Container
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsGeneralSettingsId}>
                    <GeneralSettings
                        setCargoDischarging={setCargoDischarging}
                        setCargoHeating={setCargoHeating}
                        setCargoCooling={setCargoCooling}
                        setReeferContainer={setReeferContainer}
                        setCargoReliquification={setCargoReliquification}
                        setTankCleaning={setTankCleaning}
                        count={count}
                        setCount={setCount}
                        refreshVesselMachineries={refreshVesselMachineries}
                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoDischargingId}>
                    <CargoDischarging
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoCoolingId}>
                    <CargoCooling
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoHeatingId}>
                    <CargoHeating
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsTankCleaningId}>
                    <TankCleaning
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsReeferContainerId}>
                    <ReeferContainer
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoReliquificationId}>
                    <CargoReliquification
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        activeTab={activeSettingsTab}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
            </TabContent>
        </>
    )
}

export default SpecialOperations;