import CIIAdjustmentFileUpload, { event } from 'Components/CIIAdjustmentFileUpload';
import DeletePopOver from 'Components/DeletePopOver';
import PopOver from 'Components/PopOver';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import ToolTip from 'Components/ToolTip';
import React, { useEffect, useState } from 'react'
import { Row, Col, Button, CardBody, Card, CardHeader, CardFooter } from 'reactstrap';
import CIIAdjustmentModal from './CIIAdjustmentModal';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { loadCIIAdjustmentEvents, loadCIIEventsList } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { queryClient } from 'react-query/queryClient';
import Loading from 'Components/Loading';
import { AxiosResponse } from 'axios';

interface CIIAdjustmentComponentType {
    record: { [key: string]: string | number | Date | boolean },
    VesselID: number,
    ReportID: number,
    activeTab: number,
    toggleTab: (value: number) => void
}

const CIIAdjustmentComponent = ({
    record,
    VesselID,
    ReportID,
    activeTab,
    toggleTab
}: CIIAdjustmentComponentType) => {
    /** State variables */
    const [CIIStartBool, setCIIStartBool] = useState(false);
    const [CIIEndBool, setCIIEndBool] = useState(false);
    const [CIIUploadBool, setCIIUploadBool] = useState(false);
    const [CIIResumeBool, setCIIResumeBool] = useState(false);
    const [filteredCIIEventsList, setFilteredCIIEventsList] = useState<Array<{ [key: string]: string | number | Date | boolean }>>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleNextClick = async () => {
        setIsSubmitting(true); // Start submission process
        try {
            // Simulate an API call or any action before navigating to the next tab
            // await new Promise((resolve) => setTimeout(resolve, 1000)); // Replace with actual logic
            toggleTab(activeTab + 1); // Navigate to the next tab
        } catch (error) {
            console.error("Error during submission:", error);
        } finally {
            setIsSubmitting(false); // End submission process
        }
    };

    const [eventState, setEventState] = useState<event>({
        id: 0,
        cii_adjustment: 0,
        vessel_reporting_cii_adjustment: 0,
        cii_adjustment_name: ''
    });
    const [popoverState, setPopoverState] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    /** State variables end */

    /** useQuery start */
    /** get CII adjustment Vessel Reporting */
    const {
        data: CIIAdjustmentEvents,
        isLoading: CIIAdjustmentEventsLoading,
    } = useQuery(
        [queryKeyes.vessel.VesselReportingCIIAdjusment.key, VesselID],
        async () => {
            return await loadCIIAdjustmentEvents(VesselID);
        },
        { staleTime: Infinity }
    );

    /** get CII adjusment events */
    const {
        data: CIIEvents,
        isLoading: CIIEventsLoading,
    } = useQuery(
        [queryKeyes.vessel.CIIEventsSupportingFileData.key, VesselID],
        async () => {
            return await loadCIIEventsList();
        },
        { staleTime: Infinity }
    );
    /** useQuery end */

    /** filter CIIEvents */
    useEffect(() => {
        CIIAdjustmentEvents && CIIAdjustmentEvents.length > 0 &&
            setFilteredCIIEventsList(
                CIIAdjustmentEvents.filter((item: { [key: string]: string | number | Date | boolean }) =>
                    item.cii_begin_date_time <= record?.reporting_time_utc &&
                    (item.cii_end_date_time >= record?.reporting_time_utc || item.cii_end_date_time === null)))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CIIEvents, CIIAdjustmentEvents]);
    /** useEffect end */

    /** Functions to open-close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const togBackDropCIIStart = () => {
        setCIIStartBool(!CIIStartBool);
        loadCIIAdjustmentEvents(VesselID);
        removeBodyCss();
    }

    const togBackDropCIIEnd = (event: event) => {
        setEventState(event)
        setCIIEndBool(!CIIEndBool);
        removeBodyCss();
    }

    const togBackDropCIIUpload = (event: event) => {
        setEventState(event)
        setCIIUploadBool(!CIIUploadBool)
        loadCIIAdjustmentEvents(VesselID);
        removeBodyCss();
    }

    const togBackDropCIIResume = (event: event) => {
        setEventState(event)
        loadCIIAdjustmentEvents(VesselID);
        setCIIResumeBool(!CIIResumeBool);
        removeBodyCss();
    }

    const togDeletePopover = (event: event) => {
        setEventState(event)
        setPopoverState(!popoverState);
        removeBodyCss();
    }

    /** Extra actions post request success */
    const handleResponse = (response: AxiosResponse<{ [key: string]: string | number | boolean | Date }>) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        }
    }

    /** Function to end currently ongoing adjustment event */
    const endCIIAdjustmentEvent = async (
        CIIData: { [key: string]: string | number | Date | boolean },
        CIIEvents: { [key: string]: string | number | Date | boolean }[],
        reportId: number,
        record: { [key: string]: string | number | Date | boolean }
    ) => {
        CIIData.cii_adjustment_end_event = CIIEvents?.filter((item: { [key: string]: string | number | Date | boolean }) =>
            item?.cii_adjustment === CIIData?.cii_adjustment && item.id !== CIIData.cii_adjustment_begin_event)
            ?.map((endEvent: { [key: string]: string | number | Date | boolean }) => { return endEvent.id })[0];
        CIIData.cii_end_date_time = record.reporting_time_utc;
        CIIData.cii_end_report = reportId;
        CIIData.cii_adjustment_status = false;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_cii_adjustment/${CIIData.id}/`, CIIData);
            if (response.data) {
                setCIIEndBool(!CIIEndBool);
            }
            return response.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    /** Function to resume previously ended adjustment event */
    const resumeCIIEndEvent = async (event: { [key: string]: string | number | Date | boolean }) => {
        event.cii_adjustment_end_event = null;
        event.cii_end_report = null;
        event.cii_end_date_time = null;
        event.cii_adjustment_status = true;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_cii_adjustment/${event.id}/`, event);
            if (response.data) {
                handleResponse(response);
                setCIIResumeBool(false);
                queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingCIIAdjusment.key)
            }
            return response.data;
        } catch {
            return errorToast("Internal error occured, please contact the admin");
        }
    }

    /** Function to delete CII Adjustment event */
    const handleAdjustmentEventDelete = (id: number) => {
        apiGlobal.delete(`/vessel_reporting_cii_adjustment/${id}/`).then(res => {
            if (res.status === 200 || res.status === 204) {
                successToast("Data deleted successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingCIIAdjusment.key);
                setPopoverState(false);
            }
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    return (
        <React.Fragment>
            {CIIAdjustmentEventsLoading && <Loading message='Loading required data!' />}
            {CIIEventsLoading && <Loading message='Loading required data!' />}
            <Card className='p-0 mb-0 border-0'>
                <CardHeader className='p-2'>
                    <div className="text-center">
                        <Row>
                            <Col>
                                <h4 className="page_title pos-start mb-0">CII Adjustment Events</h4>
                                <p className="card-title-desc pos-start">All readings since last report</p>
                            </Col>
                            <Col>
                                <ReportDetailsHeader />
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                <CardBody className='deck-card-body mt-2'>
                    <Row className='mb-2'>
                        <Col lg={12}>
                            <Button
                                color='primary'
                                className='btn btn-primary justify_right'
                                onClick={togBackDropCIIStart}>
                                <i className="dripicons-plus font-size-16 align-middle me-2" />
                                Create New
                            </Button>
                            <CIIAdjustmentModal
                                state={CIIStartBool}
                                setState={setCIIStartBool}
                                ongoingEvents={CIIAdjustmentEvents && CIIAdjustmentEvents.length > 0 && CIIAdjustmentEvents?.filter((item: { [key: string]: string | number | Date | boolean }) =>
                                    item.cii_adjustment_status === true)}
                                record={record}
                                loadCIIAdjustments={loadCIIAdjustmentEvents}
                                reportId={ReportID}
                                vesselId={VesselID}
                                adjustmentEvents={CIIAdjustmentEvents && CIIAdjustmentEvents.length > 0 && CIIAdjustmentEvents?.filter((item: { [key: string]: string | number | Date | boolean }) =>
                                    item.cii_begin_date_time <= record?.reporting_time_utc &&
                                    (item.cii_end_date_time >= record?.reporting_time_utc || item.cii_end_date_time === null))}
                                setRefreshKey={setRefreshKey}
                                refreshKey={refreshKey}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {filteredCIIEventsList.length > 0 ?
                            <div className="table-responsive">
                                <table className="table mb-2"
                                    key={refreshKey}>
                                    <thead className="table-light">
                                        <tr>
                                            <th className='p-2 align-middle sr-no-width'>#</th>
                                            <th className='p-2 align-middle' style={{ width: '20%' }}>Current CII adjustment event(s)</th>
                                            <th className="p-2 align-middle text-center" style={{ width: '15%' }}>Start time</th>
                                            <th className='p-2 align-middle text-center' style={{ width: '15%' }}>End Time</th>
                                            <th className='p-2 align-middle text-center' style={{ width: '15%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredCIIEventsList.map((event: { [key: string]: string | number | Date | boolean } | event, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                    <td className='p-2 align-middle'>{event.cii_adjustment_name as string}</td>
                                                    <td className="p-2 align-middle text-center">
                                                        {(event as { [key: string]: string | number | Date | boolean })
                                                            ?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                        {(event as { [key: string]: string | number | Date | boolean })
                                                            ?.cii_begin_date_time?.toString().substring(11, 16)}
                                                    </td>
                                                    <td className="p-2 align-middle text-center">
                                                        {(event as { [key: string]: string | number | Date | boolean })
                                                            ?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                        {(event as { [key: string]: string | number | Date | boolean })
                                                            ?.cii_end_date_time?.toString().substring(11, 16)}
                                                    </td>
                                                    <td className='p-2 align-middle text-center'>{
                                                        <React.Fragment>
                                                            {(event as { [key: string]: string | number | Date | boolean })
                                                                ?.cii_begin_date_time && (event as { [key: string]: string | number | Date | boolean })
                                                                    ?.cii_begin_date_time === record?.reporting_time_utc &&
                                                                <React.Fragment>
                                                                    <i
                                                                        className='dripicons-trash icon_s18'
                                                                        id={`delete_cii_adjustment-${event?.id}`}
                                                                        onClick={() => togDeletePopover(event as event)}
                                                                    />
                                                                    <ToolTip target={`delete_cii_adjustment-${event?.id}`} message={`Delete ${event.cii_adjustment_name}`} />
                                                                </React.Fragment>
                                                            }
                                                            {(!(event as { [key: string]: string | number | Date | boolean })
                                                                ?.cii_end_date_time && (event as { [key: string]: string | number | Date | boolean })
                                                                    ?.cii_begin_date_time !== record?.reporting_time_utc &&
                                                                <React.Fragment>
                                                                    <Button type="button" color="danger"
                                                                        id={`end-event-button-${event?.id}`}
                                                                        onClick={() => togBackDropCIIEnd(event as event)}>End</Button>
                                                                </React.Fragment>
                                                            )}
                                                            {((event as { [key: string]: string | number | Date | boolean })?.cii_end_date_time &&
                                                                <React.Fragment>
                                                                    <ToolTip target='cii_documents' message="Upload CII documents" />
                                                                    <ToolTip target={`cii_event_resume-${event?.id}`} message="Resume event" />
                                                                    <i
                                                                        className="ml-1 dripicons-upload icon_s18 pointer"
                                                                        onClick={() => togBackDropCIIUpload(event as event)}
                                                                        id='cii_documents'
                                                                    />
                                                                    <i
                                                                        className="ml-1 bx bx-undo icon_s23 pointer"
                                                                        onClick={() => togBackDropCIIResume(event as event)}
                                                                        id={`cii_event_resume-${event?.id}`}
                                                                    />
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                                {eventState && eventState?.id as number > 0 &&
                                    <React.Fragment>
                                        <CIIAdjustmentFileUpload
                                            state={CIIUploadBool}
                                            event={eventState}
                                            togBackDropCIIUpload={togBackDropCIIUpload}
                                        />
                                        {popoverState === true &&
                                            <DeletePopOver
                                                target={`delete_cii_adjustment-${eventState?.id}`}
                                                onClick={() => handleAdjustmentEventDelete(eventState?.id)}
                                                state={popoverState}
                                                setState={setPopoverState}
                                                message={`Are you sure you want to delete ${eventState?.cii_adjustment_name} event?`}
                                            />
                                        }
                                        {CIIResumeBool === true &&
                                            <PopOver
                                                target={`cii_event_resume-${eventState?.id}`}
                                                handleClick={() => resumeCIIEndEvent(eventState as unknown as { [key: string]: string | number | Date | boolean })}
                                                message={`Are you sure you want to resume ${eventState?.cii_adjustment_name} event?`}
                                                state={CIIResumeBool}
                                                setState={setCIIResumeBool}
                                            />
                                        }
                                        {CIIEndBool &&
                                            <PopOver
                                                target={`end-event-button-${eventState?.id}`}
                                                handleClick={() => endCIIAdjustmentEvent(eventState as unknown as { [key: string]: string | number | Date | boolean }, CIIEvents, ReportID, record)}
                                                message={`Are you sure you want to end ${eventState?.cii_adjustment_name} event?`}
                                                state={CIIEndBool}
                                                setState={setCIIEndBool}
                                            />
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            : <strong className="text-center mb-2">Currently no CII adjustment events are in progress</strong>
                        }
                    </Row>
                </CardBody>
                <CardFooter className='p-2 py-3'>
                    <Row className="ele_row1">
                        <div className="d-flex flex-wrap gap-5">
                            <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                handleNextClick();
                            }}
                                disabled={isSubmitting}
                            >
                                {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                Next <i className="bx bx-chevron-right ms-1" />
                            </Button>
                            <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                toggleTab(activeTab - 1);
                            }}><i className="bx bx-chevron-left me-1" /> Previous
                            </Button>
                        </div>
                    </Row>
                </CardFooter>
            </Card>
        </React.Fragment>
    )
}

export default CIIAdjustmentComponent