import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import VesselReportingInfo from './VesselReportingInfo';
import CreateVesselReport from './CreateVesselReport';

const EditVesselReport = () => {
    const { VesselState, ReportID } = useSelector((state: RootState) => state.Reporting);

    return (
        <React.Fragment>
            {VesselState === 'EDIT' ?
                <CreateVesselReport reportId={ReportID} /> :
                VesselState === 'VESSEL_REPORTING' && <VesselReportingInfo />
            }
        </React.Fragment>
    )
}

export default EditVesselReport