import apiGlobal from '../../../global/api.global';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import PopUp from 'Components/PopUp';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadMachineryFuelGroup, loadVesselMachineries } from 'VesselMaster/vesselMaster.hooks';
import EditFuelConsumerGroup from './EditGroup';
import { successToast } from 'Components/Toasts';
import DynamicGroupForm from './AddNewGroup';
import PopOver from 'Components/PopOver';
import ToolTip from 'Components/ToolTip';
import Loading from 'Components/Loading';
import { queryClient } from 'react-query/queryClient';
import { isConfigurationButtonDisabled } from 'GenericForms/Helper';
import EditIcon from '../../../Media/ED2.png';

interface FuelConsumerType {
    refreshVesselMachineries?: number;
    setRefreshVesselMachineries?: (value: number) => void;
    activeSettingsTab?: number;
    refreshInnerTables?: boolean;
}

/**
 * FuelConsumer Component
 *
 * This component displays and manages a list of vessel machineries
 * that are fuel consumers. Users can:
 * - Add new fuel consumer groups.
 * - Edit existing fuel consumer groups.
 * - Delete existing fuel consumer groups.
 *
 * State Management:
 * - Handles UI states such as modals, tooltips, and popovers.
 * - Fetches and updates machinery data dynamically using React Query.
 */
const FuelConsumer = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
    activeSettingsTab,
    refreshInnerTables,
}: FuelConsumerType) => {
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [state, setState] = useState(false);
    const [editFuelConsumerGroup, setEditFuelConsumerGroup] = useState(false);
    const [selectedMachinery, setSelectedMachinery] = useState(null);
    const fcGroupURL = queryKeyes.vessel.MachinaryFuelGroup.url;
    const [deletePopOverState, setDeletePopOverState] = useState(false);
    const [indexOfVesselMachinery, setIndexOfVesselMachinery] = useState<number | null>(0);
    const [openPopoverVesselMachinaryObject, setOpenPopoverVesselMachinaryObject] = useState<any | null>([]);
    const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const [hoverEditId, setEditHoverId] = useState<string | null>(null);

    /** useEffect to close the popover when the tab is changed*/
    useEffect(() => {
        setOpenPopoverIndex(null);
        setIndexOfVesselMachinery(0);
        setDeletePopOverState(false);
        if (!(openPopoverIndex === indexOfVesselMachinery && deletePopOverState === false)) {
            setIndexOfVesselMachinery(null);
        }
        queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSettingsTab, refreshInnerTables])

    /** Queries */
    /**
     * Fetches the list of vessel machineries for a given vessel.
     * This query is performed using React Query and utilizes caching.
     */
    const {
        data: vesselMachineriesList,
        isLoading: vesselMachineriesListLoading,
        isError: vesselMachineriesListError,
    }: { data: any, isLoading: any, isError: any } = useQuery(
        [queryKeyes.vessel.VesselMachineries.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
    );
    /** Machinery fuel Group */
    const {
        data: machineryFuelGroup,
        isLoading: machineryFuelGroupLoading,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroupByVesselId.key, VesselID],
        async () => {
            return await loadMachineryFuelGroup(VesselID);
        },
    );

    /**
         * Deletes a specified fuel consumer group from the server.
         * @param obj - Object containing information about the group to delete.
         */
    const handleDelete = async (obj: any) => {
        try {
            apiGlobal.delete(`/vessel_machinery_fc_group/${obj.id}/`, obj);
            await queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
            successToast('Group deleted successfully');
            setRefreshVesselMachineries(refreshVesselMachineries + 1);
        } catch (err) {
            console.error('Error:', err);
        }
    };
    return (
        <React.Fragment>
            {machineryFuelGroupLoading && <Loading message='Loading required data!' />}
            {!machineryFuelGroupLoading &&
                <React.Fragment>
                    {/* Header with "Add New Group" Button */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="navbar_menu mb-0">Fuel Consumer Machinery List</h4>
                        <button
                            className="btn btn-primary waves-effect waves-light mb-1"
                            onClick={() => {
                                setState(true);
                                setDeletePopOverState(false)
                            }}
                            disabled={isConfigurationButtonDisabled(vessel)}
                        >
                            <i className="dripicons-plus font-size-16 align-middle me-2"></i>
                            New Group
                        </button>
                    </div>
                    {/* Add New Group Popup */}
                    <PopUp
                        state={state}
                        setState={setState}
                        body={
                            <DynamicGroupForm
                                group='fuel'
                                VesselID={VesselID}
                                machineryList={vesselMachineriesList}
                                refreshState={state}
                                setRefreshState={setState}
                                isLoading={vesselMachineriesListLoading}
                                isError={vesselMachineriesListError}
                                groupURL={fcGroupURL}
                                refreshVesselMachineries={refreshVesselMachineries}
                                setRefreshVesselMachineries={setRefreshVesselMachineries}
                            />
                        }
                        title="Add New Fuel Consumer"
                        size="lg"
                    />
                    {/* Table of Vessel Machineries */}
                    <div className="table-responsive mb-3">
                        <table className="table" key={refreshVesselMachineries}>
                            <thead className="table-light">
                                <tr>
                                    <th className="p-2 align-middle sr-no-width">#</th>
                                    <th className='p-2 align-middle'>FC Group Name</th>
                                    <th className="p-2 align-middle">Machinery List</th>
                                    <th className="p-2 align-middle text-center w-20">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineryFuelGroup && machineryFuelGroup?.length > 0 ? (
                                    machineryFuelGroup.map((vesselMachinery: any, index: number) => (
                                        <tr key={vesselMachinery.id}>
                                            <td className='p-2 align-middle text-center'>{index + 1}</td>
                                            <td className="p-2 align-middle" >{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                            <td className="p-0 align-middle">
                                                <table className="table m-0">
                                                    <tbody>
                                                        {vesselMachinery?.vessel_machinery_fc_names?.map((item: string) => (
                                                            <tr key={item}>
                                                                <td className="">{item}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="p-2 align-middle text-center">
                                                {/* Edit and Delete Buttons */}
                                                <div className="d-flex justify-content-center align-items-center flex-wrap">
                                                    <button
                                                        type="button"
                                                        className="btn mt-3"
                                                        disabled={isConfigurationButtonDisabled(vessel)}
                                                    >
                                                        <img
                                                            alt="Edit details"
                                                            className='pointer mb-2'
                                                            src={EditIcon}
                                                            height="25"
                                                            id={`Edit_Fc_Vessel_Machinery_${index}`}
                                                            onMouseEnter={() => {
                                                                setEditHoverId(`Edit_Fc_Vessel_Machinery_${index}`)
                                                                setOpenPopoverVesselMachinaryObject(vesselMachinery);
                                                            }}
                                                            onMouseLeave={() => setEditHoverId(null)}
                                                            onClick={() => {
                                                                setSelectedMachinery(vesselMachinery);
                                                                setEditFuelConsumerGroup(true);
                                                                setEditHoverId(null);
                                                                setDeletePopOverState(false)
                                                            }}
                                                        />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn mt-3"
                                                        id={`Delete_Fc_Vessel_Machinery_${index}`}
                                                        disabled={isConfigurationButtonDisabled(vessel)}
                                                        onMouseEnter={() => {
                                                            setHoverId(`Delete_Fc_Vessel_Machinery_${index}`)
                                                            setOpenPopoverVesselMachinaryObject(vesselMachinery);
                                                        }}
                                                        onMouseLeave={() => setHoverId(null)}
                                                        onClick={() => {
                                                            setDeletePopOverState(true);
                                                            setIndexOfVesselMachinery(index);
                                                            setOpenPopoverVesselMachinaryObject(vesselMachinery);
                                                            setOpenPopoverIndex(index);
                                                            setHoverId(null);
                                                        }}
                                                    >
                                                        <i className="dripicons-trash icon_s18 fs-3"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="text-center">No machineries configured for this vessel</td>
                                    </tr>
                                )}
                                {editFuelConsumerGroup && (
                                    <PopUp
                                        state={editFuelConsumerGroup}
                                        setState={setEditFuelConsumerGroup}
                                        body={
                                            <EditFuelConsumerGroup
                                                group='fuel'
                                                groupName={selectedMachinery.vessel_machinery_fc_group_name}
                                                vesselMachinery={selectedMachinery}
                                                vesselMachineriesList={vesselMachineriesList}
                                                editConsumerGroup={editFuelConsumerGroup}
                                                setEditFuelConsumerGroup={setEditFuelConsumerGroup}
                                                editPutURL={fcGroupURL}
                                                refreshVesselMachineries={refreshVesselMachineries}
                                                setRefreshVesselMachineries={setRefreshVesselMachineries}
                                                state={editFuelConsumerGroup}
                                                setState={setEditFuelConsumerGroup}
                                            />
                                        }
                                        title={`Edit Group: ${selectedMachinery.vessel_machinery_fc_group_name}`}
                                        size="lg"
                                    />
                                )}
                                {openPopoverIndex === indexOfVesselMachinery &&
                                    deletePopOverState === true &&
                                    (<PopOver
                                        target={`Delete_Fc_Vessel_Machinery_${indexOfVesselMachinery}`}
                                        handleClick={() => {
                                            handleDelete(openPopoverVesselMachinaryObject);
                                            setDeletePopOverState(false);
                                            setOpenPopoverIndex(null);
                                        }}
                                        message={`Are you sure you want to delete this group`}
                                        state={deletePopOverState}
                                        setState={setDeletePopOverState}
                                    />)
                                }
                                {hoverId !== null &&
                                    <ToolTip
                                        target={hoverId}
                                        isOpen={hoverId !== null}
                                        message={`Delete ${openPopoverVesselMachinaryObject?.vessel_machinery_fc_group_name} Group?`}
                                    />}
                                {hoverEditId !== null &&
                                    <ToolTip
                                        target={hoverEditId}
                                        isOpen={hoverEditId !== null}
                                        message={`Edit ${openPopoverVesselMachinaryObject?.vessel_machinery_fc_group_name} Group?`}
                                    />}
                            </tbody>
                        </table>
                        <p> - Add To the list, machinery which are fuel consumers .</p>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default FuelConsumer