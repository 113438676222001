import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CSSModule } from 'reactstrap/types/lib/utils';

interface ViewFileInterface {
    isModalOpen: any;
    setIsModalOpen: any;
    modalFile: any;
    setModalFile: any;
    toggleModal: any;
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
}

/**
 * to view files in modal
 * @param modal 
 * @returns modal
 */
const ViewFileModal: (React.FC<ViewFileInterface>) = (modal) => {
    return (
        <>
            {/* Modal for Viewing File */}
            <Modal isOpen={modal?.isModalOpen} toggle={modal?.toggleModal} size="lg">
                <ModalHeader toggle={modal?.toggleModal} className='p-2'>View File</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/** if file is pdf show in iframe else show in img tag */}
                        {modal?.modalFile?.name?.endsWith('.pdf') ? (
                            <div style={{ height: '80vh', width: '100%' }}>
                                <iframe
                                    src={modal?.modalFile?.url}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                        ) : (
                            <img
                                src={modal?.modalFile?.url}
                                alt="Preview"
                                className="img-fluid"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '80vh',
                                    objectFit: 'contain',
                                }}
                            />
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ViewFileModal