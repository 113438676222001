import React, { useEffect, useState } from 'react'
import classnames from "classnames";
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SatelliteCommunication from 'VesselMaster/AdditionalInformation/SatelliteComm';
import VesselGeneralInfo from 'VesselMaster/AdditionalInformation/VesselGeneralInfo/VesselGeneralInfo';
import SaveBeforeChange from 'VesselMaster/SaveBeforeChange';
import PopUp from 'Components/PopUp';
import { FormikProps } from 'formik';
import {VesselConfigrationTabs, VesselDataConstants} from 'shared/constants';
interface VesselDataType {
    setCheckValuesBeforeSwitch: (value: boolean) => void;
    setTabName: (value: string) => void;
    VesselConfActiveTab: any;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
}

const VesselData: React.FC<VesselDataType> = ({ setCheckValuesBeforeSwitch, setTabName, VesselConfActiveTab, setFormik, formik }) => {
    const [activeSettingsTab, setActiveSettingsTab] = useState(VesselDataConstants.VESSEL_GENERAL_INFO);
    const [checkVesselDataValuesBeforeSwitch, setCheckVesselDataValuesBeforeSwitch] = useState(false);
    const [vesselDataTabName, setVesselDataTabName] = useState("");
    const [state, setState] = useState(false);
    const [nextTab, setNextTab] = useState<number | null>(null);
    const [tabFormik, setTabFormik] = useState<FormikProps<any>>(null);

    useEffect(()=>{
        if(VesselConfActiveTab === VesselConfigrationTabs.VESSEL_DATA && tabFormik?.values !== tabFormik?.initialValues){ 
            setFormik(tabFormik);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[activeSettingsTab, VesselConfActiveTab, tabFormik])

    // const toggle = (tab: any) => {
    //     if (checkVesselDataValuesBeforeSwitch === true) {
    //         if (activeSettingsTab !== tab) {
    //             setActiveSettingsTab(tab);
    //         }
    //     } else {
    //         setTabName(vesselDataTabName);
    //         setState(true);
    //     }
    // }

    // const handleSaveAndSwitchTab = async (formik: any) => {
    //     await formik.submitForm();
    //     formik.initialValues = formik?.values
    //     formik.resetForm({ values: formik.values });
    //     setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
    //     if (nextTab !== null) {
    //         setActiveSettingsTab(nextTab as any); // Switch to the next tab
    //         setNextTab(null); // Reset the next tab state
    //     }
    //     setState(false); // Close the popup
    // };

    // const handleNoSaveAndSwitchTab = () => {
    //     setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
    //     if (nextTab !== null) {
    //         setActiveSettingsTab(nextTab as any); // Switch to the next tab
    //         setNextTab(null); // Reset the next tab state
    //     }
    //     setState(false); // Close the popup
    // };

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            if (checkVesselDataValuesBeforeSwitch) {
                setActiveSettingsTab(tab); // Switch immediately
            } else {
                setNextTab(tab); // Store the next tab
                setState(true); // Show confirmation popup
            }
        }
    };
    
    const handleSaveAndSwitchTab = async (formik: any) => {
        if (formik) {
            await formik.submitForm();
            formik.initialValues = formik.values;
            formik.resetForm({ values: formik.initialValues });
        }
        setCheckValuesBeforeSwitch(true);
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab);
            setNextTab(null);
        }
        setState(false);
    };
    
    const handleNoSaveAndSwitchTab = (formik:any) => {
        formik.resetForm({ values: formik.initialValues });
        setCheckValuesBeforeSwitch(true);
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab);
            setNextTab(null);
        }
        setState(false);
    };
    
    return (
        <React.Fragment>
            <PopUp
                state={state}
                setState={setState}
                body={
                    <SaveBeforeChange
                        tabName={vesselDataTabName}
                        onYes={() => handleSaveAndSwitchTab(tabFormik)} // Save and switch
                        onNo={()=>handleNoSaveAndSwitchTab(tabFormik)} // Discard and switch
                    />
                }
                title="Alert"
                size="lg"
            />
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === VesselDataConstants.VESSEL_GENERAL_INFO,
                            })}
                            onClick={() => {
                                setFormik(null);
                                setTabFormik(null);
                                toggle(VesselDataConstants.VESSEL_GENERAL_INFO);
                            }} to={''}
                        >
                            Vessel General Information
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === VesselDataConstants.SATELLITE_COMMUNICATION,
                            })}
                            onClick={() => {
                                setFormik(null);
                                setTabFormik(null);
                                toggle(VesselDataConstants.SATELLITE_COMMUNICATION);
                            }} to={''}
                        >
                            Satellite Communication
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId={VesselDataConstants.VESSEL_GENERAL_INFO} className='m-0'>
                    <VesselGeneralInfo
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab}
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
                <TabPane tabId={VesselDataConstants.SATELLITE_COMMUNICATION}>
                    <SatelliteCommunication
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab}
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        setTabFormik={setTabFormik}
                    />
                </TabPane>
            </TabContent>
        </React.Fragment>
    )
}

export default VesselData