import env from 'environment_system/env_system';
import React, { useState, useMemo } from 'react';
import { Button, Row } from 'reactstrap';
import PopOver from './PopOver';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { Roles, VesselTypeConstant } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from './Toasts';
import { queryClient } from 'react-query/queryClient';
import { queryKeyes } from 'shared/queryKeys';
import { errResponse } from 'GenericForms/Helper';
import ToolTip from './ToolTip';

interface NavigationButtonsProps {
    activeTab: number;
    toggleTab: (tab: number) => void;
    isSubmitting: boolean;
    formId?: number;
    form?: string;
    port?: number;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ activeTab, toggleTab, isSubmitting, formId }) => {
    /** State variables start */
    const { Vessels, VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [verifypopup, setVerifyPopup] = useState<boolean>(false);
    const [verifySubmit, setVerifySubmit] = useState<boolean>(false);
    const [verifyTootltip, setVerifyTooltip] = useState<boolean>(false);
    /** Sate variables end */

    const isLastTab = useMemo(() => {
        return (
            (activeTab === 5 && vessel?.vessel_type !== VesselTypeConstant.LNG_CARRIER) ||
            (activeTab === 6 && vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
        );
    }, [activeTab, vessel?.vessel_type]);

    return (
        <Row className="ele_row1 pe-0">
            <div className="d-flex flex-wrap px-0">
                {isLastTab && hasRole(Roles.ES_ADMIN) && (
                    <React.Fragment>
                        <Button
                            type="submit"
                            id="verify_btn"
                            className="btn_size_cstm ml-55"
                            style={{ order: 2 }}
                            color="primary"
                            onClick={() => {
                                setVerifySubmit(true);
                                apiGlobal.post('verify_onboarding_report/', { 'vessel_id': VesselID }).then(async (res: any) => {
                                    if (res.status === 200) {
                                        successToast("Data saved successfully!");
                                        await queryClient.invalidateQueries(queryKeyes.masters.VesselMaster.key);
                                        setVerifySubmit(false);
                                    }
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                        setVerifySubmit(false);
                                    }
                                })
                            }}
                            onMouseEnter={() => {
                                if (verifySubmit || !(formId && formId > 0)) {
                                    setVerifyTooltip(true);
                                }
                            }}
                            onMouseLeave={() => setVerifyTooltip(false)}
                            disabled={verifySubmit || !(formId && formId > 0)}
                        >
                            {verifySubmit && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />} Verify
                        </Button>
                        {verifyTootltip &&
                            <ToolTip
                                target="verify_btn"
                                message={`Please save the form before verifying`}
                                isOpen={verifyTootltip}
                            />
                        }
                    </React.Fragment>

                )}
                {isLastTab ? (
                    <Button
                        type="submit"
                        color="primary"
                        style={{ order: 3 }}
                        className={`${isSubmitting && 'disabled'} btn_size_cstm ms-auto`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting && (
                            <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                        )}
                        Save
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        color="primary"
                        style={{ order: 3 }}
                        className={`${isSubmitting && 'disabled'} btn_size_cstm ms-auto`}
                        disabled={isSubmitting}
                        onClick={() => {
                            if (env.form_validation === false) {
                                toggleTab(activeTab + 1);
                            }
                        }}
                    >
                        {isSubmitting && (
                            <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                        )}
                        Next <i className="bx bx-chevron-right ms-1" />
                    </Button>
                )}
                {verifypopup && (
                    <PopOver
                        setState={setVerifyPopup}
                        state={verifypopup}
                        target="verify"
                        message="Do you want to verify and import data into the system?."
                    />
                )}
                {/* Previous Button aligned to the start */}
                <Button
                    type="button"
                    color="primary"
                    style={{ order: 1 }}
                    className='btn_size_cstm justify_left'
                    onClick={() => {
                        toggleTab(activeTab - 1);
                    }}
                >
                    <i className="bx bx-chevron-left me-1" /> Previous
                </Button>
            </div>
        </Row>
    );
};

export default NavigationButtons;
