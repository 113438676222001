import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { Field, Formik } from 'formik';
import env from 'environment_system/env_system';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap';
import { VesselMachineryConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import { loadEconAEAdditionalObject, loadVesselMachineriesByMachineType } from 'VesselMaster/vesselMaster.hooks';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import * as Yup from "yup";
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';
import { queryClient } from 'react-query/queryClient';

interface AuxEngineComponentType {
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    toggleAddTab: (value: number) => void,
}

const AuxEngineComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    toggleAddTab,
}: AuxEngineComponentType) => {
    /** State variables start */
    const [refreshKey, setRefreshKey] = useState(0);
    const [tableRefreshKey, setTableRefreshKey] = useState(0);
    const [exhaustUnits, setExhaustUnits] = useState<Array<number>>([0]);
    /** State variables end */

    /** useQueries */
    /** Load Vessel's Main Engines */
    const {
        data: auxEngines,
        isLoading: auxEnginesLoading,
        isError: auxEnginesError,
    } = useQuery(
        [queryKeyes.vessel.AEVesselMachineries.key, VesselID],
        async () => {
            return await loadVesselMachineriesByMachineType(VesselID, VesselMachineryConstant.AUXILIARY_ENGINE);
        },
        { staleTime: Infinity }
    );
    /** Load Vessel's Auxiliary Engines */
    const {
        data: auxEnginesObject,
        isLoading: auxEnginesObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.EconAEAdditionalObject.key, VesselID, ReportID],
        async () => {
            return await loadEconAEAdditionalObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    /** Assign values to Aux engine component's initial object */
    const getAEInitialValues = () => {
        let arr: any[] = [];
        if (auxEnginesObject && auxEnginesObject?.length > 0) {
            return auxEnginesObject;
        } else if (auxEngines && auxEngines?.length > 0) {
            auxEngines?.forEach((engine: any) => {
                arr.push({
                    additinalInfo: getAdditonalInfoInitialValues(engine?.id, engine?.vessel_machinery_name),
                    exhaustUnits: getExhausUnitInitialValues(engine?.id, engine?.vessel_machinery_name),
                });
            });
        }
        return arr;
    }

    /** Assign values to Additional informations's initial object */
    const getAdditonalInfoInitialValues = (machine: number, machineName: string) => {
        return ({
            ae_load_kw: null as number,
            ae_load_percent_mcr: null as number,
            ae_jw_temperature_inlet: null as number,
            ae_jw_temperature_outlet: null as number,
            ae_jw_in_pressure: null as number,
            ae_lo_temperature_inlet: null as number,
            ae_lo_temperature_outlet: null as number,
            ae_lo_in_pressure: null as number,
            gas_temperature_in: null as number,
            gas_temperature_out: null as number,
            lo_press_inlet: null as number,
            vessel_machinery: machine,
            vessel_machinery_name: machineName,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
        });
    }

    /** Assign values to Exhaust unit's initial object */
    const getExhausUnitInitialValues = (machine: number, machineName: string) => {
        let arr: any[] = [];
        arr.push({
            no_of_exhaust_units: null as number,
            exhaust_unit: null as string,
            unit_temperature: null as number,
            vessel_machinery: machine,
            vessel_machinery_name: machineName,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
        });
        return arr;
    }

    /** Aux engine addtional info's formik object */
    const AuxEngineFormik = {
        initialValues: getAEInitialValues(),
        validationSchema:
            Yup.array(
                Yup.object().shape({
                    additinalInfo: Yup.object().shape({
                        ae_load_kw: Yup.string().matches(/^\d{0,4}(\.\d{1})?$/, `${commonValidationMessages.before4after1}`).nullable(),
                        ae_load_percent_mcr: Yup.string().matches(/^\d{0,3}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        ae_jw_temperature_inlet: Yup.string().matches(/^\d{0,3}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        ae_jw_temperature_outlet: Yup.string().matches(/^\d{0,3}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        ae_jw_in_pressure: Yup.string().matches(/^\d{0,2}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        ae_lo_temperature_inlet: Yup.string().matches(/^\d{0,3}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        ae_lo_temperature_outlet: Yup.string().matches(/^\d{0,3}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        ae_lo_in_pressure: Yup.string().matches(/^\d{0,2}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                        gas_temperature_in: Yup.string().matches(/^\d{0,4}(\.\d{1})?$/, `${commonValidationMessages.before4after1}`).nullable(),
                        gas_temperature_out: Yup.string().matches(/^\d{0,4}(\.\d{1})?$/, `${commonValidationMessages.before4after1}`).nullable(),
                        lo_press_inlet: Yup.string().matches(/^\d{0,2}(\.\d{1})?$/, `${commonValidationMessages.before3after1}`).nullable(),
                    }),
                    exhaustUnits: Yup.array(
                        Yup.object().shape({
                            no_of_exhaust_units:
                                Yup.number()
                                    .integer(commonValidationMessages.integer).positive(commonValidationMessages.integer).min(1, "Please enter a value between 1 and 24.")
                                    .max(24, "Please enter a value between 1 and 24.").nullable(),
                            unit_temperature: Yup.string().matches(/^\d{0,4}(\.\d{1})?$/, `${commonValidationMessages.before4after1}`).nullable(),
                        })
                    ),
                })
            )
    }

    /** useEffect */
    useEffect(() => {
        if (auxEnginesObject && auxEnginesObject?.length > 0) {
            let arr: any[] = [];
            auxEnginesObject.forEach((value: any) => {
                arr.push(value?.exhaustUnits[0]?.no_of_exhaust_units);
            })
            setExhaustUnits(arr);
        }
    }, [auxEnginesObject])
    /** useEffect end */

    /** post submit function */
    const handleResponse = (res: any, values: any) => {
        if (res.status === 200 || res.status === 201) {
            queryClient.invalidateQueries(queryKeyes.vessel.EconAEAdditionalObject.key);
            successToast("Data saved successfully!");
            setRefreshKey(refreshKey + 1);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            values = res.data;
            if (env?.form_validation === true) {
                toggleAddTab(3);
            }
        }
    }

    /** POST request for Aux engine */
    const AuxEngineSubmit = async (values: any, actions: any) => {
        values?.forEach((value: any) => {
            Object.keys(value).forEach((key: any) => {
                if (Array.isArray(value[key])) {
                    value[key]?.forEach((item: any) => {
                        delete item.vessel_machinery_name;
                    })
                } else {
                    delete value[key].vessel_machinery_name;
                }
            });
        })
        if (auxEnginesObject && auxEnginesObject.length > 0) {
            apiGlobal.put(queryKeyes.vessel.EconAEAdditionalEdit.url(), values).then((res: any) => {
                handleResponse(res, values);
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            })
        } else {
            apiGlobal.post(queryKeyes.vessel.EconAEAdditionalInfo.url(), values).then((res: any) => {
                handleResponse(res, values);
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            })
        }
        setRefreshKey(refreshKey + 1);
        actions.setSubmitting(false);
    }
    return (
        <React.Fragment>
            {(auxEnginesLoading || auxEnginesObjectLoading) && <Loading message="Loading required data!" />}
            {auxEnginesError && <ErrorComponent message='Error loading required data!' />}
            {!(auxEnginesLoading || auxEnginesObjectLoading) && !auxEnginesError &&
                <Formik
                    initialValues={AuxEngineFormik.initialValues}
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(true);
                        AuxEngineSubmit(values, actions);
                    }}
                    key={refreshKey}
                    validationSchema={AuxEngineFormik.validationSchema}
                >
                    {(props: any) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <Card className='p-0 mb-0 border-0'>
                                <CardHeader className='p-2 border-bottom-0'>
                                    <h5>Auxiliary engine</h5>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    {props?.values && props?.values?.map((value: any, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Card className='p-0 mb-0'>
                                                    <CardHeader className='p-2'>
                                                        <h6 className='mb-0'>{value?.additinalInfo?.vessel_machinery_name}</h6>
                                                    </CardHeader>
                                                    <CardBody className='px-2 py-0'>
                                                        <Row className='mb-2 mt-2'>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_load_kw${index}`}>AE Load</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_load_kw`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_load_kw${index}`}
                                                                                name={`${index}.additinalInfo.ae_load_kw`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_load_kw}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text">kW</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_load_kw &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_load_kw &&
                                                                    <ErrorTooltip
                                                                        target={`ae_load_kw${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_load_kw}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_load_kw && props?.touched[index]?.additionalInfo?.ae_load_kw}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_load_percent_mcr${index}`}>AE Load</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_load_percent_mcr`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_load_percent_mcr${index}`}
                                                                                name={`${index}.additinalInfo.ae_load_percent_mcr`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_load_percent_mcr}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text">% MCR</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_load_percent_mcr &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_load_percent_mcr &&
                                                                    <ErrorTooltip
                                                                        target={`ae_load_percent_mcr${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_load_percent_mcr}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_load_percent_mcr && props?.touched[index]?.additionalInfo?.ae_load_percent_mcr}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-2'>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_jw_temperature_inlet${index}`}>AE J/W temperature inlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_jw_temperature_inlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_jw_temperature_inlet${index}`}
                                                                                name={`${index}.additinalInfo.ae_jw_temperature_inlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_jw_temperature_inlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_jw_temperature_inlet &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_jw_temperature_inlet &&
                                                                    <ErrorTooltip
                                                                        target={`ae_jw_temperature_inlet${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_jw_temperature_inlet}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_jw_temperature_inlet && props?.touched[index]?.additionalInfo?.ae_jw_temperature_inlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_jw_temperature_outlet${index}`}>AE J/W temperature outlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_jw_temperature_outlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_jw_temperature_outlet${index}`}
                                                                                name={`${index}.additinalInfo.ae_jw_temperature_outlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_jw_temperature_outlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_jw_temperature_outlet &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_jw_temperature_outlet &&
                                                                    <ErrorTooltip
                                                                        target={`ae_jw_temperature_outlet${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_jw_temperature_outlet}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_jw_temperature_outlet && props?.touched[index]?.additionalInfo?.ae_jw_temperature_outlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_jw_in_pressure${index}`}>AE J/W In pressure</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_jw_in_pressure`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_jw_in_pressure${index}`}
                                                                                name={`${index}.additinalInfo.ae_jw_in_pressure`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_jw_in_pressure}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text">bar</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_jw_in_pressure &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_jw_in_pressure &&
                                                                    <ErrorTooltip
                                                                        target={`ae_jw_in_pressure${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_jw_in_pressure}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_jw_in_pressure && props?.touched[index]?.additionalInfo?.ae_jw_in_pressure}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-2'>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_lo_temperature_inlet${index}`}>AE LO temperature inlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_lo_temperature_inlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_lo_temperature_inlet${index}`}
                                                                                name={`${index}.additinalInfo.ae_lo_temperature_inlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_lo_temperature_inlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_lo_temperature_inlet &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_lo_temperature_inlet &&
                                                                    <ErrorTooltip
                                                                        target={`ae_lo_temperature_inlet${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_lo_temperature_inlet}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_lo_temperature_inlet && props?.touched[index]?.additionalInfo?.ae_lo_temperature_inlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_lo_temperature_outlet${index}`}>AE LO temperature outlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_lo_temperature_outlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_lo_temperature_outlet${index}`}
                                                                                name={`${index}.additinalInfo.ae_lo_temperature_outlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_lo_temperature_outlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_lo_temperature_outlet &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_lo_temperature_outlet &&
                                                                    <ErrorTooltip
                                                                        target={`ae_lo_temperature_outlet${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_lo_temperature_outlet}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_lo_temperature_outlet && props?.touched[index]?.additionalInfo?.ae_lo_temperature_outlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`ae_lo_in_pressure${index}`}>AE LO In pressure</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additinalInfo.ae_lo_in_pressure`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`ae_lo_in_pressure${index}`}
                                                                                name={`${index}.additinalInfo.ae_lo_in_pressure`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => props?.handleChange(e)}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additinalInfo?.ae_lo_in_pressure}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.ae_lo_in_pressure &&
                                                                    props?.touched && props?.touched[index]?.additionalInfo?.ae_lo_in_pressure &&
                                                                    <ErrorTooltip
                                                                        target={`ae_lo_in_pressure${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.ae_lo_in_pressure}
                                                                        open={props?.errors[index]?.additionalInfo?.ae_lo_in_pressure && props?.touched[index]?.additionalInfo?.ae_lo_in_pressure}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Card className='card-bottom-0 p-0 mb-0' key={index}>
                                                            <CardHeader className='p-2'>
                                                                <h6 className='mb-0'>Exhaust temperatures (<sup>o</sup>C)</h6>
                                                            </CardHeader>
                                                            <CardBody className='px-2 py-0'>
                                                                <Row className='mb-2 mt-2'>
                                                                    <Col sm={3}>
                                                                        <Label className="mb-0" htmlFor={`no_of_exhaust_units${index}`}>AE number of units</Label>
                                                                        <Field name={`${index}.exhaustUnits.0.no_of_exhaust_units`}>
                                                                            {() => (
                                                                                <Input
                                                                                    type="text"
                                                                                    id={`no_of_exhaust_units${index}`}
                                                                                    name={`${index}.exhaustUnits.0.no_of_exhaust_units`}
                                                                                    className="form-cntrol max-width-7 text-right"
                                                                                    onChange={(e: any) => {
                                                                                        props?.handleChange(e);
                                                                                        if (e.target.value <= 24) {
                                                                                            let exhaustArr: any[] = [...exhaustUnits];
                                                                                            exhaustArr[index] = parseInt(e.target.value)
                                                                                            setExhaustUnits(exhaustArr);
                                                                                            let arr: any = [];
                                                                                            for (let i = 1; i <= value?.exhaustUnits[0]?.no_of_exhaust_units; i++) {
                                                                                                arr.push({
                                                                                                    no_of_exhaust_units: value?.exhaustUnits[0]?.no_of_exhaust_units,
                                                                                                    exhaust_unit: `Unit ${i + 1}`,
                                                                                                    unit_temperature: null as number,
                                                                                                    vessel_machinery: value?.exhaustUnits[0]?.vessel_machinery,
                                                                                                    vessel_machinery_name: value?.exhaustUnits[0]?.vessel_machinery_name,
                                                                                                    vessel: VesselID,
                                                                                                    voyage_information: VoyageID,
                                                                                                    vessel_reporting_information: ReportID,
                                                                                                });
                                                                                            }
                                                                                            value.exhaustUnits = arr;
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        } else {
                                                                                            let arr: any = [];
                                                                                            value.exhaustUnits = arr;
                                                                                            setExhaustUnits([]);
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e: any) => {
                                                                                        props?.handleChange(e);
                                                                                        if (e.target.value <= 24) {
                                                                                            let arr: any = [];
                                                                                            for (let i = 1; i <= exhaustUnits[index]; i++) {
                                                                                                arr.push({
                                                                                                    no_of_exhaust_units: exhaustUnits[index],
                                                                                                    exhaust_unit: `Unit ${i + 1}`,
                                                                                                    unit_temperature: null as number,
                                                                                                    vessel_machinery: value?.exhaustUnits[0]?.vessel_machinery,
                                                                                                    vessel_machinery_name: value?.exhaustUnits[0]?.vessel_machinery_name,
                                                                                                    vessel: VesselID,
                                                                                                    voyage_information: VoyageID,
                                                                                                    vessel_reporting_information: ReportID,
                                                                                                });
                                                                                            }
                                                                                            value.exhaustUnits = arr;
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        } else {
                                                                                            let arr: any = [];
                                                                                            value.exhaustUnits = arr;
                                                                                            setExhaustUnits([]);
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        }
                                                                                    }}
                                                                                    defaultValue={value?.exhaustUnits?.[0]?.no_of_exhaust_units}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.exhaustUnits && props?.errors[index]?.exhaustUnits[0]?.no_of_exhaust_units &&
                                                                            <ErrorTooltip
                                                                                target={`no_of_exhaust_units${index}`}
                                                                                message={props?.errors[index]?.exhaustUnits[0]?.no_of_exhaust_units}
                                                                                open={props?.errors[index]?.exhaustUnits[0]?.no_of_exhaust_units}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                {exhaustUnits[index] > 0 &&
                                                                    <Row className='mb-2'>
                                                                        <React.Fragment>
                                                                            {Array.from({ length: exhaustUnits[index] || 0 }).map((_, i) => {
                                                                                return (
                                                                                    <Col sm={1} className='mb-2'>
                                                                                        <Label className="mb-0" htmlFor={`unit_temperature${index}${i}`}>{`Unit ${i + 1}`}</Label>
                                                                                        <Field name={`${index}.exhaustUnits.${i}.unit_temperature`}>
                                                                                            {() => (
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id={`unit_temperature${index}${i}`}
                                                                                                    name={`${index}.exhaustUnits.${i}.unit_temperature`}
                                                                                                    className="form-cntrol max-width-7 text-right"
                                                                                                    onChange={(e: any) => props?.handleChange(e)}
                                                                                                    onBlur={(e: any) => {
                                                                                                        props?.handleChange(e);
                                                                                                        props?.handleBlur(e);
                                                                                                    }}
                                                                                                    defaultValue={value?.exhaustUnits?.[i]?.unit_temperature}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.exhaustUnits[i] && props?.errors[index]?.exhaustUnits[i]?.unit_temperature &&
                                                                                            props?.touched && props?.touched[index]?.exhaustUnits[i] && props?.touched[index]?.exhaustUnits[i]?.unit_temperature &&
                                                                                            <ErrorTooltip
                                                                                                target={`unit_temperature${index}${i}`}
                                                                                                message={props?.errors[index]?.exhaustUnits[i]?.unit_temperature}
                                                                                                open={
                                                                                                    props?.errors[index]?.exhaustUnits[i]?.unit_temperature &&
                                                                                                    props?.touched[index]?.exhaustUnits[i]?.unit_temperature
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                    </Col>
                                                                                )
                                                                            })}
                                                                        </React.Fragment>
                                                                    </Row>
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                        <Card className='card-bottom-0 p-0 mb-0' key={index}>
                                                            <CardHeader className='p-2'>
                                                                <h6 className='mb-0'>Turbocharger</h6>
                                                            </CardHeader>
                                                            <CardBody className='px-2 py-0'>
                                                                <Row className='mb-2'>
                                                                    <Col sm={3}>
                                                                        <Label className="mb-0" htmlFor={`gas_temperature_in${index}`}>Gas temperature In</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.additinalInfo.gas_temperature_in`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`gas_temperature_in${index}`}
                                                                                        name={`${index}.additinalInfo.gas_temperature_in`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => props?.handleChange(e)}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.additinalInfo?.gas_temperature_in}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text"><sup>o</sup>C</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.gas_temperature_in &&
                                                                            props?.touched && props?.touched[index]?.exhaustUnits[i] && props?.touched[index]?.exhaustUnits[i]?.gas_temperature_in &&
                                                                            <ErrorTooltip
                                                                                target={`gas_temperature_in${index}`}
                                                                                message={props?.errors[index]?.additionalInfo?.gas_temperature_in}
                                                                                open={props?.errors[index]?.additionalInfo?.gas_temperature_in && props?.touched[index]?.additionalInfo?.gas_temperature_in}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <Label className="mb-0" htmlFor={`gas_temperature_out${index}`}>Gas temperature out</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.additinalInfo.gas_temperature_out`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`gas_temperature_out${index}`}
                                                                                        name={`${index}.additinalInfo.gas_temperature_out`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => props?.handleChange(e)}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.additinalInfo?.gas_temperature_out}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text"><sup>o</sup>C</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.gas_temperature_out &&
                                                                            props?.touched && props?.touched[index]?.exhaustUnits[i] && props?.touched[index]?.exhaustUnits[i]?.gas_temperature_out &&
                                                                            <ErrorTooltip
                                                                                target={`gas_temperature_out${index}`}
                                                                                message={props?.errors[index]?.additionalInfo?.gas_temperature_out}
                                                                                open={props?.errors[index]?.additionalInfo?.gas_temperature_out && props?.touched[index]?.additionalInfo?.gas_temperature_out}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <Label className="mb-0" htmlFor={`lo_press_inlet${index}`}>LO Press inlet</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.additinalInfo.lo_press_inlet`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`lo_press_inlet${index}`}
                                                                                        name={`${index}.additinalInfo.lo_press_inlet`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => props?.handleChange(e)}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.additinalInfo?.lo_press_inlet}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text">bar</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.lo_press_inlet &&
                                                                            props?.touched && props?.touched[index]?.exhaustUnits[i] && props?.touched[index]?.exhaustUnits[i]?.lo_press_inlet &&
                                                                            <ErrorTooltip
                                                                                target={`lo_press_inlet${index}`}
                                                                                message={props?.errors[index]?.additionalInfo?.lo_press_inlet}
                                                                                open={props?.errors[index]?.additionalInfo?.lo_press_inlet && props?.touched[index]?.additionalInfo?.lo_press_inlet}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </CardBody>
                                                </Card>
                                            </React.Fragment>
                                        )
                                    })}
                                </CardBody>
                                <CardFooter className='p-2 py-3'>
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleAddTab(3);
                                                }
                                            }}
                                                disabled={props?.isSubmitting}
                                            >
                                                {props?.isSubmitting &&
                                                    <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                                                } Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                props.setErrors({})
                                                props.errors = {};
                                                toggleAddTab(1);
                                            }}>
                                                <i className="bx bx-chevron-left me-1" />Previous
                                            </Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                            </Card>
                            <FormValuesDebug values={[props?.values, props?.errors]} />
                        </Form>
                    )
                    }
                </Formik>
            }
        </React.Fragment>
    )
}

export default AuxEngineComponent