import apiGlobal from '../../../global/api.global';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import PopUp from 'Components/PopUp';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadMachineryEnergyGroup, loadVesselMachineries } from 'VesselMaster/vesselMaster.hooks';
import DynamicGroupForm from './AddNewGroup';
import EditFuelConsumerGroup from './EditGroup';
import { successToast } from 'Components/Toasts';
import PopOver from 'Components/PopOver';
import ToolTip from 'Components/ToolTip';
import Loading from 'Components/Loading';
import { queryClient } from 'react-query/queryClient';
import { isConfigurationButtonDisabled } from 'GenericForms/Helper';
import EditIcon from '../../../Media/ED2.png';

interface EnergyConsumerType {
    refreshVesselMachineries?: number;
    setRefreshVesselMachineries?: (value: number) => void;
    activeSettingsTab?: number;
    refreshInnerTables?: boolean;
}

/**
 * EnergyConsumer Component
 *
 * This component displays the list of electrical energy consumer machinery for a vessel.
 * It allows users to add new energy consumer groups, edit existing groups, and delete them.
 * 
 * Features:
 * - Fetches vessel machinery list using React Query
 * - Provides CRUD operations: Add, Edit, and Delete energy consumer groups
 * - Manages state for tooltips, popovers, and modals
 */
const EnergyConsumer = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
    activeSettingsTab,
    refreshInnerTables,
}: EnergyConsumerType) => {
    /** State variables */
    // const [vesselMachineries, setVesselMachineries] = useState<Array<VesselMachineryList>>([]);
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [state, setState] = useState(false);
    const [selectedMachinery, setSelectedMachinery] = useState(null);
    const [editEnergyConsumerGroup, setEditEnergyConsumerGroup] = useState(false);
    /** URL for the energy consumer group API */
    const ecGroupURL: string = queryKeyes.vessel.MachinaryEnergyGroup.url;
    /** State for managing popover and tooltip behavior */
    const [openPopoverVesselMachinaryObject, setOpenPopoverVesselMachinaryObject] = useState<any | null>([]);
    const [deletePopOverState, setDeletePopOverState] = useState(false);
    const [indexOfVesselMachinery, setIndexOfVesselMachinery] = useState(0);
    const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const [hoverEditId, setEditHoverId] = useState<string | null>(null);

    /** useEffect to close the popover when the tab is changed*/
    useEffect(() => {
        setOpenPopoverIndex(null);
        setIndexOfVesselMachinery(0);
        setDeletePopOverState(false);
        if (!(openPopoverIndex === indexOfVesselMachinery && deletePopOverState === false)) {
            setIndexOfVesselMachinery(null);
        }
        queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSettingsTab, refreshInnerTables])

    /** 
     * React Query: Fetches the list of vessel machineries for the given VesselID.
     */
    const {
        data: vesselMachineriesListObj,
        isLoading: vesselMachineriesListLoading,
        isError: vesselMachineriesListError,
    }: { data: any, isLoading: any, isError: any } = useQuery(
        [queryKeyes.vessel.VesselMachineries.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
    );
    /** Machinary energy Group */
    const {
        data: machineryEnergyGroup,
        isLoading: machineryEnergyGroupLoading,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key, VesselID],
        async () => {
            return await loadMachineryEnergyGroup(VesselID);
        },
    );
    const vesselMachineriesList = vesselMachineriesListObj?.filter(
        (machine: any) => machine.display === true
    );


    /**
     * Handles the deletion of an energy consumer group.
     *
     * @param obj - The object containing the energy consumer group data.
     */
    const handleDelete = async (obj: any) => {
        try {
            apiGlobal.delete(`/vessel_machinery_ec_group/${obj.id}/`, obj);
            await queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
            successToast('Group deleted successfully');
            setRefreshVesselMachineries(refreshVesselMachineries + 1);
            // setRefreshAfterDelete(!refreshAfterDelete);
        } catch (err) {
            console.error('Error:', err);
        }
    };
    return (
        <React.Fragment>
            {machineryEnergyGroupLoading && <Loading message='Loading required data!' />}
            {!machineryEnergyGroupLoading &&
                <React.Fragment>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="navbar_menu mb-0">Electrical Energy Consumer Machinery List</h4>
                        <button
                            className="btn btn-primary waves-effect waves-light mb-1"
                            onClick={() => {
                                setState(true);
                                setDeletePopOverState(false);
                            }}
                            disabled={isConfigurationButtonDisabled(vessel)}
                        >
                            <i className="dripicons-plus font-size-16 align-middle me-2"></i>
                            New Group
                        </button>
                    </div>
                    <PopUp
                        state={state}
                        setState={setState}
                        body={
                            <DynamicGroupForm
                                group='energy'
                                VesselID={VesselID}
                                machineryList={vesselMachineriesList}
                                refreshState={state}
                                setRefreshState={setState}
                                isLoading={vesselMachineriesListLoading}
                                isError={vesselMachineriesListError}
                                groupURL={ecGroupURL}
                                refreshVesselMachineries={refreshVesselMachineries}
                                setRefreshVesselMachineries={setRefreshVesselMachineries}
                            />
                        }
                        title="Add New Electrical Energy Consumer Group"
                        size="lg"
                    />
                    <div className="table-responsive mb-3">
                        <table className="table" key={refreshVesselMachineries}>
                            <thead className="table-light">
                                <tr>
                                    <th className='p-2 align-middle sr-no-width'>#</th>
                                    <th className='p-2 align-middle'>EC Group Name</th>
                                    <th className='p-2 align-middle'>Machinery List</th>
                                    <th className="p-2 align-middle text-center w-20">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineryEnergyGroup && machineryEnergyGroup?.length > 0 ? (
                                    machineryEnergyGroup.map((vesselMachinery: any, index: number) => {
                                        return (
                                            <tr key={vesselMachinery.id}>
                                                <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                <td className='p-2 align-middle' >{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                                <td className="p-0 align-middle">
                                                    <table className="table m-0">
                                                        <tbody>
                                                            {vesselMachinery?.vessel_machinery_ec_names?.map((item: string) => (
                                                                <tr key={item}>
                                                                    <td className="">{item}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                                                        <button
                                                            type="button"
                                                            className="btn mt-3"
                                                            disabled={isConfigurationButtonDisabled(vessel)}
                                                        >
                                                            <img
                                                                alt="Edit details"
                                                                className='pointer mb-2'
                                                                src={EditIcon}
                                                                height="25"
                                                                id={`Edit_Ec_Vessel_Machinery_${index}`}
                                                                onMouseEnter={() => {
                                                                    setEditHoverId(`Edit_Ec_Vessel_Machinery_${index}`)
                                                                    setOpenPopoverVesselMachinaryObject(vesselMachinery);
                                                                }}
                                                                onMouseLeave={() => setEditHoverId(null)}
                                                                onClick={() => {
                                                                    setSelectedMachinery(vesselMachinery);
                                                                    setEditEnergyConsumerGroup(true);
                                                                    setEditHoverId(null);
                                                                    setDeletePopOverState(false)
                                                                }}
                                                            />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn mt-3"
                                                            disabled={isConfigurationButtonDisabled(vessel)}
                                                        >
                                                            <i className="dripicons-trash icon_s18 fs-3"
                                                                id={`Delete_Ec_Vessel_Machinery_${index}`}
                                                                onMouseEnter={() => {
                                                                    setHoverId(`Delete_Ec_Vessel_Machinery_${index}`)
                                                                    setOpenPopoverVesselMachinaryObject(vesselMachinery);
                                                                }}
                                                                onMouseLeave={() => setHoverId(null)}
                                                                onClick={() => {
                                                                    setDeletePopOverState(true); // Enable popover visibility
                                                                    setIndexOfVesselMachinery(index); // Store the current button's index
                                                                    setOpenPopoverVesselMachinaryObject(vesselMachinery); // Store the machinery data
                                                                    setOpenPopoverIndex(index);
                                                                    setHoverId(null)
                                                                }}
                                                            ></i>
                                                        </button>
                                                        {/* Popover for delete confirmation */}

                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }))
                                    : <tr>
                                        <td colSpan={4} className="text-center">No machineries configured for this vessel</td>
                                    </tr>}
                                {editEnergyConsumerGroup && (
                                    <PopUp
                                        state={editEnergyConsumerGroup}
                                        setState={setEditEnergyConsumerGroup}
                                        body={
                                            <EditFuelConsumerGroup
                                                group='energy'
                                                groupName={selectedMachinery.vessel_machinery_ec_group_name}
                                                vesselMachinery={selectedMachinery}
                                                vesselMachineriesList={vesselMachineriesList}
                                                editConsumerGroup={editEnergyConsumerGroup}
                                                setEditFuelConsumerGroup={setEditEnergyConsumerGroup}
                                                editPutURL={ecGroupURL}
                                                refreshVesselMachineries={refreshVesselMachineries}
                                                setRefreshVesselMachineries={setRefreshVesselMachineries}
                                                state={editEnergyConsumerGroup}
                                                setState={setEditEnergyConsumerGroup}
                                            />
                                        }
                                        title={`Edit Group: ${selectedMachinery.vessel_machinery_ec_group_name}`}
                                        size="lg"
                                    />
                                )}
                                {openPopoverIndex === indexOfVesselMachinery &&
                                    deletePopOverState &&
                                    (<PopOver
                                        target={`Delete_Ec_Vessel_Machinery_${indexOfVesselMachinery}`}
                                        handleClick={() => {
                                            handleDelete(openPopoverVesselMachinaryObject);
                                            setDeletePopOverState(false);
                                            setOpenPopoverIndex(null);
                                        }}
                                        message={`Are you sure you want to delete this group`}
                                        state={deletePopOverState}
                                        setState={setDeletePopOverState}
                                    />)
                                }
                                {hoverId !== null &&
                                    <ToolTip
                                        target={hoverId}
                                        isOpen={hoverId !== null}
                                        message={`Delete ${openPopoverVesselMachinaryObject?.vessel_machinery_ec_group_name} Group?`}
                                    />}
                                {hoverEditId !== null &&
                                    <ToolTip
                                        target={hoverEditId}
                                        isOpen={hoverEditId !== null}
                                        message={`Edit ${openPopoverVesselMachinaryObject?.vessel_machinery_ec_group_name} Group?`}
                                    />}
                            </tbody>
                        </table>
                        <p> - Add to the list, machinery which are energy consumers .</p>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default EnergyConsumer