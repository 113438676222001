export const commonValidationMessages = {
  required: 'This field is required.',
  string: 'This field must be string',
  number: 'This field must be number',
  min0: 'Please enter a number greater than or equal to 0',
  min2: 'Please enter at least 2 characters.',
  min1: 'Please enter a value greater than 0.',
  max25: 'Enter a valid time up to 25.00 with two decimal places.',
  max100Char: 'Please enter less than 100 characters.',
  max50Char: 'Please enter less than 50 characters.',
  max50: 'Please enter a number less than or equal to 50',
  max7: 'Please enter a number less than or equal to67',
  max6: 'Please enter upto 6 digigts',
  max12: 'The number entered cannot exceed 12 digits.',
  max200: 'This field cannot excced 200 characters.',
  maxVal100: 'The number should within the allowable range (0-100).',
  min_180: 'Please enter a number greater than or equal to -180',
  max250: 'Please enter a number less than or equal to 250',
  max4: 'Please enter a number less than or equal to 4',
  max1: 'Please enter a number less than or equal to 1',
  positive: 'Please enter a positive integer.',
  integer: 'Please enter a positive integer.',
  validNumber: '',
  before2after2:
    'Please enter a number with up to 2 digits before and 2 digits after the decimal point.',
  before7after2:
    'Please enter a number with up to 7 digits before and 2 digits after the decimal point.',
  before2after1:
    'Please enter a number with up to 2 digits before and 1 digits after the decimal point.',
  before3after2:
    'Please enter a number with up to 3 digits before and 2 digits after the decimal point.',
  before2after10:
    'Please enter a number with up to 2 digits before and 10 digits after the decimal point.',
  before5after2:
    'Please enter a number with up to 2 digits before and 2 digits after the decimal point.',
  before5after3:
    'Please enter a number with up to 5 digits before and 3 digits after the decimal point.',
  before4after1:
    'Please enter a number with up to 4 digits before and 1 digits after the decimal point.',
  before1after3:
    'Please enter a number with up to 1 digits before and 3 digits after the decimal point.',
  before3after3:
    'Please enter a number with up to 3 digits before and 3 digits after the decimal point.',
  before3after1:
    'Please enter a number with up to 3 digits before and 1 digits after the decimal point.',
  before10after1:
    'Please enter a number with up to 10 digits before and 1 digits after the decimal point.',
  before4after2:
    'Please enter a number with up to 4 digits before and 2 digits after the decimal point.',
  before5after4:
    'Please enter a number with up to 5 digits before and 4 digits after the decimal point.',
  before2after4:
    'Please enter a number with up to 2 digits before and 4 digits after the decimal point.',
  before5after10:
    'Before decimal: 1 to 5 digits; after decimal: 2 to 10 digits are allowed',
  minDateLastRecord:
    "Please enter date & time after last reporting's date-time.",
  maxDateCurrentRecord:
    "Please enter date & time before current reporting's date-time.",
  minLatDegree: 'Latitude must be at least 0 degrees',
  maxLatDegree: 'Latitude cannot exceed 360 degrees',
  minLogDegree: 'Longitude must be at least 0 degrees',
  maxLogDegree: 'Longitude cannot exceed 360 degrees',
  minMinutes: 'minutes must be at least 0',
  maxMinutes: 'minutes cannot exceed 60',
  minSecond: 'Seconds must be at least 0',
  maxSecond: 'Seconds cannot exceed 60',
  selectYesOrNo: 'Please select Yes or No',
};

export const DraftsDisplacementValidationMessages = {
  displacement:
    'Please enter a number with up to 6 digits before and 2 digits after the decimal point',
};

export const WeatherDataValidationMessages = {
  wind_direction: '',
  gyro_course: 'Please enter a valid number between 0 and 360.',
};

export const SludgeBilgeWaterMessages = {
  tank_number: 'Please enter upto 50 aplhabets/digits.',
};

export const VesselGenInfoMessages = {
  short_name: 'The string must contain at least 2 letters.',
  call_sign: 'Please enter 7 aplphabets/digits only.',
  official_number: 'Please enter 10 digits only.',
  hull_number: 'Please enter 11 aplphabets/digits only.',
  dead_weight: 'Please enter only numbers.',
};

export const ECON = {
  max_exhaust_unit: `Number of exhaust units should not be greater than 24`,
};
