import PopUp from 'Components/PopUp';
import React, { useState } from 'react';
import { Button, CardBody } from 'reactstrap';

interface File {
    name: string; // File name
    type: 'bapli' | 'logbook'; // File type
}

interface Report {
    id: number;
    duration: string; // Duration or DateTime info
    adjustmentEvent: string; // Adjustment event name
    files: File[]; // Associated files
}

const ReferContainer: React.FC = () => {
    const [PopUpState, setPopUpState] = useState<Boolean>(false);

    const reports: Report[] = [
        {
            id: 1,
            duration: '10am - 2pm, 20 May 2022',
            adjustmentEvent: 'Event 1',
            files: [
                { name: 'bapli file', type: 'bapli' },
                { name: 'Logbook file', type: 'logbook' },
            ],
        },
        {
            id: 2,
            duration: '3pm - 6pm, 21 May 2022',
            adjustmentEvent: 'Event 2',
            files: [{ name: 'logbook file', type: 'logbook' }],
        },
    ];

    const handleUploadButtonClick = (): void => {
        setPopUpState(true)
    };

    return (
        <React.Fragment>
            <CardBody className="p-0 pb-0 mt-2">
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead className="table-light">
                            <tr>
                                <th className="p-2 align-middle sr-no-width">#</th>
                                <th className="p-2 align-middle" style={{ width: '22%' }}>
                                    Date & Time(UTC)
                                </th>
                                <th className="p-2 align-middle">Name  of Report</th>
                                <th className="p-2 align-middle" colSpan={2}>
                                    Documents
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.length === 0 ? (
                                <tr>
                                    <td colSpan={5} className="align-middle text-center">
                                        No pending files to upload.
                                    </td>
                                </tr>
                            ) : (
                                reports.map((report, index) => (
                                    <React.Fragment key={report.id}>
                                        {/* Main Row */}
                                        <tr>
                                            <td className="align-middle p-2 text-center">{index + 1}</td>
                                            <td className="align-middle p-2">{report.duration}</td>
                                            <td className="align-middle p-2">{report.adjustmentEvent}</td>
                                            <td className="align-middle p-0" colSpan={2}>
                                                {/* Nested Table for Files */}
                                                <table className="table-bordered width-100">
                                                    <tbody>
                                                        {report.files.map((file, fileIndex) => (
                                                            <tr key={fileIndex}>
                                                                <td
                                                                    className="align-middle p-2"
                                                                    style={{ width: '70%' }}
                                                                >
                                                                    {file.name}
                                                                </td>
                                                                <td className="align-middle p-2 text-center">
                                                                    <Button
                                                                        type="button"
                                                                        className="btn-sm"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            handleUploadButtonClick()
                                                                        }
                                                                    >
                                                                        Upload
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </CardBody>
            <PopUp
                state={PopUpState}
                setState={setPopUpState}
                size="md"
                title="Upload File"
                body={
                    <React.Fragment>
                        <Button color='primary'>upload</Button>
                        <div className="mt-1 mb-5">
                            <Button type="submit" color='primary' className='pos-end' onClick={() => {
                                setPopUpState(false)
                            }}>Save</Button>
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default ReferContainer;
