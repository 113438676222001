import React from 'react';
import { Formik, Field, FieldProps, Form } from 'formik';
import { Button, Card, CardBody, CardFooter, Col, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { successToast, errorToast } from 'Components/Toasts';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { useQuery } from 'react-query';
import { loadEconDeckAdditionalInformation } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
interface EconFormType {
    toggleTab?: (value: number) => void,
    activeTab?: number,
    ReportID: number,
    VesselID: number,
    VoyageID: number,
}

const OtherDetailsComponent = ({
    toggleTab,
    activeTab,
    VesselID,
    VoyageID,
    ReportID,
}: EconFormType) => {

    const {
        data: econshipAdditionalInfoObj,
        isLoading: econshipAdditionalInfoObjLoading,
        isError: econshipAdditionalInfoObjError,
    } = useQuery(
        [queryKeyes.vessel.EconDeckAdditionalInformation.key, VesselID, ReportID],
        async () => {
            return await loadEconDeckAdditionalInformation(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );

    const initialValues = (() => {
        let obj: any;
        if (econshipAdditionalInfoObj && econshipAdditionalInfoObj.length > 0) {
            if (econshipAdditionalInfoObj[0]?.display === true) {
                obj = { ...econshipAdditionalInfoObj[0] };
                return econshipAdditionalInfoObj[0];
            }
        } else {
            obj = {
                avg_speed: null,
                total_distance_covered: null,
                fore_draft: null,
                aft_draft: null,
                any_stoppages: false,
                reason_for_stoppage: null,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
            return obj;
        }
    })();

    const validationSchema = Yup.object().shape({
        avg_speed: Yup.string()
            .matches(
                /^\d{0,3}(?:\.\d{1,2})?$/,
                `${commonValidationMessages.before3after2}`
            ).nullable(),
        total_distance_covered: Yup.string()
            .matches(
                /^(?!0\d)(\d{1,7}|0)(\.\d{1,2})?$/,
                commonValidationMessages.before7after2
            ).nullable(),
        fore_draft: Yup.string()
            .matches(
                /^\d{0,2}(?:\.\d{1,2})?$/,
                commonValidationMessages.before2after2
            ).nullable(),
        aft_draft: Yup.string()
            .matches(
                /^\d{0,2}(?:\.\d{1,2})?$/,
                commonValidationMessages.before2after2
            ).nullable(),
    });

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        if (econshipAdditionalInfoObj && econshipAdditionalInfoObj.length > 0) {
            values.id = econshipAdditionalInfoObj[0].id;
            try {
                const response = await apiGlobal.put(`/econship_deck_officer_additional/${values.id}/`, values);
                if ([200, 201].includes(response.status)) {
                    successToast('Data saved successfully!');
                    queryClient.invalidateQueries(queryKeyes.vessel.EconDeckAdditionalInformation.key);
                }
            } catch (err) {
                if (err) {
                    errorToast('Internal error occurred, please contact the admin.');
                } else {
                    errorToast('An unexpected error occurred.');
                }
            }
        }
        else {
            try {
                const response = await apiGlobal.post(`/econship_deck_officer_additional/`, values);
                if ([200, 201].includes(response.status)) {
                    successToast('Data saved successfully!');
                    queryClient.invalidateQueries(queryKeyes.vessel.EconDeckAdditionalInformation.key);
                }
            } catch (err) {
                if (err) {
                    errorToast('Internal error occurred, please contact the admin.');
                } else {
                    errorToast('An unexpected error occurred.');
                }
            }
        }
        actions.setSubmitting(false);
    };
    return (
        <Card className='mb-0 border-0'>
            {(econshipAdditionalInfoObjLoading) && <Loading message='Loading required data!' />}
            {(econshipAdditionalInfoObjError) && <ErrorComponent message='Unable to load required data!' />}
            {econshipAdditionalInfoObjError && initialValues()}
            {!(econshipAdditionalInfoObjLoading) &&
                !(econshipAdditionalInfoObjError) &&
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props): any => (
                        <Form>
                            <CardBody className='p-2'>
                                <Row className="mb-2">
                                    <Col sm={3}>
                                        <div className="form-group">
                                            <Label className="mb-0" htmlFor="avg_speed">Average Speed</Label>
                                            <div className="input-group mb-3">
                                                <Field name="avg_speed">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="text"
                                                            {...field}
                                                            {...props}
                                                            id={`avg_speed`}
                                                            onChange={(e) => props.setFieldValue('avg_speed', e.target.value)}
                                                            name={field.name}
                                                            className="max-width-7 text-right form-control"
                                                        />
                                                    )}
                                                </Field><div className="input-group-text round_border">
                                                    knots
                                                </div>
                                            </div>
                                            {props.errors?.avg_speed && props.touched?.avg_speed &&
                                                props.touched?.avg_speed &&
                                                props.errors?.avg_speed && env?.form_validation === true && (
                                                    <ErrorTooltip
                                                        target={`avg_speed`}
                                                        message={props.errors?.avg_speed}
                                                        open={props.errors?.avg_speed}
                                                    />
                                                )}
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="form-group">
                                            <Label className="mb-0" htmlFor="total_distance_covered">Total distance covered </Label>
                                            <div className="input-group mb-3">
                                                <Field name="total_distance_covered">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="text"
                                                            {...field}
                                                            id={`total_distance_covered`}
                                                            name={field.name}
                                                            className="max-width-7 text-right form-control"
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">
                                                    nm
                                                </div>
                                            </div>
                                            {props.errors?.total_distance_covered && props.touched?.total_distance_covered &&
                                                props.touched?.total_distance_covered &&
                                                props.errors?.total_distance_covered && env?.form_validation === true && (
                                                    <ErrorTooltip
                                                        target={`total_distance_covered`}
                                                        message={props.errors?.total_distance_covered}
                                                        open={props.errors?.total_distance_covered}
                                                    />
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <h6>Arrival Drafts Next Port</h6>
                                    <Col sm={3}>
                                        <div className="form-group">
                                            <Label className="mb-0" htmlFor="fore_draft">Fore draft</Label>
                                            <div className="input-group mb-3">
                                                <Field name="fore_draft">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="text"
                                                            id={`fore_draft`}
                                                            className={`form-control max-width-7 text-right`}
                                                            {...field}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">
                                                    m
                                                </div>
                                            </div>
                                            {props.errors?.fore_draft && props.touched?.fore_draft &&
                                                props.touched?.fore_draft &&
                                                props.errors?.fore_draft && env?.form_validation === true && (
                                                    <ErrorTooltip
                                                        target={`fore_draft`}
                                                        message={props.errors?.fore_draft}
                                                        open={props.errors?.fore_draft}
                                                    />
                                                )}
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="form-group">
                                            <Label className="mb-0" htmlFor="aft_draft">Aft draft</Label>
                                            <div className="input-group mb-3">
                                                <Field name="aft_draft">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="text"
                                                            id={`aft_draft`}
                                                            className={`form-control max-width-7 text-right`}
                                                            {...field}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">
                                                    m
                                                </div>
                                            </div>
                                            {props.errors?.aft_draft && props.touched?.aft_draft &&
                                                props.touched?.aft_draft &&
                                                props.errors?.aft_draft && env?.form_validation === true && (
                                                    <ErrorTooltip
                                                        target={`aft_draft`}
                                                        message={props.errors?.aft_draft}
                                                        open={props.errors?.aft_draft}
                                                    />
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={3}>
                                        <div className="d-flex flex-wrap gap-2">
                                            <Label className="mb-0" htmlFor="any_stoppages">Any stoppages? </Label>
                                            <Field name={'any_stoppages'}>
                                                {({ field, form }: FieldProps) => (
                                                    <div className="square-switch sqswitch">
                                                        <Input
                                                            type="checkbox"
                                                            switch="none"
                                                            name={field.name}
                                                            id={field.name}
                                                            onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)}
                                                            defaultChecked={props.values?.any_stoppages}
                                                        />
                                                        <Label
                                                            htmlFor="any_stoppages"
                                                            data-on-label='Yes'
                                                            data-off-label='No'
                                                        >
                                                        </Label>
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        {props.values.any_stoppages && (
                                            <div className="form-group ">
                                                <Label className="mb-0" htmlFor="reason_for_stoppage">Please provide reason for stoppage</Label>
                                                <Field name="reason_for_stoppage">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="textarea"
                                                            className="form-control"
                                                            id="reason_for_stoppage"
                                                            placeholder="Provide details about the stoppages..."
                                                            {...field}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="p-2">
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                toggleTab(activeTab + 1);
                                            }}
                                        >
                                            Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn_size_cstm "
                                            onClick={() => {
                                                props?.setErrors({});
                                                toggleTab(activeTab - 1);
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <Row className='mt-2'>
                                <FormValuesDebug values={[props?.values, props?.errors, initialValues]} />
                            </Row>
                        </Form>
                    )}
                </Formik>
            }
        </Card >
    );
};

export default OtherDetailsComponent;
