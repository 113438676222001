/**
 * Voyage state contants
 */
export const VoyageConstant = {
  NEWVOYAGE: 1,
  ONGOING: 2,
  CLOSED: 3,
};

/**
 * Operation Precedence id
 */
export const OperationPrecedence = {
  SEA_PASSAGE: 1,
  PORT_STAY: 2,
  ANCHOR_STAY: 3,
  MANEUVERING: 4,
  DRIFTING_IN_PORT: 5,
  DRIFTING_AT_SEA: 6,
  MANEUVERING_CII: 7,
};

/**
 * Fixed precedence id for noon event
 */
export const EventPrecedence = {
  NOON: 1,
  CII_ADJUSTMENT_EVENT: 2,
  DROP_ANCHOR: 3,
  AT_BERTH: 4,
  HEAVE_ANCHOR: 5,
  END_OF_SEA_PASSAGE: 6,
  BEGINNING_OF_SEA_PASSAGE: 7,
  DEPARTURE_FROM_DRIFTING_PORT: 8,
  DEPARTURE_FROM_BERTH: 9,
};

/**
 * Fixed operation for strait canal transit
 */
export const ReportingOpConstant = {
  SEA_PASSAGE: 1,
  PORT_STAY: 2,
  ANCHOR_STAY: 3,
  MANEUVERING: 4,
  DRIFTING: 5,
  DRIFTING_AT_SEA: 6,
  MANEUVERING_CII_ADJUSTMENT: 7,

};

/**
 * Reporting type id
 */
export const ReportingTypeConstant = {
  INPORT: 'In Port',
  ATSEA: 'At Sea',
};

/**
 * Different engine status
 */
export const EngineStatus = {
  standByEngine: 'Stand By Engine',
  finishWithEngine: 'Finish With Engine',
  ringFullAway: 'Ring Full Away',
};

/**
 * Load condition constant
 */
export const LoadConditionConstant = {
  LADEN: 1,
  BALLAST: 2,
};

/**
 * Role constance for rolebase system
 */
export const Roles = {
  ES_ADMIN: 'ES_ADMIN', //admin = 1
  MANAGER: 'MANAGER', //Shore Officer = 2
  MASTER: 'MASTER', //DecOfficer = 3
  CHIEFENGINEER: 'CHIEFENGINEER', //CENG = 4
};

/**
 * List of STD Auto fields returned from the API
 */
export const AutoFields = {
  CREATEDBY: 'created_by',
  CREATEDON: 'created_on',
  MODIFIEDBY: 'modified_by',
  MODIFIEDON: 'modified_on',
  ID: 'id',
};

// Vessel Types
export const VesselTypeConstant = {
  TANKER: 'Tanker',
  CONTAINER: 'Container',
  GAS_CARRIER: 'Gas Carrier',
  LNG_CARRIER: 'LNG Carrier',
  GENERAL_CARGO_SHIP: 'General Cargo Ship',
};

/**
 * Vessel machinery constants
 */
export const VesselMachineryConstant = {
  MAIN_ENGINE: 1,
  AUXILIARY_ENGINE: 2,
  EGCS: 3,
  SCR: 4,
  EGR: 5,
  SHAFT_GENERATOR: 6,
  HPPED: 7,
  BOILER: 8,
  INERT_GAS_GENERATOR: 9,
  RE_LIQUIFICATION_PLANT: 10,
  CARGO_COOLING_PLANT: 11,
  GAS_COMBINATION_UNIT: 12,
  AIR_COMPRESSOR: 13,
  CARGO_PUMP_ENGINE_DRIVEN: 14,
  BALLAST_PUMP_ENGINE_DRIVEN: 15,
  POWER_SUPPLY_PANEL: 16,
  HYDRAULIC_POWER_PACK_MOTOR_DRIVEN: 17,
  CARGO_PUMP_MOTOR_DRIVEN: 18,
  BALLAST_PUMP_MOTOR_DRIVEN: 19,
  CARGO_CRANE: 20,
  CARGO_DAVIT: 21,
  BOW_THRUSTER: 22,
  ME_LO_PURIFIER: 23,
  AE_LO_PURIFIER: 24,
};

export const DO_ENGG_ReportingStatus = {
  NEW: 1,
  SAVED: 2,
  COMPLETE: 3,
};

export const ReportingEventName = {
  END_SEA_PASSAGE: 'End of Sea Passage',
  BEGINING_SEA_PASSAGE: 'Beginning of Sea Passage',
};

export const ReportingTypes = {
  IN_PORT: 'In Port',
  AT_SEA: 'At Sea',
};

/** Fuel sub type */
export const FuelSubTypes = {
  HFO: 1,
  LFO: 2,
  LNGB: 3,
};

/** Viscosity constant used to determine fuel sub types for calculations */
export const ViscosityConstant = 80;

/** Reporting events */
export const ReportingEvents = {
  NOON_FWE_IP: 1,
  NOON_SBE_IP: 2,
  NOON_FWE_AS: 3,
  NOON_RFA_AS: 4,
  NOON_SBE_AS: 5,
  EOSP: 7,
  DROP_ANCHOR: 8,
  HEAVE_ANCHOR: 9,
  AT_BERTH: 10,
  DEPARTURE_FROM_BERTH: 11,
  DRIFTING_IP: 12,
  DEPARTURE_FROM_DRIFTING_IP: 13,
  BOSP: 14,
  HASP: 15,
  DRIFTING_AS: 16,
  DEPARTURE_FROM_DRIFTING_AS: 17,
  ROSP: 18,
  CII_ADJUSTMENT: 19,
};

/** Fuel Types */
export const FuelTypes = {
  FUEL_OIL: 1,
  DIESEL_OIL: 2,
  LNG_BUNKER: 3,
  LNG_CARGO: 4,
  LPG: 5,
  H2: 6,
  NH3: 7,
  METHANOL: 8,
  BIO_DIESEL: 9,
  ETHANOL: 10,
  BIO_LNG: 11,
  BIO_H2: 12,
  HVO: 13,
  BIO_METHANOL: 14,
  E_DIESEL: 15,
  E_METHANOL: 16,
  E_LNG: 17,
  E_H2: 18,
  E_NH3: 19,
  E_LPG: 20,
  E_DME: 21,
  OTHER: 22,
  BIO_BLEND: 23,
};

/** STS operations */
export const STSOperations = {
  LOADING: 1,
  DISCHARGING: 2,
  PORT_DISCHARGING: 3,
};

/** Statuses for adjustment approval */
export const AdjustmentApprovalStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DISAPPROVED: 'disapproved',
};

/** Special Operations constant */
export const SpecialOpsConstant = {
  CARGO_COOLING: 1,
  CARGO_DISCHARGING: 2,
  CARGO_RELIQUIFICATION: 3,
  REEFER_CONTAINER: 4,
  CARGO_HEATING: 5,
  TANK_CLEANING: 6,
};

export const ReportingStatusConstant = {
  OPEN: 'open',
  SENT: 'sent',
  VALIDATED: 'validated',
};

export const PortConstant = {
  OTHER: 1,
};

/** Additional Information constant */
export const AddInfoConstant = {
  LFONDS_DECK: 'lfonds_deck',
  LFONDS_ENGG: 'lfonds_engg',
  PROACTIVE_DECK: 'proactive_deck',
  PROACTIVE_ENGG: 'proactive_engg',
  ECON_DECK: 'econ_deck',
  ECON_ENGG: 'econ_engg',
  NONE: 'none',
};

export const PropellerTypeConstant = {
  FPP: 'fixed_pitch_propeller',
};

export const FuelClassConstant = {
  FOSSIL: 1,
  BIOFUEL: 2,
  RFNBO: 3,
  Other: 4,
};

export const BDNConstant = {
  PENDING: 'Pending',
  UPLOADED: 'Uploaded',
};

/**Engineer Reports Constants*/
export const EngineerReportTabsConstant = {
  CargoHeatingId: 7,
  TankCleaningId: 8,
  CargoDischargingId: 9,
  ReeferContainerId: 10,
  CargoCoolingId: 11,
  CargoReliquificationId: 12,
  OtherSpecialOperationId: 13,
  BunkeringId: 14,
  FuelDebunkeringId: 15,
  LubeOilBunkeringId: 16,
  LubeOilDebunkeringId: 17,
};

/**Special Operations Constants*/
export const SpOpsConfigurationConstant = {
  SpOpsGeneralSettingsId: '1',
  SpOpsCargoDischargingId: '2',
  SpOpsCargoCoolingId: '3',
  SpOpsCargoHeatingId: '4',
  SpOpsTankCleaningId: '5',
  SpOpsReeferContainerId: '6',
  SpOpsCargoReliquificationId: '7',
};

/** Maneouvering time limit to consider for Drifting */
export const ManeouveringTimeLimit = 360;

/**
 * Notification list limit is the no. of records shown in Notification-Dropdown
 */
export const NotificationListLimit = 10;

/**
 * Notification User status list
 */
export const NotificationUserStatusConstant = {
  NEW: 'NEW',
  READ: 'READ',
  HIDDEN: 'HIDDEN',
  DELETED: 'DELETED',
};

/**
 * Notification Tab list
 */
export const NotificationActiveTab = {
  INBOX: 'Inbox',
  SENT: 'Sent',
  DRAFT: 'Draft',
  HIDDEN: 'Hidden',
};

/**
 * Notification state list
 */
export const NotificationStateConstant = {
  DRAFT: 'DRAFT',
  SENT: 'SENT',
  HIDDEN: 'HIDDEN',
  DELETED: 'DELETED',
};

/** GHGIE Target constant required for FuelEU Assessment */
export const GHGIETarget = 89.337;

/** Operations during which loading/discharging switch should be visible */
export const LoadingDischargingOps = [
  OperationPrecedence.MANEUVERING,
  OperationPrecedence.MANEUVERING_CII,
  OperationPrecedence.ANCHOR_STAY,
  OperationPrecedence.PORT_STAY,
];

/** LCVs of different fuels in MJ/mt */
export const FUELLCV = {
  HFO: 40500,
  LFO: 41000,
  DO: 42700,
  LNG: 49100,
  LPG: 46000,
  H2: 120000,
  NH3: 18600,
  METHANOL: 19900,
};

/** file status */
export const FileStatus = {
  PENDING: 'pending',
  UPLOADED: 'uploaded',
};

/** Carbon emission factor of DO */
export const CfDO = 3.206;

/** acceptable file types for to upload file */
export const AcceptableFileTypes: any[] = ['pdf', 'jpg', 'jpeg', 'png'];

/** to check if file is pdf or not */
export const PDFExtention: string = 'pdf';

/** maximun file size to be uploaded */
export const maxUploadSizeFile: number = 5242880; //5 MB in bytes

/** costant for delete uploaded or selected file */
export const RemoveFile = {
  SELECTED: 'selected',
  UPLOADED: 'uploaded',
};

/** CTMS Types */
export const CTMSType = {
  LOADING: 'loading',
  DISCHARGING: 'discharging',
};

/** CII Adjustment Events' beginning & end constant */
export const CIIAdjustmentEventBeginningEnd = {
  BEGINNING: 0,
  END: 1,
};

/** Ids of vessel configuration tabs */
export const VesselConfigrationTabs = {
  VESSEL_DATA: 1,
  MACHINERY: 2,
  GENERAL_SETTINGS: 3,
  SFOC: 4,
  VESSEL_FUEL: 5,
  MACHINERY_FUEL: 6,
  ENGINES: 7,
  SPECIAL_OPERATIONS: 8,
  LUBE_OIL: 9,
  SENSOR: 10,
  OTHER: 11,
  SPEED_CONSUMPTION: 12,
};

export const VesselDataConstants={
  VESSEL_GENERAL_INFO: 1,
  SATELLITE_COMMUNICATION: 2,
}

/** Onboarding status constants */
export const OnboardingStatus = {
  PENDING: 'pending',
  CONFIGURED: 'configured',
  ONBOARDED: 'onboarded',
};

/** SFOC Machinery constants */
export const SFOCMachinery = {
  APPLICABLE: 1,
  NOTAPPLICABLE: 0,
};
export const CountersAndFMPrecedence = {
  REVOLUTION_COUNRER: 1,
  ENERGY_METER: 2,
  FLOW_METERS: 3,
};
