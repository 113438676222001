import React, { useState } from 'react'
import { Card, Col, Label, Row } from 'reactstrap'
import ViewFileModal from './ViewFileModal'
import { CSSModule } from 'reactstrap/types/lib/utils';
import { AcceptableFileTypes, PDFExtention, RemoveFile } from 'shared/constants';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from './Toasts';
import { queryClient } from 'react-query/queryClient';
import { errResponse } from 'GenericForms/Helper';
import DeletePopOver from './DeletePopOver';
import PDFLogo from '../Media/pdf_logo.png'
import ToolTip from './ToolTip';

interface FilePreviewInterface {
    fileName?: string;
    url?: string;
    title: string;
    setFile?: any;
    size?: number;
    deleteURL?: string;
    invalidateQuery?: any;
    deleteID?: number;
    DeleteFileObj?: any;
    refreshKey?: number;
    setRefreshKey?: any;
    fileType?: string;
    index?: number;
    file?: any[];
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
    sm?: number;
    fileRemoveKey: number;
    setFileRemoveKey: any;
}

/**
 * Component to view small preview of the file with file name and size
 * @param file 
 * @returns 
 */
const FilePreviewComponent = ({
    fileName,
    url,
    title,
    setFile = () => { },
    size = 0,
    deleteURL = '',
    invalidateQuery = () => { },
    deleteID = 0,
    DeleteFileObj = null,
    refreshKey,
    setRefreshKey = () => { },
    sm = 2,
    fileType = '',
    index,
    file = null,
    fileRemoveKey,
    setFileRemoveKey = () => { },
}: FilePreviewInterface) => {
    /** state variable start */
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalFile, setModalFile] = useState<any>({});
    const toggleModal = () => setIsModalOpen((prev) => !prev);
    const [popOverState, setPopOverState] = useState<boolean>(false);
    /** state variable end */

    /**
     * function to show file preview on modal
     * if file is pdf or image it shows in modal else directly download the file
     * @param name name of the file
     * @param url url or path of the file
     */
    const handlePreviewFiles = (name: any, url: string) => {
        if (name
            && AcceptableFileTypes.some(ext => name.toLowerCase().endsWith(ext))) {
            /** If the file extension matches any in the array, open it in the modal */
            openFileInModal(name, url);
        }
    }

    /**
     * Function to handle state of ViewFileModal
     * @param name file name
     * @param url url or path of the file
     */
    const openFileInModal = (name: string, url: string) => {
        setModalFile({ url, name });
        setIsModalOpen(true);
    };

    /**
     * to remove selected file or delete uploaded file
     */
    const removeFile = async (fileType: string) => {
        /** for selected file */
        if (fileType === RemoveFile.SELECTED) {
            let updateFile = [...file]
            updateFile[index] = {};
            setFile(updateFile);
            return true
        }
        else if (fileType === RemoveFile.UPLOADED) {
            /** for uploaded file */
            apiGlobal.patch(`${deleteURL}${deleteID}/`, DeleteFileObj)
                .then(async (res) => {
                    if (res.status === 200) {
                        successToast("Data saved successfully!");
                        await queryClient.invalidateQueries(invalidateQuery);
                        setRefreshKey(refreshKey + 1);
                        return true
                    }
                })
                .catch(err => {
                    if (errResponse.includes(err?.response?.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
        }

    };
    return (
        <React.Fragment>
            <Col sm={sm ?? 2}>
                <Label className="mb-0">{title} </Label>
                {/** preview of the image */}
                <Card className='width-90'>
                    <Row>
                        <Col sm={2} className='align-self-center'>
                            <img
                                key={refreshKey}
                                className="avatar-sm rounded bg-light ms-1 pointer"
                                alt=""
                                src={
                                    title === 'Selected File' ? fileName?.split('.').pop() === PDFExtention ?
                                        PDFLogo :
                                        `${url}` :
                                        fileName?.split('.').pop() === PDFExtention ?
                                            PDFLogo :
                                            `${url}?${refreshKey}`
                                }
                                onClick={() => {
                                    fileName &&
                                        url &&
                                        handlePreviewFiles(fileName, url)
                                }}
                            />
                        </Col>
                        {/** display's file name with its size in KB or MB or */}
                        <Col sm={9} className='ps-4'>
                            <p className="m-0" id={`title_${index}`}>
                                {fileName && fileName?.length > 12
                                    ? `${fileName?.substring(0, 6)}...${fileName?.split('.').pop()}`
                                    : fileName}
                            </p>
                            <ToolTip message={fileName} target={`title_${index}`} placement='top' />
                            <p className="m-0">
                                {size
                                    ? size > 1024 * 1024
                                        ? `${(size / (1024 * 1024)).toFixed(2)} MB`
                                        : `${(size / 1024).toFixed(2)} KB`
                                    : '- KB'}
                            </p>
                        </Col>
                        {/** cross icon for delete or remove file */}
                        <Col sm={1} className='ps-0'>
                            <i className='bx bx-x icon_s23 pointer'
                                id={`delete_file_${fileType}_${index}`}
                                onClick={() => setPopOverState(true)}
                                key={`delete_${index}`} />
                            <DeletePopOver
                                key={`${index}_pop_over`}
                                target={`delete_file_${fileType}_${index}`}
                                message={`Are you sure you want to delete ${fileName}`}
                                onClick={async () => {
                                    if (fileType) {
                                        const fileRemoved = await removeFile(fileType);
                                        if (fileRemoved && fileType === RemoveFile.SELECTED) {
                                            setFileRemoveKey(fileRemoveKey + 1);
                                        }
                                    }
                                }}
                                state={popOverState}
                                setState={setPopOverState}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            {/** Modal to view Uploaded or selected file */}
            <ViewFileModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                modalFile={modalFile}
                setModalFile={setModalFile}
                toggleModal={toggleModal}
            />
        </React.Fragment >
    )
}

export default FilePreviewComponent;