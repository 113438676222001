import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import { loadReeferContainerDetail } from 'VesselMaster/vesselMaster.hooks';
import { RootState } from 'index';
import { useQuery } from 'react-query';
import { FileStatus } from 'shared/constants';
import { apiMedia } from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { errorToast, successToast } from 'Components/Toasts';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import { errResponse } from 'GenericForms/Helper';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';

const PendingBaplieFile = () => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [modalState, setModalState] = useState(false);
    const [fileObject, setFileObject] = useState<any>({});
    const [baplieFiles, setBaplieFiles] = useState<Array<any>>([{}]);
    /** State variables end */

    /** 
    * Fetches the list of pending EDN files for a specific vessel. 
    * Uses the `useQuery` hook to load EDN files associated with the selected vessel.
    */
    const { data: fileUploadingObj, isLoading: fileUploadingLoading, isError: fileUploadingError } = useQuery(
        [queryKeyes.vessel.ReeferContainerByVessel.key, VesselID, FileStatus.PENDING],
        async () => await loadReeferContainerDetail(VesselID, FileStatus.PENDING),
        { staleTime: Infinity }
    );

    /**
    * Adds a CSS class to the document's body to handle modal styling.
    */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /**
     * Toggles the state of the modal and applies the necessary body styling.
     */
    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /**
    * Sets the modal state to open and stores the file name and object for uploading.
    * @param file - The file object associated with the selected EDN entry.
    */
    const handleUploadButtonClick = (file: any) => {
        setModalState(!modalState);
        setFileObject(file);
    }

    /**
     * To upload Baplie File
     */
    const handleUpload = () => {
        if (fileObject && baplieFiles && baplieFiles.length > 0) {
            fileObject.baplie_file = baplieFiles[0].file
            apiMedia.put(`reefer_container_details/${fileObject.id}/`, fileObject)
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        queryClient.invalidateQueries(
                            queryKeyes.vessel.ReeferContainerByVessel.key
                        );
                        setModalState(!modalState);
                        setBaplieFiles([{}])
                        successToast("File uploaded successfully!");
                    } else {
                        errorToast("Unexpected response, please try again.");
                    }
                })
                .catch(err => {
                    if (err.response && errResponse.includes(err.response.status)) {
                        errorToast("Internal error occurred, please contact the admin");
                    } else {
                        errorToast("Failed to upload the file.");
                    }
                });
        }
    }

    if (fileUploadingLoading) return <div>Loading...</div>;
    if (fileUploadingError) return <div>Error loading files!</div>;
    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {fileUploadingLoading && <Loading message='Loading required data!' />}
                    {fileUploadingError && <ErrorComponent message='Unable to load required data!' />}
                </Col>
            </Row>
            {!fileUploadingLoading && !fileUploadingError &&
                <CardBody className="px-0 py-0 pb-0 mt-2">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th className="p-2 align-middle sr-no-width">#</th>
                                    <th className="p-2 align-middle" style={{ width: '20%' }}>Date & Time(UTC)</th>
                                    <th className='p-2 align-middle' style={{ width: '65%' }}>Name of Report</th>
                                    <th className="p-2 align-middle">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fileUploadingObj.length > 0 ? (
                                    fileUploadingObj.map((file: any, index: number) => (
                                        <tr key={index}>
                                            <td className="p-2 align-middle text-center">{index + 1}</td>
                                            <td className="p-2 align-middle" >{new Date(file.report_date_time).toUTCString()}</td>
                                            <td className='p-2 align-middle' >{file.name_of_report}</td>
                                            <td className="p-2 align-middle text-center">
                                                <Button
                                                    type="button"
                                                    className='btn-sm'
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(file)}>
                                                    Upload
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="p-2 align-middle text-center">
                                            No files are pending for upload
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            }
            <Modal
                size='md'
                isOpen={modalState}
                toggle={() => tog_backdrop()}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <ModalHeader className='p-2'>Upload Pending CII Adjustment File
                    <button
                        onClick={async () => {
                            setModalState(false);
                            setBaplieFiles([{}])
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className='p-2'>
                    <ReportingFileUpload
                        setFile={setBaplieFiles}
                        file={baplieFiles}
                        index={0}
                        sm={5}
                    />
                    <div className="mt-1 mb-5">
                        <Button type="submit" color='primary' className='pos-end' onClick={() => {
                            setModalState(false)
                            handleUpload()
                        }}>Save</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default PendingBaplieFile