import ErrorComponent from 'Components/ErrorComponent';
import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { successToast } from 'Components/Toasts';
import env from 'environment_system/env_system';
import { Field, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import React from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { Button, CardBody, CardFooter, Form, Row } from 'reactstrap'
import { OperationPrecedence } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadAvgInfoObject, loadProactiveAddDetailsObject, loadVesselAvgLoadInfo } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';

const OtherDetailsComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    tabsIdList,
    toggleDynamicTabs,
    previousTabIndex,
    toggleTab,
    record,
    tabsList
}: any) => {
    /** useQueries */
    /** Vessel's average load information */
    const { data: VesselAvgLoadInfo, isLoading: VesselAvgLoadInfoLoading, isError: VesselAvgLoadInfoError } = useQuery(
        [queryKeyes.vessel.VesselAvgLoadInfo, VesselID],
        async () => { return await loadVesselAvgLoadInfo(VesselID) },
        {
            enabled: true,
        }
    );
    /** Average load object used for edit */
    const { data: AvgLoadDetailsObject, isLoading: AvgLoadDetailsObjectLoading, isError: AvgLoadDetailsObjectError } = useQuery(
        [queryKeyes.vessel.AvgLoadDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadAvgInfoObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Additional details object used for edit */
    const { data: AdditionalDetailsObject, isLoading: AdditionalDetailsObjectLoading, isError: AdditionalDetailsObjectError } = useQuery(
        [queryKeyes.vessel.ProactiveAddDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadProactiveAddDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** useQueries end */

    /** Assign values to Additional info's initial object */
    const getInitialAdditionalInfo = () => {
        if (AdditionalDetailsObject && AdditionalDetailsObject[0]?.id > 0) {
            return AdditionalDetailsObject[0];
        } else {
            return ({
                hull_cleaning_date: null,
                average_torque: 0,
                lng_foe_factor: null,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** Assign values to Average load's intial object */
    const getInitialAverageLoad = () => {
        let arr: any[] = [];
        if (AvgLoadDetailsObject && AvgLoadDetailsObject[0]?.id > 0) {
            return AvgLoadDetailsObject;
        } else {
            if (arr !== undefined && VesselAvgLoadInfo !== undefined) {
                VesselAvgLoadInfo.forEach((load: any) => {
                    arr.push({
                        avg_load_percentage: null,
                        avg_load: load?.id,
                        avg_load_name: load?.avg_load_name,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    })
                })
            }
            return arr;
        }
    }

    /** Other details' formik object */
    const OtherDetailsFormik = {
        initialValues: {
            additionalInfo: getInitialAdditionalInfo(),
            avgLoad: getInitialAverageLoad(),
        },
        validationSchema: Yup.object({
            additionalInfo: Yup.object({
                average_torque: Yup.number().nullable(),
            }),
            avgLoad: Yup.array(Yup.object({
                avg_load_percentage: Yup.number().nullable(),
            }),)
        }),
    }

    /** Other details' submit function */
    const otherDetailsSubmit = (values: any) => {
        /** Additional info submit */
        if (AdditionalDetailsObject && AdditionalDetailsObject[0]?.id > 0) {
            apiGlobal.put(`proactive_additional_details/${AdditionalDetailsObject[0]?.id}/`, values?.additionalInfo).then((res: any) => {
                if (res.status === 200) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.ProactiveAddDetailsObject.key);
                    if (env?.form_validation === true) {
                        toggleDynamicTabs(previousTabIndex + 1);
                    }
                }
            })
        } else {
            apiGlobal.post(`proactive_additional_details/`, values?.additionalInfo).then((res: any) => {
                if (res.status === 201) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.ProactiveAddDetailsObject.key);
                    if (env?.form_validation === true) {
                        toggleDynamicTabs(previousTabIndex + 1);
                    }
                }
            })
        }

        /** Vessel Average load details submit */
        if (AvgLoadDetailsObject && AvgLoadDetailsObject[0]?.id > 0) {
            values?.avgLoad?.forEach((load: any) => {
                apiGlobal.put(`proactive_load_details/${load?.id}/`, load).then((res: any) => {
                    if (res.status === 200) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.AvgLoadDetailsObject.key);
                        if (env?.form_validation === true) {
                            toggleDynamicTabs(previousTabIndex + 1);
                        }
                    }
                })
            })
        } else {
            apiGlobal.post(`proactive_load_details/`, values?.avgLoad).then((res: any) => {
                if (res.status === 201) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.AvgLoadDetailsObject.key);
                    if (env?.form_validation === true) {
                        toggleDynamicTabs(previousTabIndex + 1);
                    }
                }
            })
        }
    }
    return (
        <>
            {(VesselAvgLoadInfoLoading || AvgLoadDetailsObjectLoading || AdditionalDetailsObjectLoading) &&
                <Loading message="Loading required data!" />}
            {VesselAvgLoadInfoError && <ErrorComponent message="Error loading required data!" />}
            {AvgLoadDetailsObjectError && getInitialAverageLoad()}
            {AdditionalDetailsObjectError && getInitialAdditionalInfo()}
            {!(VesselAvgLoadInfoLoading || AvgLoadDetailsObjectLoading || AdditionalDetailsObjectLoading) &&
                !(VesselAvgLoadInfoError) &&
                <Formik
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        otherDetailsSubmit(values);
                    }}
                    initialValues={OtherDetailsFormik.initialValues}
                    validationSchema={OtherDetailsFormik.validationSchema}
                >
                    {(props: any) => (
                        <Form onSubmit={props?.handleSubmit} autoComplete="off" noValidate>
                            <CardBody className='p-0'>
                                <Row sm={12} className='m-0'>
                                    <table className='table table-bordered mb-0 no-hover'>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h4 className='mb-0'>Other Details</h4>
                                                    <p className="card-title-desc pos-start">All readings since last report</p>
                                                </th>
                                                <th>
                                                    <h4 className='mb-0'>Average Load Details</h4>
                                                    <p className="card-title-desc pos-start">All readings since last report</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong className='mb-0'>Avg. torque of propulsion turbine</strong>
                                                    <div className='input-group'>
                                                        <Field
                                                            type="text"
                                                            name='additionalInfo.average_torque'
                                                            id='average_torque'
                                                            className='form-control text-right max-width-7'
                                                            disabled={!(record && record?.operation_precedence_id === OperationPrecedence.SEA_PASSAGE)}
                                                        />
                                                        <div className='input-group-text'>nm</div>
                                                        {props?.errors.additionalInfo && props?.errors?.additionalInfo?.average_torque && env?.form_validation === true &&
                                                            <ErrorTooltip
                                                                target={`average_torque`}
                                                                message={props?.errors?.additionalInfo?.average_torque}
                                                                open={(props?.errors?.additionalInfo && props?.errors?.additionalInfo?.average_torque) ? true : false}
                                                            />
                                                        }
                                                    </div>
                                                </td>
                                                <td className='p-0'>
                                                    <div className="table-responsive p-0">
                                                        <table className="table mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className='p-2 align-middle sr-no-width' style={{ width: '10%' }}>#</th>
                                                                    <th className='p-2 align-middle'>Machinery name</th>
                                                                    <th className='p-2 align-middle' style={{ width: '30%' }}>Average load percentage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {props?.values?.avgLoad && props?.values?.avgLoad?.length <= 0 ?
                                                                    <tr>
                                                                        <td colSpan={3} className='text-center'> No average load details found</td>
                                                                    </tr> :
                                                                    props?.values?.avgLoad?.map((load: any, index: number) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                <td className='p-2 align-middle'>{load?.avg_load_name}</td>
                                                                                <td className='p-2 align-middle'>
                                                                                    <div className='input-group'>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`avgLoad.${index}.avg_load_percentage`}
                                                                                            id={`avg_load_percentage_${index}`}
                                                                                            className='form-control text-right max-width-7'
                                                                                        />
                                                                                        <div className='input-group-text'>%</div>
                                                                                        {props?.errors?.avgLoad && props?.errors?.avgLoad[index]?.avg_load_percentage && env?.form_validation === true &&
                                                                                            <ErrorTooltip
                                                                                                target={`avg_load_percentage_${index}`}
                                                                                                message={props?.errors?.avgLoad[index]?.avg_load_percentage}
                                                                                                open={(props?.errors?.avgLoad && props?.errors?.avgLoad[index]?.avg_load_percentage) ? true : false}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                            if (env?.form_validation === false) {
                                                toggleDynamicTabs(previousTabIndex + 1);
                                            }
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        {tabsIdList[0] === 19 && (record && record.operation_precedence_id === OperationPrecedence.SEA_PASSAGE) ?
                                            <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                props.setErrors({})
                                                toggleTab(tabsList[0]?.id - 1);
                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                            tabsIdList[0] === 19 && (record && record.operation_precedence_id !== OperationPrecedence.SEA_PASSAGE) ?
                                                <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                    props.setErrors({})
                                                    toggleTab(tabsList[0]?.id - 3);
                                                }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                                <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                    props.setErrors({})
                                                    props.errors = {};
                                                    toggleDynamicTabs(previousTabIndex - 1);
                                                }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                        }
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[props?.values, props?.errors, OtherDetailsFormik.initialValues]} />
                        </Form >
                    )}
                </Formik >
            }
        </>
    )
}

export default OtherDetailsComponent