import React, { useEffect } from 'react';
import { Button, Row, Col, Card, Input, CardBody, CardFooter, Form, Label } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { fetchMachinaryOptions } from '../../VesselMaster/vesselMaster.hooks';
import { checkEmptyStringValue, handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { FormikProps, useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from "yup";
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';
import { SFOCMachinery, VesselConfigrationTabs } from "shared/constants";
import ToolTip from "Components/ToolTip";
import { TooltipMsg } from "Components/ToolTipMessage";
import { queryClient } from 'react-query/queryClient';

interface SFOCType {
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
    VesselConfActiveTab: number;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
}

const SFOC = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
    VesselConfActiveTab,
    setCheckValuesBeforeSwitch,
    setTabName,
    setFormik,
}: SFOCType) => {
    /** State variables */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    /** State variables end */

    /** Queries */
    /** Machinery on vessel */
    const { data: SFOCObject, isLoading: SFOCObjectLoading, isError: SFOCObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselMachineries.key, VesselID],
            async () => { return await fetchMachinaryOptions(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** Queries end */

    /** Assign initial values to formik object */
    const getInitialValueSFOCForm = () => {
        if (SFOCObject?.length > 0 && SFOCObject[0].id > 0) {
            return SFOCObject;
        }
    }

    /** Validation schema of SFOC */
    const SFOCValidationSchema = Yup.object().shape({
        sfoc: Yup.array().of(
            Yup.object().shape({
                eedi_eexi_technical_file: Yup.string().nullable(),
                nox_technical_file: Yup.string().nullable(),
            })
        )
    })

    /** Formik object */
    const sfocFormik: any = useFormik({
        initialValues: { sfoc: getInitialValueSFOCForm() },
        enableReinitialize: true,
        validationSchema: SFOCValidationSchema,
        onSubmit: async () => {
            const responseArray: any[] = []
            sfocFormik.setSubmitting(false);
            if (SFOCObject?.length > 0 && SFOCObject[0].id > 0) {
                sfocFormik?.values.sfoc.forEach((machine: any) => {
                    responseArray.push(apiGlobal.put(`/vessel_machinery_list/${machine.id}/`, machine))
                })
            }
            await handleServerResponse(responseArray).then(async (res) => {
                if (res) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
                    await setRefreshVesselMachineries(refreshVesselMachineries + 1);
                }
            })
        }
    });

    const tabName = 'Other';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = JSON.stringify(values) === JSON.stringify(initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        return areValuesEqual;
    };

    /** useEffect */
    useEffect(() => {
        if (VesselConfActiveTab === VesselConfigrationTabs.SFOC && sfocFormik.values !== sfocFormik.initialValues) {
            setFormik(sfocFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab, sfocFormik]);
    useEffect(() => {
        if (VesselConfActiveTab !== VesselConfigrationTabs.SFOC) {
            sfocFormik?.setTouched({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab]);
    useEffect(() => {
        sfocFormik.initialValues.sfoc = getInitialValueSFOCForm();
        sfocFormik.values.sfoc = getInitialValueSFOCForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SFOCObject])
    useEffect(() => {
        handleFormikStateChange(sfocFormik.values, sfocFormik.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sfocFormik.values, sfocFormik.initialValues]);
    /** useEffect end */

    if (SFOCObjectLoading) {
        return <Loading message='Loading required data!' />
    } else if (SFOCObjectError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <Card className='border-0'>
                {(SFOCObjectLoading) && <Loading message='Loading required data!' />}
                {(SFOCObjectError) && <ErrorComponent message='Unable to load required data!' />}
                {!(SFOCObjectLoading) &&
                    !(SFOCObjectError) &&
                    <Form onSubmit={sfocFormik?.handleSubmit} noValidate autoComplete='off'>
                        <CardBody className='p-0'>
                            <Row className="mb-2">
                                <Col sm={{ offset: 10, size: 2 }}>
                                    <Button
                                        type="button"
                                        color='primary'
                                        className='btn btn-primary waves-effect btn-label waves-light justify_right'
                                        onClick={() => {
                                            queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
                                            setRefreshVesselMachineries(refreshVesselMachineries + 1);
                                            sfocFormik.resetForm({
                                                values: { sfoc: getInitialValueSFOCForm() },
                                            });
                                        }}
                                    >
                                        <i className="mdi mdi-reload label-icon me-1" />Refresh
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="px-0">
                                    <div className="table-responsive p-0">
                                        <table className="table mb-2" key={refreshVesselMachineries}>
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                    <th className='p-2 align-middle'>Machinery Name</th>
                                                    <th className='p-2 text-center align-middle' style={{ width: '35%' }}>
                                                        <Label
                                                            id="EEDI_EEXI_technical_file"
                                                            className="mb-0"
                                                            for="EEDI_EEXI_technical_file"
                                                        >
                                                            <strong> EEDI/EEXI technical file</strong>
                                                            <i
                                                                className="bx bx-info-circle ml-2p "
                                                            />
                                                        </Label>
                                                        <ToolTip
                                                            target="EEDI_EEXI_technical_file"
                                                            message={`${TooltipMsg.SFOC.filter(
                                                                (item: any) =>
                                                                    item.target === "EEDI_EEXI_technical_file"
                                                            ).map((tool: any) => {
                                                                return tool.message;
                                                            })}`}
                                                        />
                                                    </th>
                                                    <th className='p-2 text-center align-middle' style={{ width: '35%' }}>
                                                        <Label
                                                            id="NOx_technical_file"
                                                            className="mb-0"
                                                            for="NOx_technical_file"
                                                        >
                                                            <strong> NOx technical file</strong>
                                                            <i
                                                                className="bx bx-info-circle ml-2p "
                                                            />
                                                        </Label>
                                                        <ToolTip
                                                            target="NOx_technical_file"
                                                            message={`${TooltipMsg.SFOC.filter(
                                                                (item: any) =>
                                                                    item.target === "NOx_technical_file"
                                                            ).map((tool: any) => {
                                                                return tool.message;
                                                            })}`}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sfocFormik?.values && sfocFormik?.values?.sfoc?.filter((machine: any) => machine.sfoc_precedence_id === SFOCMachinery.NOTAPPLICABLE).length === 0
                                                        ? (
                                                            <tr>
                                                                <td colSpan={4} className="text-center">No SFOC related machines added.</td>
                                                            </tr>
                                                        )
                                                        : sfocFormik?.values?.sfoc?.filter((machine: any) => machine.sfoc_precedence_id === SFOCMachinery.NOTAPPLICABLE).map((sfoc: any, index: number) => {
                                                            return (
                                                                <tr key={sfoc?.id}>
                                                                    <td className="p-2 align-middle text-center">{index + 1}</td>
                                                                    <td className="p-2 align-middle">{sfoc?.vessel_machinery_name}</td>
                                                                    <td className="p-2 align-middle text-center">
                                                                        <div className="d-inline-block">
                                                                            <div className="input-group">
                                                                                <Input
                                                                                    name={`sfoc.${index}.eedi_eexi_technical_file`}
                                                                                    type="text"
                                                                                    id={`eedi_eexi_technical_file_${index}`}
                                                                                    className="max-width-7 text-right"
                                                                                    value={sfocFormik.values?.sfoc[index]?.eedi_eexi_technical_file || ""}
                                                                                    onChange={(e: any) => {
                                                                                        if (e.target.value === "") {
                                                                                            sfocFormik?.setFieldValue(`sfoc.${index}.eedi_eexi_technical_file`, null);
                                                                                        } else {
                                                                                            sfocFormik.handleChange(e);
                                                                                            sfocFormik.handleBlur(e);
                                                                                        }
                                                                                        const fieldName = `sfoc.${index}.eedi_eexi_technical_file`;
                                                                                        checkEmptyStringValue(e, fieldName, sfocFormik?.setFieldValue);
                                                                                    }}
                                                                                    onBlur={(e: any) => {
                                                                                        if (e.target.value === "") {
                                                                                            sfocFormik?.setFieldValue(`sfoc.${index}.eedi_eexi_technical_file`, null);
                                                                                        } else {
                                                                                            sfocFormik.handleChange(e);
                                                                                            sfocFormik.handleBlur(e);
                                                                                        }
                                                                                        const fieldName = `sfoc.${index}.eedi_eexi_technical_file`;
                                                                                        checkEmptyStringValue(e, fieldName, sfocFormik?.setFieldValue);
                                                                                    }}
                                                                                />
                                                                                <div className="input-group-text">g/kWh</div>
                                                                                {env?.form_validation === true && (
                                                                                    <ErrorTooltip
                                                                                        target={`eedi_eexi_technical_file_${index}`}
                                                                                        message={sfocFormik?.errors && sfocFormik?.errors?.sfoc && sfocFormik?.errors?.sfoc[index]?.eedi_eexi_technical_file}
                                                                                        open={sfocFormik?.errors && sfocFormik?.errors?.sfoc && sfocFormik?.touched && sfocFormik?.touched?.sfoc &&
                                                                                            sfocFormik?.touched?.sfoc[index]?.eedi_eexi_technical_file &&
                                                                                            sfocFormik?.errors?.sfoc[index]?.eedi_eexi_technical_file
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-2 align-middle text-center">
                                                                        <div className="d-inline-block">
                                                                            <div className="input-group">
                                                                                <Input
                                                                                    name={`sfoc.${index}.nox_technical_file`}
                                                                                    type="text"
                                                                                    id={`nox_technical_file-${index}`}
                                                                                    className="max-width-7 text-right"
                                                                                    onChange={(e: any) => {
                                                                                        if (e.target.value === "") {
                                                                                            sfocFormik?.setFieldValue(`sfoc.${index}.nox_technical_file`, null);
                                                                                        } else {
                                                                                            sfocFormik.handleChange(e);
                                                                                            sfocFormik.handleBlur(e);
                                                                                        }
                                                                                        checkEmptyStringValue(e, `sfoc.${index}.nox_technical_file`, sfocFormik?.setFieldValue);
                                                                                    }}
                                                                                    onBlur={(e: any) => {
                                                                                        if (e.target.value === "") {
                                                                                            sfocFormik?.setFieldValue(`sfoc.${index}.nox_technical_file`, null);
                                                                                        } else {
                                                                                            sfocFormik.handleChange(e);
                                                                                            sfocFormik.handleBlur(e);
                                                                                        }
                                                                                        checkEmptyStringValue(e, `sfoc.${index}.nox_technical_file`, sfocFormik?.setFieldValue);
                                                                                    }}
                                                                                    defaultValue={sfocFormik.values?.sfoc[index]?.nox_technical_file}
                                                                                />
                                                                                <div className="input-group-text">g/kWh</div>
                                                                                {env?.form_validation === true && (
                                                                                    <ErrorTooltip
                                                                                        target={`nox_technical_file-${index}`}
                                                                                        message={sfocFormik?.errors && sfocFormik?.errors?.sfoc && sfocFormik?.errors?.sfoc[index]?.nox_technical_file}
                                                                                        open={sfocFormik?.errors && sfocFormik?.errors?.sfoc && sfocFormik?.touched && sfocFormik?.touched?.sfoc &&
                                                                                            sfocFormik?.touched?.sfoc[index]?.nox_technical_file &&
                                                                                            sfocFormik?.errors?.sfoc[index]?.nox_technical_file
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            )
                                                        })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='p-2 py-3 mb-3'>
                            <Row>
                                <Col className='pb-3'>
                                    <Button type="submit" color='primary' className='pos-end' disabled={isConfigurationButtonDisabled(vessel)}
                                    >Save</Button>
                                </Col>
                            </Row>
                        </CardFooter>
                        <Row className='mt-2'>
                            <FormValuesDebug values={[sfocFormik?.values, sfocFormik?.initialValues, sfocFormik?.touched]} />
                        </Row>
                    </Form>
                }
                <p>  - Set values of engine SFOC as per EEDI /EEXI & Nox technical files.</p>
            </Card >
        );
    };
}
export default SFOC;
