import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Input, Label, Row } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { FormikProps, useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadTankCleaningWithkWh } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { queryClient } from 'react-query/queryClient';
import { useEffect } from 'react';
import { SpOpsConfigurationConstant } from 'shared/constants';

interface TankCleaningType {
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    activeTab: any;
    setTabFormik?: (value: FormikProps<any>) => void;
}

export default function TankCleaning({
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    activeTab,
    setTabFormik,
}: TankCleaningType) {
    /** Retrieves the VesselID from the Redux store. */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /**
     * Fetches the tank cleaning data along with machinery kWh meter details using the `useQuery` hook.
     * It loads data specific to the vessel's ID and keeps the result in cache indefinitely (`staleTime: Infinity`).
     */
    const {
        data: tankCleaningObject,
        isLoading: tankCleaningLoading,
        isError: tankCleaningError,
    } = useQuery(
        [queryKeyes.vessel.TankCleaningWithkWhMachineries.key, VesselID],
        async () => {
            return await loadTankCleaningWithkWh(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /**
     * Determines the initial values for the tank cleaning form based on the fetched data.
     * If there is any tank cleaning data available, it is returned for use as initial values.
     * 
     * @returns {Array} Initial values for tank cleaning operations or an empty array.
     */
    const getInitialvaluesSpecialOperationTankCleaning = () => {
        if (tankCleaningObject && tankCleaningObject.length > 0) {
            return tankCleaningObject;
        } else {
            return [];
        }
    };

    /** Defines the Formik configuration object for handling the tank cleaning form. */
    const SpecialOperationTankCleaningFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            tankCleaning: getInitialvaluesSpecialOperationTankCleaning(),
        },
        onSubmit: () => {
            SpecialOperationTankCleaningFormik.setSubmitting(true);
            SpecialOperationTankCleaningFormik?.values?.tankCleaning?.forEach((machine: any) => {
                apiGlobal
                    .put(`/special_operation_energy_setting/${machine.id}/`, machine)
                    .then((res) => {
                        if (res.status === 200) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(queryKeyes.vessel.TankCleaningWithkWhMachineries.key)
                        }
                    })
                    .catch((err) => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occurred, please contact the admin");
                        }
                    });
            });
            SpecialOperationTankCleaningFormik.setSubmitting(false);
        },
    });

    const tabName = 'Tank Cleaning';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = JSON.stringify(values) === JSON.stringify(initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    /**Start of UseEffect */
    useEffect(() => {
        if (activeTab === SpOpsConfigurationConstant.SpOpsTankCleaningId && SpecialOperationTankCleaningFormik?.values !== SpecialOperationTankCleaningFormik?.initialValues) {
            setTabFormik(SpecialOperationTankCleaningFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, SpecialOperationTankCleaningFormik]);
    useEffect(() => {
        handleFormikStateChange(SpecialOperationTankCleaningFormik?.values, SpecialOperationTankCleaningFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpecialOperationTankCleaningFormik?.values, SpecialOperationTankCleaningFormik?.initialValues]);


    if (tankCleaningLoading) {
        return <Loading message='Loading required data!' />
    } else if (tankCleaningError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <Card className="border-0">
                {tankCleaningLoading && <Loading message="Loading required data!" />}
                {tankCleaningError && <ErrorComponent message="Unable to load required data!" />}
                {!tankCleaningLoading && !tankCleaningError &&
                    <form
                        onSubmit={SpecialOperationTankCleaningFormik?.handleSubmit}
                        noValidate
                        autoComplete="off"
                    >
                        <CardBody className="p-0">
                            <Row>
                                <Col sm={12}>
                                    <div className="table-responsive mb-5">
                                        <table className="table">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="p-2 text-center align-middle sr-no-width">#</th>
                                                    <th className="p-2 align-middle">Machinery name</th>
                                                    <th className="p-2 align-middle text-center">
                                                        Is kWh meter fitted on power pack/electrical motor panel?
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SpecialOperationTankCleaningFormik?.initialValues?.tankCleaning?.map(
                                                    (cargoHeating: any, index: any) => (
                                                        <tr key={index}>
                                                            <td className="p-2 align-middle text-center">
                                                                {index + 1}
                                                            </td>
                                                            <td className="p-2 align-middle">
                                                                {cargoHeating.vessel_machinery_ec_group_name}
                                                            </td>
                                                            <td className="p-2 align-middle text-center">
                                                                <div className="square-switch sqswitch mt-1">
                                                                    <Input
                                                                        type="checkbox"
                                                                        switch="none"
                                                                        name={`tankCleaning.${index}.tank_cleaning_kwh_meter`}
                                                                        id={`tank_cleaning_kwh_meter_${index}`}
                                                                        defaultChecked={
                                                                            SpecialOperationTankCleaningFormik?.values
                                                                                ?.tankCleaning[index]
                                                                                ?.tank_cleaning_kwh_meter
                                                                        }
                                                                        onChange={(e: any) =>
                                                                            SpecialOperationTankCleaningFormik.setFieldValue(
                                                                                `tankCleaning.${index}.tank_cleaning_kwh_meter`,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    />
                                                                    <Label
                                                                        htmlFor={`tank_cleaning_kwh_meter_${index}`}
                                                                        data-on-label="Yes"
                                                                        data-off-label="No"
                                                                        className="mb-0"
                                                                    ></Label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="p-2 py-3 mb-3">
                            <Button
                                type="submit"
                                color="primary"
                                className="justify_right"
                                disabled={isConfigurationButtonDisabled(vessel)}
                            >
                                Save
                            </Button>
                        </CardFooter>
                        <Row className="mt-2">
                            <FormValuesDebug
                                values={[
                                    SpecialOperationTankCleaningFormik?.values,
                                    SpecialOperationTankCleaningFormik?.errors,
                                    SpecialOperationTankCleaningFormik?.initialValues,
                                ]}
                            />
                        </Row>
                    </form>
                }
            </Card>
        );
    }
}
