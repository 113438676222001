import { Col, Row, Card, CardBody, Form, Input, CardFooter, Button } from "reactstrap";
import { fetchMachinaryOptions } from '../../../vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { FormikProps, useFormik } from 'formik';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from "yup";
import { handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import ToolTip from "Components/ToolTip";
import { hasRole } from "utils/auth/authUtils";
import { Roles, VesselConfigrationTabs } from "shared/constants";
import React, { useEffect } from "react";
import { queryClient } from "react-query/queryClient";

interface GeneralSettingsType {
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
    setCheckValuesBeforeSwitch: (value: boolean) => void;
    setTabName: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    VesselConfActiveTab: number;
}

const GeneralSettings = ({
    refreshVesselMachineries,
    setRefreshVesselMachineries,
    setCheckValuesBeforeSwitch,
    setTabName,
    setFormik,
    VesselConfActiveTab,
}: GeneralSettingsType) => {
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    /** Queries */
    /** Machinery on vessel */
    const { data: Machinary, isLoading: GeneralSettingLoading, isError: GeneralSettingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselMachineries.key, VesselID],
            async () => { return await fetchMachinaryOptions(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Assign initial values to formik object */
    const getInitailValueGeneralSettings = () => {
        if (Machinary?.length > 0 && Machinary[0].id > 0) {
            return Machinary
        } else {
            return []
        }
    }

    /** General Settings Formik Object */
    const GeneralSettingsFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            generalSettings: getInitailValueGeneralSettings()
        },
        validationSchema: Yup.object().shape({
            generalSettings: Yup.array(Yup.object({
                running_hour: Yup.boolean(),
                energy_parameter: Yup.boolean(),
                sfoc_required: Yup.boolean()
            }))
        }),
        onSubmit: async () => {
            GeneralSettingsFormik.setSubmitting(false);
            let responseArray: any[] = [];
            if (Machinary?.length > 0 && Machinary[0].id > 0) {
                GeneralSettingsFormik?.values?.generalSettings?.forEach((machine: any) => {
                    responseArray.push(apiGlobal.put(`/vessel_machinery_list/${machine.id}/`, machine));
                })
                /** handle server response */
                await handleServerResponse(responseArray).then(async (res) => {
                    if (res) {
                        await queryClient.invalidateQueries(queryKeyes.vessel.EconFMCounterObject.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
                        setRefreshVesselMachineries(refreshVesselMachineries + 1);
                    }
                });
            }
        }
    })

    const tabName = 'General Settings';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = JSON.stringify(values) === JSON.stringify(initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        return areValuesEqual;
    };

    /**Start of useEffect */
    useEffect(() => {
        if (VesselConfActiveTab === VesselConfigrationTabs.GENERAL_SETTINGS && GeneralSettingsFormik.values !== GeneralSettingsFormik.initialValues) {
            setFormik(GeneralSettingsFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab, GeneralSettingsFormik]);
    useEffect(() => {
        handleFormikStateChange(GeneralSettingsFormik?.values, GeneralSettingsFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GeneralSettingsFormik?.values, GeneralSettingsFormik?.initialValues]);
    /**End of useEffect */

    if (GeneralSettingLoading) {
        return <Loading message='Loading required data!' />
    } else if (GeneralSettingError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <Card className='border-0'>
                {(GeneralSettingLoading) && <Loading message='Loading required data!' />}
                {(GeneralSettingError) && <ErrorComponent message='Unable to load required data!' />}
                {!(GeneralSettingLoading) && !(GeneralSettingError) &&
                    <Form onSubmit={GeneralSettingsFormik?.handleSubmit} noValidate autoComplete='off'>
                        <CardBody className='p-0'>
                            <Row className="mb-2">
                                <Col sm={{ offset: 10, size: 2 }}>
                                    <Button
                                        type="button"
                                        color='primary'
                                        className='btn btn-primary waves-effect btn-label waves-light justify_right'
                                        onClick={() => {
                                            queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
                                            setRefreshVesselMachineries(refreshVesselMachineries + 1);
                                            GeneralSettingsFormik.resetForm({
                                                values: { generalSettings: getInitailValueGeneralSettings() },
                                            });
                                        }}
                                    >
                                        <i className="mdi mdi-reload label-icon me-1" />Refresh
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <div className="table-responsive p-0">
                                        <table className="table mb-2" key={refreshVesselMachineries}>
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                    <th className='p-2 align-middle'>Vessel machinery name</th>
                                                    <th className='p-2 align-middle'>Machinery</th>
                                                    <th className='p-2 text-center align-middle' id="running_hour_msg">Runnning hours <i className='bx bx-info-circle ml-2p' /></th>
                                                    <th className='p-2 text-center align-middle' id="energy_parameter_msg">Energy parameter <i className='bx bx-info-circle ml-2p' /></th>
                                                    {hasRole(Roles.ES_ADMIN) &&
                                                        <React.Fragment>
                                                            <th className='p-2 text-center align-middle'>SFOC <i className='bx bx-info-circle ml-2p' id="sfoc_msg" /></th>
                                                            <ToolTip target="sfoc_msg" message="Default checked for engines" />
                                                        </React.Fragment>
                                                    }
                                                    <ToolTip target="energy_parameter_msg" message="Check the box if machinery is fitted with energy meter / Kilowatt-hour meter" />
                                                    <ToolTip target="running_hour_msg" message="Check the box if running hours need to be reported in the daily reporting" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {GeneralSettingsFormik?.values &&
                                                        GeneralSettingsFormik?.values?.generalSettings.map((generalSettings: any, index: number) => {
                                                            return (
                                                                <tr key={generalSettings.id}>
                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                    <td className='p-2 align-middle'>{generalSettings.vessel_machinery_name}</td>
                                                                    <td className='p-2 align-middle'>{generalSettings.machinery}</td>
                                                                    <td className='p-2 align-middle text-center'>
                                                                        <Input
                                                                            type='checkbox'
                                                                            id={`general-setting-${index}`}
                                                                            name={`generalSettings.${index}.running_hour`}
                                                                            checked={GeneralSettingsFormik?.values?.generalSettings[index]?.running_hour}
                                                                            onChange={(e: any) => {
                                                                                GeneralSettingsFormik?.handleChange(e);
                                                                            }}
                                                                            defaultValue={GeneralSettingsFormik?.values?.generalSettings[index]?.running_hour}
                                                                        />
                                                                    </td>
                                                                    <td className='p-2 align-middle text-center'>
                                                                        <Input
                                                                            type='checkbox'
                                                                            id={`general-setting-${index}`}
                                                                            name={`generalSettings.${index}.energy_parameter`}
                                                                            checked={GeneralSettingsFormik.values?.generalSettings[index]?.energy_parameter}
                                                                            onChange={(e: any) => {
                                                                                GeneralSettingsFormik?.handleChange(e);
                                                                            }}
                                                                            defaultValue={GeneralSettingsFormik.values?.generalSettings[index]?.energy_parameter}
                                                                        />
                                                                    </td>
                                                                    {hasRole(Roles.ES_ADMIN) &&
                                                                        <td className='p-2 align-middle text-center'>
                                                                            <Input
                                                                                type='checkbox'
                                                                                id={`general-setting-${index}`}
                                                                                name={`generalSettings.${index}.sfoc_required`}
                                                                                checked={GeneralSettingsFormik.values?.generalSettings[index]?.sfoc_required}
                                                                                onChange={(e: any) => {
                                                                                    GeneralSettingsFormik?.handleChange(e);
                                                                                }}
                                                                                defaultValue={GeneralSettingsFormik.values?.generalSettings[index]?.sfoc_required}
                                                                            />
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='p-2'>
                            <Button type="submit" color='primary' className='justify_right' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                        </CardFooter>
                        <Row className='mt-2'>
                            <FormValuesDebug values={[GeneralSettingsFormik?.values, GeneralSettingsFormik?.errors, GeneralSettingsFormik.initialValues]} />
                        </Row>
                    </Form>
                }
            </Card >
        )
    };
};
export default GeneralSettings;
