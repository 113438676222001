import React, { useCallback, useEffect, useMemo, useState } from "react";
import ToolTip from "../../../Components/ToolTip";
import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "reactstrap";
import ReportDetailsHeader from "../../../Components/ReportDetailsHeader";
import { TooltipMsg } from "../../../Components/ToolTipMessage";
import AsyncSelect from "react-select/async";
import { Field, FieldProps, Formik } from "formik";
import * as Yup from "yup";
import { customStyle, errorStyle } from "../../../shared/CommonCSS";
import { errorToast, successToast } from "../../../Components/Toasts";
import apiGlobal from "../../../global/api.global";
import {
    PortConstant,
    ReportingEventName,
    ReportingEvents,
    ReportingTypeConstant,
    ReportingTypes,
} from "../../../shared/constants";
import { ETADData } from "../../../shared/dataTypes";
import FormValuesDebug from "../../../utils/debugTools/FormValuesDebug";
import {
    dateTimeFormat,
    errResponse,
    searchCountries,
    searchPorts,
} from "GenericForms/Helper";
import "../../../global/GlobalCSS.css";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
    loadETADObject,
    loadOtherPorts,
    PreviousVesselReportingInfo,
} from "VesselMaster/vesselMaster.hooks";
import Loading from "Components/Loading";
import { queryClient } from "react-query/queryClient";
import env from "environment_system/env_system";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import ErrorTooltip from "Components/ErrorTooltip";

interface ETADComponentForm {
    record: any;
    lastRecord: any;
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    toggleTab: any;
    activeTab: number;
    etaDataobject: ETADData;
}

const ETADComponent = ({
    record,
    lastRecord,
    ReportID,
    VesselID,
    VoyageID,
    toggleTab,
    activeTab,
    etaDataobject,
}: ETADComponentForm) => {
    /**
     * Fields availability as per conditions
     */
    const fields = useMemo(() => {
        const initialFields = {
            arrival_port: false,
            is_arrival_port_in_eca: false,
            expected_time_of_arrival_at_eca: false,
            masters_eta_at_pilot_station: false,
            departure_port: false,
            master_etd_from_present_port: false,
            is_departure_port_in_eca: false,
            expected_time_of_depature_from_eca: false,
            vessel_transit: true,
            date_and_time_of_arrival_at_eca: false,
            date_and_time_of_departure_from_eca: false,
        };
        // ... any additional logic for initializing fields ...
        return initialFields;
    }, []);

    /**
     * State variables for this component
     */
    const [otherArrPort, setOtherArrPort] = useState(false);
    const [otherDepPort, setOtherDepPort] = useState(false);
    const [fieldAvailability, setFieldAvailability] = useState(fields);
    const [addOtherArrPort, setAddOtherArrPort] = useState(false);
    const [addOtherDepPort, setAddOtherDepPort] = useState(false);

    //Date conversions
    if (etaDataobject && etaDataobject?.masters_eta_at_pilot_station) {
        etaDataobject.masters_eta_at_pilot_station = dateTimeFormat(
            etaDataobject.masters_eta_at_pilot_station
        );
    }

    //Date conversions
    if (etaDataobject && etaDataobject?.expected_time_of_arrival_at_eca) {
        etaDataobject.expected_time_of_arrival_at_eca = dateTimeFormat(
            etaDataobject.expected_time_of_arrival_at_eca
        );
    }

    /** Queries start */
    /** ETAD object used for edit */
    const {
        data: ETADObject,
        isLoading: ETADObjectLoading,
        isError: ETADObjectError,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.ETADObject.key, VesselID, ReportID],
        async () => {
            return await loadETADObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Other Ports from master */
    const {
        data: OtherPortsObject,
        isLoading: OtherPortsLoading,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.masters.OtherPortMaster.key, VesselID, ReportID],
        async () => {
            return await loadOtherPorts();
        },
        { staleTime: Infinity }
    );

    /** Get Previous Vessel Report */
    const {
        data: PreviousVesselReportObject,
    }: { data: any; } = useQuery(
        [queryKeyes.vessel.PreviousVesselReport.key, VesselID, ReportID],
        async () => {
            return await PreviousVesselReportingInfo(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to initial object of ETAD */
    const getInitialETAD = () => {
        if (ETADObject?.length > 0 && ETADObject[0].id > 0) {
            return ETADObject[0];
        } else {
            return {
                masters_eta_at_pilot_station: null,
                expected_time_of_arrival_at_eca: null,
                expected_time_of_depature_from_eca: null,
                is_arrival_port_in_eca: false,
                master_etd_from_present_port: null,
                is_departure_port_in_eca: false,
                vessel_transit: false,
                date_and_time_of_arrival_at_eca: null,
                date_and_time_of_departure_from_eca: null,
                arrival_port: null,
                other_arrival_port: null,
                other_arrival_port_name: null as string,
                other_arrival_port_country: null,
                departure_port: null,
                other_departure_port: null,
                other_departure_port_name: null as string,
                other_departure_port_country: null,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
        }
    };

    /** UseEffect */
    useEffect(() => {
        ETADFormik.initialValues = { getInitialETAD };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ETADObject]);

    const ETADFormik = {
        initialValues: getInitialETAD(),
        validationSchema: Yup.object().shape({
            masters_eta_at_pilot_station: Yup.date().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !fieldAvailability.masters_eta_at_pilot_station
                        ? schema.nullable()
                        : schema.min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            `${commonValidationMessages.minDateLastRecord}`
                        ).required(commonValidationMessages.required);
                }
            ),
            is_arrival_port_in_eca: Yup.boolean(),
            master_etd_from_present_port: Yup.date().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !fieldAvailability.master_etd_from_present_port
                        ? schema.nullable()
                        : schema.min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            `${commonValidationMessages.minDateLastRecord}`
                        ).required(commonValidationMessages.required);
                }
            ),
            expected_time_of_depature_from_eca: Yup.date().when(
                "is_departure_port_in_eca",
                {
                    is: true,
                    then: (schema: any) =>
                        schema.min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            `${commonValidationMessages.minDateLastRecord}`
                        ).required(commonValidationMessages.required),
                    otherwise: (schema: any) => schema.nullable(),
                }
            ),
            expected_time_of_arrival_at_eca: Yup.date().when(
                "is_arrival_port_in_eca",
                {
                    is: true,
                    then: (schema: any) =>
                        schema.min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            `${commonValidationMessages.minDateLastRecord}`
                        ).required(commonValidationMessages.required),
                    otherwise: (schema: any) => schema.nullable(),
                }
            ),
            is_departure_port_in_eca: Yup.boolean(),
            vessel_transit: Yup.boolean(),
            date_and_time_of_arrival_at_eca: Yup.date().when(
                "vessel_transit",
                {
                    is: true,
                    then: (schema: any) =>
                        schema.min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            `${commonValidationMessages.minDateLastRecord}`
                        ).required(commonValidationMessages.required),
                    otherwise: (schema: any) => schema.nullable(),
                }
            ),
            date_and_time_of_departure_from_eca: Yup.date().when(
                "vessel_transit",
                {
                    is: true,
                    then: (schema: any) =>
                        schema.min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            `${commonValidationMessages.minDateLastRecord}`
                        ).required(commonValidationMessages.required),
                    otherwise: (schema: any) => schema.nullable(),
                }
            ),
            arrival_port: Yup.number().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !fieldAvailability.arrival_port
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            departure_port: Yup.number().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !fieldAvailability.departure_port
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_arrival_port: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(
                        otherArrPort === true &&
                        OtherPortsObject
                    )
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_arrival_port_name: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherArrPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_arrival_port_country: Yup.number().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherArrPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_departure_port: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(
                        otherDepPort === true &&
                        OtherPortsObject
                    )
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_departure_port_name: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherDepPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_departure_port_country: Yup.number().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherDepPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            vessel: Yup.number(),
            voyage_information: Yup.number(),
            vessel_reporting_information: Yup.number(),
        }),
        onSubmit: () => { },
    };

    const availableFields = useCallback(() => {
        if (record && record?.reporting_type === ReportingTypes.IN_PORT) {
            if ((PreviousVesselReportObject?.reporting_type === ReportingTypeConstant?.ATSEA) &&
                (record?.additional_events) &&
                (record?.reporting_event !== ReportingEvents?.EOSP)) {
                fields.arrival_port = true;
                fields.masters_eta_at_pilot_station = true;
                fields.is_arrival_port_in_eca = true;
                fields.expected_time_of_arrival_at_eca = true;
            }
            fields.departure_port = true;
            fields.master_etd_from_present_port = true;
            fields.is_departure_port_in_eca = true;
            fields.expected_time_of_depature_from_eca = true;
        } else if (
            record &&
            record?.reporting_event_name === ReportingEventName.BEGINING_SEA_PASSAGE
        ) {
            fields.arrival_port = true;
            fields.masters_eta_at_pilot_station = true;
            fields.is_arrival_port_in_eca = true;
            fields.expected_time_of_arrival_at_eca = true;
            fields.departure_port = true;
            fields.date_and_time_of_departure_from_eca = true;
        } else if (
            record &&
            record?.reporting_type === ReportingTypes.AT_SEA &&
            record?.reporting_event_name !== ReportingEventName.BEGINING_SEA_PASSAGE
        ) {
            fields.arrival_port = true;
            fields.masters_eta_at_pilot_station = true;
            fields.is_arrival_port_in_eca = true;
            fields.expected_time_of_arrival_at_eca = true;
        }
        setFieldAvailability(fields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, record]);

    useEffect(() => {
        availableFields();
    }, [availableFields]);
    useEffect(() => {
        if (ETADObject && ETADObject[0]?.id) {
            if (ETADObject[0]?.arrival_precedence_id === PortConstant.OTHER) {
                setOtherArrPort(true);
            } else if (
                ETADObject[0]?.departure_precedence_id === PortConstant.OTHER
            ) {
                setOtherDepPort(true);
            }
        }
    }, [ETADObject]);

    return (
        <Card className="deck-card-body mb-0 border-0">
            <CardHeader className="px-0 py-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Expected Time of Arrival/Departure
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(ETADObjectLoading || OtherPortsLoading) && (
                <Loading message="Loading required data!" />
            )}
            {ETADObjectError && getInitialETAD()}
            {!(ETADObjectLoading || OtherPortsLoading) && (
                <Formik
                    initialValues={ETADFormik.initialValues}
                    validationSchema={ETADFormik.validationSchema}
                    onSubmit={(
                        values: any,
                        actions: { setSubmitting: (arg: boolean) => void }
                    ) => {
                        actions.setSubmitting(true);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        };
                        if (ETADObject && ETADObject[0]?.id && values.id) {
                            apiGlobal
                                .put(`expected_time_of_arrival/${values.id}/`, values)
                                .then((res) => {
                                    if (env?.form_validation === true) {
                                        toggleTab(activeTab + 1);
                                    }
                                    handleResponse(res);
                                    queryClient.invalidateQueries(
                                        queryKeyes.vessel.ETADObject.key
                                    );
                                    actions.setSubmitting(false);
                                })
                                .catch((err) => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast(
                                            "Internal error occured, please contact the admin"
                                        );
                                    }
                                    actions.setSubmitting(false);
                                });
                        } else {
                            if (values.vessel_transit === false) {
                                values.date_and_time_of_arrival_at_eca = null;
                                values.date_and_time_of_departure_from_eca = null;
                            }
                            const res = apiGlobal
                                .post(`expected_time_of_arrival/`, values)
                                .then((res) => {
                                    if (env?.form_validation === true) {
                                        toggleTab(activeTab + 1);
                                    }
                                    handleResponse(res);
                                    queryClient.invalidateQueries(
                                        queryKeyes.vessel.ETADObject.key
                                    );
                                    actions.setSubmitting(false);
                                })
                                .catch((err) => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast(
                                            "Internal error occured, please contact the admin"
                                        );
                                    }
                                    actions.setSubmitting(false);
                                });
                            return res;
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        handleSubmit,
                        handleChange,
                        setErrors,
                        touched,
                        handleBlur,
                        isSubmitting,
                        setSubmitting,
                    }: {
                        values: any;
                        errors: any;
                        handleSubmit: any;
                        handleChange: any;
                        setErrors: any;
                        touched: any;
                        handleBlur: any;
                        isSubmitting: any;
                        setSubmitting: any;
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <CardBody className="px-0 py-2 pb-0">
                                <Row>
                                    <Col lg={6} className="">
                                        <Card>
                                            <CardHeader className="p-2">
                                                <h3 className="card-title m-0">Arrival</h3>
                                            </CardHeader>
                                            <CardBody className="p-2">
                                                <div>
                                                    <Label
                                                        className={`${fieldAvailability.arrival_port ? "asteric" : null
                                                            } mb-0`}
                                                        for="arrival_port"
                                                    >
                                                        Arrival port name
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="arrival_port_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="arrival_port_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) => item.target === "arrival_port_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <Field name="arrival_port">
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId="arrival_port"
                                                                className="max-width-25"
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchPorts(e)}
                                                                getOptionLabel={(e: any) => e.port_name}
                                                                getOptionValue={(e: any) => e.id}
                                                                onBlur={handleBlur}
                                                                onChange={(selectedOption: any) => {
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        selectedOption.id
                                                                    );
                                                                    if (
                                                                        selectedOption?.precedence_id ===
                                                                        PortConstant.OTHER &&
                                                                        addOtherArrPort === false
                                                                    ) {
                                                                        setOtherArrPort(true);
                                                                    } else if (
                                                                        selectedOption?.precedence_id !==
                                                                        PortConstant.OTHER
                                                                    ) {
                                                                        setOtherArrPort(false);
                                                                        setAddOtherArrPort(false);
                                                                        form.setFieldValue(
                                                                            "other_arrival_port_name",
                                                                            null
                                                                        );
                                                                        form.setFieldValue(
                                                                            "other_arrival_port",
                                                                            null
                                                                        );
                                                                        form.setFieldValue(
                                                                            "other_arrival_port_country",
                                                                            null
                                                                        );
                                                                        form.setFieldValue(
                                                                            "other_arrival_port_country_name",
                                                                            null
                                                                        );
                                                                    }
                                                                }}
                                                                defaultValue={
                                                                    ETADObject &&
                                                                    ETADObject[0]?.id && {
                                                                        id: ETADObject[0]?.arrival_port,
                                                                        port_name: ETADObject[0]?.arrival_port_name,
                                                                    }
                                                                }
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    ...(
                                                                        errors.arrival_port == null
                                                                            ? customStyle
                                                                            : errorStyle
                                                                    ),
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999, // Ensures the dropdown menu is above other elements
                                                                    }),
                                                                    menuPortal: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999, // Ensures the menu portal is above other elements
                                                                    }),
                                                                }}
                                                                isDisabled={!fieldAvailability.arrival_port}
                                                                noOptionsMessage={(e: any) => {
                                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                                        return "Please select the Other option and enter the port name in the textbox provided";
                                                                    }
                                                                    return "Please enter the first 3 characters of port name";
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors &&
                                                        touched?.arrival_port &&
                                                        errors?.arrival_port &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target="arrival_port"
                                                                message={errors?.arrival_port}
                                                                open={errors?.arrival_port ? true : false}
                                                            />
                                                        )}
                                                </div>
                                                {otherArrPort === true &&
                                                    OtherPortsObject && (
                                                        <>
                                                            <Label
                                                                className="mb-0 mt-2 asteric"
                                                                for="other_arrival_port_name_dropdown"
                                                            >
                                                                Other arrival port
                                                            </Label>
                                                            <Field name="other_arrival_port">
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        inputId="other_arrival_port_name_dropdown"
                                                                        className="max-width-25"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={() => loadOtherPorts()}
                                                                        getOptionLabel={(e: any) => e.port_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onBlur={handleBlur}
                                                                        onChange={(selectedOption: any) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                selectedOption.id
                                                                            );
                                                                            form.setFieldValue(
                                                                                "other_arrival_port_name",
                                                                                selectedOption?.port_name
                                                                            );
                                                                            form.setFieldValue(
                                                                                "other_arrival_port_country",
                                                                                selectedOption?.country
                                                                            );
                                                                        }}
                                                                        defaultValue={
                                                                            ETADObject &&
                                                                            ETADObject[0]?.id && {
                                                                                id: ETADObject[0]?.other_arrival_port,
                                                                                port_name:
                                                                                    ETADObject[0]
                                                                                        ?.other_arrival_port_name,
                                                                            }
                                                                        }
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        noOptionsMessage={(e: any) => {
                                                                            if (
                                                                                e?.inputValue?.toString()?.length > 2
                                                                            ) {
                                                                                return "Please select the Other option and enter the port name in the textbox provided";
                                                                            }
                                                                            return "Please enter the first 3 characters of port name";
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <p
                                                                className="link_color_blue pointer"
                                                                onClick={() => {
                                                                    setAddOtherArrPort(true);
                                                                    setOtherArrPort(false);
                                                                    values.other_arrival_port = null;
                                                                    values.other_arrival_port_name = null;
                                                                    values.other_arrival_port_country = null;
                                                                }}
                                                            >
                                                                Click here to add new port
                                                            </p>
                                                        </>
                                                    )}
                                                {(addOtherArrPort === true || !OtherPortsObject) && (
                                                    <div className="mb-3 mt-3 ele_row1">
                                                        <Col sm={5}>
                                                            <Label
                                                                className="mb-0 asteric"
                                                                for="other_arrival_port_name"
                                                            >
                                                                Other arrival port
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="other_arrival_port_name"
                                                                id="other_arrival_port_name"
                                                                className="form-control max-width-20"
                                                            />
                                                            {errors &&
                                                                errors?.other_arrival_port_name &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target="other_arrival_port_name"
                                                                        message={errors?.other_arrival_port_name}
                                                                        open={
                                                                            errors?.other_arrival_port_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </Col>
                                                        <Col sm={5} className="ms-3">
                                                            <Label className="mb-0 asteric">
                                                                Other arrival port country
                                                            </Label>
                                                            <Field name="other_arrival_port_country">
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        id={field.name}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={(e: any) => searchCountries(e)}
                                                                        getOptionLabel={(e: any) => e.country_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onChange={(e: any) =>
                                                                            form.setFieldValue(field.name, e?.id)
                                                                        }
                                                                        styles={customStyle}
                                                                        noOptionsMessage={() => {
                                                                            return "Please enter the first 3 characters of country name";
                                                                        }}
                                                                        defaultValue={
                                                                            ETADObject &&
                                                                            ETADObject[0]?.id && {
                                                                                id: ETADObject[0]
                                                                                    ?.other_arrival_port_country,
                                                                                port_name:
                                                                                    ETADObject[0]
                                                                                        ?.other_arrival_port_country_name,
                                                                            }
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors &&
                                                                errors?.other_arrival_port_country &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target="other_arrival_port_country"
                                                                        message={errors?.other_arrival_port_country}
                                                                        open={
                                                                            errors?.other_arrival_port_country
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </Col>
                                                    </div>
                                                )}
                                                <div className="mb-3 mt-3">
                                                    <Label
                                                        className={`${fieldAvailability.masters_eta_at_pilot_station
                                                            ? "asteric"
                                                            : null
                                                            } mb-0`}
                                                        for="masters_eta_at_pilot_station"
                                                    >
                                                        Master's ETA at pilot station(UTC)
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="masters_eta_at_pilot_station_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="masters_eta_at_pilot_station_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "masters_eta_at_pilot_station_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <Field name="masters_eta_at_pilot_station">
                                                        {({ field }: FieldProps) => (
                                                            <input
                                                                type="datetime-local"
                                                                name={field.name}
                                                                id="masters_eta_at_pilot_station"
                                                                min={dateTimeFormat(
                                                                    lastRecord?.reporting_time_utc
                                                                )}
                                                                max={"9999-12-31T00:00"}
                                                                className="datetimepicker text-uppercase form-control mt-0 w-12"
                                                                defaultValue={dateTimeFormat(
                                                                    values.masters_eta_at_pilot_station,
                                                                    false,
                                                                    true
                                                                )}
                                                                disabled={
                                                                    !fieldAvailability.masters_eta_at_pilot_station
                                                                }
                                                                onBlur={handleBlur}
                                                                onChange={(e: any) => handleChange(e)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <div style={{ position: 'absolute', zIndex: -1 }}>
                                                        {errors &&
                                                            touched?.masters_eta_at_pilot_station &&
                                                            errors?.masters_eta_at_pilot_station &&
                                                            env?.form_validation === true && (
                                                                <ErrorTooltip
                                                                    target="masters_eta_at_pilot_station"
                                                                    message={errors?.masters_eta_at_pilot_station}
                                                                    open={
                                                                        errors?.masters_eta_at_pilot_station
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="mb-1">
                                                    <Field name="is_arrival_port_in_eca">
                                                        {({ field, form }: FieldProps) => (
                                                            <input
                                                                className="form-check-input mr-0-5"
                                                                type="checkbox"
                                                                id="is_arrival_port_in_eca"
                                                                name={field.name}
                                                                defaultChecked={values.is_arrival_port_in_eca}
                                                                disabled={
                                                                    !fieldAvailability.is_arrival_port_in_eca
                                                                }
                                                                onChange={(e: any) => {
                                                                    handleChange(e)
                                                                    if (e.target.checked === false) {
                                                                        form.setFieldValue("expected_time_of_arrival_at_eca", null);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Label for="is_arrival_port_in_eca">
                                                        Is arrival port in ECA and vessel is yet to enter
                                                        the region?
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="is_arrival_port_in_eca_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="is_arrival_port_in_eca_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target === "is_arrival_port_in_eca_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label
                                                        className={`mb-0 ${values?.is_arrival_port_in_eca === true
                                                            ? "asteric"
                                                            : ""
                                                            }`}
                                                        for="expected_time_of_arrival_at_eca"
                                                    >
                                                        Expected time of arrival at ECA(UTC)
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="expected_time_of_arrival_at_eca_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="expected_time_of_arrival_at_eca_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "expected_time_of_arrival_at_eca_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <Field name="expected_time_of_arrival_at_eca">
                                                        {({ field }: FieldProps) => (
                                                            <input
                                                                type="datetime-local"
                                                                name={field.name}
                                                                id="expected_time_of_arrival_at_eca"
                                                                min={dateTimeFormat(
                                                                    lastRecord?.reporting_time_utc
                                                                )}
                                                                max={"9999-12-31T00:00"}
                                                                className="datetimepicker text-uppercase form-control mt-0 w-12"
                                                                value={dateTimeFormat(
                                                                    values.expected_time_of_arrival_at_eca,
                                                                    false,
                                                                    true
                                                                )}
                                                                disabled={!values.is_arrival_port_in_eca}
                                                                onChange={(e: any) => handleChange(e)}
                                                                onBlur={handleBlur}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors &&
                                                        errors?.expected_time_of_arrival_at_eca &&
                                                        touched.expected_time_of_arrival_at_eca &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target="expected_time_of_arrival_at_eca"
                                                                message={
                                                                    errors?.expected_time_of_arrival_at_eca
                                                                }
                                                                open={
                                                                    errors?.expected_time_of_arrival_at_eca
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={6} className="">
                                        <Card>
                                            <CardHeader className="p-2">
                                                <h3 className="card-title m-0">Departure</h3>
                                            </CardHeader>
                                            <CardBody className="p-2">
                                                <div>
                                                    <Label
                                                        className={`mb-0 ${fieldAvailability.departure_port ? "asteric" : ""
                                                            }`}
                                                        for="departure_port"
                                                    >
                                                        Departure port name
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="departure_port_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="departure_port_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target === "departure_port_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <Field name="departure_port">
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId="departure_port"
                                                                className="max-width-25 mb-3"
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchPorts(e)}
                                                                getOptionLabel={(e: any) => e?.port_name}
                                                                getOptionValue={(e: any) => e?.id}
                                                                onChange={(selectedOption: any) => {
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        selectedOption.id
                                                                    );
                                                                    if (
                                                                        selectedOption?.precedence_id ===
                                                                        PortConstant.OTHER &&
                                                                        addOtherDepPort === false
                                                                    ) {
                                                                        setOtherDepPort(true);
                                                                    } else if (
                                                                        selectedOption?.precedence_id !==
                                                                        PortConstant.OTHER
                                                                    ) {
                                                                        setOtherDepPort(false);
                                                                        setAddOtherDepPort(false);
                                                                        form.setFieldValue(
                                                                            "other_departure_port_name",
                                                                            null
                                                                        );
                                                                        form.setFieldValue(
                                                                            "other_departure_port",
                                                                            null
                                                                        );
                                                                        form.setFieldValue(
                                                                            "other_departure_port_country",
                                                                            null
                                                                        );
                                                                        form.setFieldValue(
                                                                            "other_departure_port_country_name",
                                                                            null
                                                                        );
                                                                    }
                                                                }}
                                                                defaultValue={
                                                                    ETADObject &&
                                                                    ETADObject[0]?.id && {
                                                                        id: ETADObject[0]?.departure_port,
                                                                        port_name:
                                                                            ETADObject[0]?.departure_port_name,
                                                                    }
                                                                }
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    ...(
                                                                        errors.arrival_port == null
                                                                            ? customStyle
                                                                            : errorStyle
                                                                    ),
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999,
                                                                    }),
                                                                    menuPortal: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999,
                                                                    }),
                                                                }}
                                                                isDisabled={!fieldAvailability.departure_port}
                                                                noOptionsMessage={(e: any) => {
                                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                                        return "Please select the Other option and enter the port name in the textbox provided";
                                                                    }
                                                                    return "Please enter the first 3 characters of port name";
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors &&
                                                        errors?.departure_port &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target="departure_port"
                                                                message={errors?.departure_port}
                                                                open={errors?.departure_port ? true : false}
                                                            />
                                                        )}
                                                </div>
                                                {otherDepPort === true &&
                                                    OtherPortsObject && (
                                                        <>
                                                            <Label
                                                                className="mb-0 mt-2 asteric"
                                                                for="other_departure_port_name_dropdown"
                                                            >
                                                                Other departure port
                                                            </Label>
                                                            <Field name="other_departure_port">
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        inputId="other_departure_port_name_dropdown"
                                                                        className="max-width-25"
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={() => loadOtherPorts()}
                                                                        getOptionLabel={(e: any) => e.port_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onBlur={handleBlur}
                                                                        onChange={(selectedOption: any) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                selectedOption.id
                                                                            );
                                                                            form.setFieldValue(
                                                                                "other_departure_port_name",
                                                                                selectedOption?.port_name
                                                                            );
                                                                            form.setFieldValue(
                                                                                "other_departure_port_country",
                                                                                selectedOption?.country
                                                                            );
                                                                        }}
                                                                        defaultValue={
                                                                            ETADObject &&
                                                                            ETADObject[0]?.id && {
                                                                                id: ETADObject[0]?.other_departure_port,
                                                                                port_name:
                                                                                    ETADObject[0]
                                                                                        ?.other_departure_port_name,
                                                                            }
                                                                        }
                                                                        menuPortalTarget={document.body}
                                                                        styles={
                                                                            errors.departure_port === null
                                                                                ? customStyle
                                                                                : errorStyle
                                                                        }
                                                                        noOptionsMessage={(e: any) => {
                                                                            if (
                                                                                e?.inputValue?.toString()?.length > 2
                                                                            ) {
                                                                                return "Please select the Other option and enter the port name in the textbox provided";
                                                                            }
                                                                            return "Please enter the first 3 characters of port name";
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <p
                                                                className="link_color_blue pointer"
                                                                onClick={() => {
                                                                    setAddOtherDepPort(true);
                                                                    setOtherDepPort(false);
                                                                    values.other_departure_port = null;
                                                                    values.other_departure_port_name = null;
                                                                    values.other_departure_port_country = null;
                                                                }}
                                                            >
                                                                Click here to add new port
                                                            </p>
                                                        </>
                                                    )}
                                                {(addOtherDepPort === true || !OtherPortsObject) && (
                                                    <div className="mb-3 mt-3 ele_row1">
                                                        <Col sm={5}>
                                                            <Label
                                                                className="mb-0 asteric"
                                                                for="other_departure_port_name"
                                                            >
                                                                Other departure port
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="other_departure_port_name"
                                                                id="other_departure_port_name"
                                                                className="form-control max-width-20"
                                                            />
                                                            {errors &&
                                                                errors?.other_departure_port_name &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target="other_departure_port_name"
                                                                        message={errors?.other_departure_port_name}
                                                                        open={
                                                                            errors?.other_departure_port_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </Col>
                                                        <Col sm={5} className="ms-3">
                                                            <Label className="mb-0 asteric">
                                                                Other departure port country
                                                            </Label>
                                                            <Field name="other_departure_port_country">
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        id={field.name}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={(e: any) => searchCountries(e)}
                                                                        getOptionLabel={(e: any) => e.country_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onChange={(e: any) =>
                                                                            form.setFieldValue(field.name, e?.id)
                                                                        }
                                                                        styles={customStyle}
                                                                        noOptionsMessage={() => {
                                                                            return "Please enter the first 3 characters of country name";
                                                                        }}
                                                                        defaultValue={
                                                                            ETADObject &&
                                                                            ETADObject[0]?.id && {
                                                                                id: ETADObject[0]
                                                                                    ?.other_departure_port_country,
                                                                                port_name:
                                                                                    ETADObject[0]
                                                                                        ?.other_departure_port_country_name,
                                                                            }
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors &&
                                                                errors?.other_departure_port_country &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target="other_departure_port_country"
                                                                        message={
                                                                            errors?.other_departure_port_country
                                                                        }
                                                                        open={
                                                                            errors?.other_departure_port_country
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </Col>
                                                    </div>
                                                )}
                                                <div className="mb-3">
                                                    <Label
                                                        className={`mb-0 ${fieldAvailability.master_etd_from_present_port
                                                            ? "asteric"
                                                            : ""
                                                            }`}
                                                        for="master_etd_from_present_port"
                                                    >
                                                        Master's ETD from present port(UTC)
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="master_etd_from_present_port_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="master_etd_from_present_port_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "master_etd_from_present_port_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <Field name="master_etd_from_present_port">
                                                        {({ field }: FieldProps) => (
                                                            <input
                                                                type="datetime-local"
                                                                name={field.name}
                                                                id="master_etd_from_present_port"
                                                                min={dateTimeFormat(
                                                                    lastRecord?.reporting_time_utc
                                                                )}
                                                                max={"9999-12-31T00:00"}
                                                                className="datetimepicker text-uppercase form-control mt-0 w-12"
                                                                disabled={
                                                                    !fieldAvailability.master_etd_from_present_port
                                                                }
                                                                defaultValue={dateTimeFormat(
                                                                    values.master_etd_from_present_port,
                                                                    false,
                                                                    true
                                                                )}
                                                                onChange={(e: any) => handleChange(e)}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors &&
                                                        errors?.master_etd_from_present_port &&
                                                        env?.form_validation === true && (
                                                            <div style={{ position: 'absolute', zIndex: -1 }}>
                                                                <ErrorTooltip
                                                                    target="master_etd_from_present_port"
                                                                    message={
                                                                        errors?.master_etd_from_present_port
                                                                    }
                                                                    open={
                                                                        errors?.master_etd_from_present_port
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="mb-1">
                                                    <Field name="is_departure_port_in_eca">
                                                        {({ field }: FieldProps) => (
                                                            <input
                                                                className="form-check-input mr-0-5"
                                                                type="checkbox"
                                                                id="is_departure_port_in_eca"
                                                                name={field.name}
                                                                defaultChecked={values.is_departure_port_in_eca}
                                                                onChange={(e: any) => handleChange(e)}
                                                                disabled={
                                                                    !fieldAvailability.is_departure_port_in_eca
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    <Label for="is_departure_port_in_eca">
                                                        Is departure port in ECA and vessel is yet to exit
                                                        the region?
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="is_departure_port_in_eca_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="is_departure_port_in_eca_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target === "is_departure_port_in_eca_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label
                                                        className={`mb-0 ${fieldAvailability.expected_time_of_depature_from_eca
                                                            ? "aasteric"
                                                            : ""
                                                            }`}
                                                        for="expected_time_of_depature_from_eca"
                                                    >
                                                        Expected time of departure from ECA(UTC)
                                                        <i
                                                            className="bx bx-info-circle ml-2p"
                                                            id="expected_time_of_depature_from_eca_msg"
                                                        />
                                                    </Label>
                                                    <ToolTip
                                                        target="expected_time_of_depature_from_eca_msg"
                                                        message={`${TooltipMsg.ETADD.filter(
                                                            (item: any) =>
                                                                item.target ===
                                                                "expected_time_of_depature_from_eca_msg"
                                                        ).map((tool: any) => {
                                                            return tool.message;
                                                        })}`}
                                                    />
                                                    <Field name="expected_time_of_depature_from_eca">
                                                        {({ field }: FieldProps) => (
                                                            <input
                                                                type="datetime-local"
                                                                name={field.name}
                                                                id="expected_time_of_depature_from_eca"
                                                                min={dateTimeFormat(
                                                                    lastRecord?.reporting_time_utc
                                                                )}
                                                                max={"9999-12-31T00:00"}
                                                                className="datetimepicker text-uppercase form-control mt-0 w-12"
                                                                disabled={!values?.is_departure_port_in_eca}
                                                                defaultValue={dateTimeFormat(
                                                                    values.expected_time_of_depature_from_eca,
                                                                    false,
                                                                    true
                                                                )}
                                                                onChange={(e: any) => handleChange(e)}
                                                                onBlur={handleBlur}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors &&
                                                        errors?.expected_time_of_depature_from_eca &&
                                                        touched.expected_time_of_depature_from_eca &&
                                                        env?.form_validation === true && (
                                                            <ErrorTooltip
                                                                target="expected_time_of_depature_from_eca"
                                                                message={
                                                                    errors?.expected_time_of_depature_from_eca
                                                                }
                                                                open={
                                                                    errors?.expected_time_of_depature_from_eca
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody className="px-2 ">
                                                <div>
                                                    <div className="form-check">
                                                        <Field name="vessel_transit">
                                                            {({ field, form }: FieldProps) => (
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="vessel_transit"
                                                                    name={field.name}
                                                                    onChange={(e: { [key: string]: { [key: string]: string } } | React.ChangeEvent<HTMLInputElement>) => {
                                                                        handleChange(e);
                                                                        if (e.target.checked === false) {
                                                                            form.setFieldValue("date_and_time_of_arrival_at_eca", null);
                                                                            form.setFieldValue("date_and_time_of_departure_from_eca", null);
                                                                        }
                                                                    }}
                                                                    disabled={!fieldAvailability.vessel_transit}
                                                                    defaultChecked={values.vessel_transit}
                                                                />
                                                            )}
                                                        </Field>
                                                        <Label for="vessel_transit">
                                                            Will vessel transit ECA region?
                                                            <i
                                                                className="bx bx-info-circle ml-2p"
                                                                id="vessel_transit_msg"
                                                            />
                                                        </Label>
                                                        <ToolTip
                                                            target="vessel_transit_msg"
                                                            message={`${TooltipMsg.ETADD.filter(
                                                                (item: any) =>
                                                                    item.target === "vessel_transit_msg"
                                                            ).map((tool: any) => {
                                                                return tool.message;
                                                            })}`}
                                                        />
                                                    </div>
                                                    <React.Fragment>
                                                        <div className="mb-3">
                                                            <Label
                                                                className="mb-0"
                                                                for="date_and_time_of_arrival_at_eca"
                                                            >
                                                                Date & time of arrival at ECA incase vessel is
                                                                yet to enter the region(UTC)
                                                                <i
                                                                    className="bx bx-info-circle ml-2p"
                                                                    id="date_and_time_of_arrival_at_eca_msg"
                                                                />
                                                            </Label>
                                                            <ToolTip
                                                                target="date_and_time_of_arrival_at_eca_msg"
                                                                message={`${TooltipMsg.ETADD.filter(
                                                                    (item: any) =>
                                                                        item.target ===
                                                                        "date_and_time_of_arrival_at_eca_msg"
                                                                ).map((tool: any) => {
                                                                    return tool.message;
                                                                })}`}
                                                            />
                                                            <Field name="date_and_time_of_arrival_at_eca">
                                                                {({ field }: FieldProps) => (
                                                                    <input
                                                                        type="datetime-local"
                                                                        name={field.name}
                                                                        id="date_and_time_of_arrival_at_eca"
                                                                        min={dateTimeFormat(
                                                                            lastRecord.reporting_time_utc
                                                                        )}
                                                                        max={"9999-12-31T00:00"}
                                                                        value={dateTimeFormat(
                                                                            values.date_and_time_of_arrival_at_eca,
                                                                            false,
                                                                            true
                                                                        )}
                                                                        className="datetimepicker text-uppercase form-control mt-0 w-12"
                                                                        disabled={!values.vessel_transit}
                                                                        onChange={(e: any) => handleChange(e)}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors &&
                                                                errors?.date_and_time_of_arrival_at_eca &&
                                                                touched.date_and_time_of_arrival_at_eca &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target="date_and_time_of_arrival_at_eca"
                                                                        message={
                                                                            errors?.date_and_time_of_arrival_at_eca
                                                                        }
                                                                        open={
                                                                            errors?.date_and_time_of_arrival_at_eca
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div className="mb-2">
                                                            <Label
                                                                className="mb-0"
                                                                for="date_and_time_of_departure_from_eca"
                                                            >
                                                                Date & time of departure from ECA(UTC)
                                                                <i
                                                                    className="bx bx-info-circle ml-2p"
                                                                    id="date_and_time_of_departure_from_eca_msg"
                                                                />
                                                            </Label>
                                                            <ToolTip
                                                                target="date_and_time_of_departure_from_eca_msg"
                                                                message={`${TooltipMsg.ETADD.filter(
                                                                    (item: any) =>
                                                                        item.target ===
                                                                        "date_and_time_of_departure_from_eca_msg"
                                                                ).map((tool: any) => {
                                                                    return tool.message;
                                                                })}`}
                                                            />
                                                            <Field name="date_and_time_of_departure_from_eca">
                                                                {({ field }: FieldProps) => (
                                                                    <input
                                                                        type="datetime-local"
                                                                        name={field.name}
                                                                        id="date_and_time_of_departure_from_eca"
                                                                        min={dateTimeFormat(
                                                                            lastRecord.reporting_time_utc
                                                                        )}
                                                                        max={"9999-12-31T00:00"}
                                                                        className="datetimepicker text-uppercase form-control mt-0 w-12"
                                                                        disabled={!values.vessel_transit}
                                                                        value={dateTimeFormat(
                                                                            values.date_and_time_of_departure_from_eca,
                                                                            false,
                                                                            true
                                                                        )}
                                                                        onChange={(e: any) => handleChange(e)}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors &&
                                                                errors?.date_and_time_of_departure_from_eca &&
                                                                touched.date_and_time_of_departure_from_eca &&
                                                                env?.form_validation === true && (
                                                                    <ErrorTooltip
                                                                        target="date_and_time_of_departure_from_eca"
                                                                        message={
                                                                            errors?.date_and_time_of_departure_from_eca
                                                                        }
                                                                        open={
                                                                            errors?.date_and_time_of_departure_from_eca
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="p-2 py-3">
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleTab(activeTab + 1);
                                                }
                                                setSubmitting(false);
                                            }}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                            Next
                                            <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn_size_cstm"
                                            onClick={() => {
                                                setErrors({});
                                                toggleTab(activeTab - 1);
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug
                                values={[values, errors, fieldAvailability, touched]}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </Card>
    );
};

export default ETADComponent;
