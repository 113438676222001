import { checkInvalidPrimaryKey } from 'GenericForms/Helper';
import { successToast, warningToast } from '../Components/Toasts';
import apiGlobal, { handleApiError } from '../global/api.global';
import { queryKeyes } from '../shared/queryKeys';
import { getAuthData } from 'utils/auth/authUtils';
import { FuelTypes } from 'shared/constants';

export const fetchMachinaryOptions = async (VesselId: any = 0) => {
  if (!checkInvalidPrimaryKey(VesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselMachineries.url(VesselId),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

export const loadPortOptions = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.portMaster.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const loadDirectionOptions = async () => {
  try {
    const response = await apiGlobal.get(`/direction_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadCurrentDirectionOptions = async () => {
  try {
    const response = await apiGlobal.get(`/current_direction_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadCPWarrantiesFuelOptions = async (
  vesselId: number,
  voyageId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.lastCPWarranties.url(vesselId, voyageId, reportID),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

export const load_Vessel_Types = async () => {
  try {
    const response = await apiGlobal.get(`vessel_type_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadVesselSubTypes = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.VesselSubTypeMaster.url(),
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadCountries = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.CountryMaster.url(),
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadVesselOwners = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.VesselOwnerMaster.url(),
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadHullTypes = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.HullTypeMaster.url(),
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadFuelTypes = async () => {
  try {
    const response = await apiGlobal.get(`fuel_type_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadSensorOptions = async () => {
  try {
    const response = await apiGlobal.get(`sensor_list_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadMachineryOptions = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.MachineryMaster.url(),
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadReportTypeOptions = async () => {
  try {
    const response = await apiGlobal.get(`reporting_type_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadLoadConditionOptions = async () => {
  try {
    const response = await apiGlobal.get(`vessel_load_condition_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const loadStraitCanalOptions = async () => {
  try {
    const response = await apiGlobal.get(`strait_canal_transit_list_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const fuel_master = async () => {
  try {
    const response = await apiGlobal.get(`/fuel_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const vesselReportingInfo = async (ReportID: number) => {
  if (!checkInvalidPrimaryKey(ReportID)) {
    return [];
  } else {
    try {
      const reponse = await apiGlobal.get(
        queryKeyes.vessel.reporting.url(ReportID),
      );
      return reponse.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

export const vesselFuel = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.fuel.url(VesselID),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

export const allDecofficerReport = async (ReportId: number) => {
  if (!checkInvalidPrimaryKey(ReportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.decOfficerAll.url(ReportId),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

export const PreviousVesselReportingInfo = async (
  ReportID: number,
  VesselID: number,
) => {
  if (!checkInvalidPrimaryKey(VesselID, ReportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PreviousVesselReport.url(ReportID, VesselID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const vesselVoyageReportingList = async (
  VesselID: number,
  VoyageID: number,
) => {
  if (!checkInvalidPrimaryKey(VesselID, VoyageID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.vesselVoyageReporting.url(VesselID, VoyageID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const loadCompletedReports = async (vesselId: number, voyageId: number, url: string) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};
/**
 * Retruns Next Possible operation of report
 * @param number last_report_event_id
 * @param number current_possible_event_id
 * @returns
 */
export const nextPossibleOperations = async (
  current_possible_event_id: number,
  last_report_event_id: number,
) => {
  try {
    if (current_possible_event_id && last_report_event_id) {
      const response = await apiGlobal.get(
        `/possible_operations_since_last_report/get_next_possible_operations/?current_possible_event_id=${current_possible_event_id}&last_report_event_id=${last_report_event_id}`,
      );
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Function Returns next possible events
 * @param reporting_event
 * @returns
 */
export const nextPossibleEvents = async (reporting_event: number) => {
  try {
    if (reporting_event) {
      const response = await apiGlobal.get(
        `/event_management/get_next_events_by_event/?event_id=${reporting_event}`,
      );
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Function to get last vessel reporting information vessel specific
 * @param number VesselID
 * @returns
 */
export const loadLastReportVessel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LastVesselReport.url(vesselId),
      );
      return response.data;
    } catch (error: any) {
      console.error(error?.message ?? 'Error Occured');
      return [];
    }
  }
};

/**
 * Common Submit Data function to do post
 * @param data
 * @param url
 * @returns
 */
export const commonPost = async (
  data: any,
  url: string,
  message: { success?: string; error?: string } = {},
) => {
  try {
    const response = await apiGlobal.post(url, data);
    if (response.status === 200 || response.status === 201) {
      successToast(message?.success ?? 'Data saved successfully!');
    } else {
      warningToast(message?.error ?? 'Unable to process reauest!');
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Common function to make patch request
 */
export const commonRequest = async (
  url: string,
  data: any,
  method: string,
  message: { success?: string; error?: string } = {},
) => {
  try {
    const response = await apiGlobal.request({
      url,
      method,
      data,
    });

    if (response.status === 200 || response.status === 201) {
      successToast(message?.success ?? 'Data saved successfully!');
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Funtions Loads fuel type ROB
 * @param vesselId
 * @param voyageId
 * @param reportID
 * @returns
 */
export const loadFuelTypeROB = async (
  vesselId: number,
  voyageId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FuelTypeROB.url(vesselId, voyageId, reportID),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

/**
 * Returns Document Lists
 *
 * @param number cii_adjustment_event_id
 * @returns
 */
export const loadDocList = async (cii_adjustment_event_id: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIReportingDocuments.url(cii_adjustment_event_id),
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Retruns cii event documents lists
 * @param vessel_reporting_cii_adjustment_id
 * @returns
 */
export const ciiEventDocuments = async (
  vessel_reporting_cii_adjustment_id: number,
) => {
  try {
    if (vessel_reporting_cii_adjustment_id) {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CIIEventsSupportingFileData.url(
          vessel_reporting_cii_adjustment_id,
        ),
      );
      return response.data;
    }
    return [];
  } catch (err) {
    console.error(err);
  }
};

/**
 * function returns voyage list of vessels
 * @param VesselID
 * @returns
 */
export const voyageList = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    const response = await apiGlobal(
      queryKeyes.vessel.VoyageList.url(VesselID),
    );
    return response.data;
  }
};

/**
 * function returns lube oil ROB from previous record
 * @param vesselId
 * @param reportID
 * @returns
 */
export const loadPreviousLubeOilROB = async (
  vesselId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PreviousLubeOilROB.url(vesselId, reportID),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

/**
 * Returns the array with machinary fuel group of vessel
 * @returns
 */
export const loadMachineryFuelGroup = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal(
        queryKeyes.vessel.MachinaryFuelGroupByVesselId.url(VesselID),
      );
      return response.data?.filter((item: any) => item.display === true);
    } catch (err) {
      console.error(err);
    }
  }
};
/**
 * Returns the array with machinary fuel group of vessel
 * @returns
 */
export const loadSpecialOperationFuelSettings = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal(
        queryKeyes.vessel.SpecialOperationFuelSettings.url(VesselID),
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
};

/**
 * Returns the array with machinary fuel group of vessel
 * @returns
 */
export const loadSpecialOperationEnergySettings = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal(
        queryKeyes.vessel.SpecialOperationEnergySettings.url(VesselID),
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
};

/**
 * Returns the array with machinary special operations of vessel
 * @returns
 */
export const loadSpecialOperationSettingList = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal(
        queryKeyes.vessel.SpecialOperationSettingList.url(VesselID),
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
};

/**
 * Returns the array with machinary energy group of vessel
 * @returns
 */
export const loadMachineryEnergyGroup = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal(
        queryKeyes.vessel.MachinaryEnergyGroupByVesselId.url(VesselID),
      );
      return response.data?.filter((item: any) => item.display === true);
    } catch (err) {
      console.error(err);
    }
  }
};

/**
 * Get Vessel Machines
 * @param VesselID
 * @returns
 */
export const loadVesselMachineries = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselMachineries.url(VesselID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Get Vessel Machines
 * @param VesselID, machineType
 * @returns
 */
export const loadVesselMachineriesByMachineType = async (
  VesselID: number,
  machineType: number,
) => {
  if (!checkInvalidPrimaryKey(VesselID, machineType)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselMachineries.url(VesselID),
      );
      return response.data?.filter(
        (item: any) => item.precedence_id === machineType,
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Get Distinct Vessel Machines
 * @param VesselID
 * @returns
 */
export const loadDistinctMachinary = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.DistinctMachinary.url(VesselID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Get Vessel's Fule bunkaring batch
 * @param VesselID
 * @returns
 */
export const loadFuelBatches = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BunkeringByVessel.url(VesselID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Get Vessel Fuel Settings
 * @param VesselID
 * @returns
 */
export const loadFuelSettings = async (VesselID: number) => {
  if (!checkInvalidPrimaryKey(VesselID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FuelSettings.url(VesselID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const loadVesselReportObject = async (reportId: number) => {
  if (!checkInvalidPrimaryKey(reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselReportingObject.url(reportId),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
    }
  }
};

/**
 * Fetch Fuel consumption batch based on the reporting id
 * @param reportId
 * @returns
 */
export const loadFuelConsputionBath = async (reportId: number) => {
  if (!checkInvalidPrimaryKey(reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FuelConsumptionBatch.url(reportId),
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetch Fuel ROB from previous report based on vesselId  & reportId
 * @param vesselId
 * @param reportId
 * @returns
 */
export const loadPreviousFuelROB = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PreviousFuelROB.url(vesselId, reportId),
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Loads EVMRU Related data
 *
 * @param number vesselId
 * @returns array
 */
export const loadEvmruData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.Eumrv.url(vesselId),
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load CII Adjustment data vessel specific
 *
 * @param number vesselId
 * @returns array
 */
export const loadCiiAssesmentGraphData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CIIAssessmentData.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load IMODCS data vessel specific
 *
 * @param number vesselId
 * @returns array
 */
export const loadImodcsGraphData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.IMODCSData.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const loadVessels = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.Vessels.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load CII Adjustment additional events
 * @param eventId
 * @returns array
 */
export const loadAdditionalEvents = async (eventId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIAdjustmentAdditionalEvents.url(eventId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current report's fuel consumption object
 * @param eventId
 * @returns array
 */
export const loadFuelConsumptionObject = async (
  vesselId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FuelConsumptionObject.url(vesselId, reportID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's batch consumption object
 * @param eventId
 * @returns array
 */
export const loadBatchConsumptionObject = async (
  vesselId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BatchConsumptionObject.url(vesselId, reportID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's main engine settings
 * @param vesselId
 * @returns array
 */
export const loadMainEngineSettings = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.MainEngineSetting.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load engine types from master
 * @param -
 * @returns array
 */
export const loadEngineTypes = async (
  vesselId: number,
  engineType?: string,
) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      let vesselFuelIds: any[] = [];
      const vesselFuels = await apiGlobal.get(
        queryKeyes.vessel.vesselFuel.url(vesselId),
      );
      vesselFuels?.data?.forEach(
        (item: { [key: string]: string | number | Date | boolean }) => {
          vesselFuelIds.push(item.precedence_id);
        },
      );
      const response = await apiGlobal.get(
        queryKeyes.masters.EngineTypeMaster.url(),
      );
      if (
        !vesselFuelIds?.includes(FuelTypes.LNG_BUNKER) &&
        !vesselFuelIds?.includes(FuelTypes.LNG_BUNKER)
      ) {
        response.data = response.data?.filter(
          (res: { [key: string]: string | number | Date | boolean }) =>
            res?.id !== 2 && res?.id !== 3 && res?.id !== 6,
        );
      } else {
        if (engineType === '2_stroke') {
          response.data = response.data?.filter(
            (res: { [key: string]: string | number | Date | boolean }) =>
              res?.id !== 2,
          );
        } else if (engineType === '4_stroke') {
          response.data = response.data?.filter(
            (res: { [key: string]: string | number | Date | boolean }) =>
              res?.id !== 6,
          );
        }
      }
      if (!vesselFuelIds?.includes(FuelTypes.H2)) {
        response.data = response.data?.filter(
          (res: { [key: string]: string | number | Date | boolean }) =>
            res?.id !== 5,
        );
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's steaming time
 * @param reportId
 * @returns object
 */
export const loadCurrentSteaingTime = async (reportId: number) => {
  if (!checkInvalidPrimaryKey(reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CurrentSteamingTime.url(reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's distance travelled
 * @param reportId
 * @returns object
 */
export const loadCurrentDistanceTravelled = async (reportId: number) => {
  if (!checkInvalidPrimaryKey(reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CurrentDistanceTravelled.url(reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load lube oils on vessel
 * @param vesselId
 * @returns array
 */
export const loadVesselLubeOils = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselLubeOils.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load previous report of fresh water
 * @param vesselId, reportId
 * @returns array
 */
export const loadPreviousFreshWater = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PreviousFreshWater.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's drafts & displacement
 * @param vesselId, reportId
 * @returns array
 */
export const loadDraftsDisplacement = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.DraftsDisplacement.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's cargo details
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDetails = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoDetails.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's voyage parameter object
 * @param vesselId, reportId
 * @returns array
 */
export const loadVoyageParameterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VoyageParameterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's energy parameter object
 * @param vesselId, reportId
 * @returns array
 */
export const loadEnergyParameterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.EnergyParameterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's bunkering data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBunkeringObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BunkeringObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load fuel types of a vessel
 * @param vesselId, reportId
 * @returns array
 */
export const loadDebunkeringObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.DebunkeringObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current vessel's fuel data
 * @param vesselId
 * @returns array
 */
export const loadVesselFuelTypes = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.vesselFuelType.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current vessel's fuel data
 * @param vesselId
 * @returns array
 */
export const loadVesselFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.vesselFuel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's lube oil bunkering data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLOBunkering = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LOBunkeringObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load ongoing STS operation of vessel
 * @param vesselId
 * @returns array
 */
export const loadSTSOngoingObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.STSOngoingObject.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's lube oil debunkering data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLODebunkeringObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LODebunkeringObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's fresh water data
 * @param vesselId, reportId
 * @returns array
 */
export const loadFreshWaterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FreshWaterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's lube oil rob data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLubeOilROBObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LubeOilROBObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's bill of lading data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBillOfLadingObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BillOfLadingObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's ETAD data
 * @param vesselId, reportId
 * @returns array
 */
export const loadETADObject = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ETADObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load CII adjustment event's files that are not yet uploaded
 * @param vesselId
 * @returns array
 */
export const loadPendingCIIFileUpload = async (
  vesselId: number,
  fileStatus: string,
) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      if (vesselId > 0) {
        const response = await apiGlobal.get(
          queryKeyes.vessel.PendingCIIFileUpload.url(vesselId, fileStatus),
        );
        return response.data;
      }
      return [];
    } catch (error: any) {
      console.error(error?.message ?? 'Error Occured!');
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo discharging fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoDischargingFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      if (vesselId > 0) {
        const response = await apiGlobal.get(
          queryKeyes.vessel.CargoDischargingFuelMachineries.url(vesselId),
        );
        return response.data;
      }
      return [];
    } catch (error: any) {
      console.error(error?.message ?? 'Error Occured');
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo discharging energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoDischargingWithkWh = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoDischargingWithkWhMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load time periods for 4 hours table
 * @param -
 * @returns array
 */
export const loadTimePeriod = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TimePeriodObject.url,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current report's cargo discharging fuel data
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDischargingFuelObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoDischargingFuelObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's cargo discharging energy data
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDischargingEnergyObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId) || !checkInvalidPrimaryKey(reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoDischargingEnergyObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's cargo discharging energy without kWh meter data
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDischarging4hrObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoDischarging4hrObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's auxiliary engine's max SFOC
 * @param vesselId
 * @returns array
 */
export const loadAuxEngineMaxSFOC = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.AuxEngineMaxSFOC.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's ballast details data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBallastDetailsObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BallastDetailsObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's machineries selected for running hours
 * @param vesselId
 * @returns array
 */
export const loadVesselRunningHoursMachinery = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.vesselRunningHoursMachinery.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's machinery running hours' data
 * @param vesselId, reportId
 * @returns array
 */
export const loadMachineryRunningHoursObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.RunningHoursObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load fuel sub types
 * @param -
 * @returns array
 */
export const loadFuelSubTypes = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.FuelSubTypes.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current report's shore supply data
 * @param vesselId, reportId
 * @returns array
 */
export const loadShoreSupplyObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ShoreSupplyObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's main engine parameter data
 * @param vesselId, reportId
 * @returns array
 */
export const loadMainEngineParameterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.MainEngineParameterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's auxilliary engine parameter data
 * @param vesselId, reportId
 * @returns array
 */
export const loadAuxEngineParameterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.AuxEngineParameterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's thermal oil heater data
 * @param vesselId, reportId
 * @returns array
 */
export const loadThermalOilHeaterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ThermalOilHeaterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's anchorage data
 * @param vesselId, reportId
 * @returns array
 */
export const loadAnchorageObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.AnchorageObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's pilot arrival details' data
 * @param vesselId, reportId
 * @returns array
 */
export const loadPilotArrivalDetailsObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PilotArrivalDetailsObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's pilot departure details' data
 * @param vesselId, reportId
 * @returns array
 */
export const loadPilotDepartureDetailsObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PilotDepartureDetailsObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's tugs usage data
 * @param vesselId, reportId
 * @returns array
 */
export const loadTugsUsageObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.TugsUsageObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's other details data
 * @param vesselId, reportId
 * @returns array
 */
export const loadOtherDetailsObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.OtherDetailsObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's scrubing unit data
 * @param vesselId, reportId
 * @returns array
 */
export const loadScrubingUnitObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ScrubingUnitObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's sludge data
 * @param vesselId, reportId
 * @returns array
 */
export const loadSludgeObject = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.SludgeObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's bilge data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBilgeObject = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BilgeObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load port activities
 * @param -
 * @returns array
 */
export const loadPortActivities = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.PortActivities.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current report's port activities data
 * @param vesselId, reportId
 * @returns array
 */
export const loadPortActivitiesObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PortActivitiesObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load LNG Elements
 * @param -
 * @returns array
 */
export const loadLNGElements = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.LNGElements.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current voyage's LNG Cargo Quality data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGCargoQualityObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LNGCargoQualityObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's basic information use for IMO DCS, EU MRV, UK MRV
 * @param vesselId
 * @returns array
 */
export const loadVesselBasicInformation = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselBasicInformation.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo heating fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoHeatingFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoHeatingFuelMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo heating energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoHeatingWithkWh = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoHeatingWithkWhMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Heating fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoHeatingFuelObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoHeatingFuelObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Heating energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoHeatingEnergyObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoHeatingEnergyObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Heating 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoHeating4hrObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoHeating4hrObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo cooling fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoCoolingFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoCoolingFuelMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo cooling energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoCoolingWithkWh = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoCoolingWithkWhMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Cooling fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoCoolingFuelObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoCoolingFuelObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Cooling energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoCoolingEnergyObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoCoolingEnergyObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Cooling 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoCooling4hrObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoCooling4hrObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo reliquification fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoReliquificationFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoReliquificationFuelMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for cargo reliquification energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoReliquificationWithkWh = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoReliquificationWithkWhMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Reliquification fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoReliquificationFuelObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoReliquificationFuelObject.url(
          vesselId,
          voyageId,
        ),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Reliquification energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoReliquificationEnergyObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoReliquificationEnergyObject.url(
          vesselId,
          voyageId,
        ),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Cargo Reliquification 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoReliquification4hrObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoReliquification4hrObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for tank cleaning fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadTankCleaningFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.TankCleaningFuelMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for tank cleaning energy consumption
 * @param vesselId
 * @returns array
 */
export const loadTankCleaningWithkWh = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.TankCleaningWithkWhMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Tank Cleaning fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadTankCleaningFuelObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.TankCleaningFuelObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Tank Cleaning energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadTankCleaningEnergyObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.TankCleaningEnergyObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Tank Cleaning 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadTankCleaning4hrObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.TankCleaning4hrObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for reefer container fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadReeferContainerFuel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerFuelMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel machineries applicable for reefer container energy consumption
 * @param vesselId
 * @returns array
 */
export const loadReeferContainerWithkWh = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerWithkWhMachineries.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Reefer Container fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainerFuelObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerFuelObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Reefer Container energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainerEnergyObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerEnergyObject.url(vesselId, voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Reefer Container power rating data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainerPowerRatingObject = async (
  vesselId: number,
  voyageId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerPowerRatingObject.url(
          vesselId,
          voyageId,
        ),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load Fleets
 * @param -
 * @returns array
 */
export const loadFleets = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.Fleets.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load Sludge tanks
 * @param -
 * @returns array
 */
export const loadSludgeTanks = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.Sludge.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load Bilge tanks
 * @param -
 * @returns array
 */
export const loadBilgeTanks = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.Bilge.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load vessels' card details
 * @param vesselId
 * @returns array
 */
export const loadVesselCardDetails = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselCardDetails.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current report's Other Special Operation's fuel consumption data
 * @param vesselId, reportId
 * @returns array
 */
export const loadOtherSpOperationFuel = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.OtherSpOperationFuelObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's Other Special Operation's energy consumption data
 * @param vesselId, reportId
 * @returns array
 */
export const loadOtherSpOperationEnergy = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.OtherSpOperationEnergyObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's auxilliary engines
 * @param vesselId
 * @returns array
 */
export const loadVesselAuxEngines = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselAuxEngines.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load fleet's vessels
 * @param -
 * @returns array
 */
export const loadFleetVessels = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.FleetVessels.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load current report's Weather data
 * @param vesselId, reportId
 * @returns array
 */
export const loadWeatherData = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.WeatherDataObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current vessel's EEOI
 * @param vesselId
 * @returns object
 */
export const loadEEOI = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.EEOI.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current fleet's graph data
 * @param fleetId
 * @returns object
 */
export const loadFleetGraphData = async (fleetId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FleetGraphData.url(fleetId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load vessel assigned to user data
 * @param
 * @returns array
 */
export const loadUserVessel = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.UserVessel.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load vessel's CII data
 * @param vesselId
 * @returns array
 */
export const loadCIIData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CIIData.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's FC Electrical Reefer Container data
 * @param vesselId, reportId
 * @returns array
 */
export const loadReeferContainerDetailsData = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerDetailsObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's CII Adjustment reports
 * @param vesselId
 * @returns array
 */
export const loadCIIAdjustmentData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CIIAdjustment.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's CII Adjustment reports that will be updated
 * @param vesselId, ciiAdjustmentId
 * @returns array
 */
export const loadCIIAdjustmentUpdate = async (
  vesselId: number,
  ciiAdjustmentId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CIIAdjustmentUpdate.url(vesselId, ciiAdjustmentId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load year's whose vessel data is available
 * @param vesselId
 * @returns array
 */
export const loadVesselYear = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselYear.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load sts data according to vessel
 * @param vesselId
 * @returns array
 */
export const loadSTSAdjustment = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.STSAdjustment.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load sts adjustment approval data
 * @param stsId
 * @returns array
 */
export const loadSTSAdjustmentApprovalData = async (stsId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.STSAdjustmentApprovalData.url(stsId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Load LNG cargo ROB from previous report
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGCargoPreviousROB = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LNGCargoPreviousROB.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's LNG cargo ROB
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGCargoROBObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LNGCargoROBObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current correction's initial cargo ROB
 * @param vesselId
 * @returns array
 */
export const loadInitialLNGCargoROBObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.InitialLNGCargoROBObject.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's general info object
 * @param vesselId
 * @returns array
 */
export const loadVesselGenInfoObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselGenInfoObject.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's LNG bunkering object
 * @param vesselId
 * @returns array
 */
export const loadVesselLNGBunkeringObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselLNGBunkeringObject.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};
/**
 * Load vessel's lng tank
 * @param vesselId
 * @returns array
 */
export const loadVesselLNGtanks = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselLNGTanks.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's lng tank details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGTankDetailsObject = async (
  vesselId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LNGTankDetailsObject.url(vesselId, reportID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load special operations
 * @param constant
 * @returns array
 */
export const loadSpecialOperation = async (constant: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.SpecialOperationMaster.url(constant),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load Vessel's other setting object
 * @param vesselId
 * @returns array
 */
export const loadVesselOtherSettingsOperation = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselOtherSettingsObject.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's steam dump details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadSteamDumpDetailsObject = async (
  vesselId: number,
  reportID: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.SteamDumpDetailsObject.url(vesselId, reportID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's fuel sub type consumption according to special operation object
 * @param vesselId, reportId, spOperationId
 * @returns array
 */
export const loadSpOpFuelSubTypeObject = async (
  vesselId: number,
  reportID: number,
  spOperationId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID, spOperationId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.SpOpFuelSubTypeObject.url(
          vesselId,
          reportID,
          spOperationId,
        ),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's average load details
 * @param vesselId
 * @returns array
 */
export const loadVesselAvgLoadInfo = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselAvgLoadInfo.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's average load details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadAvgInfoObject = async (vesselId: number, reportID: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.AvgLoadDetailsObject.url(vesselId, reportID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report's additional details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadProactiveAddDetailsObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ProactiveAddDetailsObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current voyage's object
 * @param voyageId
 * @returns array
 */
export const loadVoyageObject = async (voyageId: number) => {
  if (!checkInvalidPrimaryKey(voyageId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VoyageObject.url(voyageId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel's latest sent report's date
 * @param vesselId
 * @returns object
 */
export const loadLastUpdatedOn = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LastUpdatedOn.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load previous report's additional details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadProactivePreviousAddDetailsObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ProactivePreviousAddDetailsObject.url(
          vesselId,
          reportId,
        ),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load auxillary machine settings
 * @param vesselId
 * @returns array
 */
export const loadAuxillaryEngineSetting = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.AuxillaryMachineSetting.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load previous report's additional details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadProactiveETAAdditionalDetail = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ProactiveETAAdditionalDetail.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const loadFuelTypesExcludingVesselFuelTypes = async (
  vesselId: number,
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FuelTypesExcludingVesselFuelTypes.url(vesselId),
    );
    return response.data as any[];
  } catch (error) {
    console.error(error);
    return [];
  }
};
/**
 * Load other ports' list
 * @param -
 * @returns array
 */
export const loadOtherPorts = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.OtherPortMaster.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load Vessel Sensor List
 * @param vesselId
 * @returns array
 */
export const loadVesselSensorList = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselSensorList.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * previous End of Sea Passage's arrival port
 * @param vesselId, reportId
 * @returns array
 */
export const loadPreviousEOSPPort = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PreviousEOSPPort.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Get current year
 * @returns object
 */
export const loadCurrentYear = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.CurrentYear.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load years from year master
 * @returns array
 */
export const loadYears = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.year.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of all sync file statuses from the server.
 * @returns - A promise that resolves to an array of sync file statuses.
 */
/**
 * Fetches the list of all sync file statuses from the server.
 * @returns - A promise that resolves to an array of sync file statuses.
 */
export const syncFileStatus = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.sync.SyncFileStatusList.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the status of a specific file based on the provided file name.
 * @param {string} fileName - The name of the file to retrieve the status for.
 * @returns {Promise<Object>} - A promise that resolves to the status of the specific file.
 */
export const syncFileStatusByFileName = async (fileName: string) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.sync.syncFileStatusByFileName.url(fileName),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of all notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of notifications.
 */
export const loadCommonNotificationURL = async (url: string) => {
  try {
    if (url) {
      if (url === 'notification/archive/') {
        const response = await apiGlobal.get(url);
        const user = getAuthData('user_id');
        const data = response?.data?.results?.filter(
          (item: any) => item.user === user,
        );
        response.data.results = data;
        return response?.data;
      } else {
        const response = await apiGlobal.get(url);
        return response.data;
      }
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
    return {};
  }
};

/**
 * Fetches the list of notification categories.
 * @returns {Promise<Array>} - A promise that resolves to an array of notification categories.
 */
export const loadNotificationCategoryList = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationCategoryList.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of notification priorities.
 * @returns {Promise<Array>} - A promise that resolves to an array of notification priorities.
 */
export const loadNotificationPriorityList = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationPriorityList.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of user groups.
 * @returns {Promise<Array>} - A promise that resolves to an array of user groups.
 */
export const loadNotificationGroupList = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.GroupList.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the current notification state.
 * @returns {Promise<Array>} - A promise that resolves to an array representing the notification state.
 */
export const loadNotificationState = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationState.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of sent notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of sent notifications.
 */
export const loadNotificationSent = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationSent.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of deleted notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of deleted notifications.
 */
export const loadNotificationDeleted = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationDeleted.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * load vessel's data & update the passed state object required for simulator
 * @param vesselId,
 * @returns object
 */
export const loadSimulatorVesselDataObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.SimulatorVesselDataObject.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetches the list of notification users.
 * @returns {Promise<Array>} - A promise that resolves to an array of notification users.
 */
export const loadNotificationUser = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationUser.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the master list of notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of notifications from the master list.
 */
export const loadNotificationListMaster = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationListMaster.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fetches the list of archived notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of archived notifications.
 */
export const loadNotificationArchive = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationArchive.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Loads the list of notifications from the inbox.
 * @returns {Promise<Array>} - A promise that resolves to an array of inbox notifications.
 */
export const loadNotificationInbox = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationInbox.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Loads the list of draft notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of draft notifications.
 */
export const loadNotificationDraft = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationDraft.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load FuelEU analysis data
 * @param vesselId
 * @returns array
 */
export const loadFuelEUAnalysisData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FuelEUAnalysisData.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetch special operation files
 * @param vesselId
 * @param reportId
 * @returns
 */
export const loadSpOpFile = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.UploadSpecialOperationFile.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetch shore supply by vessel id
 * @param vesselId
 * @param reportId
 * @returns
 */
export const loadShoreSupply = async (vesselId: number, fileStatus: string) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ShoreSupply.url(vesselId, fileStatus),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetch special operation files
 * @param vesselId
 * @returns
 */
export const loadSpOpFileByVessel = async (
  vesselId: number,
  fileStatus: string,
) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.SpecialOperationFile.url(vesselId, fileStatus),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetch reefer container details
 * @param vesselId
 * @returns
 */
export const loadReeferContainerDetail = async (
  vesselId: number,
  fileStatus: string,
) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.ReeferContainerByVessel.url(vesselId, fileStatus),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetch BDN file detail
 * @param vesselId
 * @param reportID
 * @returns
 */
export const loadBDNFiles = async (vesselId: number, reportID: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportID)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.BDNfiles.url(vesselId, reportID),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load FuelEU analysis data
 * @param vesselId
 * @returns array
 */
export const loadFuelEUAnalysisCumulativeData = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.FuelEUAnalysisCumulativeData.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Fetches the user status for notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of notification user statuses.
 */
export const loadNotificationUserStatus = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationUserStatus.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 *  Load N2 adjustment object by vessel
 * @param vesselId
 * @returns
 * @param vesselId
 * @returns
 */
export const loadN2AdjustmentObjectByVessel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.N2AdjustmentObjectByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Local vessel reporting CII adjustment by vessel
 * @param vesselId
 * @returns
 */
export const loadCIIAdjustmentEvents = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselReportingCIIAdjusment.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * To get all CII adjustment Events
 * @param vesselId
 * @returns
 */
export const loadCIIEventsList = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.CIIAdjustmentEvent.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const loadMachineryListMaster = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.MachineryListMaster.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * To get all Reporting Events
 * @param -
 * @returns
 */
export const loadReportingEventsMaster = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.ReportingEventsMaster.url(),
    );
    return response.data as Array<{ [key: string]: string | number }>;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * To get all Reporting Operations
 * @param -
 * @returns
 */
export const loadReportingOperationsMaster = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.ReportingOperationsMaster.url(),
    );
    return response.data as Array<{ [key: string]: string | number }>;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Fuel sub types of selected fuel type
 * @param vesselId
 * @returns
 */
export const loadFuelSubTypesByType = async (fuelType: number) => {
  if (!checkInvalidPrimaryKey(fuelType)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.masters.FuelSubTypesByType.url(fuelType),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load all lube oils from master *
 * @returns Array
 */
export const loadLubeOilMaster = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.LubeOilMaster.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load FC electrical reefer details's object for edit *
 * @returns Array
 */
export const loadFCElectricalReeferObject = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FCElectricalReeferObject.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Vessel's satellite communication information
 * @param vesselId
 * @returns Object
 */
export const loadSatelliteCommByVessel = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.SatelliteCommunicationByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load fuel class master
 * @returns Array
 */
export const loadFuelClass = async () => {
  const response = await apiGlobal.get(
    queryKeyes.masters.FuelClassMaster.url(),
  );
  return response.data;
};

/**
 * Vessel's satellite communication information
 * @param vesselId
 * @returns Object
 */
export const LoadOnboardingSpeedAndConsumption = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load vessel load condition master
 * @returns Array
 */
export const loadVesselConditionMaster = async () => {
  const response = await apiGlobal.get(
    queryKeyes.masters.LoadConditionMaster.url(),
  );
  return response.data;
};

/**
 * Load current voyage's object
 * @param vesselId
 * @returns object
 */
export const loadOnboardingVoyageObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.GetOnboardingVoyageByVessel.url(vesselId),
      );
      return response.data[0];
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current report object
 * @param vesselId
 * @returns object
 */
export const loadOnboardingReportObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.GetOnboardingReportByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current Bunkering object
 * @param vesselId
 * @returns array
 */
export const loadOnboardingBunkeringObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.GetOnboardingBunkeringByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current LO ROB object
 * @param vesselId
 * @returns array
 */
export const loadOnboardingLOROBObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.GetOnboardingLOROBByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current fresh water object
 * @param vesselId
 * @returns object
 */
export const loadOnboardingOtherROBObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.GetOnboardingOtherByVessel.url(vesselId),
      );
      return response.data[0];
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load current fresh water object
 * @param vesselId
 * @returns array
 */
export const loadOnboardingLNGTankObject = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.GetOnboardingLNGTankByVessel.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Econ vessel's flowmters and counters
 * @param vesselId
 * @returns Object
 */
export const loadEconFmCounterMaster = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.EconFMCounterMaster.url(vesselId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Econ vessel's flowmters and counter's report object
 * @param vesselId, reportId
 * @returns Array
 */
export const loadEconFmCounterObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.EconFMCounterObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Econ vessel's main engine report object
 * @param vesselId, reportId
 * @returns Array
 */
export const loadEconMEAdditionalObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.EconMEAdditionalObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Econ vessel's auxiliary engine report object
 * @param vesselId, reportId
 * @returns Array
 */
export const loadEconAEAdditionalObject = async (
  vesselId: number,
  reportId: number,
) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  } else {
    try {
      const response = await apiGlobal.get(
        queryKeyes.vessel.EconAEAdditionalObject.url(vesselId, reportId),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

/**
 * Load fuel types
 * @param -
 * @returns array
 */
export const loadFuelTypesArray = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.fuelTypeMaster.url(),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Econ Deck Additional Information
 * @returns object
 */
export const loadEconDeckAdditionalInformation = async (
  vesselId: number,
  reportId: number,
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.EconDeckAdditionalInformation.url(vesselId, reportId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * last ETAD object
 * by following condition
 * The Current Report's reporting type is "In Port".
 * The Previous Report's reporting type is "At Sea".
 * In the Current Report, the Additional Event checkbox is selected.
 *The Previous Report's reporting event is not "End of Sea Passage".
 * @returns object
 */
export const lastETADObject = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LastETADObject.url(vesselId, reportId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * last etad object
 * @param vesselId
 * @param reportId
 * @returns
 */
export const lastETADPort = async (vesselId: number, reportId: number) => {
  if (!checkInvalidPrimaryKey(vesselId, reportId)) {
    return [];
  }
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LastETADPort.url(vesselId, reportId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * To get all Reporting Operations
 * @returns
 */
export const loadRoleMaster = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.RoleMaster.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * To get all user profiles
 * @returns
 */
export const fetchUserProfile = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.user.profile.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * fetch user profile with user id
 * @param userId
 * @returns user profile
 */
export const fetchUserProfileById = async (userId: number) => {
  if (!checkInvalidPrimaryKey(userId)) {
    return [];
  }
  try {
    const response = await apiGlobal.get(queryKeyes.user.profileID.url(userId));
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Load user groups
 * @returns
 * @param group
 */
export const loadUserGroups = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.user.group.url());
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * get all configuration list form status
 * @returns list form form and status
 * @param vesselId
 */
export const fetchConfigurationList = async (vesselId: number) => {
  if (!checkInvalidPrimaryKey(vesselId)) {
    return [];
  }
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ConfigurationList.url(vesselId),
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getVesselListPage = async (url: string) => {
  try {
    const response = await apiGlobal.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};