import { Field, FieldProps } from "formik";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Label,
    Row,
} from "reactstrap";
import ReportDetailsHeader from "../../../Components/ReportDetailsHeader";
import { useQuery } from "react-query";
import {
    commonRequest,
    loadCPWarrantiesFuelOptions,
    vesselFuel,
} from "../../../VesselMaster/vesselMaster.hooks";
import { queryKeyes } from "../../../shared/queryKeys";
import { getInputs } from "../DeckOfficer.model";
import { CPWarranties, CPfuel } from "../../../shared/dataTypes";
import { AutoFields, FuelTypes } from "../../../shared/constants";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import apiGlobal from "../../../global/api.global";
import { successToast } from "../../../Components/Toasts";
import { dateTimeFormat, removeProperties } from "../../../GenericForms/Helper";
import { queryClient } from "../../../react-query/queryClient";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import env from "environment_system/env_system";
import ToolTip from "Components/ToolTip";
import { TooltipMsg } from "Components/ToolTipMessage";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import ErrorTooltip from "Components/ErrorTooltip";
//import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

interface CPFormType {
    VesselID: number;
    VoyageID: number;
    ReportID: number;
    CPWarrantySpeedDataObject: any;
    CpWarrantyFuelObject: any;
    toggleTab: any;
    record: any;
    activeTab: any;
    lastRecord: any;
    vessel: any;
}
export const CpWarrantyComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    CPWarrantySpeedDataObject,
    CpWarrantyFuelObject,
    toggleTab,
    record,
    activeTab,
    lastRecord,
}: CPFormType) => {
    // state variables for the CPWarraanties
    let { inputs } = getInputs("cp_warranties");

    const {
        data: fuelTypes,
        isLoading: fuelTypeLoading,
        isError: isFuelTypeError,
    } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    const {
        data: lastCPWarranty,
        isLoading: lastCPWarrantyLoading,
        isError: lastCPWarrantyError,
    } = useQuery(
        [queryKeyes.vessel.lastCPWarranties.key, VesselID, VoyageID, ReportID],
        async () => {
            return await loadCPWarrantiesFuelOptions(VesselID, VoyageID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    // Initial object used for the form
    const initialCPWarrentiesData: CPWarranties = {
        ordered_speed: (lastCPWarranty && lastCPWarranty?.speed_data?.ordered_speed && !CPWarrantySpeedDataObject) ?
            lastCPWarranty?.speed_data?.ordered_speed : null,
        date_of_change: (lastCPWarranty && lastCPWarranty?.speed_data?.ordered_speed && !CPWarrantySpeedDataObject) ?
            lastCPWarranty?.speed_data?.date_of_change : null,
        is_there_any_changes_since_last_report: false,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
        fuel: [],
    };

    if (lastCPWarrantyLoading || fuelTypeLoading) {
        return <Loading message="Loading required data!" />;
    }

    if (lastCPWarrantyError || isFuelTypeError) {
        return <ErrorComponent message="Unable to load required data!" />;
    }

    /** Prepare fuel data; if report is opened for edit then it will retrun saved fuel data else it will prepare fuel data
     * from fuelType and lastCPwarranty Data
     */
    let fuelFilteredData: CPfuel[] = getFuelValue();

    initialCPWarrentiesData.fuel = fuelFilteredData;


    const CPFormObject = CPWarrantySpeedDataObject
        ? { ...CPWarrantySpeedDataObject, fuel: fuelFilteredData }
        : initialCPWarrentiesData;
    // common object for formik
    const CPWarrantiesFormik = {
        initialValues: CPFormObject,
        validationSchema: Yup.object().shape({
            ordered_speed: Yup.number().required(commonValidationMessages.required),
            lng_foe: Yup.string()
                .matches(
                    /^\d{0,2}(?:\.\d{1,4})?$/,
                    `${commonValidationMessages.before2after4}`
                )
                .nullable(),
            is_there_any_changes_since_last_report: Yup.boolean(),
            date_of_change: Yup.date()
                .nullable()
                .when("is_there_any_changes_since_last_report", {
                    is: true,
                    then: (schema) => schema
                        .max(
                            new Date(dateTimeFormat(record?.reporting_time_utc)),
                            commonValidationMessages.maxDateCurrentRecord
                        )
                        .min(
                            new Date(dateTimeFormat(lastRecord?.reporting_time_utc)),
                            commonValidationMessages.minDateLastRecord
                        )
                        .required(commonValidationMessages.required)
                }),
            fuel: Yup.array().of((
                Yup.object().shape({
                    ordered_fuel_oil_consumption: Yup.number()
                        .typeError("Please enter a valid number")
                        .when("$is_there_any_changes_since_last_report", {
                            is: true,
                            then: (schema) => schema.required(commonValidationMessages.required),
                        }),
                })
            )),
        }),

        onSubmit: async (values: any, actions: any) => {
            let id = values?.id ? values.id + "/" : "";
            const speedResponse = await commonRequest(
                queryKeyes.vessel.CPWarrantiesSpeed.url() + id,
                values,
                values?.id ? "PATCH" : "POST"
            );
            const fuelResponse = await submitFuel(values.fuel, actions);
            if (speedResponse && fuelResponse) {
                queryClient.invalidateQueries([
                    queryKeyes.vessel.decOfficerAll.key,
                    ReportID,
                ]);
            }
        },
    };

    /**
     * Function returns array for fuel either from saved record or from last cp warranty record
     * @param number id
     * @returns number
     */
    function getFuelValue(): CPfuel[] {
        try {
            if (CpWarrantyFuelObject && CpWarrantyFuelObject.length) {
                return CpWarrantyFuelObject;
            } else if (lastCPWarranty && fuelTypes) {
                if (lastCPWarranty?.fuel_data) {
                    const rest = lastCPWarranty.fuel_data
                        .filter((item: any) => item.precedence_id !== FuelTypes.LNG_CARGO)
                        .map(({ ...rest }: { id: any } & CPfuel) => {
                            rest.vessel_reporting_information = ReportID;
                            return rest;
                        });
                    return rest;
                } else {
                    let vesselfuel: CPfuel[] = [];
                    for (let i = 0; i < fuelTypes.length; i++) {
                        if (fuelTypes[i].precedence_id !== FuelTypes.LNG_CARGO) {
                            const fuel_type = fuelTypes[i].fuel_type;

                            // Find the corresponding entry in the vesselFuel array based on id
                            const matchingVesselFuel = lastCPWarranty?.fuel_data.find(
                                (entry: any) => entry.vessel_fuel === fuel_type
                            );

                            // If a match is found, update the ordered_fuel_oil_consumption in the fuel array
                            if (matchingVesselFuel) {
                                vesselfuel.push({
                                    ordered_fuel_oil_consumption:
                                        matchingVesselFuel.ordered_fuel_oil_consumption,
                                    vessel: VesselID,
                                    voyage_information: VoyageID,
                                    vessel_reporting_information: ReportID,
                                    vessel_fuel: fuelTypes[i].fuel_type,
                                });
                            }
                        }
                    }
                    return vesselfuel;
                }
            } else if (fuelTypes) {
                let vesselfuel: CPfuel[] = [];
                for (let i = 0; i < fuelTypes.length; i++) {
                    if (fuelTypes[i].precedence_id !== FuelTypes.LNG_CARGO) {
                        vesselfuel.push({
                            ordered_fuel_oil_consumption: null,
                            vessel: VesselID,
                            voyage_information: VoyageID,
                            vessel_reporting_information: ReportID,
                            vessel_fuel: fuelTypes[i].fuel_type,
                        });
                    }
                }
                return vesselfuel as CPfuel[];
            }
            return [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    /**
     * function return boolean if value is not avaialble or is_there_any_changes_since_last_report is true
     * @param value
     * @param isChange
     * @returns
     */
    function isFieldDisabled(value: any, isChange: boolean): boolean {
        return !(value === null || value === undefined || value === "" || isChange);
    }

    const submitFuel = async (fuelData: any, actions: any) => {
        try {
            const filteredFuel = removeProperties(fuelData, [
                AutoFields.CREATEDBY,
                AutoFields.CREATEDON,
                AutoFields.MODIFIEDBY,
                AutoFields.MODIFIEDON,
            ]);
            const response = await apiGlobal.patch(
                `/cp_warranties_fuel/bulk_patch/`,
                filteredFuel
            );
            if (response.status === 200 || response.status === 201) {
                successToast("Data saved successfully");
            }
            if (env?.form_validation === true) {
                toggleTab(activeTab + 1);
            }
            actions.setSubmitting(false);
            return response;
        } catch (error) {
            console.error(error);
            actions.setSubmitting(false);
        }
    };

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">CP Warranties</h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <Formik
                initialValues={CPWarrantiesFormik.initialValues}
                validationSchema={CPWarrantiesFormik.validationSchema}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(true);
                    CPWarrantiesFormik.onSubmit(values, actions);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    setErrors,
                    touched,
                    handleBlur,
                    setFieldValue,
                }: {
                    values: any;
                    errors: any;
                    handleChange: any;
                    setErrors: any;
                    touched: any;
                    handleBlur: any;
                    setFieldValue: any;
                }) => (
                    <Form className="needs-validation" autoComplete="off" noValidate>
                        <CardBody className="px-2 py-0 mt-2">
                            <Col sm="4" className="mt-4 mb-3">
                                <div className="form-check">
                                    <Field
                                        name="is_there_any_changes_since_last_report"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="is_there_any_changes_since_last_report"
                                        checked={values.is_there_any_changes_since_last_report}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                            if (!e.target.checked) {
                                                setFieldValue("date_of_change", null);
                                            }
                                        }}
                                    />
                                    <Label for="is_there_any_changes_since_last_report">
                                        Is there any change since last report?
                                        <i
                                            className="bx bx-info-circle ml-2p"
                                            id="formCheck1"
                                        ></i>
                                    </Label>
                                    <ToolTip
                                        target="formCheck1"
                                        message={`${TooltipMsg.CP_Warranties.filter(
                                            (item: any) => item.target === "formCheck1"
                                        ).map((tool: any) => {
                                            return tool.message;
                                        })}`}
                                    />
                                </div>
                            </Col>
                            <Row className="mb-2">
                                <Col sm={3} className="mb-3">
                                    <Label className="asteric mb-0" for="ordered_speed">
                                        Ordered speed
                                        <i
                                            className="bx bx-info-circle ml-2p"
                                            id="ordered_speed_msg"
                                        ></i>
                                    </Label>
                                    <div className="input-group">
                                        <Field name="ordered_speed">
                                            {() => (
                                                <Input
                                                    type="text"
                                                    className="form-control max-width-7 text-right"
                                                    id="ordered_speed"
                                                    autoFocus={true}
                                                    disabled={isFieldDisabled(
                                                        CPWarrantiesFormik.initialValues.ordered_speed,
                                                        values.is_there_any_changes_since_last_report
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={(e: any) => handleChange(e)}
                                                    defaultValue={values?.ordered_speed}
                                                />
                                            )}
                                        </Field>
                                        <div className="input-group-text round_border">knots</div>
                                        <ToolTip
                                            target="ordered_speed_msg"
                                            message={`${TooltipMsg.CP_Warranties.filter(
                                                (item: any) => item.target === "ordered_speed_msg"
                                            ).map((tool: any) => {
                                                return tool.message;
                                            })}`}
                                        />
                                    </div>
                                    {errors?.ordered_speed &&
                                        touched?.ordered_speed &&
                                        errors?.ordered_speed &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target="ordered_speed"
                                                message={errors?.ordered_speed}
                                                open={
                                                    errors?.ordered_speed && errors?.ordered_speed
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                </Col>
                                <Col sm={3}>
                                    <div>
                                        <Label className="asteric mb-0" for="date_of_change">
                                            Date & time of change(UTC)
                                            <i
                                                className="bx bx-info-circle ml-2p"
                                                id="date_time_of_change_msg"
                                            ></i>
                                        </Label>
                                        <ToolTip
                                            target="date_time_of_change_msg"
                                            message={`${TooltipMsg.CP_Warranties.filter(
                                                (item: any) =>
                                                    item.target === "date_time_of_change_msg"
                                            ).map((tool: any) => {
                                                return tool.message;
                                            })}`}
                                        />
                                        <Field name="date_of_change">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="datetime-local"
                                                    id="date_of_change"
                                                    defaultValue={
                                                        dateTimeFormat(values.date_of_change)
                                                    }
                                                    className="datetimepicker text-uppercase mb-2 mt-0"
                                                    min={dateTimeFormat(lastRecord?.reporting_time_utc)}
                                                    max={dateTimeFormat(record?.reporting_time_utc)}
                                                    onBlur={handleBlur}
                                                    disabled={!values.is_there_any_changes_since_last_report}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    {errors?.date_of_change &&
                                        touched?.date_of_change &&
                                        errors?.date_of_change &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target={`date_of_change`}
                                                message={errors?.date_of_change}
                                                open={errors?.date_of_change ? true : false}
                                            />
                                        )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: 8 }}>
                                    <FieldArray name="fuel">
                                        {() => (
                                            <div key="fuel_key" className="table-responsive ">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="p2 align-middle sr-no-width">#</th>
                                                            {inputs
                                                                .filter(
                                                                    (table: any) => table.table_columns === true
                                                                )
                                                                .sort(
                                                                    (a: any, b: any) =>
                                                                        a.table_columns_sequence -
                                                                        b.table_columns_sequence
                                                                )
                                                                .map(({ ...props }, index: number) => {
                                                                    return (
                                                                        <th
                                                                            key={index}
                                                                            className={
                                                                                props.label === "Fuel type"
                                                                                    ? "text-left align-middle p-2"
                                                                                    : props.className +
                                                                                    " align-middle p-2"
                                                                            }
                                                                        >
                                                                            {props.label}
                                                                        </th>
                                                                    );
                                                                })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values?.fuel
                                                            ?.sort((a: any, b: any) => a.id - b.id)
                                                            ?.map((vesselFuelType: any, index: number) => (
                                                                <tr key={index}>
                                                                    <td className="p-2 align-middle text-center">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td className="p-2 align-middle">
                                                                        {
                                                                            fuelTypes?.filter(
                                                                                (fuel: any) =>
                                                                                    fuel.fuel_type ===
                                                                                    vesselFuelType.vessel_fuel
                                                                            )[0]?.fuel_type_name
                                                                        }
                                                                    </td>
                                                                    <td className="p-2 align-middle">
                                                                        <div className="input-group">
                                                                            <Field
                                                                                name={`fuel.${index}.ordered_fuel_oil_consumption`}
                                                                            >
                                                                                {({ field }: FieldProps) => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="form-control max-width-7 text-right"
                                                                                        name={field.name}
                                                                                        id={`ordered_fuel_oil_consumption_${index}`}
                                                                                        disabled={isFieldDisabled(
                                                                                            CPFormObject?.fuel[index]
                                                                                                ?.ordered_fuel_oil_consumption,
                                                                                            values.is_there_any_changes_since_last_report
                                                                                        )}
                                                                                        onBlur={handleBlur}
                                                                                        onChange={(e: any) =>
                                                                                            handleChange(e)
                                                                                        }
                                                                                        defaultValue={
                                                                                            values?.fuel[index]
                                                                                                ?.ordered_fuel_oil_consumption
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text round_border">
                                                                                mt/day
                                                                            </div>
                                                                        </div>
                                                                        {errors?.fuel &&
                                                                            touched?.fuel &&
                                                                            touched?.fuel[index]
                                                                                ?.ordered_fuel_oil_consumption &&
                                                                            errors?.fuel[index]
                                                                                ?.ordered_fuel_oil_consumption &&
                                                                            env?.form_validation === true && (
                                                                                <ErrorTooltip
                                                                                    target={`ordered_fuel_oil_consumption_${index}`}
                                                                                    message={
                                                                                        errors?.fuel[index]
                                                                                            ?.ordered_fuel_oil_consumption
                                                                                    }
                                                                                    open={
                                                                                        errors?.fuel &&
                                                                                            errors?.fuel[index]
                                                                                                ?.ordered_fuel_oil_consumption
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </FieldArray>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="p-2 py-3">
                            <Row className="ele_row1">
                                <div className="d-flex flex-wrap gap-5">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn_size_cstm pos-end"
                                        onClick={() => {
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}
                                    >
                                        Next <i className="bx bx-chevron-right ms-1" />
                                    </Button>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn_size_cstm"
                                        onClick={() => {
                                            setErrors({});
                                            toggleTab(activeTab - 1);
                                        }}
                                    >
                                        <i className="bx bx-chevron-left me-1" /> Previous
                                    </Button>
                                </div>
                            </Row>
                        </CardFooter>
                        <FormValuesDebug
                            values={[
                                values,
                                errors,
                                CPWarrantiesFormik.initialValues,
                                touched,
                            ]}
                        />
                    </Form>
                )}
            </Formik>
        </Card>
    );
};
