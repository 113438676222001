import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import React, { useState } from 'react';
import { Card, CardHeader, Col, Row, TabContent, TabPane } from 'reactstrap';
import MainEngineComponent from './partials/MainEngineComponent';
import AuxEngineComponent from './partials/AuxEngineComponent';
import CountersFlowmetersComponent from './partials/CountersFlowmetersComponent';
import { EventPrecedence } from 'shared/constants';

interface EconAddInformationType {
    toggleTab: any,
    tabsIdList: any[],
    toggleDynamicTabs: any
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    previousTabIndex: number,
    record: any
}

const EconAddInformation = ({
    toggleDynamicTabs,
    ReportID,
    VesselID,
    VoyageID,
    previousTabIndex,
    record
}: EconAddInformationType) => {
    /** State variables */
    const [activeAddTab, setactiveAddTab] = useState(1);
    /** State variables end */

    /** Change wizard's tabs */
    const toggleAddTab = (tab: number) => {
        if (activeAddTab !== tab) {
            if (tab >= 1 && tab <= (record?.event_precedence_id === EventPrecedence.NOON ? 3 : 1)) {
                setactiveAddTab(tab);
            }
        }
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <TabContent activeTab={activeAddTab} className="tabHeight">
                    <TabPane tabId={record?.event_precedence_id === EventPrecedence.NOON ? 1 : 0}>
                        <MainEngineComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddTab}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                        />
                    </TabPane>
                    <TabPane tabId={record?.event_precedence_id === EventPrecedence.NOON ? 2 : 0}>
                        <AuxEngineComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddTab}
                        />
                    </TabPane>
                    <TabPane tabId={record?.event_precedence_id === EventPrecedence.NOON ? 3 : 1}>
                        <CountersFlowmetersComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddTab}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                            record={record}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    )
}

export default EconAddInformation