import React, { createContext, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  Form,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { Field, FieldProps, Formik } from "formik";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import ToolTip from "Components/ToolTip";
import { TooltipMsg } from "Components/ToolTipMessage";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import apiGlobal, { apiMedia } from "global/api.global";
import {
  FileStatus,
  LoadConditionConstant,
  ReportingEvents,
  ReportingTypes,
  VesselTypeConstant,
} from "shared/constants";
import {
  loadBallastDetailsObject,
  loadBillOfLadingObject,
  loadCargoDetails,
  loadDraftsDisplacement,
  loadReeferContainerDetailsData,
  loadInitialLNGCargoROBObject,
  loadLNGCargoPreviousROB,
  loadLNGCargoROBObject,
  loadLNGTankDetailsObject,
  loadVesselLNGtanks,
  loadVesselOtherSettingsOperation,
} from "VesselMaster/vesselMaster.hooks";
import {
  DraftsDisplacementValidationMessages,
  commonValidationMessages,
} from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import { handleServerResponse } from "GenericForms/Helper";
import env from "environment_system/env_system";
import LNGCargoQuality from "Components/LNGCargoQuality";
import PopOver from "Components/PopOver";
import PopUp from "Components/PopUp";
import ErrorTooltip from "Components/ErrorTooltip";
import ReportingFileUpload from "Components/ReportingFileUpload";
import ErrorComponent from "Components/ErrorComponent";

const LoadingCTMSContext = createContext(null);
const DischargingCTMSContext = createContext(null);
export const UpdateLoadingCTMS = () => {
  return useContext(LoadingCTMSContext);
};

export const UpdateDischargingCTMS = () => {
  return useContext(DischargingCTMSContext);
};

interface DraftsCargoLadingType {
  record: any;
  vessel: any;
  VesselID: number;
  VoyageID: number;
  ReportID: number;
  activeTab: number;
  toggleTab: any;
}

const DraftsCargoLadingComponent = ({
  record,
  vessel,
  VesselID,
  VoyageID,
  ReportID,
  activeTab,
  toggleTab,
}: DraftsCargoLadingType) => {
  /** State variables */
  // const [blFigureAttachment, setBlFigureAttachment] = useState<Array<any>>([]);
  // const [surveyAttachment, setSurveyAttachment] = useState<Array<any>>([]);
  // const [letterofProtestAttachment, setLetterofProtestAttachment] = useState<Array<any>>([]);
  // const [blFigureAttachmentBool, setBlFigureAttachmentBool] = useState(false);
  // const [surveyAttachmentBool, setSurveyAttachmentBool] = useState(false);
  // const [letterofProtestAttachmentBool, setLetterofProtestAttachmentBool] = useState(false);
  const [loadingPopOverBool, setLoadingPopOverBool] = useState<boolean>(false);
  const [loadingPopUpBool, setLoadingPopUpBool] = useState<boolean>(false);
  const [loadingCTMS, setLoadingCTMS] = useState<boolean>(false);
  const [cargoDetailsId, setCargoDetailsId] = useState<number>(0);
  const [initialLNGCargoId, setInitialLNGCargoId] = useState<number>(0);
  const [dischargingPopOverBool, setDischargingPopOverBool] = useState<boolean>(false);
  const [dischargingPopUpBool, setDischargingPopUpBool] = useState<boolean>(false);
  const [dischargingCTMS, setDischargingCTMS] = useState<boolean>(false);
  const [baplieFiles, setBaplieFiles] = useState<Array<any>>([{}]);
  const [logBook, setLogBook] = useState<Array<any>>([{}]);
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const deleteBaplieFileObj: any = {
    baplie_file: null,
    baplie_file_path: null,
  }
  const deleteLogBookObj: any = {
    log_book_upload: null,
    log_book_file_path: null,
  }
  /** State variables end */

  /** Queries */
  /** Drafts & Displacement object used for edit */
  const {
    data: DraftsDisplacementObject,
    isLoading: DraftsDisplacementObjectLoading,
    isError: DraftsDisplacementObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.DraftsDisplacement.key, VesselID, ReportID],
    async () => {
      return await loadDraftsDisplacement(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Cargo Details object used for edit */
  const {
    data: CargoDetailsObject,
    isLoading: CargoDetailsObjectLoading,
    isError: CargoDetailsObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.CargoDetails.key, VesselID, ReportID],
    async () => {
      return await loadCargoDetails(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Ballast Details object used for edit */
  const {
    data: BallastDetailsObject,
    isLoading: BallastDetailsObjectLoading,
    isError: BallastDetailsObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.BallastDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadBallastDetailsObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Bill of Lading object used for edit */
  const {
    data: BillOfLadingObject,
    isLoading: BillOfLadingObjectLoading,
    isError: BillOfLadingObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.BillOfLadingObject.key, VesselID, ReportID],
    async () => {
      return await loadBillOfLadingObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** LNG cargo's previous report's */
  const {
    data: LNGCargoPreviousROB,
    isLoading: LNGCargoPreviousROBLoading,
    isError: LNGCargoPreviousROBError,
  } = useQuery(
    [queryKeyes.vessel.LNGCargoPreviousROB.key, VesselID, ReportID],
    async () => {
      return await loadLNGCargoPreviousROB(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** LNG cargo ROB Object used for edit */
  const {
    data: LNGCargoROBObject,
    isLoading: LNGCargoROBObjectLoading,
    isError: LNGCargoROBObjectError,
  } = useQuery(
    [queryKeyes.vessel.LNGCargoROBObject.key, VesselID, ReportID],
    async () => {
      return await loadLNGCargoROBObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Initial LNG cargo ROB Object used for edit */
  const {
    data: InitialLNGCargoObject,
    isLoading: InitialLNGCargoObjectLoading,
    isError: InitialLNGCargoObjectError,
  } = useQuery(
    [queryKeyes.vessel.InitialLNGCargoROBObject.key, VesselID],
    async () => {
      return await loadInitialLNGCargoROBObject(VesselID);
    },
    { staleTime: Infinity }
  );
  /** Vessel's LNG tanks */
  const {
    data: VesselLNGTanks,
    isLoading: VesselLNGTanksLoading,
    isError: VesselLNGTanksError,
  } = useQuery(
    [queryKeyes.vessel.VesselLNGTanks.key, VesselID],
    async () => {
      return await loadVesselLNGtanks(VesselID);
    },
    { staleTime: Infinity }
  );
  /** LNG Tank Details used for edit */
  const {
    data: LNGTankDetails,
    isLoading: LNGTankDetailsLoading,
    isError: LNGTankDetailsError,
  } = useQuery(
    [queryKeyes.vessel.LNGTankDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadLNGTankDetailsObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** LNG Tank Details used for edit */
  const {
    data: ReeferContainer,
    isLoading: ReeferContainerLoading,
    isError: ReeferContainerError,
  } = useQuery(
    [queryKeyes.vessel.ReeferContainerDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadReeferContainerDetailsData(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Vessel's other setting;s object used for edit */
  const { data: OtherSettings, isLoading: OtherSettingsLoading, isError: OtherSettingsError }:
    { data: any, isLoading: any, isError: any } = useQuery(
      [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
      async () => { return await loadVesselOtherSettingsOperation(VesselID) },
      {
        enabled: true,
        staleTime: Infinity,
      }
    );
  /** Queries end */

  /** Assign values to initial object of Drafts & Displacement */
  const getInitialDraftsDisplacement = () => {
    if (
      DraftsDisplacementObject?.length > 0 &&
      DraftsDisplacementObject[0].id > 0
    ) {
      return DraftsDisplacementObject[0];
    } else {
      return {
        fwd_draft: null,
        draft_mid: null,
        draft_aft: null,
        displacement: null,
        trim: 0,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Cargo Details */
  const getInitialCargoDetails = () => {
    if (CargoDetailsObject?.length > 0 && CargoDetailsObject[0]?.id > 0) {
      return CargoDetailsObject[0];
    } else {
      return {
        cargo_detail: "",
        amount_of_cargo_loaded: 0,
        amount_of_cargo_discharged: 0,
        total_amount_of_cargo_onboard: 0,
        no_of_reefer_container: 0,
        cargo_adjusted: 0,
        adjustment_reason: "",
        lng_foe_factor: null,
        loading: false,
        discharging: false,
        loading_completion: false,
        discharging_completion: false,
        is_loading_ctms: false,
        is_discharging_ctms: false,
        OOG_units: 0,
        feet_20: 0,
        feet_40: 0,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Ballast Details */
  const getInitialBallastDetails = () => {
    if (BallastDetailsObject?.length > 0 && BallastDetailsObject[0]?.id > 0) {
      return BallastDetailsObject[0];
    } else {
      return {
        permanent: null,
        addition: null,
        total: null,
        loaded: null,
        discharged: null,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Bill of Lading */
  const getInitialBillOfLading = () => {
    if (BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) {
      return BillOfLadingObject[0];
    } else {
      return {
        is_bill_of_lading_issued_since_last_report: false,
        cargo_survey: false,
        letter_of_protest: false,
        bl_figure: 0,
        //bl_figure_attachment: null as any,
        ship_figure_of_bl_figure: 0,
        //survey_attachment: null as any,
        surveyor_figure_of_bl_figure: 0,
        //letter_of_protest_attachment: null as any,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Reefer Container Details */
  const getInitialReeferContainerDetails = () => {
    if (vessel?.vessel_type === VesselTypeConstant.CONTAINER) {
      if (ReeferContainer && ReeferContainer?.length > 0) {
        return ReeferContainer[0];
      } else {
        return {
          loaded_container: 0,
          discharged_container: 0,
          total_onboard_container: 0,
          baplie_file: "",
          log_book_upload: "",
          reason_for_change: "",
          baplie_file_status: FileStatus.PENDING,
          log_book_status: FileStatus.PENDING,
          vessel: VesselID,
          voyage_information: VoyageID,
          vessel_reporting_information: ReportID,
        };
      }
    } else {
      return null
    }
  };
  /** Assign initial values to LNG cargo ROB formik object */
  const getLNGCargoROBInitialVals = () => {
    if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
      if (LNGCargoROBObject && LNGCargoROBObject.length > 0) {
        return LNGCargoROBObject[0];
      } else {
        return {
          cargo_consumed: 0,
          previous_lng_cargo_rob_id: null,
          cargo_rob: LNGCargoPreviousROB?.cargo_rob ?? 0,
          cargo_loaded_cargo_detail: null,
          cargo_discharge_cargo_detail: null,
          lng_initial_cargo_rob: null,
          vessel: VesselID,
          voyage_information: VoyageID,
          vessel_reporting_information: ReportID,
        };
      }
    }
  };

  /** Assign initial values to initial LNG cargo ROB formik object */
  const getInitialLNGCargoROB = () => {
    if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
      if (InitialLNGCargoObject && InitialLNGCargoObject.length > 0) {
        if (InitialLNGCargoObject[0]?.start_report === ReportID) {
          InitialLNGCargoObject[0].loading_completion = true;
        } else if (InitialLNGCargoObject[0]?.end_report === ReportID) {
          InitialLNGCargoObject[0].discharging_completion = true;
        } else {
          InitialLNGCargoObject[0].loading_completion = false;
          InitialLNGCargoObject[0].discharging_completion = false;
        }
        delete InitialLNGCargoObject[0].start_file_path;
        delete InitialLNGCargoObject[0].end_file_path;
        return InitialLNGCargoObject[0];
      } else {
        return ({
          loaded_cargo: 0,
          loading_ctms: false,
          status: "Ongoing",
          discharge_cargo: 0,
          discharge_ctms: false,
          start_report: null as number,
          end_report: null as number,
          vessel: VesselID,
        });
      }
    }
  };

  /** Assign initial values to vessel's LNG tanks formik object */
  const getVesselLNGTanks = () => {
    if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
      if (LNGTankDetails && LNGTankDetails[0]?.id > 0) {
        return LNGTankDetails;
      } else {
        let array: any[] = [];
        VesselLNGTanks?.forEach((tank: any) => {
          array.push({
            temperature: null as number,
            pressure: null,
            tank: tank?.id,
            tank_name: tank?.name_of_tanks,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
          });
        });
        return array;
      }
    }
  };

  /** UseEffect */
  useEffect(() => {
    DraftCargoLadingFormik.initialValues = {
      draftsDisplacement: getInitialDraftsDisplacement(),
      cargoDetails: getInitialCargoDetails(),
      ballastDetails: getInitialBallastDetails(),
      billofLading: getInitialBillOfLading(),
      reeferContainer: getInitialReeferContainerDetails(),
      lngCargoROB: getLNGCargoROBInitialVals(),
      initialLNGCargoROB: getInitialLNGCargoROB(),
      lngTankDetails: getVesselLNGTanks(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DraftsDisplacementObject,
    CargoDetailsObject,
    BillOfLadingObject,
    LNGCargoPreviousROB,
    LNGCargoROBObject,
    LNGCargoPreviousROBError,
    LNGTankDetails,
    VesselLNGTanks,
    InitialLNGCargoObject
  ]);
  /** UseEffect end */

  /** Formik object for all forms */
  const DraftCargoLadingFormik = {
    initialValues: {
      draftsDisplacement: getInitialDraftsDisplacement(),
      cargoDetails: getInitialCargoDetails(),
      ballastDetails: getInitialBallastDetails(),
      billofLading: getInitialBillOfLading(),
      reeferContainer: getInitialReeferContainerDetails(),
      lngCargoROB: getLNGCargoROBInitialVals(),
      initialLNGCargoROB: getInitialLNGCargoROB(),
      lngTankDetails: getVesselLNGTanks(),
    },
    validationSchema: Yup.object().shape({
      draftsDisplacement: Yup.object({
        fwd_draft: Yup.string()
          .matches(
            /^\d{0,2}(?:\.\d{1,2})?$/,
            commonValidationMessages.before2after2
          )
          .required(commonValidationMessages.required),
        draft_mid: Yup.string()
          .matches(
            /^\d{0,2}(?:\.\d{1,2})?$/,
            commonValidationMessages.before2after2
          )
          .required(commonValidationMessages.required),
        draft_aft: Yup.string()
          .matches(
            /^\d{0,2}(?:\.\d{1,2})?$/,
            commonValidationMessages.before2after2
          )
          .required(commonValidationMessages.required),
        displacement: Yup.string()
          .matches(
            /^\d{0,6}(?:\.\d{1,2})?$/,
            DraftsDisplacementValidationMessages.displacement
          )
          .required(commonValidationMessages.required),
      }),
      cargoDetails: Yup.object({
        cargo_detail: Yup.string()
          .min(2, commonValidationMessages.min2)
          .max(50, commonValidationMessages.max50Char)
          .when("$fieldAvailability", (field: any, schema) => {
            return ((record?.is_this_cargo_loading_discharging === 'no') ||
              (record?.is_this_cargo_loading_discharging === null))
              ? schema.nullable()
              : schema.required(commonValidationMessages.required);
          }),
        amount_of_cargo_loaded: Yup.string()
          .matches(
            /^(?!0\d)(\d{1,7}|0)(\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .when("$fieldAvailability", (field: any, schema) => {
            return ((record?.is_this_cargo_loading_discharging === 'no') ||
              (record?.is_this_cargo_loading_discharging === null))
              ? schema.nullable()
              : schema.required(commonValidationMessages.required);
          }),
        amount_of_cargo_discharged: Yup.string()
          .matches(
            /^(?!0\d)(\d{1,7}|0)(\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .when("$fieldAvailability", (field: any, schema) => {
            return ((record?.is_this_cargo_loading_discharging === 'no') ||
              (record?.is_this_cargo_loading_discharging === null))
              ? schema.nullable()
              : schema.required(commonValidationMessages.required);
          }),
        total_amount_of_cargo_onboard: Yup.string().when(
          "$fieldVisibility",
          (fieldVisibility: any, schema) => {
            return vessel.vessel_type === VesselTypeConstant.LNG_CARRIER &&
              record?.is_this_cargo_loading_discharging === 'yes'
              ? schema.nullable()
              : schema
                .matches(
                  /^(?!0\d)(\d{1,7}|0)(\.\d{1,2})?$/,
                  commonValidationMessages.before7after2
                )
                .required(commonValidationMessages.required);
          }
        ),
        no_of_reefer_container: Yup.string()
          .min(1, `${commonValidationMessages.min1}`)
          .max(5, `${commonValidationMessages.max50}`),
        cargo_adjusted: Yup.string()
          .matches(
            /^[+-]?\d{0,7}(?:\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .nullable(),
        adjustment_reason: Yup.string()
          .when("cargo_adjusted", (val: any, schema) => {
            return (val && val[0] !== '0' &&
              vessel.vessel_type === VesselTypeConstant.LNG_CARRIER &&
              record?.is_this_cargo_loading_discharging === 'yes')
              ? schema.required(commonValidationMessages.required)
              : schema.nullable();
          })
          .max(50, commonValidationMessages.max50Char),
      }),
      ballastDetails: Yup.object({
        permanent: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .required(commonValidationMessages.required),
        addition: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .required(commonValidationMessages.required),
        total: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .required(commonValidationMessages.required),
        loaded: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .required(commonValidationMessages.required),
        discharged: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,2})?$/,
            commonValidationMessages.before7after2
          )
          .required(commonValidationMessages.required),
      }),
      billofLading: Yup.object({
        is_bill_of_lading_issued_since_last_report: Yup.boolean(),
        cargo_survey: Yup.boolean(),
        letter_of_protest: Yup.boolean(),
        bl_figure: Yup.string().matches(
          /^\d{0,7}(?:\.\d{1,2})?$/,
          commonValidationMessages.before7after2
        ),
        //bl_figure_attachment: Yup.string().nullable(),
        ship_figure_of_bl_figure: Yup.string().matches(
          /^\d{0,7}(?:\.\d{1,2})?$/,
          commonValidationMessages.before7after2
        ),
        //survey_attachment: Yup.string().nullable(),
        surveyor_figure_of_bl_figure: Yup.string().matches(
          /^\d{0,7}(?:\.\d{1,2})?$/,
          commonValidationMessages.before7after2
        ),
        //letter_of_protest_attachment: Yup.string().nullable(),
      }),
      lngTankDetails: Yup.array(
        Yup.object({
          temperature: Yup.number()
            .min(-180, commonValidationMessages.min_180)
            .max(50, commonValidationMessages.max50)
            .required(commonValidationMessages.required),
          pressure: Yup.number()
            .min(0, commonValidationMessages.min0)
            .max(250, commonValidationMessages.max250)
            .required(commonValidationMessages.required),
        })
      ),
    }),
  };

  /** Calculate LNG cargo ROB */
  const updateTotalCargoOnboard = (
    form: any,
    values: any,
    e: any,
    op: string
  ) => {
    if (op === "loading") {
      form.setFieldValue(
        "cargoDetails.total_amount_of_cargo_onboard",
        (
          parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
          parseFloat(e.target.value) -
          parseFloat(values?.cargoDetails?.amount_of_cargo_discharged ?? 0) -
          parseFloat(values?.lngCargoROB?.cargo_consumed ?? 0) +
          parseFloat(values?.cargoDetails?.cargo_adjusted ?? 0)
        ).toFixed(2)
      );
    } else if (op === "discharging") {
      form.setFieldValue(
        "cargoDetails.total_amount_of_cargo_onboard",
        (
          parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
          parseFloat(values?.cargoDetails?.amount_of_cargo_loaded ?? 0) -
          parseFloat(e.target.value) -
          parseFloat(values?.lngCargoROB?.cargo_consumed ?? 0) +
          parseFloat(values?.cargoDetails?.cargo_adjusted ?? 0)
        ).toFixed(2)
      );
    } else {
      form.setFieldValue(
        "cargoDetails.total_amount_of_cargo_onboard",
        (
          parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
          parseFloat(values?.cargoDetails?.amount_of_cargo_loaded ?? 0) -
          parseFloat(values?.cargoDetails?.amount_of_cargo_discharged ?? 0) -
          parseFloat(values?.lngCargoROB?.cargo_consumed ?? 0) +
          parseFloat(e.target.value)
        ).toFixed(2)
      );
    }
  };


  return (
    <Card className="deck-card-body mb-0 border-0">
      {DraftsDisplacementObjectLoading ||
        CargoDetailsObjectLoading ||
        BillOfLadingObjectLoading ||
        BallastDetailsObjectLoading ||
        LNGCargoPreviousROBLoading ||
        LNGCargoROBObjectLoading ||
        InitialLNGCargoObjectLoading ||
        VesselLNGTanksLoading ||
        LNGTankDetailsLoading ||
        ReeferContainerLoading ||
        OtherSettingsLoading}
      {OtherSettingsError && <ErrorComponent message="Error loading required data!" />}
      {DraftsDisplacementObjectError && getInitialDraftsDisplacement()}
      {CargoDetailsObjectError && getInitialCargoDetails()}
      {BallastDetailsObjectError && getInitialBallastDetails()}
      {BillOfLadingObjectError && getInitialBillOfLading()}
      {LNGCargoROBObjectError &&
        (DraftCargoLadingFormik.initialValues.lngCargoROB =
          getLNGCargoROBInitialVals())}
      {InitialLNGCargoObjectError && getInitialLNGCargoROB()}
      {LNGTankDetailsError && getVesselLNGTanks()}
      {ReeferContainerError && getInitialReeferContainerDetails()}

      {!(
        DraftsDisplacementObjectLoading ||
        CargoDetailsObjectLoading ||
        BillOfLadingObjectLoading ||
        LNGCargoPreviousROBLoading ||
        LNGCargoROBObjectLoading ||
        InitialLNGCargoObjectLoading ||
        VesselLNGTanksLoading ||
        LNGTankDetailsLoading ||
        OtherSettingsLoading
      ) &&
        !(LNGCargoPreviousROBError || VesselLNGTanksError || OtherSettingsError) && (
          <Formik
            key={refreshKey}
            onSubmit={async (values: any, actions: any) => {
              actions.setSubmitting(true);
              const responseArray: any = [];
              /** Drafts & Displacement submit */
              if (
                DraftsDisplacementObject &&
                DraftsDisplacementObject[0]?.id > 0
              ) {
                responseArray.push(apiGlobal
                  .put(
                    `/draft_and_displacement/${values.draftsDisplacement.id}/`,
                    values.draftsDisplacement
                  ))
              } else {
                responseArray.push(apiGlobal
                  .post(`/draft_and_displacement/`, values.draftsDisplacement))
              }
              /** Ballast Details submit */
              if (BallastDetailsObject && BallastDetailsObject[0]?.id) {
                responseArray.push(apiGlobal
                  .put(
                    `/ballast_details/${values?.ballastDetails.id}/`,
                    values?.ballastDetails
                  ))
              } else {
                responseArray.push(apiGlobal
                  .post(`/ballast_details/`, values.ballastDetails))
              }
              /** Cargo Details submit */
              if (
                record &&
                record?.is_this_cargo_loading_discharging === "yes"
              ) {
                if (CargoDetailsObject && CargoDetailsObject[0]?.id) {
                  setCargoDetailsId(values.cargoDetails.id);
                  responseArray.push(apiGlobal
                    .put(
                      `/cargo_detail/${values.cargoDetails.id}/`,
                      values.cargoDetails
                    ))
                } else {
                  responseArray.push(apiGlobal
                    .post(`/cargo_detail/`, values.cargoDetails))
                }
              }
              /** Bill of Lading submit */
              if (
                record &&
                record.load_condition === LoadConditionConstant.LADEN
              ) {
                if (
                  BillOfLadingObject?.length > 0 &&
                  BillOfLadingObject[0]?.id > 0
                ) {
                  responseArray.push(apiGlobal
                    .put(
                      `/bill_of_lading/${values.billofLading.id}/`,
                      values.billofLading
                    ))
                } else {
                  responseArray.push(apiGlobal
                    .post(`/bill_of_lading/`, values.billofLading))
                }
              }
              /** Reefer Containers submit */
              if (
                vessel?.vessel_type === VesselTypeConstant.CONTAINER &&
                record?.reporting_type === ReportingTypes.IN_PORT
              ) {
                if (values.reeferContainer.id) {
                  const formData = new FormData();
                  Object.keys(values.reeferContainer).forEach((item: any) => {
                    formData.append(item, values.reeferContainer[item]);
                  });
                  if (baplieFiles && baplieFiles[0].file) {
                    formData.append("baplie_file", baplieFiles[0].file);
                  }
                  if (logBook && logBook[0].file) {
                    formData.append("log_book_upload", logBook[0].file);
                  }
                  responseArray.push(apiMedia
                    .put(
                      `/reefer_container_details/${ReeferContainer[0].id}/`,
                      formData
                    ))
                } else {
                  const formData = new FormData();
                  Object.keys(values.reeferContainer).forEach((item: any) => {
                    formData.append(item, values.reeferContainer[item]);
                  });
                  if (baplieFiles && baplieFiles[0].file) {
                    formData.append("baplie_file", baplieFiles[0].file);
                  }
                  if (logBook && logBook[0].file) {
                    formData.append("log_book_upload", logBook[0].file);
                  }
                  responseArray.push(apiMedia
                    .post(`/reefer_container_details/`, formData))
                }
              }
              /** Update intial LNG Cargo ROB */
              if (
                vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                record &&
                record?.is_this_cargo_loading_discharging === 'yes'
              ) {
                if (values.cargoDetails.amount_of_cargo_loaded > 0) {
                  values.initialLNGCargoROB.loaded_cargo =
                    values?.cargoDetails?.amount_of_cargo_loaded;
                  values.initialLNGCargoROB.loading_ctms = loadingCTMS;
                }
                if (values.cargoDetails.amount_of_cargo_discharged > 0) {
                  values.initialLNGCargoROB.discharge_cargo =
                    values?.cargoDetails?.amount_of_cargo_discharged;
                  values.initialLNGCargoROB.discharge_ctms = dischargingCTMS;
                }
                if (values?.cargoDetails?.loading_completion === true) {
                  values.initialLNGCargoROB.start_report = ReportID;
                }
                if (values?.cargoDetails?.discharging_completion === true) {
                  values.initialLNGCargoROB.end_report = ReportID;
                }
                if (
                  values?.initialLNGCargoROB?.end_report !== null ||
                  InitialLNGCargoObject[0]?.start_report === ReportID
                ) {
                  responseArray.push(
                    apiGlobal.put(
                      `/n2_adjustment/${values?.initialLNGCargoROB?.id}/`,
                      values?.initialLNGCargoROB
                    ))
                } else if (
                  values?.initialLNGCargoROB?.start_report === ReportID &&
                  InitialLNGCargoObject[0]?.start_report !== ReportID
                ) {
                  const res = await apiGlobal
                    .post(`/n2_adjustment/`, values?.initialLNGCargoROB)
                  if (res.status === 201) {
                    setInitialLNGCargoId(res?.data?.id);
                  }
                  responseArray.push(res);
                }
              }
              /** Submit LNG cargo ROB */
              if (
                vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                record &&
                record?.is_this_cargo_loading_discharging === 'yes'
              ) {
                if (values?.cargoDetails?.amount_of_cargo_loaded > 0) {
                  values.lngCargoROB.cargo_loaded_cargo_detail =
                    cargoDetailsId === 0
                      ? CargoDetailsObject[0]?.id
                      : cargoDetailsId;
                }
                if (values?.cargoDetails?.amount_of_cargo_discharged > 0) {
                  values.lngCargoROB.cargo_discharge_cargo_detail =
                    cargoDetailsId === 0
                      ? CargoDetailsObject[0]?.id
                      : cargoDetailsId;
                }
                if (values?.initialLNGCargoROB?.start_report === ReportID) {
                  values.lngCargoROB.lng_initial_cargo_rob =
                    initialLNGCargoId;
                  values.lngCargoROB.cargo_rob =
                    values.cargoDetails.total_amount_of_cargo_onboard;
                }
                values.lngCargoROB.lng_initial_cargo_rob =
                  InitialLNGCargoObject[0]?.id;
                values.lngCargoROB.cargo_rob = parseFloat(
                  values.cargoDetails.total_amount_of_cargo_onboard
                );
                if (LNGCargoROBObject && LNGCargoROBObject.length > 0) {
                  responseArray.push(apiGlobal
                    .put(
                      `/lng_cargo_rob/${values?.lngCargoROB?.id}/`,
                      values?.lngCargoROB
                    ))
                } else {
                  responseArray.push(apiGlobal
                    .post(`/lng_cargo_rob/`, values?.lngCargoROB))
                }
              }
              /** Cargo Parameters Submit */
              if (
                vessel &&
                vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER
              ) {
                if (LNGTankDetails && LNGTankDetails[0]?.id) {
                  values.lngTankDetails.forEach((lngTank: any) => {
                    responseArray.push(apiGlobal
                      .put(
                        `/vessel_reporting_lng_tank_detail/${lngTank.id}/`,
                        lngTank
                      ))
                  });
                } else {
                  responseArray.push(apiGlobal
                    .post(
                      `/vessel_reporting_lng_tank_detail/`,
                      values.lngTankDetails
                    ))
                }
              }
              await handleServerResponse(responseArray).then(async (res) => {
                if (res) {
                  setBaplieFiles([{}])
                  await queryClient.invalidateQueries(queryKeyes.vessel.ReeferContainerDetailsObject.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.DraftsDisplacement.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.BallastDetailsObject.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.CargoDetails.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.BillOfLadingObject.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.FCElectricalReeferObject.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.InitialLNGCargoROBObject.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoPreviousROB.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoQualityObject.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                  if (env?.form_validation === true) {
                    toggleTab(activeTab + 1);
                  }
                }
                actions.setSubmitting(false);
              })
              actions.setSubmitting(false);
            }}
            initialValues={DraftCargoLadingFormik.initialValues}
            validationSchema={DraftCargoLadingFormik.validationSchema}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleChange,
              setErrors,
              touched,
              handleBlur,
              isSubmitting,
            }: {
              values: any;
              errors: any;
              handleSubmit: any;
              handleChange: any;
              setErrors: any;
              touched: any;
              handleBlur: any;
              isSubmitting: any;
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <>
                  <Card className="p-0 mb-0 rounded-0 border-top-0 border-start-0 border-end-0">
                    <CardHeader className="py-2 px-0">
                      <div className="text-center">
                        <Row>
                          <Col>
                            <h4 className="page_title pos-start mb-0">
                              Drafts & Displacement
                            </h4>
                            <p className="card-title-desc pos-start">
                              All readings since last report
                            </p>
                          </Col>
                          <Col>
                            <ReportDetailsHeader />
                          </Col>
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody className="px-0 py-0 mt-2">
                      <Row>
                        <div className="d-flex flex-wrap">
                          <Col lg={2} className="ps-0 py-1">
                            <Label className="asteric mb-0" for="fwd_draft">
                              Fwd draft
                              <i
                                className="bx bx-info-circle ml-2p"
                                id="fwd_draft_msg"
                              ></i>
                            </Label>
                            <ToolTip
                              target="fwd_draft_msg"
                              message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "fwd_draft_msg"
                              ).map((tool: any) => {
                                return tool.message;
                              })}`}
                            />
                            <div className="mb-1 input-group">
                              <Field name="draftsDisplacement.fwd_draft">
                                {({ field, form }: FieldProps) => (
                                  <Input
                                    type="text"
                                    className="form-control text-right border-end-0"
                                    id="fwd_draft"
                                    name={field.name}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      form.setFieldValue(
                                        "draftsDisplacement.trim",
                                        (
                                          values?.draftsDisplacement.draft_aft -
                                          e.target.value
                                        ).toFixed(2)
                                      );
                                    }}
                                    onBlur={(e: any) => {
                                      handleBlur(e);
                                      handleChange(e);
                                      form.setFieldValue(
                                        "draftsDisplacement.trim",
                                        (
                                          values?.draftsDisplacement.draft_aft -
                                          e.target.value
                                        ).toFixed(2)
                                      );
                                    }}
                                    defaultValue={
                                      values &&
                                      values?.draftsDisplacement?.fwd_draft
                                    }
                                  />
                                )}
                              </Field>
                              <div className="input-group-text round_border">
                                m
                              </div>{" "}
                            </div>
                            {errors?.draftsDisplacement &&
                              touched?.draftsDisplacement?.fwd_draft &&
                              errors?.draftsDisplacement?.fwd_draft &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="fwd_draft"
                                  message={
                                    errors?.draftsDisplacement?.fwd_draft
                                  }
                                  open={
                                    errors?.draftsDisplacement &&
                                      errors?.draftsDisplacement?.fwd_draft
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </Col>
                          <Col lg={2} className="p-1 ps-2">
                            <Label className="asteric mb-0" for="draft_mid">
                              Mid draft
                              <i
                                className="bx bx-info-circle ml-2p"
                                id="draft_mid_msg"
                              ></i>
                            </Label>
                            <ToolTip
                              target="draft_mid_msg"
                              message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "draft_mid_msg"
                              ).map((tool: any) => {
                                return tool.message;
                              })}`}
                            />
                            <div className="mb-3 input-group">
                              <Field name="draft_mid">
                                {() => (
                                  <Input
                                    type="text"
                                    className="form-control text-right"
                                    id="draft_mid"
                                    name="draftsDisplacement.draft_mid"
                                    onBlur={handleBlur}
                                    onChange={(e: any) => handleChange(e)}
                                    defaultValue={
                                      values?.draftsDisplacement?.draft_mid
                                    }
                                  />
                                )}
                              </Field>
                              <div className="input-group-text round_border">
                                m
                              </div>{" "}
                            </div>
                            {errors?.draftsDisplacement &&
                              touched?.draftsDisplacement?.draft_mid &&
                              errors?.draftsDisplacement?.draft_mid &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="draft_mid"
                                  message={
                                    errors?.draftsDisplacement?.draft_mid
                                  }
                                  open={
                                    errors?.draftsDisplacement &&
                                      errors?.draftsDisplacement?.draft_mid
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </Col>
                          <Col lg={2} className="p-1">
                            <Label className="asteric mb-0" for="draft_aft">
                              Aft draft
                              <i
                                className="bx bx-info-circle ml-2p"
                                id="draft_aft_msg"
                              ></i>
                            </Label>
                            <ToolTip
                              target="draft_aft_msg"
                              message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "draft_aft_msg"
                              ).map((tool: any) => {
                                return tool.message;
                              })}`}
                            />
                            <div className="mb-3 input-group">
                              <Field name="draftsDisplacement.draft_aft">
                                {({ field, form }: FieldProps) => (
                                  <Input
                                    type="text"
                                    className="form-control text-right"
                                    id="draft_aft"
                                    name={field.name}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      form.setFieldValue(
                                        "draftsDisplacement.trim",
                                        (
                                          e.target.value -
                                          values?.draftsDisplacement.fwd_draft
                                        ).toFixed(2)
                                      );
                                    }}
                                    onBlur={(e: any) => {
                                      handleBlur(e);
                                      handleChange(e);
                                      form.setFieldValue(
                                        "draftsDisplacement.trim",
                                        (
                                          e.target.value -
                                          values?.draftsDisplacement.fwd_draft
                                        ).toFixed(2)
                                      );
                                    }}
                                    defaultValue={
                                      values &&
                                      values?.draftsDisplacement.draft_aft
                                    }
                                  />
                                )}
                              </Field>
                              <div className="input-group-text round_border">
                                m
                              </div>
                            </div>
                            {errors?.draftsDisplacement &&
                              touched?.draftsDisplacement?.draft_aft &&
                              errors?.draftsDisplacement?.draft_aft &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="draft_aft"
                                  message={
                                    errors?.draftsDisplacement?.draft_aft
                                  }
                                  open={
                                    errors?.draftsDisplacement &&
                                      errors?.draftsDisplacement?.draft_aft
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </Col>
                          <Col lg={2} className="p-1">
                            <Label className="asteric mb-0" for="displacement">
                              Displacement
                              <i
                                className="bx bx-info-circle ml-2p"
                                id="displacement_msg"
                              ></i>
                            </Label>
                            <ToolTip
                              target="displacement_msg"
                              message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) =>
                                  item.target === "displacement_msg"
                              ).map((tool: any) => {
                                return tool.message;
                              })}`}
                            />
                            <div className="mb-3 input-group">
                              <Field name="draftsDisplacement.displacement">
                                {({ field }: FieldProps) => (
                                  <Input
                                    type="text"
                                    className="form-control text-right border-end-0"
                                    id="displacement"
                                    name={field.name}
                                    onBlur={handleBlur}
                                    onChange={(e: any) => handleChange(e)}
                                    defaultValue={
                                      values &&
                                      values?.draftsDisplacement?.displacement
                                    }
                                  />
                                )}
                              </Field>
                              <div className="input-group-text round_border">
                                mt
                              </div>
                            </div>
                            {errors?.draftsDisplacement &&
                              touched?.draftsDisplacement?.displacement &&
                              errors?.draftsDisplacement?.displacement &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="displacement"
                                  message={
                                    errors?.draftsDisplacement?.displacement
                                  }
                                  open={
                                    errors?.draftsDisplacement &&
                                      errors?.draftsDisplacement?.displacement
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </Col>
                          <Col lg={2} className="p-1">
                            <Label className="mb-0" for="trim">
                              Trim
                              <i
                                className="bx bx-info-circle ml-2p"
                                id="trim_msg"
                              ></i>
                            </Label>
                            <ToolTip
                              target="trim_msg"
                              message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "trim_msg"
                              ).map((tool: any) => {
                                return tool.message;
                              })}`}
                            />
                            <div className="mb-3 input-group">
                              <Field
                                type="text"
                                className="form-control text-right"
                                id="trim"
                                name="draftsDisplacement.trim"
                                disabled
                              />
                              <div className="input-group-text round_border">
                                m
                              </div>
                              {errors?.draftsDisplacement &&
                                errors?.draftsDisplacement?.trim &&
                                env?.form_validation === true && (
                                  <ErrorTooltip
                                    target="trim"
                                    message={errors?.draftsDisplacement?.trim}
                                    open={
                                      errors?.draftsDisplacement &&
                                        errors?.draftsDisplacement?.trim
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </div>
                          </Col>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  {record &&
                    ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                      record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                      record?.is_this_cargo_loading_discharging === "yes") ||
                      (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                        record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)) &&
                    (
                      <React.Fragment>
                        <Card className="mb-0 border-0">
                          <CardHeader className="py-2 px-0">
                            <div className="text-center">
                              <Row>
                                <Col>
                                  <h4 className="page_title pos-start mb-0">
                                    Cargo Details
                                  </h4>
                                  <p className="card-title-desc pos-start">
                                    All readings since last report
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </CardHeader>
                          <CardBody className="px-0 py-0 mt-2">
                            <Row>
                              <Col sm={2}>
                                <Label
                                  className="asteric mb-0"
                                  for="amount_of_cargo_loaded"
                                >
                                  Cargo loaded
                                  <i
                                    className="bx bx-info-circle ml-2p"
                                    id="amount_of_cargo_loaded_msg"
                                  ></i>
                                </Label>
                                <ToolTip
                                  target="amount_of_cargo_loaded_msg"
                                  message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                      item.target === "amount_of_cargo_loaded_msg"
                                  ).map((tool: any) => {
                                    return tool.message;
                                  })}`}
                                />
                                <div className="input-group">
                                  <Field name="cargoDetails.amount_of_cargo_loaded">
                                    {({ field, form }: FieldProps) => (
                                      <Input
                                        type="text"
                                        className="form-control text-right"
                                        id="amount_of_cargo_loaded"
                                        name={field.name}
                                        onBlur={(e: any) => {
                                          handleBlur(e);
                                          handleChange(e);
                                          if (
                                            e.target.value !== null &&
                                            e.target.value > 0
                                          ) {
                                            form.setFieldValue(
                                              "cargoDetails.loading",
                                              true
                                            );
                                          } else {
                                            form.setFieldValue(
                                              "cargoDetails.loading",
                                              false
                                            );
                                          }
                                          if (
                                            vessel?.vessel_type ===
                                            VesselTypeConstant.LNG_CARRIER
                                          ) {
                                            updateTotalCargoOnboard(
                                              form,
                                              values,
                                              e,
                                              "loading"
                                            );
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          if (
                                            vessel?.vessel_type ===
                                            VesselTypeConstant.LNG_CARRIER
                                          ) {
                                            updateTotalCargoOnboard(
                                              form,
                                              values,
                                              e,
                                              "loading"
                                            );
                                          }
                                        }}
                                        defaultValue={
                                          values?.cargoDetails
                                            ?.amount_of_cargo_loaded
                                        }
                                      />
                                    )}
                                  </Field>
                                  {vessel?.vessel_type ===
                                    VesselTypeConstant.CONTAINER ? (
                                    <div className="input-group-text round_border">
                                      TEU
                                    </div>
                                  ) : (
                                    <div className="input-group-text round_border">
                                      mt
                                    </div>
                                  )}
                                </div>
                                {errors?.cargoDetails &&
                                  touched?.cargoDetails?.amount_of_cargo_loaded &&
                                  errors?.cargoDetails?.amount_of_cargo_loaded &&
                                  env?.form_validation === true && (
                                    <ErrorTooltip
                                      target="amount_of_cargo_loaded"
                                      message={
                                        errors?.cargoDetails
                                          ?.amount_of_cargo_loaded
                                      }
                                      open={
                                        errors?.cargoDetails &&
                                          errors?.cargoDetails
                                            ?.amount_of_cargo_loaded
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                {vessel?.vessel_type ===
                                  VesselTypeConstant.LNG_CARRIER &&
                                  values?.cargoDetails?.loading === true && (
                                    <Col sm={12}>
                                      <div className="ele_row1">
                                        <Field name="cargoDetails.loading_completion">
                                          {({ field, form }: FieldProps) => (
                                            <Input
                                              type="checkbox"
                                              id="loading_completion"
                                              name={field.name}
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  e.target.checked
                                                );
                                                if (e.target.checked === true) {
                                                  setLoadingPopOverBool(true);
                                                } else {
                                                  setLoadingPopOverBool(false);
                                                }
                                              }}
                                              defaultChecked={
                                                values?.cargoDetails
                                                  ?.loading_completion
                                              }
                                            />
                                          )}
                                        </Field>
                                        <Label className="ms-2">
                                          Is this completion of loading?
                                        </Label>
                                      </div>
                                    </Col>
                                  )}
                                {vessel?.vessel_type ===
                                  VesselTypeConstant.LNG_CARRIER &&
                                  values?.cargoDetails?.loading_completion ===
                                  true && (
                                    <PopOver
                                      target="loading_completion"
                                      handleClick={() => {
                                        setLoadingPopUpBool(true);
                                        setLoadingPopOverBool(false);
                                      }}
                                      message={"Is loading CTMS available?"}
                                      state={loadingPopOverBool}
                                      setState={setLoadingPopOverBool}
                                    />
                                  )}
                                {loadingPopUpBool === true && (
                                  <LoadingCTMSContext.Provider
                                    value={setLoadingCTMS}
                                  >
                                    <PopUp
                                      state={loadingPopUpBool}
                                      setState={setLoadingPopUpBool}
                                      body={
                                        <LNGCargoQuality
                                          VesselID={VesselID}
                                          cargoOperation={"loading"}
                                          cardHeader={
                                            "LNG Cargo Loading Quality Details"
                                          }
                                          reportId={ReportID}
                                          setState={setLoadingPopUpBool}
                                        />
                                      }
                                      title={"Loading CTMS Report"}
                                    />
                                  </LoadingCTMSContext.Provider>
                                )}
                              </Col>
                              <Col sm={2}>
                                <Label
                                  className="asteric mb-0"
                                  for="amount_of_cargo_discharged"
                                >
                                  Cargo discharged
                                  <i
                                    className="bx bx-info-circle ml-2p"
                                    id="amount_of_cargo_discharged_msg"
                                  ></i>
                                </Label>
                                <ToolTip
                                  target="amount_of_cargo_discharged_msg"
                                  message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                      item.target ===
                                      "amount_of_cargo_discharged_msg"
                                  ).map((tool: any) => {
                                    return tool.message;
                                  })}`}
                                />
                                <div className="input-group">
                                  <Field name="cargoDetails.amount_of_cargo_discharged">
                                    {({ field, form }: FieldProps) => (
                                      <Input
                                        type="text"
                                        className="form-control text-right"
                                        id="amount_of_cargo_discharged"
                                        name={field.name}
                                        onBlur={(e: any) => {
                                          handleBlur(e);
                                          handleChange(e);
                                          if (
                                            e.target.value !== null &&
                                            e.target.value > 0
                                          ) {
                                            form.setFieldValue(
                                              "cargoDetails.discharging",
                                              true
                                            );
                                          } else {
                                            form.setFieldValue(
                                              "cargoDetails.discharging",
                                              false
                                            );
                                          }
                                          if (
                                            vessel?.vessel_type ===
                                            VesselTypeConstant.LNG_CARRIER
                                          ) {
                                            updateTotalCargoOnboard(
                                              form,
                                              values,
                                              e,
                                              "discharging"
                                            );
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          if (
                                            vessel?.vessel_type ===
                                            VesselTypeConstant.LNG_CARRIER
                                          ) {
                                            updateTotalCargoOnboard(
                                              form,
                                              values,
                                              e,
                                              "discharging"
                                            );
                                          }
                                        }}
                                        defaultValue={
                                          values.cargoDetails
                                            .amount_of_cargo_discharged
                                        }
                                      />
                                    )}
                                  </Field>
                                  {vessel?.vessel_type === "Container" ? (
                                    <div className="input-group-text">TEU</div>
                                  ) : (
                                    <div className="input-group-text round_border">
                                      mt
                                    </div>
                                  )}
                                </div>
                                {errors?.cargoDetails &&
                                  touched?.cargoDetails
                                    ?.amount_of_cargo_discharged &&
                                  errors?.cargoDetails
                                    ?.amount_of_cargo_discharged &&
                                  env?.form_validation === true && (
                                    <ErrorTooltip
                                      target="amount_of_cargo_discharged"
                                      message={
                                        errors?.cargoDetails
                                          ?.amount_of_cargo_discharged
                                      }
                                      open={
                                        errors?.cargoDetails &&
                                          errors?.cargoDetails
                                            ?.amount_of_cargo_discharged
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                {vessel?.vessel_type ===
                                  VesselTypeConstant.LNG_CARRIER &&
                                  values?.cargoDetails?.discharging === true && (
                                    <Col sm={12}>
                                      <div className="ele_row1">
                                        <Field name="cargoDetails.discharging_completion">
                                          {({ field, form }: FieldProps) => (
                                            <Input
                                              type="checkbox"
                                              id="discharging_completion"
                                              name={field.name}
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  e.target.checked
                                                );
                                                if (e.target.checked === true) {
                                                  setDischargingPopOverBool(true);
                                                } else {
                                                  setDischargingPopOverBool(
                                                    false
                                                  );
                                                }
                                              }}
                                              defaultChecked={
                                                values?.cargoDetails
                                                  ?.discharging_completion
                                              }
                                            />
                                          )}
                                        </Field>
                                        <Label className="ms-2">
                                          Is this completion of discharging?
                                        </Label>
                                      </div>
                                    </Col>
                                  )}
                                <br />
                                {vessel?.vessel_type ===
                                  VesselTypeConstant.LNG_CARRIER &&
                                  values?.cargoDetails?.discharging_completion ===
                                  true && (
                                    <PopOver
                                      target="discharging_completion"
                                      handleClick={() => {
                                        setDischargingPopUpBool(true);
                                        setDischargingPopOverBool(false);
                                      }}
                                      message={"Is discharging CTMS available?"}
                                      state={dischargingPopOverBool}
                                      setState={setDischargingPopOverBool}
                                    />
                                  )}
                                {dischargingPopUpBool === true && (
                                  <DischargingCTMSContext.Provider
                                    value={setDischargingCTMS}
                                  >
                                    <PopUp
                                      state={dischargingPopUpBool}
                                      setState={setDischargingPopUpBool}
                                      body={
                                        <LNGCargoQuality
                                          VesselID={VesselID}
                                          cargoOperation={"discharging"}
                                          cardHeader={
                                            "LNG Cargo Discharging Quality Details"
                                          }
                                          reportId={ReportID}
                                          setState={setDischargingPopUpBool}
                                        />
                                      }
                                      title={"Discharging CTMS Report"}
                                    />
                                  </DischargingCTMSContext.Provider>
                                )}
                              </Col>
                              <Col sm={2}>
                                <Label
                                  className="asteric mb-0"
                                  for="total_amount_of_cargo_onboard"
                                >
                                  Cargo onboard
                                  <i
                                    className="bx bx-info-circle ml-2p"
                                    id="total_amount_of_cargo_onboard_msg"
                                  />
                                </Label>
                                <ToolTip
                                  target="total_amount_of_cargo_onboard_msg"
                                  message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                      item.target ===
                                      "total_amount_of_cargo_onboard_msg"
                                  ).map((tool: any) => {
                                    return tool.message;
                                  })}`}
                                />
                                <div className="input-group">
                                  <Field name="cargoDetails.total_amount_of_cargo_onboard">
                                    {({ field }: FieldProps) => (
                                      <Input
                                        type="text"
                                        id="total_amount_of_cargo_onboard"
                                        name={field.name}
                                        className="form-control text-right"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        disabled={
                                          vessel?.vessel_type ===
                                            VesselTypeConstant.LNG_CARRIER
                                            ? true
                                            : false
                                        }
                                        value={
                                          vessel?.vessel_type !==
                                            VesselTypeConstant.LNG_CARRIER
                                            ? values?.cargoDetails
                                              ?.total_amount_of_cargo_onboard
                                            : vessel?.vessel_type ===
                                              VesselTypeConstant.LNG_CARRIER &&
                                              Number.isNaN(
                                                values?.cargoDetails
                                                  ?.total_amount_of_cargo_onboard
                                              ) === true
                                              ? null
                                              : (LNGCargoPreviousROB && LNGCargoPreviousROB?.id > 0)
                                                ?
                                                (parseFloat(LNGCargoPreviousROB?.cargo_rob) +
                                                  parseFloat(values?.cargoDetails?.amount_of_cargo_loaded) -
                                                  parseFloat(values?.cargoDetails?.amount_of_cargo_discharged) +
                                                  parseFloat(values?.cargoDetails?.cargo_adjusted) -
                                                  parseFloat(values?.lngCargoROB?.cargo_consumed)
                                                )
                                                :
                                                values?.cargoDetails
                                                  ?.total_amount_of_cargo_onboard
                                        }
                                      />
                                    )}
                                  </Field>
                                  {vessel?.vessel_type === "Container" ? (
                                    <div className="input-group-text">TEU</div>
                                  ) : (
                                    <div className="input-group-text round_border">
                                      mt
                                    </div>
                                  )}
                                  {errors?.cargoDetails &&
                                    touched?.cargoDetails
                                      ?.total_amount_of_cargo_onboard &&
                                    errors?.cargoDetails
                                      ?.total_amount_of_cargo_onboard &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target="total_amount_of_cargo_onboard"
                                        message={
                                          errors?.cargoDetails
                                            ?.total_amount_of_cargo_onboard
                                        }
                                        open={
                                          errors?.cargoDetails &&
                                            errors?.cargoDetails
                                              ?.total_amount_of_cargo_onboard
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </div>
                              </Col>
                              <Col sm={3}>
                                <React.Fragment>
                                  <Label
                                    className="asteric mb-0"
                                    for="cargoDetails_cargo_detail"
                                  >
                                    Cargo details
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="cargo_detail_msg"
                                    />
                                  </Label>
                                  <ToolTip
                                    target="cargo_detail_msg"
                                    message={`${TooltipMsg.Draft_and_Displacement.filter(
                                      (item: any) =>
                                        item.target === "cargo_detail_msg"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <Field name="cargoDetails.cargo_detail">
                                    {() => (
                                      <textarea
                                        className="form-control"
                                        name="cargoDetails.cargo_detail"
                                        id="cargoDetails_cargo_detail"
                                        rows={
                                          values?.cargoDetails?.cargo_detail?.toString()
                                            ?.length < 10
                                            ? 1
                                            : 2
                                        }
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={
                                          values?.cargoDetails?.cargo_detail
                                        }
                                      />
                                    )}
                                  </Field>
                                  {errors?.cargoDetails &&
                                    touched?.cargoDetails?.cargo_detail &&
                                    errors?.cargoDetails?.cargo_detail &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target="cargoDetails_cargo_detail"
                                        message={
                                          errors?.cargoDetails?.cargo_detail
                                        }
                                        open={
                                          errors?.cargoDetails &&
                                            errors?.cargoDetails?.cargo_detail
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </React.Fragment>
                              </Col>
                            </Row>
                            {vessel?.vessel_type === VesselTypeConstant.CONTAINER &&
                              <Row className="mb-3">
                                <Col sm={2}>
                                  <Label
                                    className="mb-0"
                                    for="OOG_units"
                                  >
                                    OOG units
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="OOG_units_msg"
                                    ></i>
                                  </Label>
                                  <ToolTip
                                    target="OOG_units_msg"
                                    message={`${TooltipMsg.Draft_and_Displacement.filter(
                                      (item: any) =>
                                        item.target === "OOG_units_msg"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <div className="input-group">
                                    <Field name="cargoDetails.OOG_units">
                                      {({ field }: FieldProps) => (
                                        <Input
                                          type="text"
                                          className="form-control text-right"
                                          id="OOG_units"
                                          name={field.name}
                                          onBlur={(e: any) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          onChange={(e: any) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          defaultValue={
                                            values.cargoDetails.OOG_units
                                          }
                                        />
                                      )}
                                    </Field>
                                    <div className="input-group-text">mt</div>
                                  </div>
                                  {errors?.cargoDetails &&
                                    touched?.cargoDetails?.OOG_units &&
                                    errors?.cargoDetails?.OOG_units &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target="OOG_units"
                                        message={
                                          errors?.cargoDetails?.OOG_units
                                        }
                                        open={
                                          errors?.cargoDetails &&
                                            errors?.cargoDetails?.OOG_units
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col sm={2}>
                                  <Label className="mb-0" for="feet_20">
                                    20 ft units
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="feet_20_msg"
                                    ></i>
                                  </Label>
                                  <ToolTip
                                    target="feet_20_msg"
                                    message={`${TooltipMsg.Draft_and_Displacement.filter(
                                      (item: any) =>
                                        item.target === "feet_20_msg"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <div className="input-group">
                                    <Field name="cargoDetails.feet_20">
                                      {({ field }: FieldProps) => (
                                        <Input
                                          type="text"
                                          className="form-control text-right"
                                          id="feet_20"
                                          name={field.name}
                                          onBlur={(e: any) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          onChange={(e: any) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          defaultValue={
                                            values.cargoDetails.feet_20
                                          }
                                        />
                                      )}
                                    </Field>
                                    <div className="input-group-text round_border">
                                      TEU
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={2}>
                                  <Label className="mb-0" for="feet_40">
                                    40 ft units
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="feet_40_msg"
                                    ></i>
                                  </Label>
                                  <ToolTip
                                    target="feet_40_msg"
                                    message={`${TooltipMsg.Draft_and_Displacement.filter(
                                      (item: any) =>
                                        item.target === "feet_40_msg"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <div className="input-group">
                                    <Field name="cargoDetails.feet_40">
                                      {({ field }: FieldProps) => (
                                        <Input
                                          type="text"
                                          className="form-control text-right"
                                          id="feet_40"
                                          name={field.name}
                                          onBlur={(e: any) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          onChange={(e: any) => {
                                            handleBlur(e);
                                            handleChange(e);
                                          }}
                                          defaultValue={
                                            values.cargoDetails.feet_40
                                          }
                                        />
                                      )}
                                    </Field>
                                    <div className="input-group-text round_border">
                                      TEU
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            }
                            {vessel?.vessel_type ===
                              VesselTypeConstant.LNG_CARRIER && (
                                <Row className="mb-3">
                                  <Col sm={3}>
                                    <Label
                                      className="asteric mb-0"
                                      for="adjustment_reason"
                                    >
                                      Reason for cargo adjustment
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="adjustment_reason_msg"
                                      ></i>
                                    </Label>
                                    <ToolTip
                                      target="adjustment_reason_msg"
                                      message={`${TooltipMsg.Draft_and_Displacement.filter(
                                        (item: any) =>
                                          item.target === "adjustment_reason_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <Field name="cargoDetails.adjustment_reason">
                                      {() => (
                                        <textarea
                                          className="form-control"
                                          id="adjustment_reason"
                                          name="cargoDetails.adjustment_reason"
                                          rows={
                                            values?.cargoDetails?.adjustment_reason?.toString()
                                              ?.length < 10
                                              ? 1
                                              : 2
                                          }
                                          onBlur={handleBlur}
                                          onChange={(e: any) => handleChange(e)}
                                          defaultValue={
                                            values?.cargoDetails?.adjustment_reason
                                          }
                                          disabled={
                                            values?.cargoDetails?.cargo_adjusted ===
                                            0 ||
                                            values?.cargoDetails?.cargo_adjusted ===
                                            "0"
                                          }
                                        />
                                      )}
                                    </Field>
                                    {errors?.cargoDetails &&
                                      touched?.cargoDetails?.adjustment_reason &&
                                      errors?.cargoDetails?.adjustment_reason &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target="adjustment_reason"
                                          message={
                                            errors?.cargoDetails?.adjustment_reason
                                          }
                                          open={
                                            errors?.cargoDetails &&
                                              errors?.cargoDetails?.adjustment_reason
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col sm={2}>
                                    <>
                                      <Label className="mb-0" for="cargo_adjusted">
                                        Cargo adjusted
                                        <i
                                          className="bx bx-info-circle ml-2p"
                                          id="cargo_adjusted_msg"
                                        ></i>
                                      </Label>
                                      <ToolTip
                                        target="cargo_adjusted_msg"
                                        message={`${TooltipMsg.Draft_and_Displacement.filter(
                                          (item: any) =>
                                            item.target === "cargo_adjusted_msg"
                                        ).map((tool: any) => {
                                          return tool.message;
                                        })}`}
                                      />
                                      <div className="input-group">
                                        <Field name="cargoDetails.cargo_adjusted">
                                          {({ field, form }: FieldProps) => (
                                            <Input
                                              type="text"
                                              className="form-control text-right"
                                              id="cargo_adjusted"
                                              name={field.name}
                                              onBlur={(e: any) => {
                                                handleChange(e);
                                                updateTotalCargoOnboard(
                                                  form,
                                                  values,
                                                  e,
                                                  "adjusted"
                                                );
                                              }}
                                              onChange={(e: any) => {
                                                updateTotalCargoOnboard(
                                                  form,
                                                  values,
                                                  e,
                                                  "adjusted"
                                                );
                                              }}
                                              defaultValue={
                                                values.cargoDetails.cargo_adjusted
                                              }
                                            />
                                          )}
                                        </Field>
                                        <div className="input-group-text round_border">
                                          mt
                                        </div>
                                      </div>
                                    </>
                                  </Col>
                                  <Col sm={2}>
                                    <Label className="mb-0">Cargo used</Label>
                                    <div className="input-group">
                                      {" "}
                                      <Field
                                        type="text"
                                        name="lngCargoROB.cargo_consumed"
                                        id="lngCargoROB.cargo_consumed"
                                        className="form-control text-right"
                                        disabled
                                      />
                                      <div className="input-group-text round_border">
                                        mt
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                          </CardBody>
                        </Card>
                      </React.Fragment>
                    )}
                  {vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER && (
                    <Card className="p-0 mb-0 rounded-0 border-end-0 border-start-0 border-bottom-0 border-top-0">
                      <CardHeader className="p-2 border-0">
                        <div className="text-center">
                          <Row>
                            <Col>
                              <h4 className="page_title pos-start mb-0">
                                Cargo Tank Parameters
                              </h4>
                              <p className="card-title-desc pos-start">
                                All readings since last report
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </CardHeader>
                      <CardBody className="px-2 py-0 border-0">
                        <div className="table-responsive pr-0">
                          <table className="table mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="p-2 align-middle sr-no-width">
                                  #
                                </th>
                                <th className="p-2 align-middle">Tank Name</th>
                                <th className="p-2 align-middle text-center asteric">
                                  Temperature
                                </th>
                                <th className="p-2 align-middle text-center asteric">
                                  Pressure
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.lngTankDetails?.map(
                                (tank: any, index: any) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-2 align-middle text-center">
                                        {index + 1}
                                      </td>
                                      <td className="p-2 align-middle">
                                        <p className="mb-0">
                                          {tank?.tank_name}
                                        </p>
                                      </td>
                                      <td className="p-2 text-center align-middle">
                                        <div className="d-inline-block">
                                          <div className="input-group">
                                            <Field name="swell_height">
                                              {() => (
                                                <Input
                                                  type="text"
                                                  name={`lngTankDetails.${index}.temperature`}
                                                  id={`temperature_${index}`}
                                                  className="form-control text-right max-width-7"
                                                  onBlur={handleBlur}
                                                  onChange={(e: any) => {
                                                    handleChange(e);
                                                  }}
                                                  defaultValue={
                                                    values?.lngTankDetails[
                                                      index
                                                    ]?.temperature
                                                  }
                                                />
                                              )}
                                            </Field>
                                            <div className="input-group-text">
                                              <sup>o</sup>C
                                            </div>
                                          </div>
                                        </div>
                                        {errors?.lngTankDetails &&
                                          touched?.lngTankDetails &&
                                          touched?.lngTankDetails[index]
                                            ?.temperature &&
                                          errors?.lngTankDetails[index]
                                            ?.temperature &&
                                          env?.form_validation === true && (
                                            <ErrorTooltip
                                              target={`temperature_${index}`}
                                              message={
                                                errors?.lngTankDetails[index]
                                                  ?.temperature
                                              }
                                              open={
                                                errors?.lngTankDetails &&
                                                  errors?.lngTankDetails[index]
                                                    ?.temperature
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                      </td>
                                      <td className="p-2 text-center align-middle">
                                        <div className="d-inline-block">
                                          <div className="input-group">
                                            <Field
                                              name={`lngTankDetails.${index}.pressure`}
                                            >
                                              {() => (
                                                <Input
                                                  type="text"
                                                  name={`lngTankDetails.${index}.pressure`}
                                                  id={`pressure-${index}`}
                                                  className="form-control text-right max-width-7"
                                                  onBlur={handleBlur}
                                                  onChange={(e: any) => {
                                                    handleChange(e);
                                                  }}
                                                  defaultValue={
                                                    values?.lngTankDetails[
                                                      index
                                                    ]?.pressure
                                                  }
                                                />
                                              )}
                                            </Field>
                                            <div className="input-group-text">
                                              mbar
                                            </div>
                                          </div>
                                        </div>
                                        {errors?.lngTankDetails &&
                                          touched?.lngTankDetails &&
                                          touched?.lngTankDetails[index]
                                            ?.pressure &&
                                          errors?.lngTankDetails[index]
                                            ?.pressure &&
                                          env?.form_validation === true && (
                                            <ErrorTooltip
                                              target={`pressure-${index}`}
                                              message={
                                                errors?.lngTankDetails[index]
                                                  ?.pressure
                                              }
                                              open={
                                                errors?.lngTankDetails &&
                                                  errors?.lngTankDetails[index]
                                                    ?.pressure
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {vessel?.vessel_type === VesselTypeConstant.CONTAINER &&
                    OtherSettings && OtherSettings[0]?.reefer_container_available === true &&
                    record &&
                    ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                      record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                      record?.is_this_cargo_loading_discharging === "yes") ||
                      (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                        record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)) &&
                    (
                      <Card className="p-0 mb-0 rounded-0 border-start-0 border-end-0">
                        <CardHeader className="py-2 px-0">
                          <div className="text-center">
                            <Row>
                              <Col>
                                <h4 className="page_title pos-start mb-0">
                                  Reefer Containers
                                </h4>
                                <p className="card-title-desc pos-start">
                                  All readings since last report
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </CardHeader>
                        <CardBody className="px-0 py-0 mt-2">
                          <Row>
                            <Col sm={2} className="p-2">
                              <Label
                                for="no_of_reefer_containers_loaded"
                                className="mb-0"
                              >
                                No. of reefer containers loaded
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="no_of_reefer_containers_loaded_msg"
                                />
                              </Label>
                              <ToolTip
                                target="no_of_reefer_containers_loaded_msg"
                                message={`${TooltipMsg.Reefer_Container.filter(
                                  (item: any) =>
                                    item.target ===
                                    "no_of_reefer_containers_loaded_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <Field
                                type="text"
                                className="form-control text-right"
                                id="no_of_reefer_containers_loaded"
                                name="reeferContainer.loaded_container"
                              />
                              {errors?.reeferContainer &&
                                errors?.reeferContainer?.loaded_container &&
                                env?.form_validation === true && (
                                  <ErrorTooltip
                                    target="no_of_reefer_containers_loaded"
                                    message={
                                      errors?.reeferContainer?.loaded_container
                                    }
                                    open={
                                      errors?.reeferContainer &&
                                        errors?.reeferContainer?.loaded_container
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </Col>
                            <Col sm={2} className="p-2">
                              <Label
                                for="no_of_reefer_containers_discharged"
                                className="mb-0"
                              >
                                No. of reefer containers discharged
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="no_of_reefer_containers_discharged_msg"
                                />
                              </Label>
                              <ToolTip
                                target="no_of_reefer_containers_discharged_msg"
                                message={`${TooltipMsg.Reefer_Container.filter(
                                  (item: any) =>
                                    item.target ===
                                    "no_of_reefer_containers_discharged_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <Field
                                type="text"
                                className="form-control text-right"
                                id="no_of_reefer_containers_discharged"
                                name="reeferContainer.discharged_container"
                              />
                              {errors?.reeferContainer &&
                                errors?.reeferContainer?.discharged_container &&
                                env?.form_validation === true && (
                                  <ErrorTooltip
                                    target="no_of_reefer_containers_discharged"
                                    message={
                                      errors?.reeferContainer
                                        ?.discharged_container
                                    }
                                    open={
                                      errors?.reeferContainer &&
                                        errors?.reeferContainer
                                          ?.discharged_container
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </Col>
                            <Col sm={2} className="p-2">
                              <Label
                                for="total_reefer_containers_onboard"
                                className="mb-0"
                              >
                                Total reefer containers onboard
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="total_reefer_containers_onboard_msg"
                                />
                              </Label>
                              <ToolTip
                                target="total_reefer_containers_onboard_msg"
                                message={`${TooltipMsg.Reefer_Container.filter(
                                  (item: any) =>
                                    item.target ===
                                    "total_reefer_containers_onboard_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <Field
                                type="text"
                                className="form-control text-right"
                                name="reeferContainer.total_onboard_container"
                                id="total_reefer_containers_onboard"
                              />
                              {errors?.reeferContainer &&
                                errors?.reeferContainer
                                  ?.total_onboard_container &&
                                env?.form_validation === true && (
                                  <ErrorTooltip
                                    target="total_reefer_containers_onboard"
                                    message={
                                      errors?.reeferContainer
                                        ?.total_onboard_container
                                    }
                                    open={
                                      errors?.reeferContainer &&
                                        errors?.reeferContainer
                                          ?.total_onboard_container
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </Col>
                            <Col sm={3} className="p-2">
                              <Label
                                for="reason_for_change"
                                className="mb-0"
                              >
                                Reason for change in number of reefer containers onboard
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="reason_for_change_msg"
                                />
                              </Label>
                              <ToolTip
                                target="reason_for_change_msg"
                                message={`${TooltipMsg.Reefer_Container.filter(
                                  (item: any) =>
                                    item.target ===
                                    "reason_for_change_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <Field name="reeferContainer.reason_for_change">
                                {() => (
                                  <textarea
                                    className="form-control"
                                    name="reeferContainer.reason_for_change"
                                    id="reason_for_change"
                                    rows={
                                      values?.reeferContainer?.reason_for_change?.toString()?.length < 10 ? 1 : 2
                                    }
                                    onChange={(e: any) => handleChange(e)}
                                    onBlur={(e: any) => handleChange(e)}
                                    defaultValue={
                                      values?.reeferContainer?.reason_for_change
                                    }
                                  />
                                )}
                              </Field>
                              {errors?.reeferContainer &&
                                errors?.reeferContainer
                                  ?.reason_for_change &&
                                env?.form_validation === true && (
                                  <ErrorTooltip
                                    target="total_reefer_containers_onboard"
                                    message={
                                      errors?.reeferContainer
                                        ?.reason_for_change
                                    }
                                    open={
                                      errors?.reeferContainer &&
                                        errors?.reeferContainer
                                          ?.reason_for_change
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col sm={2}>
                              <Label className="mb-0" for="feet_20">
                                20 ft units
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="feet_20_msg"
                                ></i>
                              </Label>
                              <ToolTip
                                target="feet_20_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                  (item: any) =>
                                    item.target === "feet_20_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <div className="input-group">
                                <Field name="reeferContainer.feet_20">
                                  {({ field }: FieldProps) => (
                                    <Input
                                      type="text"
                                      className="form-control text-right"
                                      id="feet_20"
                                      name={field.name}
                                      onBlur={(e: any) => {
                                        handleBlur(e);
                                        handleChange(e);
                                      }}
                                      onChange={(e: any) => {
                                        handleBlur(e);
                                        handleChange(e);
                                      }}
                                      defaultValue={
                                        values.reeferContainer.feet_20
                                      }
                                    />
                                  )}
                                </Field>
                                <div className="input-group-text round_border">
                                  TEU
                                </div>
                              </div>
                            </Col>
                            <Col sm={2}>
                              <Label className="mb-0" for="feet_40">
                                40 ft units
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="feet_40_msg"
                                ></i>
                              </Label>
                              <ToolTip
                                target="feet_40_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                  (item: any) =>
                                    item.target === "feet_40_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <div className="input-group">
                                <Field name="reeferContainer.feet_40">
                                  {({ field }: FieldProps) => (
                                    <Input
                                      type="text"
                                      className="form-control text-right"
                                      id="feet_40"
                                      name={field.name}
                                      onBlur={(e: any) => {
                                        handleBlur(e);
                                        handleChange(e);
                                      }}
                                      onChange={(e: any) => {
                                        handleBlur(e);
                                        handleChange(e);
                                      }}
                                      defaultValue={
                                        values.reeferContainer.feet_40
                                      }
                                    />
                                  )}
                                </Field>
                                <div className="input-group-text round_border">
                                  TEU
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH &&
                            <Row className="py-0">
                              <ReportingFileUpload
                                setFile={setBaplieFiles}
                                file={baplieFiles}
                                fileUploadStatus={values?.reeferContainer?.baplie_file_status === FileStatus.UPLOADED}
                                fileURL={values?.reeferContainer?.baplie_file_path}
                                deleteURL={`/reefer_container_details/`}
                                invalidateQuery={queryKeyes.vessel.ReeferContainerDetailsObject.key}
                                deleteID={values?.reeferContainer?.id}
                                refreshKey={refreshKey}
                                setRefreshKey={setRefreshKey}
                                title={'Baplie File'}
                                DeleteFileObj={deleteBaplieFileObj}
                                index={0}
                              />
                            </Row>
                          }
                          <Row className="py-0">
                            <ReportingFileUpload
                              setFile={setLogBook}
                              file={logBook}
                              fileUploadStatus={values?.reeferContainer?.log_book_status === FileStatus.UPLOADED}
                              fileURL={values?.reeferContainer?.log_book_file_path}
                              deleteURL={`/reefer_container_details/`}
                              invalidateQuery={queryKeyes.vessel.ReeferContainerDetailsObject.key}
                              deleteID={values?.reeferContainer?.id}
                              refreshKey={refreshKey}
                              setRefreshKey={setRefreshKey}
                              title={'Log Book Upload'}
                              DeleteFileObj={deleteLogBookObj}
                              index={0}
                            />
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  <Row className="mt-3 mb-2">
                    <Col sm={4}>
                      <Label
                        className="mb-0"
                        for="is_bill_of_lading_issued_since_last_report"
                      >
                        Is Bill of Lading issued since last report?
                        <i
                          className="bx bx-info-circle ml-2p"
                          id="bill_of_lading_msg"
                        ></i>
                      </Label>
                      <ToolTip
                        target="bill_of_lading_msg"
                        message={`${TooltipMsg.Draft_and_Displacement.filter(
                          (item: any) => item.target === "bill_of_lading_msg"
                        ).map((tool: any) => {
                          return tool.message;
                        })}`}
                      />
                    </Col>
                    <Col lg={1}>
                      <Field
                        name={
                          "billofLading.is_bill_of_lading_issued_since_last_report"
                        }
                      >
                        {({ field }: FieldProps) => (
                          <div className="square-switch sqswitch">
                            <Input
                              type="checkbox"
                              switch="none"
                              name={field.name}
                              id="is_bill_of_lading_issued_since_last_report"
                              onChange={handleChange}
                              defaultChecked={
                                values?.billofLading
                                  .is_bill_of_lading_issued_since_last_report
                              }
                            />
                            <Label
                              htmlFor="is_bill_of_lading_issued_since_last_report"
                              data-on-label="Yes"
                              data-off-label="No"
                              className="mb-0"
                            ></Label>
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  {values?.billofLading
                    .is_bill_of_lading_issued_since_last_report === true && (
                      <Card className="p-0 mb-0 rounded-0 border-start-0 border-end-0 border-bottom-0">
                        <CardHeader className="p-2">
                          <div className="text-center">
                            <Row>
                              <Col>
                                <h4 className="page_title pos-start mb-0">
                                  Bill of Lading
                                </h4>
                                <p className="card-title-desc pos-start">
                                  All readings since last report
                                </p>
                              </Col>
                              <Col></Col>
                            </Row>
                          </div>
                        </CardHeader>
                        <CardBody className="px-2 py-0 mt-2">
                          <Row>
                            <Col sm={3}>
                              <Label className="asteric mb-0" for="bl_figure">
                                BL figures (Cargo)
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="bl_figure_msg"
                                ></i>
                              </Label>
                              <ToolTip
                                target="bl_figure_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                  (item: any) => item.target === "bl_figure_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <div className="mb-3 input-group">
                                <Field
                                  type="text"
                                  className="form-control text-right max-width-7"
                                  id="bl_figure"
                                  name="billofLading.bl_figure"
                                />
                                <div className="input-group-text round_border">
                                  mt
                                </div>
                              </div>
                              {errors?.billofLading &&
                                errors?.billofLading?.bl_figure &&
                                env?.form_validation === true && (
                                  <ErrorTooltip
                                    target="bl_figure"
                                    message={errors?.billofLading?.bl_figure}
                                    open={
                                      errors?.billofLading &&
                                        errors?.billofLading?.bl_figure
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                            </Col>
                            <Col lg={3}>
                              <Label
                                className="asteric mb-0"
                                for="ship_figure_of_bl_figure"
                              >
                                Ship figures (Cargo)
                                <i
                                  className="bx bx-info-circle ml-2p"
                                  id="ship_figure_of_bl_figure_msg"
                                ></i>
                              </Label>
                              <ToolTip
                                target="ship_figure_of_bl_figure_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                  (item: any) =>
                                    item.target === "ship_figure_of_bl_figure_msg"
                                ).map((tool: any) => {
                                  return tool.message;
                                })}`}
                              />
                              <div className="mb-3 input-group">
                                <Field
                                  type="text"
                                  className="form-control text-right max-width-7"
                                  id="ship_figure_of_bl_figure"
                                  name="billofLading.ship_figure_of_bl_figure"
                                />
                                <div className="input-group-text round_border">
                                  mt
                                </div>
                                {errors?.billofLading &&
                                  errors?.billofLading
                                    ?.ship_figure_of_bl_figure &&
                                  env?.form_validation === true && (
                                    <ErrorTooltip
                                      target="ship_figure_of_bl_figure"
                                      message={
                                        errors?.billofLading
                                          ?.ship_figure_of_bl_figure
                                      }
                                      open={
                                        errors?.billofLading &&
                                          errors?.billofLading
                                            ?.ship_figure_of_bl_figure
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                              </div>
                            </Col>
                            {/* <Col lg={3}>
                                                <Label for="bl_figure_attachment" className="mt-3 mb-0">BL figure attachment
                                                    <i className='bx bx-info-circle ml-2p' id='bl_figure_attachment_msg' />
                                                    <i className="ml-1 dripicons-upload icon_s23 mt-3"
                                                        onClick={() => {
                                                            setBlFigureAttachmentBool(true);
                                                        }}
                                                        data-toggle="modal"
                                                        id='bl_figure_attachment'></i>
                                                </Label>
                                                {(BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) ?
                                                    <>
                                                        <br /><Label className='mt-0 primary-label'>{values?.billofLading.bl_figure_attachment.toString().substring(10, 34)}</Label>
                                                    </> : null
                                                }
                                                <ToolTip target='bl_figure_attachment_msg'
                                                    message={`${TooltipMsg.Draft_and_Displacement.filter((item: any) => item.target === 'bl_figure_attachment_msg')
                                                        .map((tool: any) => { return tool.message })}`}
                                                />
                                            </Col>
                                            <ReportingFileUploadModal
                                                state={blFigureAttachmentBool}
                                                stateName={'BLFigureAttachment'}
                                                array={blFigureAttachment}
                                                setArray={setBlFigureAttachment}
                                                modalId={'bl_figure_attachment'}
                                            /> */}
                          </Row>
                          <Row>
                            <Col lg={3}>
                              <div className="d-flex flex-wrap gap-2 mb-3 mt-2">
                                <Label
                                  className="mb-0 mt-3 w-10"
                                  for="cargo_survey"
                                >
                                  Cargo survey?
                                  <i
                                    className="bx bx-info-circle ml-2p"
                                    id="cargo_survey_msg"
                                  ></i>
                                </Label>
                                <ToolTip
                                  target="cargo_survey_msg"
                                  message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                      item.target === "cargo_survey_msg"
                                  ).map((tool: any) => {
                                    return tool.message;
                                  })}`}
                                />
                                <Field name={"billofLading.cargo_survey"}>
                                  {({ field }: FieldProps) => (
                                    <div className="square-switch sqswitch mt-3">
                                      <Input
                                        type="checkbox"
                                        switch="none"
                                        name={field.name}
                                        id="cargo_survey"
                                        onChange={handleChange}
                                        defaultChecked={
                                          values?.billofLading.cargo_survey
                                        }
                                      />
                                      <Label
                                        htmlFor="cargo_survey"
                                        data-on-label="Yes"
                                        data-off-label="No"
                                      ></Label>
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </Col>
                            {values?.billofLading.cargo_survey === true && (
                              <>
                                <Col lg={3}>
                                  <Label
                                    className="asteric mb-0"
                                    for="surveyor_figure_of_bl_figure"
                                  >
                                    Surveyor figures (Cargo)
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="surveyor_figure_of_bl_figure_msg"
                                    ></i>
                                  </Label>
                                  <ToolTip
                                    target="surveyor_figure_of_bl_figure_msg"
                                    message={`${TooltipMsg.Draft_and_Displacement.filter(
                                      (item: any) =>
                                        item.target ===
                                        "surveyor_figure_of_bl_figure_msg"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <div className="input-group">
                                    <Field
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id="surveyor_figure_of_bl_figure"
                                      name="billofLading.surveyor_figure_of_bl_figure"
                                    />
                                    <div className="input-group-text round_border">
                                      mt
                                    </div>
                                    {errors?.billofLading &&
                                      errors?.billofLading
                                        ?.surveyor_figure_of_bl_figure &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target="surveyor_figure_of_bl_figure"
                                          message={
                                            errors?.billofLading
                                              ?.surveyor_figure_of_bl_figure
                                          }
                                          open={
                                            errors?.billofLading &&
                                              errors?.billofLading
                                                ?.surveyor_figure_of_bl_figure
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </div>
                                </Col>
                                {/* <Col lg={3}>
                                                        <Label for="survey_attachment" className="mt-3 mb-0">Surveyor figure attachment
                                                            <i className='bx bx-info-circle ml-2p' id='survey_attachment_msg' />
                                                            <i className="ml-1 dripicons-upload icon_s23 mt-3"
                                                                onClick={() => {
                                                                    setSurveyAttachmentBool(true);
                                                                }}
                                                                data-toggle="modal"
                                                                id='survey_attachment'></i>
                                                        </Label>
                                                        {(BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) ?
                                                            <Label className='mt-0 primary-label'>{values?.billofLading.survey_attachment}</Label> : null
                                                        }
                                                        <ToolTip target='survey_attachment_msg'
                                                            message={`${TooltipMsg.Draft_and_Displacement
                                                                .filter((item: any) => item.target === 'survey_attachment_msg')
                                                                .map((tool: any) => { return tool.message })}`}
                                                        />
                                                    </Col>
                                                    <ReportingFileUploadModal
                                                        state={surveyAttachmentBool}
                                                        array={surveyAttachment}
                                                        setArray={setSurveyAttachment}
                                                        modalId={'survey_attachment'}
                                                    /> */}
                              </>
                            )}
                          </Row>
                          {/* <Row>
                                            <Col lg={3}>
                                                <div className="d-flex flex-wrap gap-2 mb-3">
                                                    <Label className="mt-3 w-10">
                                                        Letter of protest?<i className='bx bx-info-circle ml-2p' id='letter_of_protest_msg'></i>
                                                    </Label>
                                                    <ToolTip target='letter_of_protest_msg'
                                                        message={`${TooltipMsg.Draft_and_Displacement.filter((item: any) => item.target === 'letter_of_protest_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'billofLading.letter_of_protest'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch mt-3">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id="letter_of_protest"
                                                                    onChange={handleChange}
                                                                    defaultChecked={values?.billofLading.letter_of_protest}
                                                                />
                                                                <Label
                                                                    htmlFor="letter_of_protest"
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'>
                                                                </Label>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </Col>
                                            {values?.billofLading.letter_of_protest &&
                                                <>
                                                    <Col lg={3}>
                                                        <Label for="letter_of_protest_attachment" className="mt-2">Letter of protest attachment
                                                            <i className='bx bx-info-circle ml-2p' id='letter_of_protest_attachment_msg' />
                                                            <i className="ml-1 dripicons-upload icon_s23 mt-2"
                                                                onClick={() => {
                                                                    setLetterofProtestAttachmentBool(true);
                                                                }}
                                                                data-toggle="modal"
                                                                id='letter_of_protest_attachment' />
                                                        </Label>
                                                        {(BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) ?
                                                            <Label className='mt-0 primary-label'>{values?.billofLading.letter_of_protest_attachment}</Label> : null
                                                        }
                                                        <ToolTip target='letter_of_protest_attachment_msg'
                                                            message={`${TooltipMsg.Draft_and_Displacement
                                                                .filter((item: any) => item.target === 'letter_of_protest_attachment_msg')
                                                                .map((tool: any) => { return tool.message })}`}
                                                        />
                                                    </Col>
                                                    <ReportingFileUploadModal
                                                        state={letterofProtestAttachmentBool}
                                                        array={letterofProtestAttachment}
                                                        setArray={setLetterofProtestAttachment}
                                                        modalId={'letter_of_protest_attachment'}
                                                    />
                                                </>
                                            }
                                        </Row> */}
                        </CardBody>
                      </Card>
                    )}
                  <Card className="p-0 mb-0 rounded-0 border-start-0 border-end-0">
                    <CardHeader className="px-0 py-2">
                      <div className="text-center">
                        <Row>
                          <Col>
                            <h4 className="page_title pos-start mb-0">
                              Ballast Details
                            </h4>
                            <p className="card-title-desc pos-start">
                              All readings since last report
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody className="ps-2 pr-2 pt-0 pb-0 mt-2">
                      <Row>
                        <Col sm={2} className="px-0">
                          <Label className="asteric mb-0" for="permanent">
                            Permanent ballast
                            <i
                              className="bx bx-info-circle ml-2p"
                              id="permanent_msg"
                            ></i>
                          </Label>
                          <ToolTip
                            target="permanent_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                              (item: any) => item.target === "permanent_msg"
                            ).map((tool: any) => {
                              return tool.message;
                            })}`}
                          />
                          <div className="input-group mb-3 ">
                            <Field name="ballastDetails.permanent">
                              {() => (
                                <Input
                                  type="text"
                                  className="form-control text-right max-width-7"
                                  id="permanent"
                                  name="ballastDetails.permanent"
                                  onBlur={handleBlur}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={
                                    values?.ballastDetails?.permanent
                                  }
                                />
                              )}
                            </Field>
                            <div className="input-group-text round_border">
                              mt
                            </div>
                            {errors?.ballastDetails &&
                              touched?.ballastDetails?.permanent &&
                              errors?.ballastDetails?.permanent &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="permanent"
                                  message={errors?.ballastDetails?.permanent}
                                  open={
                                    errors?.ballastDetails &&
                                      errors?.ballastDetails?.permanent
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </div>
                        </Col>
                        <Col sm={2} className="px-0">
                          <Label className="asteric mb-0" for="addition">
                            Additional ballast
                            <i
                              className="bx bx-info-circle ml-2p"
                              id="addition_msg"
                            ></i>
                          </Label>
                          <ToolTip
                            target="addition_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                              (item: any) => item.target === "addition_msg"
                            ).map((tool: any) => {
                              return tool.message;
                            })}`}
                          />
                          <div className="input-group mb-3">
                            <Field name="ballastDetails.addition">
                              {() => (
                                <Input
                                  type="text"
                                  className="form-control text-right max-width-7 border-end-0"
                                  id="addition"
                                  name="ballastDetails.addition"
                                  onBlur={handleBlur}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={
                                    values?.ballastDetails?.addition
                                  }
                                />
                              )}
                            </Field>
                            <div className="input-group-text round_border">
                              mt
                            </div>
                            {errors?.ballastDetails &&
                              touched?.ballastDetails?.addition &&
                              errors?.ballastDetails?.addition &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="addition"
                                  message={errors?.ballastDetails?.addition}
                                  open={
                                    errors?.ballastDetails &&
                                      errors?.ballastDetails?.addition
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </div>
                        </Col>
                        <Col sm={3} className="px-0">
                          <Label className="asteric mb-0" for="total">
                            Total ballast
                            <i
                              className="bx bx-info-circle ml-2p"
                              id="total_msg"
                            ></i>
                          </Label>
                          <ToolTip
                            target="total_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                              (item: any) => item.target === "total_msg"
                            ).map((tool: any) => {
                              return tool.message;
                            })}`}
                          />
                          <div className="input-group mb-3">
                            <Field name="ballastDetails.total">
                              {() => (
                                <Input
                                  type="text"
                                  className="form-control text-right max-width-7"
                                  id="total"
                                  name="ballastDetails.total"
                                  onBlur={handleBlur}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.ballastDetails?.total}
                                />
                              )}
                            </Field>
                            <div className="input-group-text round_border">
                              mt
                            </div>
                            {errors?.ballastDetails &&
                              touched?.ballastDetails?.total &&
                              errors?.ballastDetails?.total &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="total"
                                  message={errors?.ballastDetails?.total}
                                  open={
                                    errors?.ballastDetails &&
                                      errors?.ballastDetails?.total
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="p-0 mb-0 border-0">
                    <CardHeader className="px-0 py-2">
                      <div className="text-center">
                        <Row>
                          <Col>
                            <h4 className="page_title pos-start mb-0">
                              Ballast Operations
                            </h4>
                            <p className="card-title-desc pos-start">
                              All readings since last report
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody className="ps-2 pr-2 pt-0 pb-0 mt-2">
                      <Row>
                        <Col sm={2} className="px-0">
                          <Label className="asteric mb-0" for="loaded">
                            Loaded
                            <i
                              className="bx bx-info-circle ml-2p"
                              id="loaded_msg"
                            ></i>
                          </Label>
                          <ToolTip
                            target="loaded_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                              (item: any) => item.target === "loaded_msg"
                            ).map((tool: any) => {
                              return tool.message;
                            })}`}
                          />
                          <div className="input-group mb-3">
                            <Field name="ballastDetails.loaded">
                              {() => (
                                <Input
                                  type="text"
                                  className="form-control text-right max-width-7"
                                  id="loaded"
                                  name="ballastDetails.loaded"
                                  onBlur={handleBlur}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={values?.ballastDetails?.loaded}
                                />
                              )}
                            </Field>
                            <div className="input-group-text round_border">
                              mt
                            </div>
                            {errors?.ballastDetails &&
                              touched?.ballastDetails?.loaded &&
                              errors?.ballastDetails?.loaded &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="loaded"
                                  message={errors?.ballastDetails?.loaded}
                                  open={
                                    errors?.ballastDetails &&
                                      errors?.ballastDetails?.loaded
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </div>
                        </Col>
                        <Col sm={2} className="px-0">
                          <Label className="asteric mb-0" for="discharged">
                            Discharged
                            <i
                              className="bx bx-info-circle ml-2p"
                              id="discharged_msg"
                            ></i>
                          </Label>
                          <ToolTip
                            target="discharged_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                              (item: any) => item.target === "discharged_msg"
                            ).map((tool: any) => {
                              return tool.message;
                            })}`}
                          />
                          <div className="input-group mb-3">
                            <Field name="ballastDetails.discharged">
                              {() => (
                                <Input
                                  type="text"
                                  className="form-control text-right max-width-7 border-end-0Fwd draft"
                                  id="discharged"
                                  name="ballastDetails.discharged"
                                  onBlur={handleBlur}
                                  onChange={(e: any) => handleChange(e)}
                                  defaultValue={
                                    values?.ballastDetails?.discharged
                                  }
                                />
                              )}
                            </Field>
                            <div className="input-group-text round_border">
                              mt
                            </div>
                            {errors?.ballastDetails &&
                              touched?.ballastDetails?.discharged &&
                              errors?.ballastDetails?.discharged &&
                              env?.form_validation === true && (
                                <ErrorTooltip
                                  target="discharged"
                                  message={errors?.ballastDetails?.discharged}
                                  open={
                                    errors?.ballastDetails &&
                                      errors?.ballastDetails?.discharged
                                      ? true
                                      : false
                                  }
                                />
                              )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <CardFooter className="p-2 py-3">
                    <Row className="ele_row1">
                      <div className="d-flex flex-wrap gap-5">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn_size_cstm pos-end"
                          onClick={() => {
                            if (env?.form_validation === false) {
                              toggleTab(activeTab + 1);
                            }
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                          Next <i className="bx bx-chevron-right ms-1" />
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          className="btn_size_cstm"
                          onClick={() => {
                            setErrors({});
                            toggleTab(activeTab - 1);
                          }}
                        >
                          <i className="bx bx-chevron-left me-1" /> Previous
                        </Button>
                      </div>
                    </Row>
                  </CardFooter>
                  <FormValuesDebug
                    values={[
                      values,
                      errors,
                      DraftCargoLadingFormik.initialValues,
                    ]}
                  />
                </>
              </Form>
            )}
          </Formik>
        )}
    </Card>
  );
};

export default DraftsCargoLadingComponent;
