import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import React from 'react'
import { Card, CardHeader, Row, Col, TabContent, TabPane } from 'reactstrap';
import OtherDetailsComponent from './partials/OtherDetailsComponent';
import { ReportingTypeConstant } from 'shared/constants';
interface EconDeckAdditionalInformationType {
    toggleTab?: (value: number) => void,
    activeTab?: number,
    record: any
    ReportID: number,
    VesselID: number,
    VoyageID: number,
}

const EconAddInformation = ({
    toggleTab,
    activeTab,
    record,
    ReportID,
    VesselID,
    VoyageID,
}: EconDeckAdditionalInformationType) => {
    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <TabContent activeTab={1} className="tabHeight">
                    <TabPane tabId={
                        record?.reporting_type === ReportingTypeConstant.ATSEA ? 1 : 0
                    }>
                        <OtherDetailsComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleTab={toggleTab}
                            activeTab={activeTab}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    )
}

export default EconAddInformation;
