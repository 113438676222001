import { commonValidationMessages } from "Components/ValidationErrorMessages";
import React from "react";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Label,
  Row,
} from "reactstrap";
import { errorToast, successToast } from "Components/Toasts";
import { Field, FieldArray, Formik } from "formik";
import { EventPrecedence, ReportingOpConstant } from "shared/constants";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import apiGlobal from "global/api.global";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { loadThermalOilHeaterObject } from "VesselMaster/vesselMaster.hooks";
import Loading from "Components/Loading";
import { queryClient } from "react-query/queryClient";
import { errResponse } from "GenericForms/Helper";
import ErrorTooltip from "Components/ErrorTooltip";
import env from "environment_system/env_system";

interface ThermalOilHeaterComponentType {
  VesselID: number;
  VoyageID: number;
  ReportID: number;
  toggleAddTab: any;
  toggleDynamicTabs: any;
  previousTabIndex: number;
  record: any;
}

const ThermalOilHeaterComponent = ({
  VesselID,
  VoyageID,
  ReportID,
  toggleAddTab,
  toggleDynamicTabs,
  previousTabIndex,
  record,
}: ThermalOilHeaterComponentType) => {
  /** Queries */
  /** Thermal Oil Heater Object used for edit */
  const {
    data: ThermalOilHeaterObject,
    isLoading: ThermalOilHeaterObjectLoading,
    isError: ThermalOilHeaterObjectError,
  } = useQuery(
    [queryKeyes.vessel.ThermalOilHeaterObject.key, VesselID, ReportID],
    async () => {
      return await loadThermalOilHeaterObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Queries end */

  /** Assign values to initial object of Thermal Oil Heater */
  const getInitialThermalOilHeaterValues = () => {
    if (ThermalOilHeaterObject && ThermalOilHeaterObject.length > 0) {
      return ThermalOilHeaterObject;
    } else {
      return [
        {
          name_of_thermal_oil_heater: "",
          outlet_temperature: 0,
          inlet_temperature: 0,
          exhaust_temperature: 0,
          outlet_press: 0,
          inlet_press: 0,
          temperature_set_point: 0,
          th_expansion_tank_level: 0,
          vessel: VesselID,
          voyage_information: VoyageID,
          vessel_reporting_information: ReportID,
        },
      ];
    }
  };

  /** Thermal oil heater's formik object */
  const ThermalOilHeaterFormik = {
    initialValues: {
      thermalOilHeater: getInitialThermalOilHeaterValues(),
    },
    validationSchema: Yup.object().shape({
      thermalOilHeater: Yup.array(
        Yup.object({
          outlet_temperature: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          inlet_temperature: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          exhaust_temperature: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          outlet_press: Yup.string().matches(
            /^\d{0,3}(?:\.\d{1,2})?$/,
            `${commonValidationMessages.before3after2}`
          ),
          inlet_press: Yup.string().matches(
            /^\d{0,3}(?:\.\d{1,2})?$/,
            `${commonValidationMessages.before3after2}`
          ),
          temperature_set_point: Yup.string().matches(
            /^\d{0,4}(\.\d{1})?$/,
            `${commonValidationMessages.before4after1}`
          ),
          th_expansion_tank_level: Yup.string().matches(
            /^\d{0,4}(?:\.\d{1,2})?$/,
            `${commonValidationMessages.before4after2}`
          ),
        })
      ),
    }),
  };
  return (
    <Card className="p-0 mb-0 border-0">
      <CardHeader className="p-2">
        <div className="text-center mb-2">
          <Row>
            <Col>
              <h4 className="page_title pos-start mb-0">Thermal Oil Heaters</h4>
              <p className="card-title-desc pos-start">
                All readings since last report
              </p>
            </Col>
          </Row>
        </div>
      </CardHeader>
      {ThermalOilHeaterObjectLoading && (
        <Loading message="Loading required data!" />
      )}
      {ThermalOilHeaterObjectError && getInitialThermalOilHeaterValues()}
      {!ThermalOilHeaterObjectLoading && (
        <Formik
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            const handleResponse = (response: any) => {
              if (response.status === 201 || response.status === 200) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(
                  queryKeyes.vessel.ThermalOilHeaterObject.key
                );
              }
            };
            if (ThermalOilHeaterObject && ThermalOilHeaterObject.length > 0) {
              values.thermalOilHeater.map((heater: any) => {
                apiGlobal
                  .put(`/lfonds_thermal_oil_heater/${heater.id}/`, heater)
                  .then((res) => {
                    handleResponse(res);
                  })
                  .catch((err) => {
                    if (errResponse.includes(err.response.status)) {
                      errorToast(
                        "Internal error occured, please contact the admin"
                      );
                    }
                  });
                return "";
              });
            } else {
              apiGlobal
                .post(`/lfonds_thermal_oil_heater/`, values.thermalOilHeater)
                .then((res) => {
                  handleResponse(res);
                })
                .catch((err) => {
                  if (errResponse.includes(err.response.status)) {
                    errorToast(
                      "Internal error occured, please contact the admin"
                    );
                  }
                });
            }
          }}
          initialValues={ThermalOilHeaterFormik.initialValues}
          validationSchema={ThermalOilHeaterFormik.validationSchema}
        >
          {({
            values,
            errors,
            handleSubmit,
          }: {
            values: any;
            errors: any;
            handleSubmit: any;
            handleChange: any;
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <FieldArray name="thermalOilHeater">
                {({ push, remove }) => (
                  <React.Fragment>
                    {values &&
                      values?.thermalOilHeater?.map(
                        (heater: any, index: number) => {
                          return (
                            <CardBody className="engineer-card-body">
                              <Row>
                                <Col>
                                  <div className="ele_row">
                                    {index ===
                                      values?.thermalOilHeater?.length - 1 && (
                                        <Col lg={index === 0 ? 12 : 11}>
                                          <Label
                                            className="link_color_blue mt-2 justify_right"
                                            onClick={() =>
                                              push(
                                                ThermalOilHeaterFormik
                                                  .initialValues
                                                  .thermalOilHeater[0]
                                              )
                                            }
                                          >
                                            Add another heater
                                          </Label>
                                        </Col>
                                      )}
                                    {values?.thermalOilHeater?.length ===
                                      1 ? null : (
                                      <Col>
                                        <button
                                          type="button"
                                          className="btn justify_right"
                                        >
                                          <i
                                            className="dripicons-trash icon_s18"
                                            onClick={() => remove(index)}
                                          />
                                        </button>
                                      </Col>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`name_of_thermal_oil_heater-${index}`}
                                  >
                                    Name
                                  </Label>
                                  <Field
                                    name={`thermalOilHeater.${index}.name_of_thermal_oil_heater`}
                                    type="text"
                                    className="form-control"
                                    id={`name_of_thermal_oil_heater-${index}`}
                                    autoFocus
                                  />
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.name_of_thermal_oil_heater &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`name_of_thermal_oil_heater-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.name_of_thermal_oil_heater
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.name_of_thermal_oil_heater
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`outlet_temperature-${index}`}
                                  >
                                    Outlet temperature
                                  </Label>
                                  <div className="input-group">
                                    <Field
                                      name={`thermalOilHeater.${index}.outlet_temperature`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`outlet_temperature-${index}`}
                                    />
                                    <div className="input-group-text round_border">
                                      <sup>o</sup>C
                                    </div>
                                  </div>
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.outlet_temperature &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`outlet_temperature-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.outlet_temperature
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.outlet_temperature
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`inlet_temperature-${index}`}
                                  >
                                    Inlet temperature
                                  </Label>
                                  <div className="input-group">
                                    <Field
                                      name={`thermalOilHeater.${index}.inlet_temperature`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`inlet_temperature-${index}`}
                                    />
                                    <div className="input-group-text round_border">
                                      <sup>o</sup>C
                                    </div>
                                  </div>
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.inlet_temperature &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`inlet_temperature-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.inlet_temperature
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.inlet_temperature
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`exhaust_temperature-${index}`}
                                  >
                                    Exhaust temperature
                                  </Label>
                                  <div className="input-group">
                                    <Field
                                      name={`thermalOilHeater.${index}.exhaust_temperature`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`exhaust_temperature-${index}`}
                                    />
                                    <div className="input-group-text round_border">
                                      <sup>o</sup>C
                                    </div>
                                  </div>
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.exhaust_temperature &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`exhaust_temperature-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.exhaust_temperature
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.exhaust_temperature
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`outlet_press-${index}`}
                                  >
                                    Outlet press
                                  </Label>
                                  <Field
                                    name={`thermalOilHeater.${index}.outlet_press`}
                                    type="text"
                                    className="form-control text-right max-width-7"
                                    id={`outlet_press-${index}`}
                                  />
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.outlet_press &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`outlet_press-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.outlet_press
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.outlet_press
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`inlet_press-${index}`}
                                  >
                                    Inlet press
                                  </Label>
                                  <Field
                                    name={`thermalOilHeater.${index}.inlet_press`}
                                    type="text"
                                    className="form-control text-right max-width-7"
                                    id={`inlet_press-${index}`}
                                  />
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.inlet_press &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`inlet_press-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.inlet_press
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.inlet_press
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`thermalOilHeater.${index}.temperature_set_point`}
                                  >
                                    Temperature set point
                                  </Label>
                                  <div className="input-group">
                                    <Field
                                      name={`thermalOilHeater.${index}.temperature_set_point`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`thermalOilHeater.${index}.temperature_set_point`}
                                    />
                                    <div className="input-group-text round_border">
                                      <sup>o</sup>C
                                    </div>
                                  </div>
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.temperature_set_point &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`temperature_set_point-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.temperature_set_point
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.temperature_set_point
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                                <Col lg={3}>
                                  <Label
                                    className="mb-0"
                                    for={`th_expansion_tank_level-${index}`}
                                  >
                                    TH expansion tank level
                                  </Label>
                                  <div className="input-group">
                                    <Field
                                      name={`thermalOilHeater.${index}.th_expansion_tank_level`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`th_expansion_tank_level-${index}`}
                                    />
                                    <div className="input-group-text round_border">
                                      m
                                    </div>
                                  </div>
                                  {errors?.thermalOilHeater &&
                                    errors?.thermalOilHeater[index]
                                      ?.th_expansion_tank_level &&
                                    env?.form_validation === true && (
                                      <ErrorTooltip
                                        target={`th_expansion_tank_level-${index}`}
                                        message={
                                          errors?.thermalOilHeater[index]
                                            ?.th_expansion_tank_level
                                        }
                                        open={
                                          errors?.thermalOilHeater &&
                                            errors?.thermalOilHeater[index]
                                              ?.th_expansion_tank_level
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                </Col>
                              </Row>
                            </CardBody>
                          );
                        }
                      )}
                  </React.Fragment>
                )}
              </FieldArray>
              <CardFooter className="p-2 py-3">
                <Row className="ele_row1">
                  <div className="d-flex flex-wrap gap-5">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn_size_cstm pos-end"
                      onClick={() => {
                        toggleDynamicTabs(previousTabIndex + 1);
                      }}
                    >
                      Next <i className="bx bx-chevron-right ms-1" />
                    </Button>
                    {record &&
                      record?.event_precedence_id === EventPrecedence.NOON &&
                      record.operation_precedence_id ===
                      ReportingOpConstant.SEA_PASSAGE ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                        onClick={() => {
                          toggleAddTab(2);
                        }}
                      >
                        <i className="bx bx-chevron-left" /> Previous
                      </Button>
                    ) : (record &&
                      record?.event_precedence_id !== EventPrecedence.NOON &&
                      record.operation_precedence_id ===
                      ReportingOpConstant.SEA_PASSAGE) ||
                      (record &&
                        record?.event_precedence_id === EventPrecedence.NOON &&
                        record.operation_precedence_id !==
                        ReportingOpConstant.SEA_PASSAGE) ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                        onClick={() => {
                          toggleAddTab(1);
                        }}
                      >
                        <i className="bx bx-chevron-left" /> Previous
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                        onClick={() => {
                          toggleDynamicTabs(previousTabIndex - 1);
                        }}
                      >
                        <i className="bx bx-chevron-left " /> Previous
                      </Button>
                    )}
                  </div>
                </Row>
              </CardFooter>
              <FormValuesDebug
                values={[values, errors, ThermalOilHeaterFormik.initialValues]}
              />
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default ThermalOilHeaterComponent;
