import { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'reactstrap';
import { apiMedia } from 'global/api.global';
import '../global/GlobalCSS.css';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import { ciiEventDocuments } from 'VesselMaster/vesselMaster.hooks';
import { queryClient } from 'react-query/queryClient';
import ReportingFileUpload from './ReportingFileUpload';
import { FileStatus } from 'shared/constants';
import { handleServerResponse } from 'GenericForms/Helper';

export interface event {
    id: number,
    cii_adjustment: number,
    vessel_reporting_cii_adjustment: number,
    cii_adjustment_name: string
}

interface CIIAdjustmentFileUploadType {
    state: boolean,
    event: event,
    togBackDropCIIUpload: any,
}

const CIIAdjustmentFileUpload = ({
    state,
    event,
    togBackDropCIIUpload,
}: CIIAdjustmentFileUploadType) => {
    /** State variables */
    const [modalState, setModalState] = useState(state);
    const [CIIFiles, setCIIFiles] = useState<Array<any>>([{}])
    const [refreshKey, setRefreshKey] = useState<number>(0)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const DeleteFileObj: any = {
        vessel_reporting_cii_adjustment_supporting_file_name: null,
        file_path: null
    }
    /** State variables end */
    /** filter CIIEvents */
    useEffect(() => {
        queryClient.invalidateQueries(queryKeyes.vessel.CIIEventsSupportingFileData.key)
    }, [modalState]);
    /** useEffect end */

    /**
     * Documents for the CII Event
     */
    let { data: documentsSubmit, isLoading: documentsLoading, isError: documentsError } = useQuery(
        [queryKeyes.vessel.CIIEventsSupportingFileData.key, event.id],
        async () => {
            return await ciiEventDocuments(event.id)
        }, {
        staleTime: Infinity,
        enabled: state, // Only fetch when modal is open
    });

    useEffect(() => {
        setModalState(state);
    }, [state])
    /** useEffect end */

    function tog_backdrop() {
        setModalState(!modalState);
    }

    const CIIAdjustmentFileUploadSubmit = async () => {
        if (documentsSubmit.length > 0) {
            // Track success state for the entire batch
            const responseArray: any[] = []
            documentsSubmit.forEach(async (submitFile: any, index: number) => {
                const formData = new FormData();
                Object.keys(submitFile).forEach(key => {
                    formData.append(key, submitFile[key]);
                });
                if (CIIFiles && Array.isArray(CIIFiles) && CIIFiles[index]?.file) {
                    formData.set('vessel_reporting_cii_adjustment_supporting_file_name', CIIFiles[index]?.file)
                }
                // Make the API request
                responseArray.push(apiMedia.put(`/vessel_reporting_cii_adjustment_supporting_file/${submitFile.id}/`, formData));
            });
            await handleServerResponse(responseArray).then(async (res) => {
                if (res) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.CIIEventsSupportingFileData.key);
                    setRefreshKey(refreshKey + 1);
                    togBackDropCIIUpload();
                }
            })
        }
        setIsSubmitting(false)
    };

    return (
        <Modal
            isOpen={modalState}
            toggle={() => {
                tog_backdrop();
            }}
            style={{
                maxWidth: "900px",
                margin: "auto",
            }}
            backdrop={"static"}
            id="staticBackdrop"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Upload documents of {event.cii_adjustment_name} event
                </h5>
                <button
                    onClick={() => {
                        togBackDropCIIUpload()
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Form className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsSubmitting(true)
                        CIIAdjustmentFileUploadSubmit();
                        return false;
                    }}>

                    {(documentsLoading) &&
                        <Row>
                            <Col><div>
                                <div className="alert-border-left mb-0 alert alert-info alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" aria-label="Close"></button>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Loading Document List!
                                </div>
                            </div>
                            </Col>
                        </Row>
                    }

                    {(documentsError) &&
                        <Row>
                            <Col><div>
                                <div className="alert-label-icon label-arrow alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" aria-label="Close"></button>
                                    <i className="mdi mdi-block-helper label-icon"></i>
                                    <strong>Danger</strong> - Unable to process request!
                                </div>
                            </div>
                            </Col>
                        </Row>
                    }
                    {((!documentsLoading && !documentsError) && documentsSubmit) && (
                        documentsSubmit.map((list: any, index: number) => {
                            return (
                                <div key={index} className='mb-2'>
                                    <Col sm={9}>
                                        <h6 className='m-0'>{list.cii_document_name}</h6>
                                    </Col>
                                    <Col>
                                        <ReportingFileUpload
                                            key={index}
                                            setFile={setCIIFiles}
                                            file={CIIFiles}
                                            fileUploadStatus={list?.file_status === FileStatus.UPLOADED}
                                            fileURL={list?.file_path}
                                            deleteURL={`/vessel_reporting_cii_adjustment_supporting_file/`}
                                            invalidateQuery={queryKeyes.vessel.CIIEventsSupportingFileData.key}
                                            deleteID={list?.id}
                                            refreshKey={refreshKey}
                                            setRefreshKey={setRefreshKey}
                                            title={''}
                                            DeleteFileObj={DeleteFileObj}
                                            sm={3}
                                            index={index}
                                        />
                                        <hr className='m-0' />
                                    </Col>
                                </div>
                            )
                        }))
                    }
                    <Row>
                        <Col>
                            <div className=" mt-3 mb-5">
                                <Button type="submit" color='primary' className='pos-end' disabled={isSubmitting}>
                                    {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                    Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal >
    )
}
export default CIIAdjustmentFileUpload