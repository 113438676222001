import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { WidgetsData } from "./WidgetsData";
import '../global/GlobalCSS.css'
import Layout from "../HorizontalMenu/Menu";
import Navbar from "../HorizontalMenu/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "index";
import { getVesselsAction, setVoyageID } from "Store/Generic/ReportingSlice";
import { Roles } from "shared/constants";
import DashboardFleet from "./DashboardFleet";
import { hasRole, userVessels } from "utils/auth/authUtils";
import { loadLastReportVessel, loadVesselCardDetails } from "VesselMaster/vesselMaster.hooks";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import VesselCard from "Components/VesselCard";
import CreateVesselReport from "VesselReportingInfo/CreateVesselReport";
import ShipIcon from '../Media/dashboard_ship.png';
import Pending from "./PendingFiles/Pending";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";

const Dashboard = () => {
    /** State variables */
    const { Vessels, VesselID, VesselState } = useSelector((state: RootState) => state.Reporting);
    const dispatch = useDispatch();
    const [userAssignedVessels, setUserAssignedVessels] = useState<Array<any>>([]);
    let applicableVessels = userVessels();
    /** State varibles end */

    /** Queries */
    /** Vessel card details */
    const { data: CardDetails, isLoading: CardDetailsLoading, isError: CardDetailsError } = useQuery(
        [queryKeyes.vessel.VesselCardDetails.key],
        async () => {
            return await loadVesselCardDetails()
        },
        {
            enabled: true,
        }
    );
    /** Vessel's last report */
    const { data: LastVesselReport } = useQuery(
        [queryKeyes.vessel.LastVesselReport.key, VesselID],
        async () => {
            return await loadLastReportVessel(VesselID)
        },
        {
            enabled: true,
        }
    );
    /** Queries end */

    /** useEffect */
    useEffect(() => {
        dispatch(getVesselsAction('vessel_master' as string));
    }, [dispatch]);
    useEffect(() => {
        if (LastVesselReport !== undefined) {
            dispatch(setVoyageID(LastVesselReport?.voyage_information))
        }
    }, [LastVesselReport, dispatch])
    useEffect(() => {
        let arr: any[] = [];
        if (CardDetails && userAssignedVessels?.length < applicableVessels?.length) {
            applicableVessels.forEach((vessel: any) => {
                arr.push(CardDetails?.filter((item: any) => Object.values(item)?.includes(vessel))[0]);
            });
            setUserAssignedVessels(arr);
        }
    }, [CardDetails, applicableVessels, userAssignedVessels?.length]);
    /** useEffect end */

    /** Fetch vessels assigned to user */
    const ValidVessels: any = Vessels.filter((vessel: any) => vessel.display === true);
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <h4 style={{ 'display': 'inline' }} key={'dashboard'} className="page_title">Dashboard</h4>
                    {CardDetailsLoading && <Loading message="Loading required data!" />}
                    {CardDetailsError && <ErrorComponent message="Error loading required data!" />}
                    {!CardDetailsLoading && !CardDetailsError &&
                        <>
                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && ValidVessels &&
                                <Row key={'dashboard-widget'}>
                                    {(WidgetsData || []).map((widget: any) => (
                                        <Col sm={3} key={widget.id}>
                                            <Card className="max-h-20">
                                                <CardBody className={widget.className}>
                                                    <Row className="align-items-center">
                                                        <Col xs={6}>
                                                            <img height={100} src={ShipIcon} alt="" />
                                                        </Col>
                                                        <Col xs={6} className="">
                                                            <h5 className="mb-3 lh-1 d-block w-7 text-center">
                                                                {widget.title}
                                                            </h5>
                                                            <div className="text-nowrap wid-data text-center">
                                                                <span className="primary-label">{(ValidVessels.length)}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            }
                            {(hasRole(Roles.ES_ADMIN) ||
                                hasRole(Roles.MANAGER)) &&
                                <DashboardFleet />
                            }
                            {/* {hasRole(Roles.ES_ADMIN) &&
                        <Row>
                            <Col lg={4}>
                                <VesselCard Vessels={CardDetails?.filter((item: any) => item.vessel_id === userVesselMaster)} />
                            </Col>
                            <Col lg={{ size: 3, offset: 5 }}>
                                <Link
                                    to="/masters/vessel_master">
                                    <button color='primary' className='btn btn-primary mb-3' onClick={() => {
                                        handleVesselState(-1, 'CREATE_VESSEL_REPORTING');
                                    }}>
                                        <i className="dripicons-plus font-size-16 align-middle me-2" />
                                        Create New Report
                                    </button>
                                </Link>
                            </Col>
                        </Row>
                    } */}
                            {(hasRole(Roles.CHIEFENGINEER) || hasRole(Roles.MASTER)) &&
                                <Row className="mt-3">
                                    <VesselCard Vessels={userAssignedVessels} />
                                </Row>
                            }
                            <Row className="px-2 mt-3">
                                <Pending />
                            </Row>
                        </>
                    }
                </Container>
            </div>
            {VesselState === 'CREATE_VESSEL_REPORTING' && <CreateVesselReport />}
        </React.Fragment>
    );
};

export default Dashboard;