import React from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import apiGlobal from 'global/api.global';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Row } from 'reactstrap';
import { DO_ENGG_ReportingStatus } from 'shared/constants';
import { successToast } from 'Components/Toasts';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { loggedInUser } from 'utils/auth/authUtils';
import { Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

interface RemarksType {
    record: any,
    handleVesselState: any,
    toggleTab: any,
    activeTab: number
}

const Remarks = ({
    record,
    handleVesselState,
    toggleTab,
    activeTab,
}: RemarksType) => {
    /** State variables start */
    const { ReportID } = useSelector((state: RootState) => state.Reporting);
    /** State variables end */

    /** Actions to perform when a request is submitted successfully */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        }
    }

    /**
     * send POST request for remark
     * @param remark 
     */
    const saveRemark = (remark: any) => {
        /** Remarks' submit request */
        apiGlobal.post(`/deck_officer_remark/`, remark).then(res => {
            handleResponse(res);
        }).catch(err => {
            console.error(err);
        });
    }

    /** Actions to perform when clicked on button 'Save & Complete' */
    const handleSaveandComplete = async (remark: any) => {
        let report = { ...record }
        report.officer_reporting_status = DO_ENGG_ReportingStatus.COMPLETE;
        if (remark.remarks) {
            saveRemark(remark)
        }
        try {
            const response = await apiGlobal.put(`/vessel_reporting_information/${ReportID}/`, report);
            handleResponse(response);
            handleVesselState('VESSEL_REPORTING');
            return response.data
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    /** Assign values to formik's initial object */
    const getInitialValues = () => {
        return ({
            remarks: "",
            remark_file: null as any,
            user: loggedInUser()
        })
    }

    /** Formik object for Remarks */
    const RemarksFormik = {
        initialValues: getInitialValues()
    }
    return (
        <Card>
            <CardHeader className='p-2 pb-0'>
                <div className="text-center mb-3">
                    <Row>
                        <Col>
                            <h5 className="page_title pos-start mb-0">Remarks (if any)</h5>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <Formik
                onSubmit={(values: any, actions: any) => {
                    actions.setSubmitting(true);
                    /** Remarks' submit request */
                    if (values.remarks) {
                        saveRemark(values)
                    }
                }}
                initialValues={RemarksFormik.initialValues}
            >
                {props => (
                    <Form className="needs-validation" autoComplete="off" onSubmit={props?.handleSubmit}>
                        <CardBody className='deck-card-body'>
                            <Row>
                                <div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'alignment', 'bulletedList', 'numberedList'],
                                        }}
                                        onBlur={(event, editor) => {
                                            props?.setFieldValue('remarks', editor.getData());
                                        }}
                                    />
                                </div>
                            </Row>
                        </CardBody>
                        <CardFooter className='p-2 py-3'>
                            <Row className="ele_row1">
                                <div className="d-flex flex-wrap gap-5 ">
                                    <Button type="submit" color="primary" className="btn_size_cstm pos-end12">Save</Button>
                                    <Button type="button" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                        handleSaveandComplete(props.values);
                                    }}>Save & Complete</Button>
                                    <Button type="button" color="primary" className="btn_size_cstm " onClick={() => {
                                        props.setSubmitting(false);
                                        toggleTab(activeTab - 1);
                                    }}><i className="bx bx-chevron-left me-1" />Previous</Button>
                                </div>
                            </Row>
                        </CardFooter>
                        <FormValuesDebug values={[props?.values, props?.errors, RemarksFormik.initialValues]} />
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default Remarks