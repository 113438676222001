import apiGlobal from '../../../global/api.global';
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { FormikProps, useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadCargoDischargingWithkWh } from 'VesselMaster/vesselMaster.hooks';
import ErrorComponent from 'Components/ErrorComponent';
import { useEffect } from 'react';
import { SpOpsConfigurationConstant } from 'shared/constants';

interface CargoDischargingType {
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?:(value:FormikProps<any>)=> void;
    formik?:FormikProps<any>;
    activeTab:any;
    setTabFormik?:(value:FormikProps<any>)=> void;
}

const CargoDischarging = ({
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    formik,
    activeTab,
    setTabFormik,
}: CargoDischargingType) => {
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /** Queries */
    /** Load Cargo Discharging wuth KWH machinaries */
    const { data: CargoDischarging, isLoading: CargoDischargingLoading, isError: CargoDischargingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoDischargingWithkWhMachineries.key, VesselID],
            async () => { return await loadCargoDischargingWithkWh(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Assign initial values to cargo Discharging formik object */
    const getCargoDischargingInitailValues = () => {
        if (CargoDischarging?.length > 0) {
            return CargoDischarging
        }
    }

    /** cargo Discharging Formik Object */
    const CargoDischargingFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            cargoDischarging: getCargoDischargingInitailValues()
        },
        onSubmit: () => {
            CargoDischargingFormik?.setSubmitting(false)
            CargoDischargingFormik?.values?.cargoDischarging?.foreach((val: any) => {
                apiGlobal.put(`/special_operation_energy_setting/${val.id}/`, val)
                    .then(res => {
                        if (res.status === 200) {
                            successToast("Data saved successfully!");
                        }
                    })
                    .catch(err => {
                        if (errResponse.includes(err?.response?.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    });
            })
        }
    })

    const tabName = 'Cargo Discharging';
      const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = JSON.stringify(values) === JSON.stringify(initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
      };
    
      useEffect(() => {
        handleFormikStateChange(CargoDischargingFormik?.values, CargoDischargingFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [CargoDischargingFormik?.values, CargoDischargingFormik?.initialValues]);

      useEffect(() => {
        if(activeTab === SpOpsConfigurationConstant.SpOpsCargoDischargingId && CargoDischargingFormik.values !== CargoDischargingFormik.initialValues){
            setTabFormik(CargoDischargingFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [activeTab, formik, CargoDischargingFormik]);

    if (CargoDischargingLoading) {
        return <Loading message='Loading required data!' />
    }
    if (CargoDischargingError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <>
                <Card className='border-0'>
                    {CargoDischargingLoading && <Loading message="Loading required data!" />}
                    {CargoDischargingError && <ErrorComponent message="Error loading required data!" />}
                    {!(CargoDischargingLoading) && !(CargoDischargingError) &&
                        <Form onSubmit={CargoDischargingFormik?.handleSubmit} noValidate autoComplete='off'>
                            <CardBody className='p-0'>
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive p-0">
                                            <table className="table mb-2">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                        <th className='p-2 align-middle'>Machinery name</th>
                                                        <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <>
                                                        {CargoDischargingFormik?.initialValues &&
                                                            CargoDischargingFormik?.initialValues?.cargoDischarging?.map((cargoDischarging: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                        <td className='p-2 align-middle'>{cargoDischarging.vessel_machinery_ec_group_name}</td>
                                                                        <td className='p-2 align-middle text-center'>
                                                                                    <div className="square-switch sqswitch mt-1">
                                                                                        <Input
                                                                                            type="checkbox"
                                                                                            switch="none"
                                                                                            name={`cargoDischarging.${index}.cargo_discharging_kwh_meter`}
                                                                                            id={`cargo_discharging_kwh_meter${index}`}
                                                                                            defaultChecked={CargoDischargingFormik?.values?.cargoDischarging && CargoDischargingFormik?.values?.cargoDischarging[index]?.cargo_discharging_kwh_meter}
                                                                                            onChange={(e: any) => CargoDischargingFormik?.setFieldValue(`cargoDischarging.${index}.cargo_discharging_kwh_meter`, e.target.checked)}
                                                                                        />
                                                                                        <Label
                                                                                            htmlFor={`cargo_discharging_kwh_meter${index}`}
                                                                                            data-on-label="Yes"
                                                                                            data-off-label="No"
                                                                                            className="mb-0"
                                                                                        ></Label>
                                                                                    </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3 mb-3'>
                                <Button type="submit" color='primary' className='justify_right' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                            </CardFooter>
                            <Row className='mt-2'>
                                <FormValuesDebug values={[CargoDischargingFormik?.values, CargoDischargingFormik?.errors, CargoDischargingFormik.initialValues]} />
                            </Row>
                        </Form>
                    }
                </Card>
            </>
        )
    }
}
export default CargoDischarging;