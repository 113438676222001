import React, { useState } from 'react';
import { Card, NavItem, TabContent, TabPane, NavLink, Container, Nav, CardHeader, Row, Col } from "reactstrap";
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import classnames from "classnames";
import '../../global/GlobalCSS.css'
import GeneralSettings from './VesselMachinery/GeneralSettings/MachineryGeneralSetings';
import SFOC from './SFOC';
import Engines from './Engines';
import FuelMachineryConfiguration from './FuelMachineryConfiguration';
import SpecialOperations from './SpecialOperations/SpecialOperations';
import LubeOilMaster from './LubeOil/LubeOilMaster';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SensorsMaster from './SensorsMaster';
import VesselDetailsHeader from '../../Components/VesselDetailsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselMachineryConfig from './VesselMachinery/VesselMachineryConfig';
import Other from './Other/Other';
import { VesselConfigrationTabs, VesselTypeConstant } from 'shared/constants';
import { RootState } from 'index';
import VesselFuelConfiguration from 'VesselMaster/VesselConfiguration/VesselFuelConfiguration/VesselFuelConfiguration';
import VesselData from './VesselData';
import PopUp from 'Components/PopUp';
import SaveBeforeChange from 'VesselMaster/SaveBeforeChange';
import SpeedConsumptionTable from './SpeedConsumptionTable';
import { FormikProps } from 'formik';

const VesselConfiguration = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState(VesselConfigrationTabs.VESSEL_DATA);
    const dispatch = useDispatch();
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: { [key: string]: string | number | boolean | Date | null }) => rec.id === VesselID);
    const [refreshVesselMachineries, setRefreshVesselMachineries] = useState(0);
    const [deletePopOverActive, setDeletePopOverActive] = useState<boolean>(false);
    const [checkValuesBeforeSwitch, setCheckValuesBeforeSwitch] = useState(false);
    const [state, setState] = useState(false);
    const [tabName, setTabName] = useState('');
    const [nextTab, setNextTab] = useState<number | null>(null);
    const [formik, setFormik] = useState<FormikProps<any>>(null);

    const toggle = (tab: number) => {
        if (checkValuesBeforeSwitch === true) {
            if (activeSettingsTab !== tab) {
                setActiveSettingsTab(tab);
            }
        }
        else {
            setNextTab(tab);
            setState(true);
        }
    }

    const handleSaveAndSwitchTab = async (formik: any) => {
        await formik.submitForm();
        formik.initialValues = formik?.values
        formik.resetForm({ values: formik.values });
        setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab); // Switch to the next tab
            setNextTab(null); // Reset the next tab state
        }
        setState(false); // Close the popup
    };

    // const handleSaveAndSwitchTab = async (formik: FormikProps<any>) => {
    //     console.log(formik);
    //     if (!formik) {
    //         console.error("Formik is not defined!");
    //         return;
    //     }
    //     if (formik) {
    //         console.log("Submitting form...");
    //         await formik.submitForm(); // Submit the form
    
    //         // Sync current values with initialValues
    //         formik.resetForm({ values: formik.values });
    
    //         setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
    //         if (nextTab !== null) {
    //             setActiveSettingsTab(nextTab); // Switch to the next tab
    //             setNextTab(null); // Reset the next tab state
    //         }
    //     }
    //     setState(false); // Close the popup
    // };
    
    const handleNoSaveAndSwitchTab = () => {
        formik.resetForm({ values: formik.values });
        setCheckValuesBeforeSwitch(true); // Reset the unsaved changes flag
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab); // Switch to the next tab
            setNextTab(null); // Reset the next tab state
        }
        setState(false); // Close the popup
    };

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    return (
        <React.Fragment>
            {/* <PopUp
                state={state}
                setState={setState}
                body={<SaveBeforeChange
                    tabName={tabName}
                    onYes={() => {
                        handleSaveAndSwitchTab(formik)
                        setState(false);
                        setActiveSettingsTab(nextTab);
                        setCheckValuesBeforeSwitch(false);
                    }}
                    onNo={() => {
                        setCheckValuesBeforeSwitch(true);
                        if (nextTab !== null) {
                            setActiveSettingsTab(nextTab);
                            setNextTab(null);
                        }
                        setState(false); // Close popup
                    }}
                />
            }
                title="Alert"
                size="lg"
            /> */}
            <PopUp
                state={state}
                setState={setState}
                body={
                    <SaveBeforeChange
                        tabName={tabName}
                        onYes={() => handleSaveAndSwitchTab(formik)} // Save and switch
                        onNo={handleNoSaveAndSwitchTab} // Discard and switch
                    />
                }
                title="Alert"
                size="lg"
            />
            <React.Fragment>
                <Layout children={Navbar} />
                <div className="page-content">
                    <Container fluid>
                        <Card>
                            <CardHeader className="px-2 py-0">
                                <Row className='pb-2'>
                                    <Col sm="2" className="d-flex align-items-center">
                                        <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState(null) }}>
                                            <i className="bx bx-chevron-left me-1" /> Back
                                        </button>
                                    </Col>
                                    <Col sm="10">
                                        <VesselDetailsHeader />
                                    </Col>
                                </Row>
                                <Nav tabs className='border-0'>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.VESSEL_DATA,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.VESSEL_DATA);
                                            }}
                                        >
                                            Vessel Data
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.MACHINERY,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.MACHINERY);
                                            }}
                                        >
                                            Machinery
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.GENERAL_SETTINGS,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.GENERAL_SETTINGS);
                                            }}
                                        >
                                            General Settings
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.SFOC,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.SFOC);
                                            }}
                                        >
                                            SFOC
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.VESSEL_FUEL,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.VESSEL_FUEL);
                                            }}
                                        >
                                            Vessel Fuel
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.MACHINERY_FUEL,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.MACHINERY_FUEL);
                                                setDeletePopOverActive(false);
                                            }}
                                        >
                                            Machinery Fuel
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.ENGINES,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.ENGINES);
                                            }}
                                        >
                                            Engines
                                        </NavLink>
                                    </NavItem>
                                    {Object.values(VesselTypeConstant).includes(vessel?.vessel_type) &&
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeSettingsTab === VesselConfigrationTabs.SPECIAL_OPERATIONS,
                                                })}
                                                onClick={() => {
                                                    setFormik(null);
                                                    toggle(VesselConfigrationTabs.SPECIAL_OPERATIONS);
                                                }}
                                            >
                                                Special operations
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.LUBE_OIL,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.LUBE_OIL);
                                            }}
                                        >
                                            Lube Oil
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.SENSOR,
                                            })}
                                            onClick={() => {
                                                toggle(VesselConfigrationTabs.SENSOR);
                                            }}
                                        >
                                            Sensors
                                        </NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.OTHER,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.OTHER);
                                            }}
                                        >
                                            Other
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === VesselConfigrationTabs.SPEED_CONSUMPTION,
                                            })}
                                            onClick={() => {
                                                setFormik(null);
                                                toggle(VesselConfigrationTabs.SPEED_CONSUMPTION);
                                            }}
                                        >
                                            Speed & Consumption
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={activeSettingsTab}>
                                <TabPane tabId={VesselConfigrationTabs.MACHINERY} className='margin-16'>
                                    <VesselMachineryConfig
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.GENERAL_SETTINGS} className='margin-16'>
                                    <GeneralSettings
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SFOC} className='margin-16'>
                                    <SFOC
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        VesselConfActiveTab={activeSettingsTab}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.ENGINES} className='margin-16'>
                                    <Engines
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        VesselConfActiveTab={activeSettingsTab}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.VESSEL_FUEL} className='margin-16'>
                                    <VesselFuelConfiguration
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.MACHINERY_FUEL} className='margin-16'>
                                    <FuelMachineryConfiguration
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                        VesselConfActiveTab={activeSettingsTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SPECIAL_OPERATIONS} className='margin-16'>
                                    <SpecialOperations
                                        refreshVesselMachineries={refreshVesselMachineries}
                                        setRefreshVesselMachineries={setRefreshVesselMachineries}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                        VesselConfActiveTab={activeSettingsTab}
                                        // handleNoSaveAndSwitchTab={handleNoSaveAndSwitchTab}
                                        // handleSaveAndSwitchTab={handleSaveAndSwitchTab}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.LUBE_OIL} className='margin-16'>
                                    <LubeOilMaster
                                    />
                                </TabPane>
                                {/* <TabPane tabId={VesselConfigrationTabs.SENSOR} className='margin-16'>
                                    <SensorsMaster />
                                </TabPane> */}
                                <TabPane tabId={VesselConfigrationTabs.OTHER} className='margin-16'>
                                    <Other
                                        VesselConfActiveTab={activeSettingsTab}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.VESSEL_DATA} className='margin-16'>
                                    <VesselData
                                        VesselConfActiveTab={activeSettingsTab}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                    />
                                </TabPane>
                                <TabPane tabId={VesselConfigrationTabs.SPEED_CONSUMPTION} className='margin-16'>
                                    <SpeedConsumptionTable
                                        VesselConfActiveTab={activeSettingsTab}
                                        deletePopOverActive={deletePopOverActive}
                                        setDeletePopOverActive={setDeletePopOverActive}
                                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                                        setTabName={setTabName}
                                        setFormik={setFormik}
                                        formik={formik}
                                    />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default VesselConfiguration;
