import PopUp from 'Components/PopUp';
import Layout from 'HorizontalMenu/Menu';
import React, { useState } from 'react';
import { Navbar, Container, Row, Button } from 'reactstrap';
import TempPassword from './TempPassword';
import PopOver from 'Components/PopOver';
import CreateUserComponent from './CreateUserComponent';
import DeletePopOver from 'Components/DeletePopOver'
import ResetPassword from './ResetPassword';
import { errorToast, successToast } from 'Components/Toasts';
import { fetchUserProfile } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import ToolTip from 'Components/ToolTip';

const Users = () => {
    /** State variables start */
    const [hoverId, setHoverId] = useState<string>('');
    const [createUserBool, setCreateUserBool] = useState<boolean>(false);
    const [tempPopUpBool, setTempPopUpBool] = useState<Boolean>(false);
    const [deleteUserBool, setDeleteUserBool] = useState<Boolean>(false);
    const [resetPasswordBool, setResetPasswordBool] = useState<Boolean>(false);
    const [blockUserBool, setBlockUserBool] = useState<Boolean>(false);
    const [editUserBool, setEditUserBool] = useState<boolean>(false);
    /**common popover state */
    const [target, setTarget] = useState<string>('block_user');
    const [name, setName] = useState<string>('');
    const [status, setStatus] = useState<null | boolean>(null);
    const [userID, setUserID] = useState<number>(0);
    /*common popover   end */
    /** State variables end */

    /**
     * Fetch user profile
     * @param
     * @returns all user profile
     */
    const { data: profile, isLoading, isError } = useQuery(
        [queryKeyes.user.profile.key],
        async () => {
            return await fetchUserProfile();
        },
        { staleTime: Infinity }
    )

    /**
     * block and unblock user
     * @param userId 
     */
    const BlockUnblock = async (userId: number) => {
        await apiGlobal.post(queryKeyes.user.block.url(), { user_id: userId })
            .then((res) => {
                if (res.status === 200) {
                    successToast(res.data.message);
                    queryClient.invalidateQueries(queryKeyes.user.profile.key);
                }
            })
            .catch((err) => {
                errorToast(err.response?.data?.error || "An error occurred");
            })
            .finally(() => {
                setBlockUserBool(false);
            });
    };

    /**
     * Deleet user display false 
     * @param userId 
     */
    const DeleteUser = async (userId: number) => {
        await apiGlobal
            .post(queryKeyes.user.delete.url(), { user_id: userId })
            .then((res) => {
                if (res.status === 200) {
                    successToast("Data deleted successfully!");
                    queryClient.invalidateQueries(queryKeyes.user.profile.key);
                }
            })
            .catch((err: any) => {
                errorToast(err.response?.data?.error || "An error occurred");
            })
            .finally(() => {
                setDeleteUserBool(false);
            });
    };

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content" >
                <Container fluid>
                    <Row className="mb-2">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">Users</h5>
                            <Button
                                className="ms-auto justify_right"
                                color="primary"
                                onClick={() => { setCreateUserBool(true); }}
                            >
                                +Create
                            </Button>
                        </div>
                    </Row>
                    {isLoading && <Loading message='Loading required data!' />}
                    {isError && <ErrorComponent message='Error loading required data!' />}
                    {!isLoading && !isError &&
                        <Row>
                            <div className="table-responsive">
                                <table className="table mb-2">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="p-2 align middle sr-no-width">#</th>
                                            <th className="p-2 align middle" style={{ width: '20%' }}>Name</th>
                                            <th className="p-2 align middle" style={{ width: '20%' }}>Mobile</th>
                                            <th className="p-2 align middle" style={{ width: '30%' }}>Email</th>
                                            <th className="p-2 align middle" style={{ width: '20%' }}>Group</th>
                                            <th className="p-2 align middle text-center" colSpan={5} style={{ width: '5%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {profile
                                            ?.filter((user: any) => user.display)
                                            .map((user: any, index: number) => (
                                                <tr key={user.id}>
                                                    <td className="p-2 align middle">{index + 1}</td>
                                                    <td className="p-2 align middle">
                                                        {user?.first_name} {user?.middle_name} {user?.last_name}
                                                    </td>
                                                    <td className="p-2 align middle">{user?.mobile || 'Mobile not set'}</td>
                                                    <td className="p-2 align middle">{user?.email || 'Email not set'}</td>
                                                    <td className="p-2 align middle">{user?.group}</td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className="btn btn-soft-primary waves-effect waves-light"
                                                            id={`Edit_user_profile_${user?.id}`}
                                                            onMouseEnter={() => setHoverId(`Edit_user_profile_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={async () => {
                                                                setEditUserBool(true);
                                                                setUserID(user?.id);
                                                                setHoverId("");
                                                            }}
                                                        >
                                                            <i className="bx bx-user font-size-16 align-middle"></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className="btn btn-soft-primary waves-effect waves-light"
                                                            id={`Veiw_password_${user?.id}`}
                                                            onMouseEnter={() => setHoverId(`Veiw_password_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                setTempPopUpBool(true)
                                                                setUserID(user?.id)
                                                                setName(
                                                                    `${user?.first_name || ""} ${user?.middle_name || ""} ${user?.last_name || ""}`.trim()
                                                                );
                                                            }}
                                                        >
                                                            <i className="dripicons-preview font-size-16 align-middle"></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className="btn btn-soft-primary waves-effect waves-light"
                                                            id={`Reset_password_${user?.id}`}
                                                            onMouseEnter={() => setHoverId(`Reset_password_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                setResetPasswordBool(true)
                                                                setHoverId("")
                                                                setName(
                                                                    `${user?.first_name || ""} ${user?.middle_name || ""} ${user?.last_name || ""}`.trim()
                                                                ); setUserID(user?.id)
                                                            }}
                                                        >
                                                            <i className="bx bx-reset font-size-16 align-middle"></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align-middle">
                                                        <button
                                                            type="button"
                                                            className={`btn ${user?.block ? "btn-soft-success" : "btn-soft-danger"
                                                                } waves-effect waves-light`}
                                                            id={`block_user_${user?.id}`}
                                                            onMouseEnter={() => setHoverId(`block_user_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                setBlockUserBool(true);
                                                                setTarget(`block_user_${user?.id}`);
                                                                setName(
                                                                    `${user?.first_name || ""} ${user?.middle_name || ""} ${user?.last_name || ""}`.trim()
                                                                );
                                                                setStatus(user?.block);
                                                                setHoverId("");
                                                            }}
                                                        >
                                                            <i
                                                                className={`bx ${user?.block ? "bx-circle" : "bx-block"
                                                                    } font-size-16 align-middle`}
                                                            ></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className="btn btn-soft-danger waves-effect waves-light"
                                                            id={`delete_user_${user.id}`}
                                                            onMouseEnter={() => setHoverId(`delete_user_${user.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                setDeleteUserBool(true);
                                                                setHoverId("");
                                                                setTarget(`delete_user_${user.id}`);
                                                                setName(
                                                                    `${user.first_name || ""} ${user.middle_name || ""} ${user.last_name || ""}`.trim()
                                                                );
                                                            }}
                                                        >
                                                            <i className="dripicons-trash font-size-16 align-middle"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    }
                </Container>
            </div>
            {resetPasswordBool &&
                <PopUp
                    state={resetPasswordBool}
                    setState={setResetPasswordBool}
                    body={<ResetPassword
                        userID={userID}
                        name={name}
                        setState={setResetPasswordBool}
                    />}
                    title="Reset password"
                    size="md"
                />
            }
            {tempPopUpBool &&
                <PopUp
                    state={tempPopUpBool}
                    setState={setTempPopUpBool}
                    body={<TempPassword
                        userID={userID}
                        userName={name}
                        setState={setTempPopUpBool}
                    />}
                    title="View temporary password"
                    size="md"

                />
            }
            {blockUserBool &&
                <PopOver
                    key={target}
                    state={blockUserBool}
                    message={`Are you sure you want to ${status ? "unblock" : "block"} ${name} ?`}
                    setState={setBlockUserBool}
                    target={target}
                    handleClick={() => {
                        const userId = parseInt(target.split("_").pop());
                        BlockUnblock(userId);
                    }}
                    placement="top"
                />
            }
            {deleteUserBool &&
                <DeletePopOver
                    state={deleteUserBool}
                    message={`Are you sure you want to delete ${name} ?`}
                    setState={setDeleteUserBool}
                    target={target}
                    onClick={() => {
                        const userId = parseInt(target.split("_").pop());
                        DeleteUser(userId);
                    }}
                    placement="top"
                    key={target}
                />}
            {editUserBool &&
                <PopUp
                    state={editUserBool}
                    setState={setEditUserBool}
                    body={<CreateUserComponent
                        setState={setEditUserBool}
                        userID={userID}
                    />}
                    title="Edit User"
                    size="lg"
                />
            }
            {hoverId &&
                <ToolTip
                    target={hoverId}
                    message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                    isOpen={hoverId ? true : false}
                />
            }
            <PopUp
                state={createUserBool}
                setState={setCreateUserBool}
                body={<CreateUserComponent setState={setCreateUserBool} />}
                title="Add User"
                size="lg"
            />
        </React.Fragment >
    );
}

export default Users;
