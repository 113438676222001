import AdjustmentPaginationComponent from 'Adjustments/AdjustmentPaginationComponent';
import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Label, Row, Col, Button } from 'reactstrap';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';

const DisapprovedVoyageAdjustment = ({
    active
}: any) => {
    /** State variables */
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        ciiAdjustment: []
    });
    const [count, setCount] = useState(0);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [voyageAdjustment, setVoyageAdjustment] = useState<any>({});
    const [revokeBool, setRevokeBool] = useState<any>({});
    /** State variables end */

    /** useEffect start */
    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselID, active])
    /** useEffect end */

    /** Load approved CII adjustment events of the selected vessels */
    const loadData = async () => {
        await apiGlobal
            .get(`vessel_reporting_cii_adjustment/get_cii_adjustment_by_vessel/?vessel_id=${VesselID}`).then((response) => {
                setTableDetails((prevDetails) => ({
                    ...prevDetails,
                    ciiAdjustment: response.data.filter(
                        (item: any) => item.user_approved === AdjustmentApprovalStatus.DISAPPROVED
                    ),
                }));
                setCount((count) => count + 1);
            })
            .catch(() => {
                setTableDetails((prevDetails) => ({
                    ...prevDetails,
                    ciiAdjustment: [],
                }));
            });
    };

    /** Function to revoke N2 Correction */
    const revokeVoyageAdjustment = () => {
        voyageAdjustment.user_approved = AdjustmentApprovalStatus.PENDING;
        apiGlobal.put(`/vessel_reporting_cii_adjustment/${voyageAdjustment?.id}/`, voyageAdjustment).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                loadData();
                setRevokeBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }
    return (
        <>
            {tableDetails?.ciiAdjustment?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-2'>No Voayge Adjustments are disapproved for this vessel</Label>
                </div> :
                <>
                    <Row className='mb-0'>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <thead className="table-light">
                                        <tr>
                                            <th className='p-2 text-center aligen-middle' style={{ width: '6.1%' }}>#</th>
                                            <th className='p-2'>Duration</th>
                                            {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                <th className='p-2 aligen-middle text-center' style={{ width: '23%' }}>Actions</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableDetails && tableDetails?.ciiAdjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((cii: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            <>
                                                                <b>{cii?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {cii?.cii_begin_date_time?.toString().substring(11, 16)}</b> to
                                                                <b> {cii?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {cii?.cii_end_date_time?.toString().substring(11, 16)}</b>
                                                            </>
                                                        </td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <td className='align-middle text-center p-2'>
                                                                <Button
                                                                    type='button'
                                                                    className='btn btn-primary waves-effect btn-label waves-light'
                                                                    color='primary'
                                                                    id={`disapprove_btn${cii?.id}`}
                                                                    onClick={() => {
                                                                        setVoyageAdjustment(cii);
                                                                        setRevokeBool(true);
                                                                    }}
                                                                >
                                                                    <i className="bx bx-left-arrow-circle label-icon"></i>
                                                                    Mark as pending
                                                                </Button>
                                                                {revokeBool === true &&
                                                                    <PopOver
                                                                        target={`disapprove_btn${voyageAdjustment?.id}`}
                                                                        handleClick={revokeVoyageAdjustment}
                                                                        message={'Are you sure you want to revoke?'}
                                                                        state={revokeBool}
                                                                        setState={setRevokeBool}
                                                                    />
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {tableDetails?.ciiAdjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            }
        </>
    )
}

export default DisapprovedVoyageAdjustment