import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Col, Row, Label, Button, Form, Input, CardFooter, Card, CardBody, CardHeader } from "reactstrap";
import { useQuery } from 'react-query';
import AsyncSelect from "react-select/async";
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { queryKeyes } from 'shared/queryKeys';
import { loadBunkeringObject, loadDebunkeringObject, loadFuelBatches, loadFuelTypeROB, loadOtherPorts, vesselFuel } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { dateTimeFormat, handleServerResponse, searchCountries, searchPorts } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import '../../../global/GlobalCSS.css';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { queryClient } from 'react-query/queryClient';
import env from 'environment_system/env_system';
import { FuelTypes, PortConstant } from 'shared/constants';
import { customStyle } from 'shared/CommonCSS';
import ErrorTooltip from 'Components/ErrorTooltip';

interface DebunkeringType {
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    activeTab: number,
    toggleTab: any
    tabsIdList: any[],
    record: any,
    toggleDynamicTabs: any,
    previousTabIndex: number,
    lastRecord: any,
    tabsList: any[]
}

const DebunkeringComponent =
    ({
        ReportID,
        VesselID,
        VoyageID,
        toggleTab,
        tabsIdList,
        record,
        toggleDynamicTabs,
        previousTabIndex,
        lastRecord,
        tabsList
    }: DebunkeringType) => {
        /** State variables */
        const [port, setPort] = useState(0);
        const [otherPort, setOtherPort] = useState(false);
        const [otherPortName, setOtherPortName] = useState('');
        const [otherCountry, setOtherCountry] = useState(0);
        const [addOtherPort, setAddOtherPort] = useState(false);
        const [otherPortId, setOtherPortId] = useState(null);
        /** State variables end */

        /** Queryies */
        /** Vessel distinct fuels  */
        const { data: fuelTypes, isLoading: fuelTypesLoading, isError: fuelTypesError }:
            { data: any[], isLoading: any, isError: any } = useQuery(
                [queryKeyes.vessel.fuel.key, VesselID],
                async () => {
                    return await vesselFuel(VesselID);
                },
                { staleTime: Infinity }
            );
        /** Fuel batches from debunkering */
        const { data: fuelBatches, isLoading: fuelBatchesLoading, isError: fuelBatchesError } = useQuery(
            [queryKeyes.vessel.BunkeringByVessel.key, VesselID],
            async () => {
                return await loadFuelBatches(VesselID);
            },
            { staleTime: Infinity }
        )
        /** Fuel batchs from debunkering */
        const { data: fuelTypeROB, isLoading: fuelTypeROBLoading, isError: fuelTypeROBError } = useQuery(
            [queryKeyes.vessel.FuelTypeROB.key, VesselID, VoyageID, ReportID],
            async () => {
                return await loadFuelTypeROB(VesselID, VoyageID, ReportID);
            },
            { staleTime: Infinity }
        )
        /** Debunkering object used for edit */
        const { data: DebunkeringObject, isLoading: DebunkeringObjectLoading, isError: DebunkeringObjectError } = useQuery(
            [queryKeyes.vessel.DebunkeringObject.key, VesselID, ReportID],
            async () => {
                return await loadDebunkeringObject(VesselID, ReportID);
            },
            { staleTime: Infinity }
        )
        /**Bunkering object used for edit */
        const { data: BunkeringObject, isLoading: BunkeringObjectLoading } = useQuery(
            [queryKeyes.vessel.BunkeringObject.key, VesselID, ReportID],
            async () => {
                return await loadBunkeringObject(VesselID, ReportID);
            },
            { staleTime: Infinity }
        )
        /** Other Ports from master */
        const { data: OtherPortsObject, isLoading: OtherPortsLoading }:
            { data: any, isLoading: any, isError: any } = useQuery(
                [queryKeyes.masters.OtherPortMaster.key, VesselID, ReportID],
                async () => {
                    return await loadOtherPorts();
                },
                { staleTime: Infinity }
            )
        /** Queries end */

        /** Assign initial values to formik object */
        const getintialValueDebunkuring = () => {
            let obj: any[] = [];
            if (DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0) {
                return DebunkeringObject;
            } else if (!(fuelBatchesLoading || fuelBatchesLoading) &&
                (fuelBatches && obj.length <= fuelBatches.length)) {
                fuelBatches?.filter((item: any) => item.status === "inuse" && item.quantity_remaining > 0)?.map((batch: any) => {
                    obj.push({
                        quantity_debunkered: null,
                        date_of_debunkering: null,
                        bunkering: batch.id,
                        fuel_type_name: batch.fuel_type_name,
                        port_of_debunkering: null,
                        other_port: null as number,
                        other_port_name: null as string,
                        other_country: null as number,
                        other_country_name: null as string,
                        batch_name: batch.bunkering_supply_name,
                        rob: batch.quantity_remaining,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    })
                    return batch;
                })
                return obj;
            } else {
                obj = [
                    {
                        quantity_debunkered: 0,
                        date_of_debunkering: null,
                        debunkering: 0,
                        fuel_type_name: 0,
                        port_of_debunkering: 0,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    }
                ]
            }
            return obj;
        }

        /**useEffect start */
        /** Create and assign initial object to Formik's initial values' object  */
        useEffect(() => {
            DebunkeringFormik.initialValues.debunkering = getintialValueDebunkuring();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [fuelBatches, DebunkeringObject])
        useEffect(() => {
            if ((DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0) &&
                (DebunkeringObject[0]?.port_precedence_id === PortConstant.OTHER)) {
                setOtherPort(true);
                setPort(DebunkeringObject[0]?.port_of_debunkering);
                setOtherPortId(DebunkeringObject[0]?.other_port);
                setOtherPortName(DebunkeringObject[0]?.other_port_name);
                setOtherCountry(DebunkeringObject[0]?.other_country);
            } else if (DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0) {
                setPort(DebunkeringObject[0]?.port_of_debunkering);
            }
        }, [DebunkeringObject])
        /**useEffect end */

        /** Debunkering Formik object */
        const DebunkeringFormik = {
            initialValues: {
                debunkering: getintialValueDebunkuring(),
            },
            validationSchema: Yup.object().shape({
                debunkering: Yup.array(
                    Yup.object({
                        quantity_debunkered: Yup.string().matches(/^\d{0,5}(?:\.\d{1,3})?$/, `${commonValidationMessages.before5after3}`)
                            .required(commonValidationMessages.required),
                        port_of_debunkering: Yup.number().required(commonValidationMessages.required),
                        date_of_debunkering: Yup
                            .date()
                            .min(dateTimeFormat(lastRecord?.reporting_time_utc, true)) //lastRecord?.reporting_time_utc
                            .max(dateTimeFormat(record?.reporting_time_utc, true))
                            .required(commonValidationMessages.required),
                        other_port: Yup.string().when('$fieldAvailability', (field: any, schema) => {
                            return !(otherPort === true && OtherPortsObject)
                                ? schema.nullable()
                                : schema.required(commonValidationMessages.required);
                        }),
                        other_port_name: Yup.string().when('$fieldAvailability', (field: any, schema) => {
                            return !(addOtherPort === true || !OtherPortsObject)
                                ? schema.nullable()
                                : schema.required(commonValidationMessages.required);
                        }),
                        other_country: Yup.number().when('$fieldAvailability', (field: any, schema) => {
                            return !(addOtherPort === true || !OtherPortsObject)
                                ? schema.nullable()
                                : schema.required(commonValidationMessages.required);
                        }),
                    }).test(
                        function (value: any) {
                            if (DebunkeringObject && DebunkeringObject[0]?.id) {
                                if (fuelBatches && value.quantity_debunkered > (fuelBatches.filter((item: any) =>
                                    item.id === value?.bunkering)[0]?.quantity_remaining)) {
                                    return new Yup.ValidationError(
                                        `Debunkering should be less than or equal to batch ROB`,
                                        undefined,
                                        'debunkering_quantity');
                                }
                            }
                            return true
                        }
                    )
                )
            })
        }

        const responseArray: any = [];
        /** PUT request for Debunkering */
        const putDebunkering = (value: any) => {
            const res = apiGlobal.put(`/debunkering_details/${value.id}/`, value)
            responseArray.push(res);
            handleResponse();
        }

        /** POST request for Debunkering */
        const postDebunkering = (values: any) => {
            const res = apiGlobal.post(`/debunkering_details/`, values)
            responseArray.push(res);
            handleResponse();
        }

        /** Post submit actions based on response */
        const handleResponse = () => {
            queryClient.invalidateQueries(queryKeyes.vessel.Bunkering.key);
            queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
            queryClient.invalidateQueries(queryKeyes.vessel.DebunkeringObject.key);
            if (env?.form_validation === true) {
                toggleDynamicTabs(previousTabIndex + 1);
            }
        }

        /** Debunkering submit function */
        const debunkeringSubmit = async (values: any, actions: any) => {
            /** Assign default values to port & date of debunkering if not selected */
            values.debunkering.map((debunker: any) => {
                debunker.port_of_debunkering = port;
                debunker.other_port = otherPortId;
                debunker.other_port_name = otherPortName;
                debunker.other_country = otherCountry;
                if (debunker.date_of_debunkering === 0) {
                    debunker.date_of_debunkering = null;
                }
                if (port > 0) {
                    debunker.port_of_debunkering = port;
                }
                return '';
            })
            /** Debunkering submit */
            if (DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0) {
                values.debunkering.forEach((debunker: any) => {
                    putDebunkering(debunker);
                })
            } else {
                postDebunkering(values.debunkering);
            }
            /** Total quantity debunkered w.r.t. fuel type */
            let totalDebunkering = values.debunkering.reduce(
                (acc: any, curr: any) => {
                    const { fuel_type_name, quantity_debunkered } = curr;
                    const quantity = parseFloat(quantity_debunkered);
                    if (!isNaN(quantity)) {
                        acc[fuel_type_name] = (acc[fuel_type_name] || 0) + quantity;
                        return acc;
                    }
                    return '';
                },
                {}
            );
            /** Update Bunkering */
            values.debunkering.forEach((debunker: any) => {
                let batch = fuelBatches?.filter((item: any) => item.id === debunker?.bunkering)[0];
                batch.quantity_remaining = batch.previous_quantity_remaining - debunker.quantity_debunkered;
                const res = apiGlobal.put(`bunkering/${debunker?.bunkering}/`, batch)
                responseArray.push(res);
                handleResponse();
            })
            /** Update fuel oil ROB */
            fuelTypeROB.map((type: any, index: number) => {
                type.debunkered_quantity = totalDebunkering[type.vessel_fuel].toFixed(2);
                const bunker: any = BunkeringObject.filter((item: any) => item.fuel_type_name === type.vessel_fuel)[0]?.quantity_bunkered ?? 0;
                type.rob = (((type.previous_rob - type.total_fuel_consumed) + parseFloat(bunker)) - totalDebunkering[type.vessel_fuel]).toFixed(2);
                const res = apiGlobal.put(`/fo_rob/${type.id}/`, fuelTypeROB[index])
                responseArray.push(res);
                handleResponse();
                return '';
            });
            const response = await handleServerResponse(responseArray);
            if (response) {
                handleResponse();
            }
            actions.setSubmitting(false);
        }
        return (
            <Card className='p-0 mb-0 border-0'>
                <CardHeader className='p-2'>
                    <Row>
                        <Col>
                            <h4 className="page_title mb-0">Fuel Debunkering</h4>
                            <p className="card-title-desc pos-start">All readings since last report & as per BDN</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </CardHeader>
                {(fuelTypesLoading ||
                    fuelTypeROBLoading ||
                    fuelBatchesLoading ||
                    DebunkeringObjectLoading ||
                    BunkeringObjectLoading ||
                    OtherPortsLoading) && <Loading message='Loading required data!' />}
                {(fuelTypeROBError ||
                    fuelTypesError ||
                    fuelBatchesError) && <ErrorComponent message='Unable to load required data!' />}
                {DebunkeringObjectError && getintialValueDebunkuring()}
                {!(fuelTypesLoading ||
                    fuelTypeROBLoading ||
                    fuelBatchesLoading ||
                    DebunkeringObjectLoading ||
                    BunkeringObjectLoading ||
                    OtherPortsLoading) &&
                    !(fuelTypeROBError ||
                        fuelTypesError ||
                        fuelBatchesError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(true);
                            debunkeringSubmit(values, actions);
                        }}
                        initialValues={DebunkeringFormik.initialValues}
                        validationSchema={DebunkeringFormik.validationSchema}
                    >
                        {({ values, errors, handleSubmit, handleChange, setErrors, touched, handleBlur, isSubmitting }:
                            { values: any, errors: any, handleSubmit: any, handleChange: any, setErrors: any, touched: any, handleBlur: any, isSubmitting: any }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                <CardBody className='px-2 py-0'>
                                    {fuelTypes && fuelTypes?.length > 0 &&
                                        <>
                                            <Row className='mb-2'>
                                                <Col lg={4}>
                                                    <Label className='asteric mb-0' for='port_of_debunkering'>Port of debunkering</Label>
                                                    <Field name={`debunkering.0.port_of_bunkering`}>
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={`debunkering.0.port_of_debunkering`}
                                                                inputId='port_of_debunkering'
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchPorts(e)}
                                                                getOptionLabel={e => e.port_name}
                                                                getOptionValue={e => e.id}
                                                                onBlur={() => form.setFieldTouched(field.name, true)}
                                                                onChange={(e: any) => {
                                                                    setPort(e.id);
                                                                    values?.debunkering?.forEach((debunker: any) => {
                                                                        debunker.port_of_debunkering = e?.id;
                                                                    })
                                                                    if (e?.precedence_id === PortConstant.OTHER && addOtherPort === false) {
                                                                        setOtherPort(true);
                                                                    } else if (e?.precedence_id !== PortConstant.OTHER) {
                                                                        setOtherPort(false);
                                                                        setAddOtherPort(false);
                                                                        setOtherPortId(null);
                                                                        setOtherPortName(null);
                                                                        setOtherCountry(null);
                                                                        if (values?.debunkering && values?.debunkering?.length > 0) {
                                                                            values?.debunkering?.forEach((bunker: any, index: any) => {
                                                                                form.setFieldValue(`debunkering.${index}.other_port`, null);
                                                                                form.setFieldValue(`debunkering.${index}.other_port_name`, null);
                                                                                form.setFieldValue(`debunkering.${index}.other_country`, null);
                                                                            })
                                                                        }
                                                                    }
                                                                }}
                                                                menuPortalTarget={document.body}
                                                                defaultValue={(DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0) ?
                                                                    {
                                                                        id: DebunkeringObject[0].port_of_debunkering,
                                                                        port_name: DebunkeringObject[0].port_of_debunkering_name
                                                                    } : null
                                                                }
                                                                noOptionsMessage={(e: any) => {
                                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                                        return 'Please select the Other option and enter the port name in the textbox provided'
                                                                    }
                                                                    return 'Please enter the first 3 characters of port name';
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors?.debunkering && touched?.debunkering && touched?.debunkering[0]?.port_of_debunkering &&
                                                        errors?.debunkering[0]?.port_of_debunkering && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`port_of_debunkering`}
                                                            message={errors?.debunkering[0]?.port_of_debunkering}
                                                            open={errors?.debunkering[0]?.port_of_debunkering ? true : false}
                                                        />
                                                    }
                                                </Col>
                                                {otherPort === true && OtherPortsObject &&
                                                    <Col lg={4}>
                                                        <Label className="mb-0 asteric" for='other_port_name_dropdown'>Other port</Label>
                                                        <Field name="debunkering.0.other_port">
                                                            {({ field, form }: FieldProps) => (
                                                                <AsyncSelect
                                                                    name={field.name}
                                                                    inputId='other_port_name_dropdown'
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    loadOptions={() => loadOtherPorts()}
                                                                    getOptionLabel={(e: any) => e.port_name}
                                                                    getOptionValue={(e: any) => e.id}
                                                                    onBlur={() => form.setFieldTouched(field.name, true)}
                                                                    onChange={(selectedOption: any) => {
                                                                        values?.debunkering?.forEach((debunker: any, index: any) => {
                                                                            form.setFieldValue(`debunkering.${index}.other_port`, selectedOption.id);
                                                                            form.setFieldValue(`debunkering.${index}.other_port_name`, selectedOption?.port_name);
                                                                            form.setFieldValue(`debunkering.${index}.other_country`, selectedOption?.country);
                                                                        })
                                                                        setOtherPortId(selectedOption?.id);
                                                                        setOtherPortName(selectedOption?.port_name);
                                                                        setOtherCountry(selectedOption?.country)
                                                                    }}
                                                                    defaultValue={DebunkeringObject && DebunkeringObject[0]?.id &&
                                                                    {
                                                                        id: DebunkeringObject[0]?.other_port,
                                                                        port_name: DebunkeringObject[0]?.other_port_name
                                                                    }}
                                                                    menuPortalTarget={document.body}
                                                                    styles={customStyle}
                                                                    noOptionsMessage={(e: any) => {
                                                                        if (e?.inputValue?.toString()?.length > 2) {
                                                                            return 'Please select the Other option and enter the port name in the textbox provided'
                                                                        }
                                                                        return 'Please enter the first 3 characters of port name';
                                                                    }}
                                                                />)}
                                                        </Field>
                                                        <p className='link_color_blue pointer' onClick={() => {
                                                            setAddOtherPort(true);
                                                            setOtherPort(false);
                                                            values?.debunkering?.forEach((debunker: any, index: any) => {
                                                                values.debunkering[index].other_port = null;
                                                                values.debunkering[index].other_port_name = null;
                                                                values.debunkering[index].other_country = null;
                                                            })
                                                        }}>Click here to add new port</p>
                                                    </Col>
                                                }
                                                {(addOtherPort === true || !OtherPortsObject) &&
                                                    <>
                                                        <Col lg={4}>
                                                            <Label className='mb-0 asteric'>Other port name</Label>
                                                            <Field name={`debunkering.0.other_port_name`}>
                                                                {({ form }: FieldProps) => (
                                                                    <Input
                                                                        type='text'
                                                                        name={`debunkering.0.other_port_name`}
                                                                        id='other_port_name'
                                                                        className='form-control'
                                                                        onBlur={(e: any) => {
                                                                            setOtherPortName(e.target.value);
                                                                            if (values?.debunkering && values?.debunkering?.length > 0) {
                                                                                values?.debunkering?.forEach((debunker: any, index: any) => {
                                                                                    form.setFieldValue(`debunkering.${index}.other_port_name`, e.target.value);
                                                                                })
                                                                            }
                                                                        }}
                                                                        defaultValue={values?.debunkering[0]?.other_port_name}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.debunkering && touched?.debunkering && touched?.debunkering[0]?.other_port_name &&
                                                                errors?.debunkering[0]?.other_port_name && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target={`other_port_name`}
                                                                    message={errors?.debunkering[0]?.other_port_name}
                                                                    open={(errors && errors?.debunkering[0]?.other_port_name) ? true : false}
                                                                />
                                                            }
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label className='mb-0 asteric'>Other country name</Label>
                                                            <Field name={`debunkering.0.other_country`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        id={'other_country'}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={(e: any) => searchCountries(e)}
                                                                        getOptionLabel={(e: any) => e.country_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        onBlur={() => {
                                                                            form.setFieldTouched(field.name, true);
                                                                        }}
                                                                        onChange={(e: any) => {
                                                                            setOtherCountry(e?.id);
                                                                            values?.debunkering?.forEach((debunker: any, index: any) => {
                                                                                form.setFieldValue(`debunkering.${index}.other_country`, e?.id);
                                                                            })
                                                                        }}
                                                                        defaultValue={DebunkeringObject && DebunkeringObject[0]?.id > 0 && {
                                                                            id: values?.other_country,
                                                                            country_name: values?.other_country_name
                                                                        }}
                                                                        className="select-height"
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        noOptionsMessage={() => {
                                                                            return 'Please enter the first 3 characters of country name';
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.debunkering && touched?.debunkering && touched?.debunkering[0]?.other_country &&
                                                                errors?.debunkering[0]?.other_country && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target={`other_country`}
                                                                    message={errors?.debunkering[0]?.other_country}
                                                                    open={(errors && errors?.debunkering[0]?.other_country) ? true : false}
                                                                />
                                                            }
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                            <div className="table-responsive">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="p-2 align middle">#</th>
                                                            <th className='p-2 align-middle'>Fuel</th>
                                                            <th className='p-2 align-middle'>Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <>
                                                            {fuelTypes && fuelTypes?.filter((item: any) => item.precedence_id !== FuelTypes.LNG_CARGO)?.map((fuelType: any, index: number) => (
                                                                <React.Fragment key={fuelType.id}>
                                                                    <tr key={index}>
                                                                        <td >{index + 1}</td>
                                                                        <td className=''>{fuelType.fuel_type_name}</td>
                                                                        <td className='align-middle p-0' colSpan={4}>
                                                                            <FieldArray name='debunkering'>
                                                                                {() => (
                                                                                    <table style={{ 'width': '100%' }} className='table-bordered m-0 p-0'>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '50%' }} className='p-2 align-middle'>
                                                                                                    Fuel batches
                                                                                                </th>
                                                                                                <th className='p-2 align-middle text-center asteric'>Quantity debunkered</th>
                                                                                                <th className='p-2 align-middle text-center'>ROB</th>
                                                                                                <th className='p-2 align-middle text-center asteric'>Date of debunkering</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {values?.debunkering.filter((item: any) => item.fuel_type_name === fuelType.fuel_type)
                                                                                                .map((batch: any) => {
                                                                                                    return (
                                                                                                        <tr>
                                                                                                            <td className='p-2 align-middle'>
                                                                                                                {
                                                                                                                    DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0 ?
                                                                                                                        batch.supply_name : batch.batch_name
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td className='p-2 text-center align-middle'>
                                                                                                                <div className='d-inline-block'>
                                                                                                                    <div className="input-group">
                                                                                                                        <Field name={`debunkering.${values?.debunkering.indexOf(batch)}.quantity_debunkered`}>
                                                                                                                            {({ field, form }: FieldProps) => (
                                                                                                                                <Input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control text-right max-width-7"
                                                                                                                                    id={`quantity_debunkered_${values?.debunkering.indexOf(batch)}`}
                                                                                                                                    name={field.name}
                                                                                                                                    onBlur={handleBlur}
                                                                                                                                    onChange={(e: any) => {
                                                                                                                                        handleChange(e);
                                                                                                                                        form.setFieldValue(`debunkering.${values?.debunkering.indexOf(batch)}.port_of_debunkering`, port);
                                                                                                                                    }}
                                                                                                                                    defaultValue={values?.debunkering[values?.debunkering.indexOf(batch)].quantity_debunkered}
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        </Field>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {errors?.debunkering && touched?.debunkering && touched?.debunkering[(values?.debunkering.indexOf(batch))]?.quantity_debunkered &&
                                                                                                                    errors?.debunkering[(values?.debunkering.indexOf(batch))]?.quantity_debunkered && env?.form_validation === true &&
                                                                                                                    <ErrorTooltip
                                                                                                                        target={`quantity_debunkered_${values?.debunkering.indexOf(batch)}`}
                                                                                                                        message={errors?.debunkering[(values?.debunkering.indexOf(batch))]?.quantity_debunkered}
                                                                                                                        open={(errors?.debunkering && errors?.debunkering[(values?.debunkering.indexOf(batch))]?.quantity_debunkered) ? true : false}
                                                                                                                    />
                                                                                                                }
                                                                                                                {errors?.debunkering_quantity && touched?.debunkering && touched?.debunkering[(values?.debunkering.indexOf(batch))]?.quantity_debunkered &&
                                                                                                                    env?.form_validation === true &&
                                                                                                                    <ErrorTooltip
                                                                                                                        target={`quantity_debunkered_${values?.debunkering.indexOf(batch)}`}
                                                                                                                        message={errors?.debunkering_quantity}
                                                                                                                        open={errors?.debunkering_quantity ? true : false}
                                                                                                                    />

                                                                                                                }
                                                                                                            </td>
                                                                                                            <td className='p-2 align-middle'>
                                                                                                                <div className={`p-2 text-center 
                                                                                                                ${DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0 ?
                                                                                                                        (batch.quantity_remaining - values?.debunkering[values?.debunkering.indexOf(batch)].quantity_debunkered < 0 ?
                                                                                                                            'label-red' : '') :
                                                                                                                        (batch.rob - values?.debunkering[values?.debunkering.indexOf(batch)].quantity_debunkered < 0 ?
                                                                                                                            'label-red' : '')}`}>
                                                                                                                    <b className='m-0'>{
                                                                                                                        DebunkeringObject?.length > 0 && DebunkeringObject[0].id > 0 ?
                                                                                                                            (batch.quantity_remaining - values?.debunkering[values?.debunkering.indexOf(batch)].quantity_debunkered)?.toFixed(2) :
                                                                                                                            (batch.rob - values?.debunkering[values?.debunkering.indexOf(batch)].quantity_debunkered)?.toFixed(2)
                                                                                                                    }
                                                                                                                    </b>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className='p-2 text-center align-middle'>
                                                                                                                <div className='d-inline-block'>
                                                                                                                    <Field name={`debunkering.${values?.debunkering.indexOf(batch)}.date_of_debunkering`}>
                                                                                                                        {({ field }: FieldProps) => (
                                                                                                                            <input
                                                                                                                                type="date"
                                                                                                                                name={field.name}
                                                                                                                                id={`date_of_debunkering_${values?.debunkering.indexOf(batch)}`}
                                                                                                                                min={dateTimeFormat(lastRecord?.reporting_time_utc, true)}
                                                                                                                                max={dateTimeFormat(record?.reporting_time_utc, true)}
                                                                                                                                onBlur={handleBlur}
                                                                                                                                onChange={(e: any) => handleChange(e)}
                                                                                                                                className='datetimepicker text-uppercase'
                                                                                                                                defaultValue={
                                                                                                                                    values?.debunkering[values?.debunkering.indexOf(batch)].date_of_debunkering
                                                                                                                                }
                                                                                                                            />
                                                                                                                        )}
                                                                                                                    </Field>
                                                                                                                </div>
                                                                                                                {errors?.debunkering && touched?.debunkering && touched?.debunkering[values?.debunkering.indexOf(batch)]?.date_of_debunkering &&
                                                                                                                    errors?.debunkering[values?.debunkering.indexOf(batch)]?.date_of_debunkering && env?.form_validation === true &&
                                                                                                                    <ErrorTooltip
                                                                                                                        target={`date_of_debunkering_${values?.debunkering.indexOf(batch)}`}
                                                                                                                        message={errors?.debunkering[values?.debunkering.indexOf(batch)]?.date_of_debunkering}
                                                                                                                        open={(errors?.debunkering && errors?.debunkering[values?.debunkering.indexOf(batch)]?.date_of_debunkering) ? true : false}
                                                                                                                    />
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                )}
                                                                            </FieldArray>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                            )}
                                                        </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </CardBody>
                                <CardFooter className='p-2 py-3'>
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleDynamicTabs(previousTabIndex + 1);
                                                }
                                            }}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                Next <i className="bx bx-chevron-right ms-1" /></Button>
                                            {tabsIdList[0] === tabsList
                                                .filter((item: any) => item.tab === 'Fuel Debunkering')[0]?.id &&
                                                (record && record.operation_precedence_id === 1) ?
                                                <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                    setErrors({});
                                                    toggleTab(tabsList[0]?.id - 1);
                                                }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                                tabsIdList[0] === tabsList
                                                    .filter((item: any) => item.tab === 'Fuel Debunkering')[0]?.id &&
                                                    (record && record.operation_precedence_id !== 1) ?
                                                    <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                        setErrors({});
                                                        toggleTab(tabsList[0]?.id - 3);
                                                    }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                                    <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                        setErrors({});
                                                        toggleDynamicTabs(previousTabIndex - 1);
                                                    }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                            }
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug values={[values, errors, touched]} />
                            </Form >
                        )}
                    </Formik >
                }
            </Card>
        )
    }

export default DebunkeringComponent