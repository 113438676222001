import ToolTip from "Components/ToolTip";
import React, { useEffect, useState } from "react";
import { Label, Col, Button, Row } from "reactstrap";
import apiGlobal from "global/api.global";
import { RootState } from "index";
import { useSelector } from "react-redux";
import VoyageAdjustmentPopup from "./VoyageAdjustmentPopup";
import PopOver from "Components/PopOver";
import { successToast, errorToast } from "Components/Toasts";
import { errResponse } from "GenericForms/Helper";
import { AdjustmentApprovalStatus, Roles } from "shared/constants";
import { hasRole } from "utils/auth/authUtils";
import VoyageAdjustmentTableHeadComponent from "./VoyageAdjustmentTableHeadComponent";
import AdjustmentPaginationComponent from "Adjustments/AdjustmentPaginationComponent";
import EditIcon from '../../Media/ED2.png';

const ApprovedVoyageAdjustment = ({ active }: any) => {
    /** State variables start */
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        ciiAdjustment: [],
    });
    const [count, setCount] = useState(0);
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [eventState, setEventState] = useState<any>({});
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [ciiAdjustment, setCIIAdjustment] = useState<any>({});
    const [pendingBool, setPendingBool] = useState<any>({});
    const [hoverId, setHoverId] = useState<string | null>(null);
    /** State varibles end */

    /** Load approved CII adjustment events of the selected vessels */
    const loadData = async () => {
        await apiGlobal
            .get(`vessel_reporting_cii_adjustment/get_cii_adjustment_by_vessel/?vessel_id=${VesselID}`).then((response) => {
                setTableDetails((prevDetails) => ({
                    ...prevDetails,
                    ciiAdjustment: response.data.filter(
                        (item: any) => item.user_approved === AdjustmentApprovalStatus.APPROVED
                    ),
                }));
                setCount((count) => count + 1);
            })
            .catch(() => {
                setTableDetails((prevDetails) => ({
                    ...prevDetails,
                    ciiAdjustment: [],
                }));
            });
    };

    /** useEffect start */
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselID, active]);
    /** useEffect end */

    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Function to move adjustment in pending tab */
    const pendingVoyageAdjustment = () => {
        ciiAdjustment.user_approved = AdjustmentApprovalStatus.PENDING;
        apiGlobal.put(`/vessel_reporting_cii_adjustment/${ciiAdjustment?.id}/`, ciiAdjustment).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                loadData();
                setPendingBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }
    return (
        <>
            {tableDetails?.ciiAdjustment?.length === 0 ? (
                <div className="text-center">
                    <Label className='mb-3'>No CII adjustment event files are pending for uploading</Label>
                </div>
            ) : (
                <>
                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <VoyageAdjustmentTableHeadComponent />
                                    <tbody>
                                        {tableDetails && tableDetails?.ciiAdjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((cii: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            <>
                                                                <b>{cii?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {cii?.cii_begin_date_time?.toString().substring(11, 16)}</b> to
                                                                <b> {cii?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {cii?.cii_end_date_time?.toString().substring(11, 16)}</b>
                                                            </>
                                                        </td>
                                                        <td className='nopadtop align-middle p-2'>{cii?.cii_adjustment_name}</td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <>
                                                                <td className='nopadtop d-flex flex-warp p-2 gap-2 justify-content-center align-items-center'>
                                                                    <img
                                                                        src={EditIcon}
                                                                        alt="Edit Icon"
                                                                        height="30"
                                                                        className='pointer'
                                                                        id={`edit-${index}`}
                                                                        onMouseEnter={() => setHoverId(`edit-${index}`)}
                                                                        onMouseLeave={() => setHoverId(null)}
                                                                        onClick={() => {
                                                                            setAdjustmentPopup(true);
                                                                            setEventState(cii);
                                                                            setHoverId(null);
                                                                        }}
                                                                    />
                                                                    <Button type='button'
                                                                        className='btn btn-primary waves-effect btn-label waves-light'
                                                                        color="primary"
                                                                        id={`disapprove_btn${cii?.id}`}
                                                                        onClick={() => {
                                                                            setCIIAdjustment(cii);
                                                                            setPendingBool(true);
                                                                        }}
                                                                    ><i className="bx bx-left-arrow-circle label-icon"></i>
                                                                        Mark as pending
                                                                    </Button>
                                                                </td>                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            }
                                            )
                                        }
                                        {hoverId !== null &&
                                            <ToolTip
                                                target={hoverId}
                                                message={`Edit Voyage Adjustment`}
                                                isOpen={hoverId !== null}
                                            />
                                        }
                                        {pendingBool === true &&
                                            <PopOver
                                                target={`disapprove_btn${ciiAdjustment?.id}`}
                                                handleClick={pendingVoyageAdjustment}
                                                message={'Are you sure you want to move this adjustment to pending section?'}
                                                state={pendingBool}
                                                setState={setPendingBool}
                                            />
                                        }
                                        {
                                            adjustmentPopup === true &&
                                            <VoyageAdjustmentPopup
                                                state={adjustmentPopup}
                                                setState={setAdjustmentPopup}
                                                toggle={tog_backdrop}
                                                event={eventState}
                                                target={`popup${eventState?.id}`}
                                                root="approved"
                                            />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {tableDetails?.ciiAdjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            )}
        </>
    );
};

export default ApprovedVoyageAdjustment;
