import ToolTip from 'Components/ToolTip';
import React, { useEffect, useRef, useState } from 'react'
import { Label, Col, Button, Row, Modal } from 'reactstrap';
import apiGlobal, { apiMedia } from 'global/api.global';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import LNGCargoQuality from 'Components/LNGCargoQuality';
import PopUp from 'Components/PopUp';
import { AdjustmentApprovalStatus, CTMSType, FileStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import ReportingFileUploadModal from 'Components/ReportingFileUploadModal';
import AdjustmentPaginationComponent from 'Adjustments/AdjustmentPaginationComponent';
import ValidateIcon from '../../Media/VLD1.png'
import UploadIcon from '../../Media/UP.png'
import { errorToast, successToast } from 'Components/Toasts';

const PendingN2Adjustment = ({
    active
}: any) => {
    /** State variables start */
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        n2Adjustment: []
    })
    const [count, setCount] = useState(0);
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [n2adjustment, setN2Adjustment] = useState<any>({});
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [loadingPopUpBool, setLoadingPopUpBool] = useState(false);
    const [dischargingPopUpBool, setDischargingPopUpBool] = useState(false);
    const [loadingFile, setLoadingFile] = useState<Array<any>>([{}]);
    const [dischargingFile, setDischargingFile] = useState<Array<any>>([{}]);
    const [loadingFileBool, setLoadingFileBool] = useState(false);
    const [dischargingFileBool, setDischargingFileBool] = useState(false);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const formSubmitRef = useRef<any>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    /** State varibles end */

    /** Load N2 corrections of selected vessel pending for user approval */
    const loadData = async () => {
        await apiGlobal.get(`n2_adjustment/get_n2_adjustment_by_vessel/?vessel_id=${VesselID}`).then(response => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                n2Adjustment: response.data.filter((item: any) => item.approval_status === AdjustmentApprovalStatus.PENDING)
            }));
            setCount(count => count + 1);
        }).catch(() => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                n2Adjustment: []
            }));
        })
    };

    /** useEffect start */
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselID, active, refreshKey])

    useEffect(() => {
        setLoadingFileBool(false);
        setDischargingFileBool(false);
    }, [refreshKey])
    /** useEffect end */

    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const saveLoadingCTMSFile = () => {
        const formData = new FormData();
        n2adjustment.ctms_start_file = loadingFile[0].file;
        formData.append('ctms_start_file', loadingFile[0].file);
        apiMedia.patch(`/n2_adjustment/${n2adjustment.id}/`, formData).then(res => {
            if (res.status === 200) {
                loadData();
                setLoadingFileBool(false);
                successToast("Data saved successfully!");
                setLoadingFile([{}])
            }
        }).catch(() => {
            errorToast("Internal error occured, please contact the admin");
        });
    }

    const saveDischargingCTMSFile = () => {
        const formData = new FormData();
        n2adjustment.ctms_end_file = dischargingFile[0].file;
        formData.append('ctms_end_file', dischargingFile[0].file);
        apiMedia.patch(`/n2_adjustment/${n2adjustment.id}/`, formData).then(res => {
            if (res.status === 200) {
                loadData();
                setDischargingFileBool(false);
                successToast("Data saved successfully!");
                setDischargingFile([{}])
            }
        }).catch(() => {
            errorToast("Internal error occured, please contact the admin");
        });
    }

    return (
        <>
            {tableDetails?.n2Adjustment?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-3'>No N<sub>2</sub> corrections available for this vessel</Label>
                </div> :
                <>
                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <thead className="table-light">
                                        <tr>
                                            <th className='align-middle text-center p-2 sr-no-width'>#</th>
                                            <th className='align-middle p-2' style={{ width: '25%' }}>Duration</th>
                                            <th className='align-middle text-center p-2'>Loading CTMS</th>
                                            <th className='align-middle text-center p-2'>Loading CTMS file</th>
                                            <th className='align-middle text-center p-2'>Discharging CTMS</th>
                                            <th className='align-middle text-center p-2'>Discharging CTMS file</th>
                                            {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                <th className='align-middle text-center p-2'>Actions</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableDetails && tableDetails?.n2Adjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((n2: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className='nopadtop align-middle p-2 align-middle text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2 align-middle'>
                                                            {n2?.end_report !== null ?
                                                                <>
                                                                    <b>{n2?.start_date_time?.toString().substring(0, 10)}&nbsp;
                                                                        {n2?.start_date_time?.toString().substring(11, 16)}</b> to
                                                                    <b> {n2?.end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                        {n2?.end_date_time?.toString().substring(11, 16)}</b>
                                                                </>
                                                                : <p><strong>Currently in progress</strong></p>
                                                            }
                                                        </td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            {n2?.loading_ctms === true ?
                                                                <div className='text-center'>
                                                                    <i className="mdi mdi-check-underline-circle icon_s23" style={{ color: '#5AB834' }}></i>
                                                                </div> :
                                                                <>
                                                                    <span className='mb-0'>Report not yet submitted</span><br />
                                                                    <Label className='link_color_blue mb-0'
                                                                        onClick={() => setLoadingPopUpBool(true)}>
                                                                        Click here to fill the report
                                                                    </Label>
                                                                    <PopUp
                                                                        state={loadingPopUpBool}
                                                                        setState={setLoadingPopUpBool}
                                                                        body={
                                                                            <LNGCargoQuality
                                                                                VesselID={VesselID}
                                                                                cargoOperation={'loading'}
                                                                                cardHeader={'LNG Cargo Loading Quality Details'}
                                                                                setState={setLoadingPopUpBool}
                                                                                reportId={n2?.start_report}
                                                                                form={"singleN2Adjustment"}
                                                                                adjustmentDetails={n2}
                                                                            />
                                                                        }
                                                                        title={'Loading CTMS Report'}
                                                                        size='md'
                                                                    />
                                                                </>
                                                            }
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            {n2?.ctms_start_file_status === FileStatus.PENDING ?
                                                                <img
                                                                    src={UploadIcon}
                                                                    alt="Upload Icon"
                                                                    className='text-centre pointer'
                                                                    height="30"
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setLoadingFileBool(true);
                                                                    }}
                                                                />
                                                                :
                                                                <Label
                                                                    className='link_color_blue pointer'
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setLoadingFileBool(true);
                                                                    }}>View / Replace</Label>
                                                            }
                                                        </td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            {n2?.discharge_ctms === true ?
                                                                <div className='text-center'>
                                                                    <i className="mdi mdi-check-underline-circle icon_s23" style={{ color: '#5AB834' }}></i>
                                                                </div> :
                                                                <div className='text-center'>
                                                                    <Label className='mb-0 align-middle'>Report not yet submitted</Label><br />
                                                                    <Label className='link_color_blue mb-0'
                                                                        onClick={() => setDischargingPopUpBool(true)}>
                                                                        Click here to fill the report
                                                                    </Label>
                                                                    <PopUp
                                                                        state={dischargingPopUpBool}
                                                                        setState={setDischargingPopUpBool}
                                                                        body={
                                                                            <LNGCargoQuality
                                                                                VesselID={VesselID}
                                                                                cargoOperation={'discharging'}
                                                                                cardHeader={'LNG Cargo Discharging Quality Details'}
                                                                                setState={setDischargingPopUpBool}
                                                                                reportId={n2?.end_report}
                                                                                form={"singleN2Adjustment"}
                                                                                adjustmentDetails={n2}
                                                                            />
                                                                        }
                                                                        title={'Discharging CTMS Report'}
                                                                        size='md'
                                                                    />
                                                                </div>
                                                            }
                                                        </td>
                                                        <td className='align-middle p-2 text-center'>
                                                            {n2?.ctms_end_file_status === FileStatus.PENDING ?
                                                                <img
                                                                    src={UploadIcon}
                                                                    alt="Upload Icon"
                                                                    className='text-centre pointer'
                                                                    height="30"
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setDischargingFileBool(true);
                                                                    }}
                                                                />
                                                                :
                                                                <Label
                                                                    className='link_color_blue pointer'
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setDischargingFileBool(true);
                                                                    }}>View / Replace</Label>
                                                            }
                                                        </td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <td className='text-center'>
                                                                <img
                                                                    src={ValidateIcon}
                                                                    alt="Validate Icon"
                                                                    id={`n2_validate${index}`}
                                                                    className='pointer align-center'
                                                                    height="30"
                                                                    onMouseEnter={() => setHoverId(`n2_validate${index}`)}
                                                                    onMouseLeave={() => setHoverId(null)}
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setAdjustmentPopup(true);
                                                                        setHoverId(null);
                                                                    }}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }{hoverId !== null &&
                                            <ToolTip
                                                target={hoverId}
                                                message={`Validate N2 Adjustment`}
                                                isOpen={hoverId !== null}
                                            />
                                        }
                                        {loadingFileBool === true &&
                                            <ReportingFileUploadModal
                                                state={loadingFileBool}
                                                setState={setLoadingFileBool}
                                                array={loadingFile}
                                                setArray={setLoadingFile}
                                                modalId={`loading-file-${n2adjustment?.id}`}
                                                title='Upload Loading CTMS file'
                                                size='lg'
                                                save={saveLoadingCTMSFile}
                                                n2={n2adjustment}
                                                refreshKey={refreshKey}
                                                setRefreshKey={setRefreshKey}
                                                CTMSFileType={CTMSType.LOADING}
                                            />
                                        }
                                        {dischargingFileBool === true &&
                                            <ReportingFileUploadModal
                                                state={dischargingFileBool}
                                                setState={setDischargingFileBool}
                                                array={dischargingFile}
                                                setArray={setDischargingFile}
                                                modalId={`discharging-file-${n2adjustment?.id}`}
                                                title='Upload Discharging CTMS file'
                                                size='lg'
                                                save={saveDischargingCTMSFile}
                                                n2={n2adjustment}
                                                refreshKey={refreshKey}
                                                setRefreshKey={setRefreshKey}
                                                CTMSFileType={CTMSType.DISCHARGING}
                                            />
                                        }
                                        {adjustmentPopup === true &&
                                            <Modal
                                                size='xl'
                                                isOpen={adjustmentPopup}
                                                toggle={() => {
                                                    tog_backdrop();
                                                }}
                                                backdrop={"static"}
                                                id="staticBackdrop"
                                            >
                                                <div className="modal-header p-1 d-flex align-items-center">
                                                    <h5 className="modal-title mb-0" id="staticBackdropLabel">
                                                        LNG Cargo Quality Details
                                                    </h5>
                                                    <button
                                                        onClick={() => {
                                                            setAdjustmentPopup(false);
                                                        }}
                                                        type="button"
                                                        className="close pb-1"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <Row>
                                                        <Col lg={6}>
                                                            <LNGCargoQuality
                                                                VesselID={VesselID}
                                                                reportId={n2adjustment?.start_report}
                                                                cargoOperation={'loading'}
                                                                cardHeader={'LNG Cargo Loading Quality Details'}
                                                                form="n2Adjustment"
                                                                adjustmentDetails={n2adjustment}
                                                                ref={formSubmitRef}
                                                                setState={setAdjustmentPopup}
                                                                tableRefresh={loadData}
                                                            />
                                                        </Col>
                                                        <Col lg={6}>
                                                            <LNGCargoQuality
                                                                VesselID={VesselID}
                                                                reportId={n2adjustment?.end_report}
                                                                cargoOperation={'discharging'}
                                                                cardHeader={'LNG Cargo Discharging Quality Details'}
                                                                form="n2Adjustment"
                                                                adjustmentDetails={n2adjustment}
                                                                ref={formSubmitRef}
                                                                setState={setAdjustmentPopup}
                                                                tableRefresh={loadData}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={{ size: 8, offset: 4 }}>
                                                            <Button type='button' className='btn pos-end-14' color='danger' onClick={() => {
                                                                if (formSubmitRef.current) {
                                                                    formSubmitRef.current.submitForm(AdjustmentApprovalStatus.DISAPPROVED);
                                                                }
                                                            }}>Disapprove</Button>
                                                            <Button type='button' className='btn pos-end-10' color='primary' onClick={() => {
                                                                if (formSubmitRef.current) {
                                                                    formSubmitRef.current.submitForm(AdjustmentApprovalStatus.PENDING);
                                                                }
                                                            }}>Save</Button>
                                                            <Button type='button' className='btn justify_right' color='primary' onClick={() => {
                                                                if (formSubmitRef.current) {
                                                                    formSubmitRef.current.submitForm(AdjustmentApprovalStatus.APPROVED);
                                                                }
                                                            }}>Save & Approve</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Modal>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row >
                    {tableDetails?.n2Adjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            }
        </>
    )
}

export default PendingN2Adjustment