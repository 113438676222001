import {
    loadLNGCargoQualityObject,
    loadLNGElements,
} from "VesselMaster/vesselMaster.hooks";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import Loading from "./Loading";
import ErrorComponent from "./ErrorComponent";
import { Field, FieldArray, Formik } from "formik";
import apiGlobal from "global/api.global";
import { Card, CardBody, Col, Label, Row, Form, Button } from "reactstrap";
import { errorToast, successToast } from "./Toasts";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import * as Yup from "yup";
import { errResponse } from "GenericForms/Helper";
import {
    UpdateDischargingCTMS,
    UpdateLoadingCTMS,
} from "ReportingWizard/DeckOfficerReport/ReportComponents/DraftsCargoLadingComponent";
import { AdjustmentApprovalStatus } from "shared/constants";

interface LNGCargoQualityType {
    VesselID: number;
    cargoOperation: string;
    cardHeader: string;
    reportId?: number;
    setState?: any;
    form?: string;
    adjustmentDetails?: any;
    ref?: any;
    tableRefresh?: any;
}

const LNGCargoQuality = forwardRef(
    (
        {
            VesselID,
            cargoOperation,
            cardHeader,
            reportId,
            setState,
            form,
            adjustmentDetails,
            tableRefresh,
        }: LNGCargoQualityType,
        ref
    ) => {
        /** State variables */
        const [approvalStatus, setApprovalStatus] = useState("");
        const childRef = React.useRef<any>(null);
        useImperativeHandle(ref, () => ({
            submitForm: (status: string) => {
                setApprovalStatus(status);
                childRef.current.handleSubmit(status);
            },
        }));
        const setLoadingCTMS = UpdateLoadingCTMS();
        const setDischargingCTMS = UpdateDischargingCTMS();
        /** State variables end */

        /** Queries */
        /** Load Elements */
        const {
            data: LNGElements,
            isLoading: LNGElementsLoading,
            isError: LNGElementsError,
        } = useQuery(
            [queryKeyes.masters.LNGElements.key],
            async () => {
                return await loadLNGElements();
            },
            { staleTime: Infinity }
        );
        /** LNG Cargo Quality Object used for edit */
        const {
            data: CargoQualityObject,
            isLoading: CargoQualityObjectLoading,
            isError: CargoQualityObjectError,
        } = useQuery(
            [queryKeyes.vessel.LNGCargoQualityObject.key, VesselID, reportId],
            async () => {
                return await loadLNGCargoQualityObject(VesselID, reportId);
            },
            { staleTime: Infinity }
        );
        /** Queries end */

        /** Assign values to initial object of LNG Cargo */
        const getInitialValues = () => {
            let obj: any[] = [];
            let lng_element_name: any[] = [];
            if (CargoQualityObject && CargoQualityObject.length > 0) {
                return CargoQualityObject[0];
            } else if (
                LNGElements !== undefined &&
                obj !== undefined &&
                obj.length <= LNGElements.length
            ) {
                LNGElements.map((element: any) => {
                    lng_element_name.push({
                        molar_fraction: null,
                        lng_element: element.element_name,
                        molecular_weight: element.molecular_weight,
                    });
                    return "";
                });
                obj.push({
                    lng_element_name: lng_element_name,
                    cargo_operation: cargoOperation,
                    density: null,
                    cargo_loaded: null,
                    cargo_discharged: null,
                    report: reportId,
                    vessel: VesselID,
                });
                return obj[0];
            }
        };

        /** useEffect */
        useEffect(() => {
            LNGCargoQualityFormik.initialValues.cargoQuality = getInitialValues();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [LNGElements, CargoQualityObject]);

        /** useEffect end */

        /** LNG Cargo Quality's formik object */
        const LNGCargoQualityFormik = {
            initialValues: {
                cargoQuality: getInitialValues(),
            },
            validationSchema: Yup.object({}).test(
                "molar_fraction_sum",
                "The sum of molar fraction should be exactly 100",
                function (value: any) {
                    let sum = 0;
                    value.cargoQuality.lng_element_name.map(
                        (element: any) => {
                            if (element.molar_fraction === null) {
                                element.molar_fraction = 0;
                            }
                            sum += parseFloat(element.molar_fraction);
                            return "";
                        }
                    );
                    if (sum.toFixed(2) !== "100.00") {
                        return new Yup.ValidationError(
                            `The sum of molar fraction should be exactly 100`,
                            undefined,
                            `cargoQuality.lng_element_name`
                        );
                    }
                    return true;
                }
            ),
        };

        return (
            <>
                {(LNGElementsLoading || CargoQualityObjectLoading) && (
                    <Loading message="Loading required data!" />
                )}
                {LNGElementsError && (
                    <ErrorComponent message="Unable to load required data!" />
                )}
                {CargoQualityObjectError && getInitialValues()}
                {!(LNGElementsLoading || CargoQualityObjectLoading) &&
                    !LNGElementsError && (
                        <Formik
                            innerRef={childRef}
                            onSubmit={(values: any, actions: any) => {
                                actions.setSubmitting(false);
                                const handleResponse = (response: any) => {
                                    if (response.status === 201 || response.status === 200) {
                                        successToast("Data saved successfully!");
                                        setState(false);
                                        if (form === "singleN2Adjustment") {
                                            if (reportId === adjustmentDetails?.start_report) {
                                                adjustmentDetails.loading_ctms = true;
                                            } else if (reportId === adjustmentDetails?.end_report) {
                                                adjustmentDetails.discharge_ctms = true;
                                            }
                                            if (
                                                (adjustmentDetails?.loading_ctms === true &&
                                                    cargoOperation === "discharging") ||
                                                (adjustmentDetails?.discharging_ctms === true &&
                                                    cargoOperation === "loading")
                                            ) {
                                                adjustmentDetails.approval_status =
                                                    AdjustmentApprovalStatus.APPROVED;
                                            }
                                            apiGlobal
                                                .put(
                                                    `/n2_adjustment/${adjustmentDetails?.id}/`,
                                                    adjustmentDetails
                                                )
                                                .then(() => {
                                                    setState(false);
                                                })
                                                .catch((err) => {
                                                    if (errResponse.includes(err?.response?.status)) {
                                                        errorToast(
                                                            "Internal error occured, please contact the admin"
                                                        );
                                                    }
                                                });
                                        }
                                    }
                                };
                                if (form === "n2Adjustment") {
                                    adjustmentDetails.approval_status = approvalStatus;
                                    delete adjustmentDetails.ctms_start_file
                                    delete adjustmentDetails.ctms_end_file
                                    apiGlobal
                                        .patch(
                                            `/n2_adjustment/${adjustmentDetails?.id}/`,
                                            adjustmentDetails,
                                        )
                                        .then((res) => {
                                            handleResponse(res);
                                            tableRefresh();
                                        })
                                        .catch((err) => {
                                            if (errResponse.includes(err?.response?.status)) {
                                                errorToast(
                                                    "Internal error occured, please contact the admin"
                                                );
                                            }
                                        });
                                } else {
                                    if (CargoQualityObject && CargoQualityObject.length > 0) {
                                        apiGlobal
                                            .put(
                                                `/cargo_quality_details/${values?.cargoQuality?.id}/`,
                                                values?.cargoQuality
                                            )
                                            .then((res) => {
                                                handleResponse(res);
                                            })
                                            .catch((err) => {
                                                if (errResponse.includes(err?.response?.status)) {
                                                    errorToast(
                                                        "Internal error occured, please contact the admin"
                                                    );
                                                }
                                            });
                                    } else {
                                        apiGlobal
                                            .post(`/cargo_quality_details/`, values.cargoQuality)
                                            .then((res) => {
                                                handleResponse(res);
                                                if (
                                                    (cargoOperation === "loading") &&
                                                    (res.status === 201 || res.status === 200)
                                                ) {
                                                    setLoadingCTMS(true);
                                                }
                                                if (
                                                    (cargoOperation === "discharging") &&
                                                    (res.status === 201 || res.status === 200)
                                                ) {
                                                    setDischargingCTMS(true);
                                                }
                                            })
                                            .catch((err) => {
                                                if (errResponse.includes(err?.response?.status)) {
                                                    errorToast(
                                                        "Internal error occured, please contact the admin"
                                                    );
                                                }
                                            });
                                    }
                                }
                            }}
                            initialValues={LNGCargoQualityFormik.initialValues}
                            validationSchema={LNGCargoQualityFormik.validationSchema}
                        >
                            {({
                                values,
                                errors,
                                handleSubmit,
                                handleChange,
                            }: {
                                values: any;
                                errors: any;
                                handleSubmit: any;
                                handleChange: any;
                            }) => (
                                <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                    <h6>{cardHeader}</h6>
                                    <Card>
                                        <CardBody className="p-0">
                                            <FieldArray name={`cargoQuality.lng_element_name`}>
                                                {({ push }) => (
                                                    <table className="table mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="p-2">Element name</th>
                                                                <th className="p-2 text-center">
                                                                    Molecular weight
                                                                </th>
                                                                <th className="p-2 text-center">
                                                                    Molar fraction percentage
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values &&
                                                                values?.cargoQuality?.lng_element_name.map(
                                                                    (element: any, indx: any) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td className="p-0 align-middle">
                                                                                        {indx >=
                                                                                            LNGCargoQualityFormik.initialValues
                                                                                                .cargoQuality.lng_element_name
                                                                                                .length ? (
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`cargoQuality.lng_element_name.${indx}.lng_element`}
                                                                                                className="form-control p-1 ps-2 w-75"
                                                                                                onChange={(e: any) =>
                                                                                                    handleChange(e)
                                                                                                }
                                                                                                placeholder="Element name"
                                                                                            ></Field>
                                                                                        ) : (
                                                                                            <Label className="p-1 ps-2 mb-0">
                                                                                                {element.lng_element}
                                                                                            </Label>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="p-0 align-middle d-flex justify-content-center">
                                                                                        {indx >=
                                                                                            LNGCargoQualityFormik.initialValues
                                                                                                .cargoQuality.lng_element_name
                                                                                                .length ? (
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`cargoQuality.lng_element_name.${indx}.molecular_weight`}
                                                                                                className="form-control w-50 p-1 text-right"
                                                                                                value={element.molecular_weight}
                                                                                                placeholder="Weight"
                                                                                            ></Field>
                                                                                        ) : (
                                                                                            <Label className="p-1 mb-0 text-center">
                                                                                                {element.molecular_weight}
                                                                                            </Label>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="p-0 align-middle">
                                                                                        {form === "view-n2Adjustment" ? (
                                                                                            <Label className="p-1 mb-0 text-center pos-center">
                                                                                                {
                                                                                                    values?.cargoQuality
                                                                                                        ?.lng_element_name[indx]
                                                                                                        ?.molar_fraction
                                                                                                }
                                                                                            </Label>
                                                                                        ) : (
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`cargoQuality.lng_element_name.${indx}.molar_fraction`}
                                                                                                className="form-control w-50 p-1 text-right pos-center"
                                                                                                placeholder="Fraction"
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                        </tbody>
                                                        {form !== "view-n2Adjustment" && (
                                                            <tfoot>
                                                                <tr>
                                                                    <td className="px-2 py-1" colSpan={3}>
                                                                        <Label
                                                                            className="link_color_blue mb-0 align-middle"
                                                                            onClick={() =>
                                                                                push({
                                                                                    molar_fraction: null,
                                                                                    lng_element: null,
                                                                                    molecular_weight: null,
                                                                                })
                                                                            }
                                                                        >
                                                                            Add another compound
                                                                        </Label>
                                                                    </td>
                                                                </tr>
                                                                {errors &&
                                                                    errors?.cargoQuality &&
                                                                    errors?.cargoQuality?.lng_element_name && (
                                                                        <tr>
                                                                            <td colSpan={3}>
                                                                                <Label className="error">
                                                                                    {
                                                                                        errors?.cargoQuality
                                                                                            ?.lng_element_name
                                                                                    }
                                                                                </Label>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                            </tfoot>
                                                        )}
                                                    </table>
                                                )}
                                            </FieldArray>
                                            <Row className="px-2 py-1">
                                                <Col lg={6}>
                                                    {form === "view-n2Adjustment" ? (
                                                        <div className="ele_row1">
                                                            <Label className="mb-0 dark_lbl">Density</Label>
                                                            <Label className="p-1 mb-0 text-center pos-center dark_lbl">
                                                                {values?.cargoQuality?.density}
                                                            </Label>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <Label className="mb-0">Density</Label>
                                                            <div className="input-group">
                                                                <Field
                                                                    type="text"
                                                                    name={`cargoQuality.density`}
                                                                    className="form-control text-right"
                                                                ></Field>
                                                                <div className="input-group-text">
                                                                    kg/m<sup>3</sup>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </Col>
                                                {cargoOperation === "loading" ? (
                                                    <Col lg={6}>
                                                        {form === "view-n2Adjustment" ? (
                                                            <div className="ele_row1">
                                                                <Label className="mb-0 dark_lbl">
                                                                    Cargo loaded
                                                                </Label>
                                                                <Label className="p-1 mb-0 text-center pos-center">
                                                                    {values?.cargoQuality?.cargo_loaded}
                                                                </Label>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <Label className="mb-0 dark_lbl">
                                                                    Cargo loaded
                                                                </Label>
                                                                <div className="input-group">
                                                                    <Field
                                                                        type="text"
                                                                        name={`cargoQuality.cargo_loaded`}
                                                                        className="form-control text-right"
                                                                    ></Field>
                                                                    <div className="input-group-text">mt</div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Col>
                                                ) : (
                                                    <Col lg={6}>
                                                        {form === "view-n2Adjustment" ? (
                                                            <div className="ele_row1">
                                                                <Label className="mb-0 dark_lbl">
                                                                    Cargo discharged
                                                                </Label>
                                                                <Label className="p-1 mb-0 text-center pos-center">
                                                                    {values?.cargoQuality?.cargo_discharged}
                                                                </Label>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <Label className="mb-0 dark_lbl">
                                                                    Cargo dicharged
                                                                </Label>
                                                                <div className="input-group">
                                                                    <Field
                                                                        type="text"
                                                                        name={`cargoQuality.cargo_discharged`}
                                                                        className="form-control text-right"
                                                                    ></Field>
                                                                    <div className="input-group-text">mt</div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Col>
                                                )}
                                            </Row>
                                            {form !== "n2Adjustment" && (
                                                <Row className="mt-3">
                                                    <Col lg={{ size: 2, offset: 10 }}>
                                                        <Button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            color="primary"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>
                                    <FormValuesDebug
                                        values={[
                                            values,
                                            errors,
                                            LNGCargoQualityFormik.initialValues,
                                        ]}
                                    />
                                </Form>
                            )}
                        </Formik>
                    )}
            </>
        );
    }
);

export default LNGCargoQuality;
