import React, { useEffect, useState } from 'react'
import { CardBody, Card, Col, Row, Input, Label, Button, Form, CardHeader, CardFooter } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { checkEmptyStringValue, handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { fetchMachinaryOptions, loadAuxillaryEngineSetting, loadEngineTypes, loadMainEngineSettings, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import { FormikProps, useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import * as Yup from "yup";
import { FuelTypes, VesselConfigrationTabs, VesselMachineryConstant } from 'shared/constants';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
import { queryClient } from 'react-query/queryClient';
import AsyncSelect from 'react-select/async';
import { customStyle } from 'shared/CommonCSS';

interface EnginesType {
  refreshVesselMachineries: number;
  setRefreshVesselMachineries: (value: number) => void;
  VesselConfActiveTab: number;
  setCheckValuesBeforeSwitch?: (value: boolean) => void;
  setTabName?: (value: string) => void;
  engineFormRef?: any;
  setFormik?: (value: FormikProps<any>) => void;
  formik?: FormikProps<any>;
}

const Engines = ({
  refreshVesselMachineries,
  setRefreshVesselMachineries,
  VesselConfActiveTab,
  setCheckValuesBeforeSwitch,
  setTabName,
  engineFormRef,
  setFormik,
}: EnginesType) => {
  /** State variables start */
  const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [vesselFuelIds, setVesselFuelIds] = useState<Array<number>>([]);
  const [mainEngineSpinner, setMainEngineSpinner] = useState<boolean>(false);
  const [auxEngineSpinner, setAuxEngineSpinner] = useState<boolean>(false);
  const [spinnerIndex, setSpinnerIndex] = useState<number | null>();
  const [formRefreshKey, setFormRefreshKey] = useState<number>(0);
  /** State variables end */

  /** Queries */
  /** Load main engine setting */
  const {
    data: MainEngine,
    isLoading: MainEngineLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.MainEngineSetting.key, VesselID],
    async () => {
      return await loadMainEngineSettings(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load machinery data*/
  const {
    data: VesselMachinery,
    isLoading: VesselMachineryLoading,
    isError: VesselMachineryError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.VesselMachineries.key, VesselID],
    async () => {
      return await fetchMachinaryOptions(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** load auxillary engine data*/
  const {
    data: AuxillaryEngine,
    isLoading: AuxillaryEngineLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.AuxillaryMachineSetting.key, VesselID],
    async () => {
      return await loadAuxillaryEngineSetting(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load distinct fuel types configured on vessel */
  const {
    data: VesselFuelTypes,
    isLoading: VesselFuelTypesLoading,
  } = useQuery(
    [queryKeyes.vessel.vesselFuelType.key, VesselID],
    async () => {
      return await loadVesselFuelTypes(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Queries end */

  /**Initial values start */
  /** get specific mahinaries from vessel_machinery_list */
  const VesselMachineryList = (engine_type: any) => {
    const machinary: number[] = [];
    VesselMachinery?.forEach((machineObj: any) => {
      if (machineObj.precedence_id === engine_type) {
        machinary.push(machineObj);
      }
    });

    return machinary;
  };
  /** Assign initial values to Engines formik object */
  const getMainEngineInitialValues = () => {
    let mainEngineArr: any[] = [];
    let mainEngineIds: any[] = [];
    const EngineListArr = VesselMachineryList(
      VesselMachineryConstant.MAIN_ENGINE
    );
    if (MainEngine?.length === EngineListArr.length) {
      return MainEngine;
    } else if (MainEngine?.length !== EngineListArr.length) {
      MainEngine?.forEach((engineObj) => {
        mainEngineArr.push(engineObj);
        mainEngineIds.push(engineObj.machinery_name);
      });
      EngineListArr.forEach((MachinaryObj: any) => {
        if (!mainEngineIds.includes(MachinaryObj.id)) {
          mainEngineArr.push({
            machinery: MachinaryObj.vessel_machinery_name,
            engine_type: "2_stroke",
            propeller_type: "controllable_pitch_propeller",
            engine_type_master: null,
            engine_type_name: null,
            mcr_kw: null,
            mcr_rpm: null,
            shaft_power: 0,
            propeller_pitch: null,
            eedi: 0,
            eexi: 0,
            vessel: VesselID,
            machinery_name: MachinaryObj.id,
            precedence_id: MachinaryObj.precedence_id,
          });
        }
      });
    }
    return mainEngineArr;
  };
  const getAuxillaryEngineInitialValues = () => {
    let AuxillaryEngineArr: any[] = [];
    let auxIds: any[] = [];
    const VesselMachineryListArr = VesselMachineryList(
      VesselMachineryConstant.AUXILIARY_ENGINE
    );
    if (AuxillaryEngine?.length === VesselMachineryListArr.length) {
      return AuxillaryEngine;
    } else if (AuxillaryEngine?.length !== VesselMachineryListArr.length) {
      AuxillaryEngine?.forEach((engineObj) => {
        AuxillaryEngineArr.push(engineObj);
        auxIds.push(engineObj.vessel_machinery_name);
      });
      VesselMachineryListArr.forEach((MachinaryObj: any) => {
        if (!auxIds.includes(MachinaryObj.id)) {
          AuxillaryEngineArr.push({
            vessel_machinery: MachinaryObj.vessel_machinery_name,
            power_output_rating: 0,
            eedi: 0,
            rated_voltage: 0,
            rated_frequency: 0,
            rated_rpm: 0,
            alternator_power_rating: 0,
            vessel: VesselID,
            vessel_machinery_name: MachinaryObj.id,
            engine_type_name: null,
            engine_type: null,
          });
        }
      });
    }
    return AuxillaryEngineArr;
  };
  /**Inital values end */

  const tabName = 'Engines';
  const handleFormikStateChange = (values: any, initialValues: any) => {
    const areValuesEqual = JSON.stringify(values) === JSON.stringify(initialValues);
    setCheckValuesBeforeSwitch(areValuesEqual);
    setTabName(tabName);
    return areValuesEqual;
  };

  /** useEffect */
  useEffect(() => {
    EngineFormik.initialValues.mainEngine =
      getMainEngineInitialValues();
    EngineFormik.initialValues.auxillaryEngine =
      getAuxillaryEngineInitialValues();
    setFormRefreshKey(formRefreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuxillaryEngine, VesselMachinery]);
  useEffect(() => {
    let arr: any[] = [];
    if (VesselFuelTypes && VesselFuelTypes?.length > 0) {
      VesselFuelTypes?.forEach((fuelType: { [key: string]: string | number | Date | boolean }) => {
        arr.push(fuelType?.precedence_id);
      })
      setVesselFuelIds(arr);
    }
  }, [VesselFuelTypes, VesselID]);
  /** useEffect end */

  // General Settings Formik Object
  const EngineFormik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      mainEngine: getMainEngineInitialValues(),
      auxillaryEngine: getAuxillaryEngineInitialValues(),
    },
    validationSchema: Yup.object().shape({
      mainEngine: Yup.array(
        Yup.object().shape({
          engine_type: Yup.string().oneOf([
            "2_stroke",
            "4_stroke",
            "steam_turbine_type",
          ]),
          propeller_type: Yup.string().oneOf([
            "controllable_pitch_propeller",
            "fixed_pitch_propeller",
          ]),
          shaft_power: Yup.number()
            .min(0, "Shaft power must be a positive number")
            .nullable(),
          propeller_pitch: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{1,10})?$/,
              commonValidationMessages.before5after10
            )
            .when("propeller_type", {
              is: "fixed_pitch_propeller",
              then: (schema: any) =>
                schema.required(commonValidationMessages.required),
              otherwise: (schema: any) => schema.nullable(),
            }),
          eedi: Yup.string()
            .matches(
              /^\d{1,4}(\.\d{0,2})?$/,
              commonValidationMessages.before4after2
            )
            .nullable(),
          eexi: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,2})?$/,
              commonValidationMessages.before5after2
            )
            .nullable(),
        })
      ),
      auxillaryEngine: Yup.array(
        Yup.object().shape({
          power_output_rating: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,2})?$/,
              commonValidationMessages.before5after2
            )
            .required(commonValidationMessages.required),
          rated_voltage: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,2})?$/,
              commonValidationMessages.before5after2
            )
            .nullable(),
          rated_frequency: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,2})?$/,
              commonValidationMessages.before5after2
            )
            .nullable(),
          rated_rpm: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,2})?$/,
              commonValidationMessages.before5after2
            )
            .nullable(),
          alternator_power_rating: Yup.string()
            .matches(
              /^\d{1,5}(\.\d{0,2})?$/,
              commonValidationMessages.before5after2
            )
            .nullable(),
          engine_type: Yup.number()
            .when(
              "$fieldVisibility",
              (fieldVisibility: any, schema) => {
                return (!vesselFuelIds?.includes(FuelTypes.LNG_BUNKER) && !vesselFuelIds?.includes(FuelTypes.LNG_CARGO))
                  ? schema.nullable()
                  : schema.required(commonValidationMessages.required);
              }
            ),
        })
      ),
    }),
    onSubmit: async () => {
      let responseArray: any[] = [];
      let data: any;
      if (EngineFormik?.values?.mainEngine) {
        EngineFormik?.values?.mainEngine.forEach(async (item: any) => {
          if (item.id) {
            responseArray.push(apiGlobal.put(`/main_engine_setting/${item.id}/`, item));
          } else {
            responseArray.push(apiGlobal.post(`/main_engine_setting/`, item));
          }
        })
      }
      if (EngineFormik?.values?.auxillaryEngine) {
        EngineFormik?.values?.auxillaryEngine.forEach(async (item: any) => {
          if (item.id) {
            responseArray.push(apiGlobal.put(`/auxillary_machine_setting/${item.id}/`, item));
          } else {
            responseArray.push(apiGlobal.post(`/auxillary_machine_setting/`, item));
          }
        })
      }
      /** handle server response */
      await handleServerResponse(responseArray).then(async (res) => {
        if (res) {
          await queryClient.invalidateQueries(queryKeyes.vessel.AuxillaryMachineSetting.key);
          await queryClient.invalidateQueries(queryKeyes.vessel.MainEngineSetting.key);
          setRefreshVesselMachineries(refreshVesselMachineries + 1);
        } else {
          setMainEngineSpinner(false)
          setAuxEngineSpinner(false)
        }
      });
      if (await data) {
        return await data;
      }
      setMainEngineSpinner(false)
      queryClient.invalidateQueries(queryKeyes.vessel.MainEngineSetting.key);
      queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
      queryClient.invalidateQueries(queryKeyes.vessel.AuxillaryMachineSetting.key);
      queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
      queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
      setRefreshVesselMachineries(refreshVesselMachineries + 1);
    }
  });

  // Attach the `formik` instance to the parent via the `engineFormRef`
  //  if (engineFormRef) {
  //   engineFormRef.current = EngineFormik; // Set the formik instance
  //   console.log(engineFormRef.current);
  //   console.log(EngineFormik);
  //   console.log(JSON.stringify(EngineFormik.values) === JSON.stringify(EngineFormik.initialValues))
  //     // engineFormRef.current.submitForm();
  // }

  useEffect(() => {
    EngineFormik.initialValues.mainEngine = getMainEngineInitialValues();
    EngineFormik.values.mainEngine = getMainEngineInitialValues();
    EngineFormik.initialValues.auxillaryEngine = getAuxillaryEngineInitialValues();
    EngineFormik.values.auxillaryEngine = getAuxillaryEngineInitialValues();
    setRefreshVesselMachineries(refreshVesselMachineries + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VesselFuelTypes, FuelTypes, VesselID])

  const handleEngineSubmit = async (values: any, url: any, engineType: any, errors: any) => {
    let responseArray: any[] = [];
    let data: any;
    if (!errors) {
      if (values?.id) {
        data = apiGlobal.put(`/${url}/${values.id}/`, values)
        responseArray.push(data);
      } else {
        data = apiGlobal.post(`/${url}/`, values)
        responseArray.push(data);
      }
      /** handle server response */
      await handleServerResponse(responseArray).then(async (res) => {
        if (res) {
          if (engineType === "AuxillaryEngine") {
            await queryClient.invalidateQueries(queryKeyes.vessel.AuxillaryMachineSetting.key);
          } else {
            await queryClient.invalidateQueries(queryKeyes.vessel.MainEngineSetting.key);
          }
          setRefreshVesselMachineries(refreshVesselMachineries + 1);
        } else {
          setMainEngineSpinner(false)
          setAuxEngineSpinner(false)
        }
      });
    }
    if (await data) {
      return await data;
    }
    setMainEngineSpinner(false)
  };

  useEffect(() => {
    handleFormikStateChange(EngineFormik?.values, EngineFormik?.initialValues);
    if (engineFormRef) {
      engineFormRef.current = EngineFormik;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EngineFormik?.values, EngineFormik?.initialValues]);

  // useEffect(() => {
  //   console.log("EngineFormik")
  //   if (VesselConfActiveTab === VesselConfigrationTabs.ENGINES && formik !== EngineFormik) {
  //     console.log("EngineFormik");
  //     setFormik(EngineFormik);
  //   }
  //   console.log("hit ")
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [VesselConfActiveTab, EngineFormik]);
  useEffect(() => {
          if (VesselConfActiveTab === VesselConfigrationTabs.ENGINES && EngineFormik?.initialValues !== EngineFormik?.values ) {
              setFormik(EngineFormik); // Example: Assign formik instance for the "Other" tab
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [VesselConfActiveTab,EngineFormik]);

  useEffect(() => {
    // if (vesselDataTabId === VesselConfigrationTabs.VESSEL_DATA && activeTab !== "1") {
    //   EngineFormik??.setTouched({})
    // }
    if (VesselConfActiveTab !== VesselConfigrationTabs.ENGINES) {
      EngineFormik?.setTouched([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VesselConfActiveTab]);

  if (MainEngineLoading || AuxillaryEngineLoading || VesselMachineryLoading || VesselFuelTypesLoading) {
    return <Loading message='Loading required data!' />
  }
  if (VesselMachineryError) {
    return <ErrorComponent message='Error loading required data!' />
  } else {
    return (
      <React.Fragment>
        <Row className="mb-2">
          <Col sm={{ offset: 10, size: 2 }}>
            <Button
              type="button"
              color='primary'
              className='btn btn-primary waves-effect btn-label waves-light justify_right'
              onClick={() => {
                queryClient.invalidateQueries(queryKeyes.vessel.MainEngineSetting.key);
                queryClient.invalidateQueries(queryKeyes.vessel.VesselMachineries.key);
                queryClient.invalidateQueries(queryKeyes.vessel.AuxillaryMachineSetting.key);
                queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
                queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
                setRefreshVesselMachineries(refreshVesselMachineries + 1);
              }}
            >
              <i className="mdi mdi-reload label-icon me-1" />Refresh
            </Button>
          </Col>
        </Row>
        {(AuxillaryEngineLoading ||
          VesselMachineryLoading ||
          MainEngineLoading ||
          VesselFuelTypesLoading) && <Loading message="Loading required data!" />}
        {VesselMachineryError && (
          <ErrorComponent message="Error loading required data!" />
        )}
        {!(
          AuxillaryEngineLoading ||
          VesselMachineryLoading ||
          MainEngineLoading ||
          VesselFuelTypesLoading
        ) &&
          !VesselMachineryError &&
          <Form
            onSubmit={EngineFormik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <React.Fragment>
              <Card className='p-0'>
                <CardHeader className="p-2">
                  <h4 className="mb-0">Main Engines</h4>
                </CardHeader>
                {/* Handle Main engine form */}
                {EngineFormik?.values?.mainEngine &&
                  EngineFormik?.values?.mainEngine.map(
                    (engine: any, index: number) =>
                    (<React.Fragment>
                      <Card className='mb-0' key={refreshVesselMachineries}>
                        <CardHeader className="p-2">
                          <h5 className="mb-0">
                            {engine.machinery}
                          </h5>
                        </CardHeader>
                        <CardBody className="p-2">
                          {EngineFormik?.values?.mainEngine[index]
                            ?.precedence_id !==
                            VesselMachineryConstant.HPPED && (
                              <Row>
                                <Col sm={5}>
                                  <Label className="bold_lbl asteric">Engine Type</Label>
                                  <div className="ele_row1">
                                    {/* 2-Stroke Option */}
                                    <div className="form-check">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name={`mainEngine.${index}.engine_type`}
                                        id={`stroke2_${index}`}
                                        value="2_stroke"
                                        checked={
                                          EngineFormik?.values?.mainEngine[index]?.engine_type === "2_stroke"
                                        }
                                        onChange={() => {
                                          EngineFormik.setFieldValue(
                                            `mainEngine.${index}.engine_type`,
                                            "2_stroke"
                                          );
                                        }}
                                      />
                                      <Label className="form-check-label mr-1" htmlFor={`stroke2_${index}`}>
                                        2 stroke
                                      </Label>
                                    </div>

                                    {/* 4-Stroke Option */}
                                    <div className="form-check">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name={`mainEngine.${index}.engine_type`}
                                        id={`stroke4_${index}`}
                                        value="4_stroke"
                                        checked={
                                          EngineFormik?.values?.mainEngine[index]?.engine_type === "4_stroke"
                                        }
                                        onChange={() => {
                                          EngineFormik.setFieldValue(
                                            `mainEngine.${index}.engine_type`,
                                            "4_stroke"
                                          );
                                        }}
                                      />
                                      <Label htmlFor={`stroke4_${index}`}>4 stroke</Label>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={3}>
                                  <Label className="mb-0">MCR</Label>
                                  <div className="ele_row1">
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        name={`mainEngine.${index}.mcr_kw`}
                                        id={`mainEngine.${index}.mcr_kw`}
                                        className="form-control text-right"
                                        value={EngineFormik?.values?.mainEngine[index]?.mcr_kw || ""}
                                        onChange={(e) => {
                                          const fieldName = `mainEngine.${index}.mcr_kw`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      <div className="input-group-text small-unit-size">kW</div>
                                    </div>
                                    <p className="mb-0 mx-2 mt-2">@</p>
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        name={`mainEngine.${index}.mcr_rpm`}
                                        id={`mainEngine.${index}.mcr_rpm`}
                                        className="form-control text-right"
                                        value={EngineFormik?.values?.mainEngine[index]?.mcr_rpm || ""}
                                        onChange={(e) => {
                                          const fieldName = `mainEngine.${index}.mcr_rpm`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      <div className="input-group-text small-unit-size">rpm</div>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div className="ele_row1">
                                    <div>
                                      <Label className="mb-0">EEDI</Label>
                                      <div className="input-group mb-3">
                                        <Input
                                          type="text"
                                          className="form-control max-width-7 text-right"
                                          name={`mainEngine.${index}.eedi`}
                                          value={EngineFormik?.values?.mainEngine[index]?.eedi || ""}
                                          onChange={(e) => {
                                            const fieldName = `mainEngine.${index}.eedi`;
                                            checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                          }}
                                        />
                                        <div className="input-group-text round_border small-unit-size">
                                          gCO<sub>2</sub>/ton-mile
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ms-3">
                                      <Label className="mb-0">EEXI</Label>
                                      <div className="input-group mb-3">
                                        <Input
                                          type="text"
                                          className="form-control max-width-7 text-right"
                                          name={`mainEngine.${index}.eexi`}
                                          value={EngineFormik?.values?.mainEngine[index]?.eexi || ""}
                                          onChange={(e) => {
                                            const fieldName = `mainEngine.${index}.eexi`;
                                            checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                          }}
                                        />
                                        <div className="input-group-text round_border small-unit-size">
                                          gCO<sub>2</sub>/ton-mile
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>

                              </Row>
                            )}
                          <Row>
                            {EngineFormik?.values?.mainEngine[index]
                              ?.precedence_id !==
                              VesselMachineryConstant.HPPED && (
                                <Col sm={5}>
                                  <div className="ele_row1">
                                    {/* Propeller Type Label */}
                                    <div>
                                      <Label className="bold_lbl mb-2 asteric">Propeller Type</Label>

                                      {/* Controllable Pitch Propeller */}
                                      <div className="form-check">
                                        <Input
                                          name={`mainEngine.${index}.propeller_type`}
                                          className="form-check-input"
                                          type="radio"
                                          id={`ccpp_${index}`}
                                          value="controllable_pitch_propeller"
                                          checked={
                                            EngineFormik?.values?.mainEngine[index]?.propeller_type ===
                                            "controllable_pitch_propeller"
                                          }
                                          onChange={() =>
                                            EngineFormik.setFieldValue(
                                              `mainEngine.${index}.propeller_type`,
                                              "controllable_pitch_propeller"
                                            )
                                          }
                                        />
                                        <Label
                                          className="form-check-label mr-1"
                                          htmlFor={`ccpp_${index}`}
                                        >
                                          Controllable pitch propeller
                                        </Label>
                                      </div>
                                    </div>

                                    {/* Fixed Pitch Propeller */}
                                    <div className="form-check d-flex align-items-end">
                                      <Input
                                        className="form-check-input mb-1"
                                        type="radio"
                                        name={`mainEngine.${index}.propeller_type`}
                                        id={`fpp_${index}`}
                                        value="fixed_pitch_propeller"
                                        checked={
                                          EngineFormik?.values?.mainEngine[index]?.propeller_type ===
                                          "fixed_pitch_propeller"
                                        }
                                        onChange={() =>
                                          EngineFormik.setFieldValue(
                                            `mainEngine.${index}.propeller_type`,
                                            "fixed_pitch_propeller"
                                          )
                                        }
                                      />
                                      <Label
                                        className="form-check-label ms-2"
                                        htmlFor={`fpp_${index}`}
                                      >
                                        Fixed pitch propeller
                                      </Label>
                                    </div>
                                  </div>
                                </Col>

                              )}
                            <Col sm={7} className="">
                              <div className="ele_row1">
                                {EngineFormik?.values?.mainEngine[index]?.precedence_id !==
                                  VesselMachineryConstant.HPPED && (
                                    <React.Fragment>
                                      <div>
                                        {EngineFormik?.values?.mainEngine[index]?.propeller_type ===
                                          "fixed_pitch_propeller" && (
                                            <React.Fragment>
                                              <Label
                                                className={`mb-0 ${EngineFormik?.values?.mainEngine[index]?.propeller_type ===
                                                  "fixed_pitch_propeller" && "asteric"
                                                  }`}
                                              >
                                                Pitch
                                              </Label>
                                              <div className="input-group mb-3">
                                                <Input
                                                  type="text"
                                                  className="form-control max-width-7 text-right"
                                                  name={`mainEngine.${index}.propeller_pitch`}
                                                  value={
                                                    EngineFormik?.values?.mainEngine[index]
                                                      ?.propeller_pitch || ""
                                                  }
                                                  onChange={(e) => {
                                                    EngineFormik.setFieldValue(
                                                      `mainEngine.${index}.propeller_pitch`,
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <div className="input-group-text round_border">m</div>
                                              </div>
                                            </React.Fragment>
                                          )}
                                      </div>
                                      <div
                                        className={`${EngineFormik?.values?.mainEngine[index]?.propeller_type ===
                                          "fixed_pitch_propeller" && "ms-3"
                                          }`}
                                      >
                                        <Label className="mb-0">Shaft Power</Label>
                                        <div className="input-group mb-3">
                                          <Input
                                            type="text"
                                            className="form-control max-width-7 text-right"
                                            name={`mainEngine.${index}.shaft_power`}
                                            value={
                                              EngineFormik?.values?.mainEngine[index]?.shaft_power || ""
                                            }
                                            onChange={(e) => {
                                              EngineFormik.setFieldValue(
                                                `mainEngine.${index}.shaft_power`,
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <div className="input-group-text round_border">kW</div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                <div className="ms-3">
                                  <Label className="mb-0" htmlFor={`engine_type_master_${index}`}>
                                    Engine Category
                                  </Label>
                                  <AsyncSelect
                                    key={refreshKey}
                                    name={`mainEngine.${index}.engine_type_master`}
                                    inputId={`engine_type_master_${index}`}
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={() =>
                                      loadEngineTypes(
                                        VesselID,
                                        EngineFormik?.values?.mainEngine[index]?.engine_type
                                      )
                                    }
                                    getOptionLabel={(e: any) => e.name}
                                    getOptionValue={(e: any) => e.id}
                                    onChange={(e: any) => {
                                      EngineFormik.setFieldValue(
                                        `mainEngine.${index}.engine_type_master`,
                                        e?.id
                                      );
                                      EngineFormik.setFieldValue(
                                        `mainEngine.${index}.engine_type_name`,
                                        e?.name
                                      );
                                    }}
                                    defaultValue={
                                      EngineFormik?.values?.mainEngine[index]?.engine_type_master
                                        ? {
                                          id: EngineFormik?.values?.mainEngine[index]?.engine_type_master,
                                          name: EngineFormik?.values?.mainEngine[index]?.engine_type_name,
                                        }
                                        : null
                                    }
                                    styles={customStyle}
                                    className="select-height min-width-9-5"
                                  />
                                </div>
                              </div>
                            </Col>

                          </Row>
                        </CardBody>
                        <CardFooter className="p-2">
                          <Button
                            type="button"
                            color="primary"
                            className="justify_right "
                            onClick={async () => {
                              setSpinnerIndex(index)
                              setMainEngineSpinner(true);
                              const error = EngineFormik?.errors?.mainEngine?.[index];
                              const res: any = await handleEngineSubmit(
                                EngineFormik?.values?.mainEngine[index],
                                "main_engine_setting",
                                "MainEngine",
                                error
                              );
                              if (res && res?.data) {
                                // Update the specific index in auxillaryEngine array
                                const updatedMainEngine = [...EngineFormik.values.mainEngine]; // Create a copy to preserve immutability
                                updatedMainEngine[index] = res.data; // Update the specific index with the response
                                // Use setFieldValue to update Formik state
                                EngineFormik.setFieldValue('MainEngine', updatedMainEngine);
                              }
                              setMainEngineSpinner(false);
                            }}
                            disabled={isConfigurationButtonDisabled(vessel) || index === spinnerIndex && mainEngineSpinner}
                          >
                            {mainEngineSpinner &&
                              index === spinnerIndex &&
                              <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />} Save
                          </Button>
                        </CardFooter>
                      </Card>
                    </React.Fragment>
                    )
                  )}
              </Card>
              <Card>
                <CardHeader className="p-2">
                  <h4 className="mb-0">Auxiliary Engine</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    {EngineFormik?.values?.auxillaryEngine &&
                      EngineFormik?.values?.auxillaryEngine.map(
                        (auxEngine: any, index: number) => (
                          <Col md="6" key={index}>
                            <Card key={refreshVesselMachineries}>
                              <CardHeader className="p-2">
                                <h5 className="mb-0">
                                  {auxEngine.vessel_machinery}
                                </h5>
                              </CardHeader>
                              <CardBody className="p-2">
                                <Row>
                                  <Col>
                                    {/* Power Output Rating */}
                                    <Label className="asteric mb-0">Power output rating</Label>
                                    <div className="input-group mb-3">
                                      <Input
                                        type="text"
                                        id={`power_output_rating_${index}`}
                                        className="form-control max-width-7 text-right"
                                        name={`auxillaryEngine.${index}.power_output_rating`}
                                        value={EngineFormik?.values?.auxillaryEngine?.[index]?.power_output_rating || ""}
                                        onChange={(e) => {
                                          const fieldName = `auxillaryEngine.${index}.power_output_rating`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      {EngineFormik?.errors?.auxillaryEngine?.[index]?.power_output_rating &&
                                        env?.form_validation &&
                                        EngineFormik?.touched?.auxillaryEngine?.[index]?.power_output_rating && (
                                          <ErrorTooltip
                                            target={`power_output_rating_${index}`}
                                            message={EngineFormik?.errors?.auxillaryEngine?.[index]?.power_output_rating}
                                            open={!!EngineFormik?.errors?.auxillaryEngine?.[index]?.power_output_rating}
                                          />
                                        )}
                                      <div className="input-group-text round_border">kW</div>
                                    </div>

                                    {/* Rated Frequency */}
                                    <Label className="mb-0">Rated frequency</Label>
                                    <div className="input-group mb-3">
                                      <Input
                                        type="text"
                                        id={`rated_frequency_${index}`}
                                        className="form-control max-width-7 text-right"
                                        name={`auxillaryEngine.${index}.rated_frequency`}
                                        value={EngineFormik?.values?.auxillaryEngine?.[index]?.rated_frequency || ""}
                                        onChange={(e) => {
                                          const fieldName = `auxillaryEngine.${index}.rated_frequency`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      {EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_frequency &&
                                        env?.form_validation &&
                                        EngineFormik?.touched?.auxillaryEngine?.[index]?.rated_frequency && (
                                          <ErrorTooltip
                                            target={`rated_frequency_${index}`}
                                            message={EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_frequency}
                                            open={!!EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_frequency}
                                          />
                                        )}
                                      <div className="input-group-text round_border">Hz</div>
                                    </div>

                                    {/* Alternator Power Rating */}
                                    <Label className="mb-0">Alternator power rating</Label>
                                    <div className="input-group mb-3">
                                      <Input
                                        type="text"
                                        id={`alternator_power_rating_${index}`}
                                        className="form-control max-width-7 text-right"
                                        name={`auxillaryEngine.${index}.alternator_power_rating`}
                                        value={EngineFormik?.values?.auxillaryEngine?.[index]?.alternator_power_rating || ""}
                                        onChange={(e) => {
                                          const fieldName = `auxillaryEngine.${index}.alternator_power_rating`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      {EngineFormik?.errors?.auxillaryEngine?.[index]?.alternator_power_rating &&
                                        env?.form_validation &&
                                        EngineFormik?.touched?.auxillaryEngine?.[index]?.alternator_power_rating && (
                                          <ErrorTooltip
                                            target={`alternator_power_rating_${index}`}
                                            message={EngineFormik?.errors?.auxillaryEngine?.[index]?.alternator_power_rating}
                                            open={!!EngineFormik?.errors?.auxillaryEngine?.[index]?.alternator_power_rating}
                                          />
                                        )}
                                      <div className="input-group-text round_border">kVA</div>
                                    </div>
                                  </Col>

                                  <Col>
                                    {/* Rated Voltage */}
                                    <Label className="mb-0">Rated voltage</Label>
                                    <div className="input-group mb-3">
                                      <Input
                                        type="text"
                                        id={`rated_voltage_${index}`}
                                        className="form-control max-width-7 text-right"
                                        name={`auxillaryEngine.${index}.rated_voltage`}
                                        value={EngineFormik?.values?.auxillaryEngine?.[index]?.rated_voltage || ""}
                                        onChange={(e) => {
                                          const fieldName = `auxillaryEngine.${index}.rated_voltage`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      {EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_voltage &&
                                        env?.form_validation &&
                                        EngineFormik?.touched?.auxillaryEngine?.[index]?.rated_voltage && (
                                          <ErrorTooltip
                                            target={`rated_voltage_${index}`}
                                            message={EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_voltage}
                                            open={!!EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_voltage}
                                          />
                                        )}
                                      <div className="input-group-text round_border">V</div>
                                    </div>

                                    {/* Rated RPM */}
                                    <Label className="mb-0">Rated RPM</Label>
                                    <div className="input-group mb-3">
                                      <Input
                                        type="text"
                                        id={`rated_rpm_${index}`}
                                        className="form-control max-width-7 text-right"
                                        name={`auxillaryEngine.${index}.rated_rpm`}
                                        value={EngineFormik?.values?.auxillaryEngine?.[index]?.rated_rpm || ""}
                                        onChange={(e) => {
                                          const fieldName = `auxillaryEngine.${index}.rated_rpm`;
                                          checkEmptyStringValue(e, fieldName, EngineFormik?.setFieldValue);
                                        }}
                                      />
                                      {EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_rpm &&
                                        env?.form_validation &&
                                        EngineFormik?.touched?.auxillaryEngine?.[index]?.rated_rpm && (
                                          <ErrorTooltip
                                            target={`rated_rpm_${index}`}
                                            message={EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_rpm}
                                            open={!!EngineFormik?.errors?.auxillaryEngine?.[index]?.rated_rpm}
                                          />
                                        )}
                                    </div>

                                    {/* Engine Category */}
                                    <React.Fragment>
                                      <Label
                                        className={`mb-0 ${(vesselFuelIds?.includes(FuelTypes.LNG_BUNKER) ||
                                          vesselFuelIds?.includes(FuelTypes.LNG_BUNKER)) &&
                                          "asteric"
                                          }`}
                                        for={`engine_type_${index}`}
                                      >
                                        Engine Category
                                      </Label>
                                      <AsyncSelect
                                        name={`auxillaryEngine.${index}.engine_type`}
                                        inputId={`engine_type_${index}`}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={() => loadEngineTypes(VesselID)}
                                        getOptionLabel={(e: any) => e.name}
                                        getOptionValue={(e) => e.id}
                                        onChange={(e: any) => {
                                          EngineFormik?.setFieldValue(
                                            `auxillaryEngine.${index}.engine_type`,
                                            e?.id
                                          );
                                          EngineFormik?.setFieldValue(
                                            `auxillaryEngine.${index}.engine_type_name`,
                                            e?.name
                                          );
                                        }}
                                        defaultValue={
                                          AuxillaryEngine?.length > 0 && {
                                            id: EngineFormik?.values?.auxillaryEngine?.[index]?.engine_type,
                                            name: EngineFormik?.values?.auxillaryEngine?.[index]?.engine_type_name,
                                          }
                                        }
                                        styles={customStyle}
                                        className="select-height max-width-13"
                                      />
                                      {EngineFormik?.errors?.auxillaryEngine?.[index]?.engine_type &&
                                        env?.form_validation &&
                                        EngineFormik?.touched?.auxillaryEngine?.[index]?.engine_type && (
                                          <ErrorTooltip
                                            target={`engine_type_${index}`}
                                            message={EngineFormik?.errors?.auxillaryEngine?.[index]?.engine_type}
                                            open={!!EngineFormik?.errors?.auxillaryEngine?.[index]?.engine_type}
                                          />
                                        )}
                                    </React.Fragment>
                                  </Col>
                                </Row>
                              </CardBody>
                              <CardFooter className="p-2">
                                <Button
                                  type="button"
                                  color="primary"
                                  className="justify_right "
                                  onClick={async () => {
                                    setSpinnerIndex(index)
                                    setAuxEngineSpinner(true);
                                    const error = EngineFormik?.errors?.AuxillaryEngine?.[index];
                                    const res: any = await handleEngineSubmit(
                                      EngineFormik?.values
                                        ?.auxillaryEngine[index],
                                      "auxillary_machine_setting",
                                      "AuxillaryEngine",
                                      error
                                    );
                                    if (res && res?.data) {
                                      // Update the specific index in auxillaryEngine array
                                      const updatedAuxillaryEngine = [...EngineFormik.values.auxillaryEngine]; // Create a copy to preserve immutability
                                      updatedAuxillaryEngine[index] = res.data; // Update the specific index with the response
                                      // Use setFieldValue to update Formik state
                                      EngineFormik.setFieldValue('auxillaryEngine', updatedAuxillaryEngine);
                                    }
                                    setAuxEngineSpinner(false);
                                  }}
                                  disabled={isConfigurationButtonDisabled(vessel) || index === spinnerIndex && auxEngineSpinner}
                                >
                                  {auxEngineSpinner && index === spinnerIndex && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}Save
                                </Button>
                              </CardFooter>
                            </Card>
                          </Col>
                        )
                      )}
                  </Row>
                </CardBody>
              </Card>
            </React.Fragment>
            <Row className="mt-2">
              <FormValuesDebug
                values={[
                  EngineFormik?.values,
                  EngineFormik?.errors,
                  EngineFormik.initialValues,
                ]}
              />
            </Row>
          </Form>
        }
        <p>- Set pre-defined engine parameters as per EEDI/EEXI Technical files.</p>
      </React.Fragment>
    )
  };
};
export default Engines;
