import React, { useEffect } from 'react';
import { Popover, PopoverBody, Button } from 'reactstrap';

const DeletePopOver = ({
    target,
    onClick,
    state,
    setState,
    message
}: any) => {
    /** Open close popover */
    function handlePopoverState() {
        setState(!state);
    }
    /**
     * set the popover state to false when clicking outside the popover
     * @param {MouseEvent} event
     */
    function handleClickOutside(event: MouseEvent) {
        const popoverElement = document.getElementById(target);
        const clickedElement = event.target as HTMLElement;

        if (popoverElement && !popoverElement.contains(clickedElement)) {
            setState(false);
        }
    }

    //set the proper event listners for the popover state management 
    useEffect(() => {
        if (state) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <Popover
            placement="top"
            isOpen={state}
            target={target}
            toggle={() => {
                setState(!state);
            }}
        >
            <PopoverBody>
                {message ? <p>{message}</p> : <p>Are you sure you want to delete this record?</p>}
                <Button
                    type="button"
                    color="danger"
                    className="mr-2 mt-3 popover_btn"
                    onClick={onClick}
                    onMouseEnter={() => document.removeEventListener('mousedown', handleClickOutside)}
                    onMouseLeave={() => document.addEventListener('mousedown', handleClickOutside)}
                >
                    Yes
                </Button>
                <Button
                    type="button"
                    color="primary"
                    className="mt-3"
                    onClick={() => handlePopoverState()}
                >
                    No
                </Button>
            </PopoverBody>
        </Popover>
    );
};

export default DeletePopOver;
