import React, { useState } from 'react';
import { Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import '../../../global/GlobalCSS.css';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadPendingCIIFileUpload } from 'VesselMaster/vesselMaster.hooks';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { apiMedia } from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { errResponse, filterUniqueByFields } from 'GenericForms/Helper';
import { errorToast, successToast } from 'Components/Toasts';
import { FileStatus } from 'shared/constants';
import ReportingFileUpload from 'Components/ReportingFileUpload';

const CIIPendingFiles = () => {
    /** State variables */
    const [modalState, setModalState] = useState(false);
    const [fileObject, setFileObject] = useState<any>({});
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [CIIFile, setCIIFiles] = useState<any>([{}]);
    /** State variables end */

    /** Queries */
    /** CII adjustment event's files that are not yet uploaded  */
    const { data: fileUploadingObj, isLoading: fileUploadingObjLoading, isError: fileUploadingObjError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.PendingCIIFileUpload.key, VesselID, FileStatus.PENDING],
            async () => {
                return await loadPendingCIIFileUpload(VesselID, FileStatus.PENDING);
            },
        );

    /** Queries end */
    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /**
     * set modal true to upload file
     * set current object to setFileObject
     * @param file 
     * @param obj 
     */
    const handleUploadButtonClick = (obj: any) => {
        setModalState(!modalState);
        setFileObject(obj);
    }

    /** Filter out unqiue records by cii_begin_date_time, cii_end_date_time, cii_adjustment_events_document */
    const filtered = fileUploadingObj && filterUniqueByFields(fileUploadingObj, ['cii_begin_date_time', 'cii_end_date_time', 'cii_adjustment_events_document'])

    /** to upload CII Files */
    const PendingFileUploadSubmit = async () => {
        if (fileObject && CIIFile && CIIFile.length > 0) {
            fileObject.vessel_reporting_cii_adjustment_supporting_file_name = CIIFile[0].file
            await apiMedia.put(`/vessel_reporting_cii_adjustment_supporting_file/${fileObject.id}/`, fileObject)
                .then(res => {
                    if (res.status === 200) {
                        setModalState(!modalState);
                        setCIIFiles([{}])
                        successToast("Success! Files uploaded!");
                        queryClient.invalidateQueries(queryKeyes.vessel.PendingCIIFileUpload.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                })
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {fileUploadingObjLoading && <Loading message='Loading required data!' />}
                    {fileUploadingObjError && <ErrorComponent message='Unable to load required data!' />}
                </Col>
            </Row>
            {!fileUploadingObjLoading && !fileUploadingObjError &&
                <React.Fragment>
                    <CardBody className='p-0 pb-0 mt-2'>
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        <th className='p-2 align-middle' style={{ width: '22%' }}>Duration</th>
                                        <th className='p-2 align-middle'>CII Adjustment event</th>
                                        <th className='p-2 align-middle'>Document</th>
                                        <th className='p-2 align-middle text-center' style={{ width: '15%' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtered && filtered.length === 0 ?
                                        <tr>
                                            <td colSpan={5} className='align-middle text-center'>
                                                No pending files to upload.
                                            </td>
                                        </tr>
                                        : filtered && filtered.map((file: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='align-middle p-2 text-center'>{index + 1}</td>
                                                    <td className={`align-middle p-2
                                                        ${file?.cii_begin_date_time === null && file?.cii_end_date_time === null ? 'text-center' : null}`}>
                                                        {file?.cii_begin_date_time !== null && file?.cii_end_date_time !== null ?
                                                            `${file?.cii_begin_date_time?.toString()?.substring(0, 10)} 
                                                            ${file?.cii_begin_date_time?.toString().substring(12, 16)} - 
                                                            ${file?.cii_end_date_time?.toString().substring(0, 10)}
                                                                ${file?.cii_end_date_time?.toString().substring(12, 16)}` :
                                                            file?.cii_begin_date_time === null && file?.cii_end_date_time !== null ?
                                                                `Begin date time - 
                                                            ${file?.cii_end_date_time?.toString().substring(0, 10)}
                                                                ${file?.cii_end_date_time?.toString().substring(12, 16)}` :
                                                                file?.cii_begin_date_time !== null && file?.cii_end_date_time === null ?
                                                                    `${file?.cii_begin_date_time?.toString()?.substring(0, 10)} 
                                                            ${file?.cii_begin_date_time?.toString().substring(12, 16)} - 
                                                            End date time` : "---"
                                                        }
                                                    </td>
                                                    <td className='align-middle p-2'>{file.cii_adjustment_name}</td>
                                                    <td className='align-middle p-0' colSpan={2}>
                                                        <table className='table-bordered width-100'>
                                                            <tbody>
                                                                {fileUploadingObj?.filter((item: any) =>
                                                                    item.cii_begin_date_time === file.cii_begin_date_time &&
                                                                    item.cii_end_date_time === file.cii_end_date_time &&
                                                                    item.cii_adjustment_events_document === file.cii_adjustment_events_document)
                                                                    .map((field: any) =>
                                                                        <tr>
                                                                            <td className='align-middle p-2' style={{ width: '70%' }}>{field.cii_document_name}</td>
                                                                            <td className='align-middle p-2 text-center'>
                                                                                <Button
                                                                                    type="button"
                                                                                    className='btn-sm'
                                                                                    color="primary"
                                                                                    onClick={() => handleUploadButtonClick(field)}>
                                                                                    Upload
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                    <Modal
                        size='md'
                        isOpen={modalState}
                        toggle={() => tog_backdrop()}
                        backdrop={"static"}
                        id="staticBackdrop"
                    >
                        <ModalHeader className='p-2'>Upload Pending CII Adjustment File
                            <button
                                onClick={async () => {
                                    setModalState(false);
                                    setCIIFiles([{}])
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </ModalHeader>
                        <ModalBody className='p-2'>
                            <ReportingFileUpload
                                setFile={setCIIFiles}
                                file={CIIFile}
                                index={0}
                                sm={5}
                            />
                            <div className="mt-1 mb-5">
                                <Button type="submit" color='primary' className='pos-end' onClick={() => {
                                    setModalState(false)
                                    PendingFileUploadSubmit()
                                }}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default CIIPendingFiles